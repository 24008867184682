* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.kBRaGg {
    background-color: rgb(236, 245, 254) !important;
    color: rgb(12, 108, 242) !important;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.mainea .footer {
    background-color: #0d0d2b !important;
}

.mainea .header::after {
    display: none !important;

}

.mainea .header,
.mainea {
    background-color: #0d0d2b !important;
}

.mainea {
    font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
        Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.vfy {
    background-color: #8b5cf6 !important;
    border: none !important;

    box-shadow: 0 0 20px 2px #8b5cf6 !important;
}

.eVUBqp {
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    z-index: 2;
    background-color: rgb(18, 29, 51);
    position: sticky;
    top: 0px;
}

.cQDlQl {
    box-sizing: border-box;
    margin: 0px auto;
    max-width: 80rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 0px 1rem;
    width: 100%;
    height: 4.75rem;
}

a {
    text-decoration: none;
    color: var(--grey900);
}

a {
    background-color: transparent;
}

.jPvoRr {
    flex-shrink: 0;
    margin-right: 1.5rem;
    color: rgb(255, 255, 255);
}

.bZhfok {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.ffonJJ {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
}

ul,
ol {
    padding: 0px;
    margin: 0px;
    list-style: none;
}

.kRTKLx {
    min-width: 4.75rem;
    margin-right: 1.25rem;
}


.Header__List-ra9ecu-8 a {
    color: rgb(204, 210, 222);
    transition: color 500ms ease 0s, background-color 500ms ease 0s;
    cursor: pointer;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    position: relative;
    width: 100%;
}

.kmtyGJ {
    font-weight: 500;
}

.vvWMe {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
}

a {
    text-decoration: none;
    color: var(--grey900);
}

a {
    background-color: transparent;
}

.duaFoJ {
    height: 100%;
    position: relative;
    cursor: pointer;
}

li {
    list-style: none;
}

.gdPmLE {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 100%;
    padding: 0px 0.75rem;
    margin-right: 0.5rem;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    appearance: button;
}

button,
select {
    text-transform: none;
}

.duaFoJ svg {
    border-radius: 4px;
    transition: background-color 0.3s ease 0s;
    color: rgb(255, 255, 255);
}

.duaFoJ .EllipsisMenu__MegaMenuContainer-s24ovs-1 {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

.INfLT {
    position: absolute;
    margin: 0px auto;
    top: 0px;
    left: -17.5rem;
    width: 35rem;
    padding-top: 3rem;
    z-index: 50;
}

.idhROZ {
    background-color: rgb(255, 255, 255);
    border-radius: 0.75rem;
    box-shadow: rgba(103, 113, 133, 0.4) 0.75rem 1.5rem 4rem;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 1.5rem;
}

.kJUygl {
    margin-right: 1.25rem;
    width: 8.125rem;
}

.INfLT::before {
    content: "";
    border-style: solid;
    border-width: 0px 0.75rem 0.75rem;
    border-color: transparent transparent rgb(255, 255, 255);
    position: absolute;
    top: -0.75rem;
    left: 18.25rem;
    margin: 0px auto;
    width: 1.5rem;
    padding-top: 3rem;
}

.gThmmq {
    color: rgb(18, 29, 51);
    margin-bottom: 1.25rem;
    text-transform: capitalize;
}

.hOyGHK {
    font-weight: 600;
}

.jdRAXE {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
}

p {
    line-height: 1.5;
    margin: 0px;
}

.lnAMCv {
    margin-bottom: 1rem;
}

.iksypf {
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: right 0.1875rem top 0.5625rem;
    border-radius: 0.25rem;
    color: rgb(103, 113, 133);
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0px -0.25rem;
    padding: 0.25rem 0.5rem;
    position: relative;
    transition: background 300ms ease 0s, color 300ms ease 0s,
        padding-right 300ms ease 0s;
    white-space: nowrap;
}

.lnAMCv:last-child {
    margin-bottom: 0px;
}

.kJUygl:last-child {
    margin-right: 0px;
}

.kvQKhY {
    width: 100%;
    -webkit-box-pack: end;
    justify-content: flex-end;
}

.bZhfok {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.kvQKhY>*+ :not(:last-child),
.kvQKhY> :first-child {
    margin-right: 1.25rem;
}

.eLyMAV {
    margin-left: auto;
    max-width: 22rem;
}

.ibLEuO {
    position: relative;
    height: 2.75rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

.ctBmkR {
    background: none rgba(255, 255, 255, 0.1);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    color: rgb(255, 255, 255);
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 2.75rem;
    transition: background-color 300ms ease-in-out 0s;
}

.imGUBn {
    background: none;
    border: none;
    padding: 0px;
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 2.75rem;
    position: absolute;
    top: 0px;
    pointer-events: none;
}

element.style {
    opacity: 0;
    visibility: visible;
    transform-origin: 50% 50% 0px;
}

.bOFsqz {
    height: 44px;
}

.hUelrf {
    font-size: 14px;
    width: auto;
    height: 44px;
    padding: 10px 14px;
}

.cxOGwh {
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 0.75rem 1.375rem;
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 0.01em;
    line-height: 144%;
    border-radius: 0.5rem;
    border: 0.125rem solid rgb(255, 255, 255);
    cursor: pointer;
    transition: all 0.3s ease 0s;
    white-space: nowrap;
    color: rgb(255, 255, 255);
    background: none;
}

.crgNqM {
    height: 44px;
}

.cDdOpj {
    width: auto;
    font-size: 14px;
    height: 44px;
    padding: 10px 14px;
}

.llgLia {
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 0.75rem 1.375rem;
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 0.01em;
    line-height: 144%;
    border-radius: 0.5rem;
    border: 0.125rem solid transparent;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    white-space: nowrap;
    color: rgb(18, 29, 51);
    background: rgb(255, 255, 255);
}

.dOAKcZ {
    height: 100%;
    position: relative;
    display: none;
    cursor: pointer;
}

.WziMa {
    padding: 0.5rem;
}

.ccraQD {
    appearance: none;
    background: none;
    border: none;
    height: 1.4rem;
    padding: 0px;
    transform: rotate(0deg);
    transition: transform 500ms ease 0s;
    min-width: 1.5rem;
    color: rgb(255, 255, 255);
    outline: none;
    cursor: pointer;
    z-index: 1;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
}

.dOAKcZ svg {
    border-radius: 4px;
    transition: background-color 0.3s ease 0s;
}

.ifFoww:not(focus):not(active) {
    clip: rect(0px, 0px, 0px, 0px);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.dBUZdq {
    display: none;
    margin-left: 1.25rem;
}

.IKRsL {
    position: absolute;
    top: 5rem;
    visibility: hidden;
}

.cePXDf {
    background-color: rgb(20, 70, 153);
    background-image: url(../../../../assets/images/img/home-hero-gradient.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    color: rgb(255, 255, 255);
}

.llCkQr {
    display: block;
    color: inherit;
}

.jqlzrh {
    box-sizing: content-box;
    max-width: 62.5rem;
    margin: 0px auto;
    padding: 0px 2.5rem;
}

.ldGnJz {
    max-width: 46.6875rem;
    padding: 10.625rem 0px;
}

.cCjakL {
    color: rgb(255, 255, 255);
    margin-bottom: 1.5rem;
}

.kRtqet {
    font-size: 3rem;
    font-weight: 600;
    line-height: 3.625rem;
}

h1 {
    font-size: 3.25rem;
    line-height: 1.3em;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0px 0px 0.5em;
    color: var(--grey900);
    font-weight: 500;
}

h1 {
    font-size: 2em;
    margin: 0.67em 0px;
}

.kYEboM {
    margin-bottom: 2rem;
    color: rgb(255, 255, 255);
}

.hrbjxU {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2.125rem;
}

p {
    line-height: 1.5;
    margin: 0px;
}

.dKTyun {
    display: flex;
    gap: 1rem;
}

.icspSe:not(focus):not(active) {
    clip: rect(0px, 0px, 0px, 0px);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.bKwmbl {
    width: 100%;
    max-width: 24.1875rem;
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 0.5rem;
    padding: 0.75rem 1rem;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: rgb(255, 255, 255);
}

footer #left-area ul.elementor-icon-list-items,
.elementor-edit-area .elementor-element ul.elementor-icon-list-items,
.elementor .elementor-element ul.elementor-icon-list-items {
    margin-left: 0 !important;
}

footer {
    color: white !important;
}

button,
input {
    overflow: visible;
}

.kBRaGg {
    background-color: rgb(236, 245, 254);
    color: rgb(12, 108, 242);
    font-size: 0.875rem;
    line-height: 1.25rem;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0px;
}

@media (max-width: 1200px) {
    .jPvoRr {
        margin-right: 0.5rem;
    }

    .kRTKLx {
        margin-right: 0.5rem;
    }
}

@media (max-width: 1023px) {
    .ddctkp {
        display: none;
    }

    .dOAKcZ {
        display: flex;
    }
}

@media (max-width: 768px) {
    .cxOGwh {
        font-size: 0.875rem;
        line-height: 143%;
        padding: 0.625rem 0.875rem;
    }

    .hrbjxU {
        font-size: 1.0625rem;
        line-height: 1.5rem;
    }

    h1 {
        font-size: 2.5rem;
    }

    .kRtqet {
        font-size: 1.5rem;
        line-height: 1.8125rem;
    }

    .jqlzrh {
        padding: 0px 1rem;
    }

    .llgLia {
        font-size: 0.875rem;
        line-height: 143%;
        padding: 0.625rem 0.875rem;
    }
}

@media (max-width: 620px) {
    .bZhfok {
        display: none;
    }

    .dBUZdq {
        display: flex;
    }

    .eLyMAV {
        display: none;
    }

    .dOAKcZ {
        display: none;
    }
}

@media (max-width: 48rem) {
    .ldGnJz {
        padding: 7.5rem 0px 5rem;
    }
}

/*  */

.jqlzrh {
    box-sizing: content-box;
    max-width: 62.5rem;
    margin: 0px auto;
    padding: 0px 2.5rem;
}

.lextIZ {
    position: relative;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    width: 100%;
    margin-top: -70px;
}

.evZIbd {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 62.5rem;
    width: 100%;
}

.iGlXs {
    width: 100%;
}

.hIwTsa {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(12rem, auto));
    gap: 1.5rem;
    border-radius: 0.5rem;
    width: 100%;
    margin-bottom: 1.25rem;
}

.lhvJwz {
    background-image: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255));
    border-radius: 0.5rem;
    box-shadow: rgba(103, 113, 133, 0.2) 0.75rem 1.5rem 4rem 0rem;
    color: rgb(18, 29, 51);
    min-width: 0px;
    padding: 1.25rem;
    transition: background-image 0.6s ease 0s;
}

.gqAVrV {
    display: flex;
    align-items: flex-start;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.eymRoB {
    flex-shrink: 0;
}

.evkPTr {
    height: 2.5rem;
    width: 2.5rem;
}

.bwPenO {
    display: flex;
    align-items: flex-start;
    -webkit-box-pack: end;
    justify-content: flex-end;
    flex-wrap: wrap;
    max-width: 170px;
    gap: 0.5rem;
}

.eAgFyd {
    background-color: rgb(239, 236, 254);
    border-radius: 0.25rem;
    border: none;
    color: rgb(83, 34, 229);
    cursor: pointer;
    font-weight: 500;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    transition: background-color 0.3s ease 0s;
}

.eyFFcP {
    background-color: rgb(230, 250, 236);
    color: rgb(0, 153, 76);
}

.jVbWjV {
    display: flex;
    margin-bottom: 0.25rem;
}

.iRSNHM {
    color: inherit;
    font-weight: 500;
    font-size: 1rem;
}

.kmtyGJ {
    font-weight: 500;
}

.vvWMe {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
}

.kKWdjS {
    color: rgb(103, 113, 133);
    margin-left: 0.5rem;
}

.kfPjgL {
    color: rgb(152, 161, 178);
    font-weight: 500;
    font-size: 1rem;
}

.jVbWjV {
    display: flex;
    margin-bottom: 0.25rem;
}

.jXjlIz {
    display: flex;
}

iRSNHM {
    color: inherit;
    font-weight: 500;
    font-size: 1rem;
}

.kmtyGJ {
    font-weight: 500;
}

.vvWMe {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
}

.heAKQt {
    margin-left: 0.5rem;
}

.kfPjgL {
    color: rgb(152, 161, 178);
    font-weight: 500;
    font-size: 1rem;
}

.kmtyGJ {
    font-weight: 500;
}

.eAgFyd {
    background-color: rgb(239, 236, 254);
    border-radius: 0.25rem;
    border: none;
    color: rgb(83, 34, 229);
    cursor: pointer;
    font-weight: 500;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    transition: background-color 0.3s ease 0s;
}

.cHgCcY {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.evVLDw {
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    font-weight: 500;
    letter-spacing: 0.01em;
    cursor: pointer;
    transition: all 0.3s ease 0s;
    white-space: nowrap;
    background: none;
    border: none;
    border-radius: 0px;
    color: rgb(18, 29, 51);
    padding: 0px;
    font-size: 0.875rem;
    line-height: 143%;
}

.evVLDw svg {
    width: 1.25rem;
    height: 1.25rem;
}

.evVLDw svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
}

@media (max-width: 768px) {
    .nnmmn {
        display: none !important;
    }
}

@media (max-width: 768px) {
    .hIwTsa {
        grid-template-columns: repeat(8, 1fr);
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .eBHa-Dv {
        padding: 0px;
    }

    .iGlXs {
        max-width: 100%;
        overflow-x: auto;
        padding: 0px 16px 76px;
    }
}

@media (max-width: 768px) {
    .jqlzrh {
        padding: 0px 1rem;
    }

    .lextIZ {
        margin-top: -2.5rem;
    }

    .lhvJwz {
        width: 252px;
    }
}

/*  */
img.wp-smiley,
img.emoji {
    display: inline !important;
    border: none !important;
    box-shadow: none !important;
    height: 1em !important;
    width: 1em !important;
    margin: 0 0.07em !important;
    vertical-align: -0.1em !important;
    background: none !important;
    padding: 0 !important;
}

.elementor-toggle-item {
    margin-bottom: 1rem;
    cursor: pointer;
}

.elementor-tab-title {
    font-weight: bold;
    color: #333;
}

.elementor-tab-content {
    margin-top: 0.5rem;
    padding-left: 1rem;
    color: #555;
}

.rotateaf {
    transition: all 0.3s ease-in-out;
}

.rotatef {
    rotate: 180deg;
    transition: all 0.3s ease-in-out;
}

.hidden {
    display: none;
}

.dblock.elementor-tab-content {
    display: block !important;
}

@media (max-width:990px) {
    .fldla {
        flex-basis: 0;
        flex-grow: 0;
    }

    .homepaag .top-wrap {
        width: max-content !important;
    }
}