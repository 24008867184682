.hasa {
    font-weight: bold;
    text-transform: uppercase;
}

.nasaaa {
    /* color: var(--primary); */

    text-decoration: none !important;

}

.mm-active .faris {
    color: white !important;
}

.faris {
    font-size: 1rem;
    color: #c8c8c8;
    margin-right: 13px;
    margin-left: 2px;
}