/*
  Theme Name: IKO - ICO & Crypto Landing WordPress Theme
  Support: themegenix@gmail.com
  Description: IKO - ICO & Crypto Landing WordPress Theme
  Version: 1.0
*/

/* CSS Index
============================
01. Variable CSS
02. Header
03. Mobile Menu
04. OffCanvas
05. Banner
06. Breadcrumb
07. Contribution
08. Brand
09. Features
10. Chart
11. RoadMap
12. Team
13. Faq
14. Download
15. Document
16. Blog
17. Pagination
18. Contact
19. Footer
20. Preloader
============================
*/



/*=============================
	01. Variable CSS
===============================*/
:root {
    --tg-body-font-family: 'Outfit', sans-serif;
    --tg-heading-font-family: 'Plus Jakarta Sans', sans-serif;
    --tg-primary-color: #E275FF;
    --tg-theme-color2: #5729D6;
    --tg-secondary-color: #0F101E;
    --tg-body-font-color: #92939E;
    --tg-heading-font-color: #FFFFFF;
    --tg-paragraph-color: #92939E;
    --tg-body-font-size: 16px;
    --tg-body-font-weight: 400;
    --tg-heading-font-weight: 600;
    --tg-body-line-height: 1.62;
    --tg-heading-line-height: 1.2;
    --tg-green: #010314;
    --tg-white: #ffffff;
    --tg-black: #010314;
    --tg-gray: #93B2C8;
}

.homepg :root {
    scroll-behavior: auto;
}



.homepg img,
.homepg .img {
    max-width: 100%;
    transition: all 0.3s ease-out 0s;
}

.homepg .f-left {
    float: left
}

.homepg .f-right {
    float: right
}

.homepg .fix {
    overflow: hidden
}

.homepg a,
.homepg button {
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    text-decoration: none;
}

.homepg a:focus,
.homepg .btn:focus,
.homepg button:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
}

.homepg a:hover,
.homepg .portfolio-cat a:hover,
.homepg .footer -menu li a:hover {
    color: var(--tg-primary-color);
    text-decoration: none;
}

.homepg a,
.homepg button {
    color: var(--tg-primary-color);
    outline: medium none;
    text-decoration: none;
}

.homepg .btn:focus,
.homepg button:focus,
.homepg input:focus,
.homepg input:focus,
.homepg textarea,
.homepg textarea:focus {
    outline: 0
}

.homepg .uppercase {
    text-transform: uppercase;
}

.homepg .capitalize {
    text-transform: capitalize;
}

.homepg h1,
.homepg h2,
.homepg h3,
.homepg h4,
.homepg h5,
.homepg h6 {
    font-family: var(--tg-heading-font-family);
    color: var(--tg-heading-font-color);
    margin-top: 0px;
    margin-bottom: .7rem;
    font-style: normal;
    line-height: var(--tg-heading-line-height);
    font-weight: var(--tg-heading-font-weight);
    text-transform: inherit;
}

.homepg h1 a,
.homepg h2 a,
.homepg h3 a,
.homepg h4 a,
.homepg h5 a,
.homepg h6 a {
    color: inherit;
}

.homepg h1 {
    font-size: 2.5rem;
}

.homepg h2 {
    font-size: 2rem;
}

.homepg h3 {
    font-size: 1.75rem;
}

.homepg h4 {
    font-size: 1.5rem;
}

.homepg h5 {
    font-size: 1.25rem;
}

.homepg h6 {
    font-size: 1rem;
}

.homepg .list-wrap {
    margin: 0px;
    padding: 0px;
}

.homepg .list-wrap li {
    list-style: none
}

.homepg p {
    font-size: var(--tg-body-font-size);
    font-weight: var(--tg-body-font-weight);
    line-height: var(--tg-body-line-height);
    color: var(--tg-paragraph-color);
    margin-bottom: 15px;
}

.homepg hr {
    border-bottom: 1px solid var(--tg-primary-color);
    border-top: 0 none;
    margin: 30px 0;
    padding: 0;
}

.homepg label {
    color: var(--tg-body-font-color);
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
}

.homepg *::-moz-selection {
    background: var(--tg-primary-color);
    color: var(--tg-white);
    text-shadow: none;
}

.homepg ::-moz-selection {
    background: var(--tg-primary-color);
    color: var(--tg-white);
    text-shadow: none;
}

.homepg ::selection {
    background: var(--tg-primary-color);
    color: var(--tg-white);
    text-shadow: none;
}

.homepg *::-moz-placeholder {
    color: var(--tg-body-font-color);
    font-size: var(--tg-body-font-size);
    opacity: 1;
}

.homepg *::placeholder {
    color: var(--tg-body-font-color);
    font-size: var(--tg-body-font-size);
    opacity: 1;
}

.homepg ::-webkit-scrollbar-track,
.homepg ::-webkit-scrollbar-track {
    box-shadow: none;
    -webkit-box-shadow: none;
    background-color: var(--tg-secondary-color);
    border-radius: 10px;
}

.homepg ::-webkit-scrollbar,
.homepg ::-webkit-scrollbar {
    width: 8px;
    background-color: var(--tg-secondary-color);
}

.homepg ::-webkit-scrollbar-thumb,
.homepg ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-image: linear-gradient(0deg, var(--tg-primary-color) 0%, var(--tg-green) 47.60%, var(--tg-black) 99.23%);
}

.homepg .theme-overlay {
    position: relative
}

.homepg .theme-overlay::before {
    background: var(--tg-primary-color) none repeat scroll 0 0;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.6;
    position: absolute;
    top: 0;
    width: 100%;
}

.homepg .separator {
    border-top: 1px solid var(--tg-primary-color);
}

/* Bootstrap 5 */
.homepg .container {
    padding-left: 15px;
    padding-right: 15px;
}

.homepg .row {
    --bs-gutter-x: 30px;
}

.homepg .row.g-0 {
    --bs-gutter-x: 0;
}

.homepg .gutter-y-30 {
    --bs-gutter-y: 30px;
}

/*=============================
	1. Button style
===============================*/
.homepg .btn {
    user-select: none;
    -moz-user-select: none;
    background: var(--tg-white) none repeat scroll 0 0;
    border: medium none;
    border-radius: 30px;
    color: var(--tg-black);
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: -0.42px;
    line-height: 1;
    margin-bottom: 0;
    padding: 18px 45px;
    text-align: center;
    text-transform: none;
    touch-action: manipulation;
    transition: all 0.3s ease 0s;
    vertical-align: middle;
    white-space: nowrap;
}

.homepg .btn::after {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    color: var(--tg-primary-color);
    line-height: 1;
    margin-left: 20px;
    transition: all 0.3s ease 0s;
}

.homepg .btn:hover {
    background: var(--tg-secondary-color);
    color: var(--tg-white);
}

.homepg .btn:hover::after {
    color: var(--tg-white);
}

.homepg .btn.btn-two {
    background: var(--tg-secondary-color);
    color: var(--tg-white);
}

.homepg .btn.btn-two::after {
    color: var(--tg-white);
}

.homepg .btn.btn-two:hover {
    background: var(--tg-white);
    color: var(--tg-black);
}

.homepg .btn.btn-two:hover::after {
    color: var(--tg-primary-color);
}

.homepg .breadcrumb>.active {
    color: var(--tg-primary-color);
}

/* scrollUp */
.homepg .scroll-top {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: -10%;
    right: 30px;
    font-size: var(--tg-body-font-size);
    border-radius: 30px;
    z-index: 99;
    color: var(--tg-white);
    text-align: center;
    cursor: pointer;
    background: var(--tg-primary-color);
    transition: 1s ease;
    border: none;
}

.homepg .scroll-top.open {
    bottom: 30px;
}

.homepg .scroll-top::after {
    position: absolute;
    z-index: -1;
    content: '';
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 1;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 80%);
}

.homepg .scroll-top:hover {
    background: var(--tg-secondary-color);
}


/*=============================
	02. Header
===============================*/
.homepg .custom-container {
    max-width: 1710px;
}

.homepg .transparent-header {
    position: absolute;
    left: 0;
    top: 0px;
    width: 100%;
    z-index: 9;
    height: auto;
}

.homepg #header-fixed-height.active-height {
    display: block;
    height: 105px;
}

.homepg .menu-area {
    padding: 40px 0 20px;
}

.homepg .menu-nav {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.homepg .logo a,
.homepg .logo img {
    transition: 0s !important;
}

.homepg .navbar-wrap {
    display: flex;
    flex-grow: 1;
}

.homepg .navbar-wrap ul {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 70px;
    margin: 0 auto;
    border: 1px solid rgba(255, 255, 255, 0.15);
    background: var(--tg-black);
    border-radius: 40px;
}

.homepg .navbar-wrap>ul>li {
    list-style: none;
    display: block;
    position: relative;
    margin-right: 65px;
}

.homepg .navbar-wrap>ul>li:last-child {
    margin-right: 0;
}

.homepg .navbar-wrap ul li a {
    color: var(--tg-heading-font-color);
    padding: 24px 0;
    display: block;
    line-height: 1;
    position: relative;
    z-index: 1;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.96px;
    text-transform: uppercase;
}

.homepg .navbar-wrap>ul>li.active>a,
.homepg .navbar-wrap>ul>li:hover>a {
    color: var(--tg-primary-color);
}

.homepg .navbar-wrap>ul>li.menu-item-has-children>a::after {
    content: "\f107";
    display: inline-block;
    font-weight: 700;
    font-family: "Font Awesome 5 Free";
    margin-left: 5px;
}

.homepg .main-menu .navigation li.menu-item-has-children .dropdown-btn {
    display: none;
}

.homepg .mobile-menu .navigation .dropdown-btn.open span {
    transform: rotate(180deg);
}

.homepg .header-action>ul {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.homepg .header-action>ul li {
    position: relative;
    margin-left: 15px;
}

.homepg .header-action ul li:first-child {
    margin-left: 0;
}

.homepg .header-action ul li a {
    color: var(--tg-heading-font-color);
    font-size: 14px;
}

.homepg .header-action .header-login a {
    font-size: 14px;
    display: flex;
    align-items: center;
    background: var(--tg-white);
    color: var(--tg-black);
    font-weight: 700;
    gap: 12px;
    padding: 14px 23px;
    border-radius: 30px;
}

.homepg .header-action .header-login a i {
    color: var(--tg-primary-color);
    transition: all 0.3s ease-out 0s;
}

.homepg .header-action .header-login a:hover {
    background: var(--tg-primary-color);
    color: var(--tg-white);
}

.homepg .header-action .header-login a:hover i {
    color: var(--tg-white);
}

.homepg .offcanvas-menu .menu-tigger {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-primary-color);
    color: var(--tg-white);
    border-radius: 50%;
    font-size: 16px;
}

.homepg .offcanvas-menu .menu-tigger:hover {
    background: var(--tg-white);
    color: var(--tg-primary-color);
}

.homepg .navbar-wrap ul li .sub-menu {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    min-width: 230px;
    border: 1px solid rgba(43 44 56 / 46%);
    background: var(--tg-secondary-color);
    margin: 0 0;
    transform: scale(1, 0);
    transform-origin: 0 0;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-box-shadow: 0px 30px 70px 0px rgba(7 8 8 / 15%);
    -moz-box-shadow: 0px 30px 70px 0px rgba(7 8 8 / 15%);
    box-shadow: 0px 30px 70px 0px rgba(7 8 8 / 15%);
    border-radius: 0;
    padding: 18px 0;
    display: block;
    visibility: hidden;
    opacity: 0;
    z-index: 9;
    border-radius: 0 0 10px 10px;
}

.homepg .navbar-wrap ul li .sub-menu .sub-menu {
    right: auto;
    left: 100%;
    top: 0;
}

.homepg .navbar-wrap ul li .sub-menu li {
    margin-left: 0;
    text-align: left;
    display: block;
    position: relative;
}

.homepg .navbar-wrap ul li .sub-menu li a {
    padding: 9px 15px 9px 25px;
    color: var(--tg-white);
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    font-size: 14px;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.96px;
    text-transform: uppercase;
}

.homepg .navbar-wrap ul li .sub-menu li.active a,
.homepg .navbar-wrap ul li .sub-menu li a:hover {
    color: var(--tg-primary-color);
}

.homepg .navbar-wrap ul li:hover>.sub-menu {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

.homepg .sticky-menu {
    position: fixed;
    left: 0;
    margin: auto;
    top: 0;
    width: 100%;
    z-index: 99;
    background: var(--tg-secondary-color);
    -webkit-animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
    -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
    border-radius: 0;
}

.homepg .sticky-menu.menu-area {
    padding: 20px 0;
}

/*=============================
	03. Mobile Menu
===============================*/
.homepg .nav-outer .mobile-nav-toggler {
    position: relative;
    float: right;
    font-size: 40px;
    line-height: 50px;
    cursor: pointer;
    display: none;
    color: var(--tg-white);
    margin-right: 30px;
    top: 15px;
}

.homepg .nav-logo img {
    max-width: 150px;
    max-height: 38px;
}

.homepg .mobile-menu {
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
    padding-right: 30px;
    max-width: 100%;
    height: 100%;
    z-index: 9991;
    border-radius: 0px;
    transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -moz-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -webkit-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -ms-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86)e;
    -o-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -webkit-transform: translateX(101%);
    -ms-transform: translateX(101%);
    transform: translateX(101%);
}

.homepg .mobile-menu .navbar-collapse {
    display: block !important;
}

.homepg .mobile-menu .nav-logo {
    position: relative;
    padding: 30px 25px;
    text-align: left;
}

.mobile-menu-visible {
    overflow: hidden;
}

.mobile-menu-visible .mobile-menu {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
}

.homepg .mobile-menu .navigation li.current>a:before {
    height: 100%;
}

.homepg .menu-backdrop {
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    transition: all 700ms ease;
    -moz-transition: all 700ms ease;
    -webkit-transition: all 700ms ease;
    -ms-transition: all 700ms ease;
    -o-transition: all 700ms ease;
    opacity: 0;
    visibility: hidden;
    background: rgba(0, 0, 0, 0.5);
}

.mobile-menu-visible .menu-backdrop {
    opacity: 1;
    visibility: visible;
}

.homepg .mobile-menu .menu-box {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background: var(--tg-secondary-color);
    padding: 0px 0px;
    z-index: 5;
    box-shadow: -9px 0 14px 0px rgb(0 0 0 / 6%);
}

.mobile-menu-visible .mobile-menu .menu-box {
    opacity: 1;
    visibility: visible;
}

.homepg .mobile-menu .close-btn {
    position: absolute;
    right: 15px;
    top: 28px;
    line-height: 30px;
    width: 35px;
    text-align: center;
    font-size: 20px;
    color: var(--tg-primary-color);
    cursor: pointer;
    z-index: 10;
    -webkit-transition: all 0.9s ease;
    -o-transition: all 0.9s ease;
    transition: all 0.9s ease;
}

.mobile-menu-visible .mobile-menu .close-btn {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
}

.homepg .mobile-menu .navigation {
    position: relative;
    display: block;
    width: 100%;
    float: none;
    margin: 0;
    padding: 0;
}

.homepg .mobile-menu .navigation ul {
    padding: 0;
    margin: 0;
}

.homepg .mobile-menu .navigation li {
    position: relative;
    display: block;
    border-top: 1px solid rgb(255 255 255 / 5%);
}

.homepg .mobile-menu .navigation:last-child {
    border-bottom: 1px solid rgb(255 255 255 / 5%);
}

.homepg .mobile-menu .navigation li>ul>li:first-child {
    border-top: 1px solid rgb(255 255 255 / 5%);
}

.homepg .mobile-menu .navigation li>a {
    position: relative;
    display: block;
    line-height: 24px;
    padding: 10px 60px 10px 25px;
    font-size: 14px;
    font-weight: 700;
    color: var(--tg-heading-font-color);
    text-transform: uppercase;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    border: none;
    letter-spacing: 1px;
}

.homepg .mobile-menu .navigation li ul li>a {
    font-size: 14px;
    margin-left: 20px;
    text-transform: uppercase;
}

.homepg .mobile-menu .navigation li ul li ul li a {
    margin-left: 40px;
}

.homepg .mobile-menu .navigation li ul li ul li ul li a {
    margin-left: 60px;
}

.homepg .mobile-menu .navigation li>a:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.homepg .mobile-menu .navigation li.menu-item-has-children .dropdown-btn {
    position: absolute;
    right: 15px;
    top: 6px;
    width: 32px;
    height: 32px;
    text-align: center;
    font-size: 16px;
    line-height: 32px;
    color: var(--tg-white);
    background: var(--tg-primary-color);
    cursor: pointer;
    border-radius: 2px;
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    z-index: 5;
}

.homepg .mobile-menu .navigation li.menu-item-has-children .dropdown-btn i {
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
}

.homepg .mobile-menu .navigation li.menu-item-has-children .dropdown-btn.open i {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    color: var(--tg-primary-color);
}

.homepg .mobile-menu .navigation li>ul,
.homepg .mobile-menu .navigation li>ul>li>ul {
    display: none;
}

.homepg .mobile-menu .social-links ul {
    display: flex;
    position: relative;
    text-align: center;
    padding: 30px 20px 20px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.homepg .mobile-menu .social-links li {
    position: relative;
    display: inline-block;
    margin: 0px 6px 10px;
}

.homepg .mobile-menu .social-links li a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    position: relative;
    line-height: 32px;
    font-size: 16px;
    color: var(--tg-heading-font-color);
    -webkit-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
    border: 1px solid rgb(255 255 255 / 5%);
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}

.homepg .mobile-menu .social-links li a svg.e-font-icon-svg {
    width: 17px;
    fill: currentColor;
}

.homepg .mobile-menu .social-links li a:hover {
    border-color: var(--tg-primary-color);
    background: var(--tg-primary-color);
    color: var(--tg-white);
}

.homepg .menu-area .mobile-nav-toggler {
    position: relative;
    float: right;
    cursor: pointer;
    line-height: 1;
    display: none;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    background: var(--tg-primary-color);
    font-size: 16px;
    border-radius: 50%;
    color: var(--tg-white);
}

@media (max-width:1000px) {

    .homepg .menu-area .mobile-nav-toggler {
        display: flex !important
    }

}

/*=============================
	04. OffCanvas
===============================*/
.homepg .extra-info {
    background: var(--tg-black) none repeat scroll 0 0;
    height: 100%;
    padding: 30px;
    position: fixed;
    right: 0;
    top: 0;
    width: 340px;
    z-index: 999;
    overflow-y: scroll;
    transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -moz-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -webkit-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -ms-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86)e;
    -o-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -webkit-transform: translateX(101%);
    -ms-transform: translateX(101%);
    transform: translateX(101%);
}

.homepg .extra-info.active {
    transform: translateX(0);
}

.homepg .close-icon {
    margin-top: -16px;
    text-align: right;
}

.homepg .close-icon>button {
    background: transparent;
    border: 0 none;
    color: var(--tg-primary-color);
    cursor: pointer;
    font-size: 20px;
    padding: 0;
}

.homepg .extra-info .logo-side img {
    max-width: 151px;
}

.homepg .side-info {
    border-top: 1px solid var(--tg-primary-color);
    padding-top: 25px;
}

.homepg .contact-list h4 {
    color: var(--tg-heading-font-color);
    font-weight: 700;
    font-size: 18px;
}

.homepg .contact-list p {
    color: var(--tg-paragraph-color);
    margin: 0;
    margin-bottom: 2px;
    line-height: 26px;
}

.homepg .social-icon-right {
    display: flex;
    align-items: center;
    gap: 20px;
}

.homepg .social-icon-right>a {
    color: var(--tg-primary-color);
    display: block;
    text-align: center;
    font-size: 17px;
}

.homepg .social-icon-right>a svg {
    display: block;
    width: 16px;
    height: 16px;
    fill: var(--tg-primary-color);
}

.homepg .social-icon-right>a:hover {
    color: var(--tg-white);
}

.homepg .offcanvas-overly {
    position: fixed;
    background: var(--tg-secondary-color);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

.homepg .offcanvas-overly.active {
    opacity: .7;
    visibility: visible;
}

.homepg .extra-info::-webkit-scrollbar {
    width: 0px;
}


/*=============================
	05. Banner
===============================*/
.homepg .banner-bg {
    background-size: cover;
    background-position: center;
    border-radius: 0 0 20px 20px;
    position: relative;
    padding: 140px 0px 120px;
    min-height: 100vh;
    display: flex;
    align-items: center;
    margin: 0 20px;
}

.homepg .banner-bg::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, var(--tg-primary-color) 0%, var(--tg-green) 47.60%, transparent 65.23%);
    z-index: -1;
    border-radius: 0px 0px 20px 20px;
}

.homepg .banner-content {
    position: relative;
    z-index: 1;
}

.homepg .banner-content .title {
    font-size: 65px;
    margin-bottom: 45px;
    letter-spacing: -2.6px;
}

.homepg .banner-content .title span {
    background: linear-gradient(180deg, var(--tg-white) 0%, var(--tg-primary-color) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.homepg .banner-content p {
    font-size: 20px;
    margin-bottom: 0;
    color: var(--tg-white);
    font-weight: 500;
}

.homepg .coming-time {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 30px 75px;
}

.homepg .time-count {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    background: var(--tg-white);
    border-radius: 10px;
    min-width: 110px;
    color: var(--tg-paragraph-color);
    font-weight: 500;
    font-size: 14px;
    padding: 15px;
    position: relative;
    line-height: 1.4;
}

.homepg .time-count span {
    line-height: .9;
    font-size: 35px;
    font-weight: 700;
    color: var(--tg-black);
    font-family: var(--tg-heading-font-family);
    display: block;
    margin-top: 7px;
}

.homepg .time-count::after,
.homepg .time-count::before {
    content: "";
    position: absolute;
    right: -35px;
    top: 50%;
    transform: translateY(-50%);
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: var(--tg-white);
    opacity: .3;
}

.homepg .time-count::after {
    right: -45px;
}

.homepg .time-count:last-child:after,
.homepg .time-count:last-child:before {
    display: none;
}

.homepg .banner-scroll-down {
    display: flex;
    align-items: center;
    justify-content: center;
}

.homepg .banner-scroll-down a {
    color: var(--tg-white);
    display: block;
    width: 30px;
    height: 50px;
    text-align: center;
}

.homepg .banner-scroll-down a span {
    display: block;
    width: 15px;
    height: 15px;
    border-bottom: 2px solid var(--tg-white);
    border-right: 2px solid var(--tg-white);
    transform: rotate(45deg);
    margin: -10px 0 0 8px;
    animation: animate 2s infinite;
}

.homepg .banner-scroll-down a span:nth-child(2) {
    animation-delay: -0.2s;
}

.homepg .banner-scroll-down a span:nth-child(3) {
    animation-delay: -0.4s;
}

.homepg .banner-shape-wrap img {
    position: absolute;
}

.homepg .banner-shape-wrap img:nth-child(1) {
    left: 107px;
    bottom: 134px;
}

.homepg .banner-shape-wrap img:nth-child(2) {
    right: 107px;
    bottom: 120px;
}

@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-15px, -15px);
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: rotate(45deg) translate(15px, 15px);
    }
}

@keyframes alltuchtopdown {
    0% {
        transform: rotateX(0deg) translateY(0px);
    }

    50% {
        transform: rotateX(0deg) translateY(-30px);
    }

    100% {
        transform: rotateX(0deg) translateY(0px);
    }
}

.homepg .alltuchtopdown {
    -webkit-animation-name: alltuchtopdown;
    animation-name: alltuchtopdown;
    -webkit-animation-duration: 6s;
    animation-duration: 6s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

@keyframes leftToRight {
    0% {
        transform: rotateX(0deg) translateX(0px);
    }

    50% {
        transform: rotateX(0deg) translateX(50px);
    }

    100% {
        transform: rotateX(0deg) translateX(0px);
    }
}

.homepg .leftToRight {
    -webkit-animation-name: leftToRight;
    animation-name: leftToRight;
    -webkit-animation-duration: 8s;
    animation-duration: 8s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

/*=============================
	06. Breadcrumb
===============================*/
.homepg .breadcrumb-bg {
    background-size: cover;
    background-position: center;
    border-radius: 0 0 20px 20px;
    position: relative;
    z-index: 1;
    padding: 270px 0 195px;
    margin: 0 20px;
    overflow: hidden;
}

.homepg .breadcrumb-bg::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, var(--tg-primary-color) 0%, var(--tg-green) 47.60%, transparent 65.23%);
    z-index: -1;
}

.homepg .breadcrumb-content {
    text-align: center;
}

.homepg .breadcrumb-content .title {
    font-size: 60px;
    margin-bottom: 0;
    letter-spacing: -1.8px;
}

.homepg .breadcrumb-content .breadcrumb {
    justify-content: center;
    margin-bottom: 0;
}

.homepg .breadcrumb-content .breadcrumb-item {
    color: var(--tg-white);
    font-size: 18px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.homepg .breadcrumb-content .breadcrumb-item a {
    color: #B4B4C3;
}

.homepg .breadcrumb-content .breadcrumb-item a:hover {
    color: var(--tg-primary-color);
}

.homepg .breadcrumb-content .breadcrumb-item+.breadcrumb-item::before {
    padding-right: 10px;
    color: var(--tg-white);
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    font-size: 18px;
    line-height: 1;
    margin-top: 3px;
}

.homepg .breadcrumb-content .breadcrumb-item+.breadcrumb-item {
    padding-left: 10px;
}

.homepg .breadcrumb-shape-wrap img {
    position: absolute;
    z-index: -1;
}

.homepg .breadcrumb-shape-wrap img:nth-child(1) {
    left: 6%;
    top: 42%;
}

.homepg .breadcrumb-shape-wrap img:nth-child(2) {
    right: 8%;
    bottom: 22%;
}

.homepg .rotateme {
    -webkit-animation-name: rotateme;
    animation-name: rotateme;
    -webkit-animation-duration: 10s;
    animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

@keyframes rotateme {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotateme {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}


/*=============================
	07. Contribution
===============================*/
.homepg .contribution-area {
    position: relative;
}

.homepg .contribution-title .title {
    font-size: 60px;
    text-align: center;
    margin-bottom: 0;
    letter-spacing: -1.8px;
}

.homepg .contribution-title {
    margin-bottom: 60px;
}

.homepg .contribution-title .title span {
    color: var(--tg-primary-color);
}

.homepg .progress-wrap .list-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 160px;
    gap: 30px;
}

.homepg .progress-wrap .list-wrap li {
    position: relative;
    color: #AAABB2;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    padding-bottom: 40px;
}

.homepg .progress-wrap .list-wrap li::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 17px;
    width: 2px;
    height: 14px;
    background: var(--tg-white);
}

.homepg .progress-wrap .progress {
    height: 32px;
    background-color: #1C1D34;
    border-radius: 25px;
    padding: 7px 8px;
    margin-bottom: 20px;
}

.homepg .progress-wrap .progress-bar {
    transition: 0s;
    border-radius: 25px;
    background: linear-gradient(286deg, var(--tg-primary-color) 0%, var(--tg-green) 47.60%, var(--tg-black) 98.23%)
}

.homepg .progress-wrap .progress-title {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 700;
    font-family: var(--tg-body-font-family);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.homepg .contribution-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 25px;
    margin-top: 50px;
    flex-wrap: wrap;
}

.homepg .contribution-shape-wrap img {
    position: absolute;
    z-index: -1;
}

.homepg .contribution-shape-wrap img:nth-child(1) {
    left: 2%;
    bottom: 25%;
}

.homepg .contribution-shape-wrap img:nth-child(2) {
    right: 3%;
    bottom: 23%;
}

/*=============================
	08. Brand
===============================*/
.homepg .brand-title {
    display: inline-block;
    padding: 0 18px;
}

.homepg .brand-title::after,
.homepg .brand-title::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--tg-primary-color);
}

.homepg .brand-title::after {
    left: auto;
    right: 0;
}

.homepg .brand-item-wrap {
    border-top: 1px solid rgba(255, 255, 255, 0.10);
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    position: relative;
    padding: 45px 0;
}

.homepg .brand-item-wrap::after,
.homepg .brand-item-wrap::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18%;
    height: 100%;
    background: linear-gradient(90deg, var(--tg-black) 0%, rgba(1, 3, 20, 0.00) 100%);
    z-index: 5;
    pointer-events: none;
}

.homepg .brand-item-wrap::after {
    left: auto;
    right: 0;
    transform: rotate(180deg);
}

.homepg .brand-active .col-12 {
    padding: 0 15px;
}

.homepg .brand-item {
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.homepg .brand-item img {
    opacity: .5;
    cursor: pointer;
    transition: .3s linear;
}

.homepg .brand-item img:hover {
    opacity: 1;
}

@media (max-width: 767.98px) {
    .homepg .brand-item img {
        opacity: 1;
    }
}

/*=============================
	09. Features
===============================*/
.homepg .section-title .title {
    background: linear-gradient(180deg, var(--tg-white) 0%, var(--tg-secondary-color) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 50px;
    margin-bottom: 0;
    letter-spacing: -1.5px;
}

.homepg .features-item {
    display: flex;
    align-items: center;
    background: var(--tg-secondary-color);
    border: 1px solid rgba(43 44 56 / 46%);
    border-radius: 15px;
    padding: 40px 70px 40px 55px;
    margin-bottom: 30px;
}

.homepg .features-content {
    width: 50%;
    margin-right: 20px;
}

.homepg .features-content .title {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: -0.84px;
    margin-bottom: 25px;
}

.homepg .features-img {
    flex-grow: 1;
    text-align: right;
}

.homepg .features-item .features-img img {
    max-width: 164px;
}

.homepg .features-content p {
    margin-bottom: 0;
}

/* features-two */
.homepg .features-bg {
    background-size: cover;
    background-position: center;
    border-radius: 20px 20px 0 0;
    position: relative;
    margin: 0 20px;
    overflow: hidden;
}

.homepg .features-bg::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, var(--tg-primary-color) 0%, var(--tg-green) 47.60%, var(--tg-black) 65.23%);
    z-index: -1;
    transform: rotate(180deg);
}

.homepg .features-inner-wrap {
    position: relative;
    padding: 140px 0;
    z-index: 1;
}

.homepg .features-line-shape {
    display: block;
    width: 100%;
    height: 2px;
    background: linear-gradient(225deg, var(--tg-black) 0%, rgba(255, 255, 255, 0.45) 54.98%, rgba(1, 3, 20, 0.99) 100%);
    margin: 0 auto;
}

.homepg .features-item-wrap {
    margin-bottom: 90px;
}

.homepg .features-item-two {
    border-radius: 15px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.14) 0%, rgba(255, 255, 255, 0.00) 100%);
    box-shadow: 0px 16px 18px 0px rgba(1, 12, 50, 0.10);
    display: flex;
    align-items: center;
    padding: 30px 30px;
    margin-bottom: 30px;
    transition: .3s linear;
}

.homepg .features-item-two:hover {
    transform: translateY(-5px);
}

.homepg .features-img-two {
    width: 95px;
    flex: 0 0 auto;
    margin-right: 30px;
}

.homepg .features-content-two .title {
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.72px;
}

.homepg .section-title .title img {
    border-radius: 50px;
    margin: 0 5px;
    max-height: 51px;
}

.homepg .section-title-two .title {
    background: linear-gradient(180deg, var(--tg-white) 0%, var(--tg-primary-color) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.homepg .features-shape-wrap img {
    position: absolute;
}

.homepg .features-shape-wrap img:nth-child(1) {
    left: 15%;
    bottom: 6%;
}

.homepg .features-shape-wrap img:nth-child(2) {
    right: 9%;
    bottom: 0%;
}

/*=============================
	10. Chart
===============================*/
.homepg .vertical-inner-wrap {
    position: relative;
}

.homepg .vertical-inner-wrap::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(225deg, var(--tg-black) 0%, rgba(255, 255, 255, 0.45) 54.98%, rgba(1, 3, 20, 0.99) 100%);
}

.homepg .chart-wrap {
    position: relative;
}

.homepg .chart-wrap .chart {
    width: 340px;
    height: 340px;
    margin: 0 0 160px 60px;
}

.homepg .chart-wrap .chart-tab {
    position: absolute;
    top: 393px;
    left: 0;
    right: 0;
    z-index: 1;
}

.homepg .chart-tab .nav-tabs {
    border-bottom: none;
    display: inline-flex;
    background: #1C1D34;
    border-radius: 30px;
    padding: 10px 10px;
    justify-content: center;
}

.homepg .chart-tab .nav-tabs .nav-link {
    margin-bottom: 0;
    background: transparent;
    border: none;
    font-size: 14px;
    font-weight: 700;
    border-radius: 30px;
    color: var(--tg-white);
    padding: 8px 35px;
}

.homepg .chart-tab .nav-tabs .nav-link.active {
    background: var(--tg-white);
    color: var(--tg-black);
}

.homepg .chart-list .list-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px 0;
}

.homepg .chart-list .list-wrap li {
    color: var(--tg-white);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.48px;
    width: 50%;
    position: relative;
    padding-left: 30px;
}

.homepg .chart-list .list-wrap li span {
    content: "";
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: #44A08D;
    position: absolute;
    left: 0;
    top: 3px;
}

.homepg .chart-inner-wrap .right-side-content {
    margin-left: 190px;
}

.homepg .chart-inner-wrap .right-side-content img {
    margin-bottom: 30px;
}

.homepg .chart-inner-wrap .right-side-content p {
    margin-bottom: 25px;
    margin-left: 20px;
}

.homepg .right-side-content .list-wrap li {
    display: flex;
    font-size: 18px;
    font-weight: 500;
    color: var(--tg-white);
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    padding: 9px 20px;
    transition: .3s ease-in-out;
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.homepg .right-side-content .list-wrap li span {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(255 255 255 / 10%);
    color: var(--tg-white);
    border-radius: 50%;
}

.homepg .right-side-content .list-wrap li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(82deg, var(--tg-primary-color) 0%, var(--tg-green) 47.60%, var(--tg-black) 98.23%);
    transition: .3s ease-in-out;
    opacity: 0;
    z-index: -1;
}

.homepg .right-side-content .list-wrap li:hover::before {
    opacity: 1;
}

.homepg .right-side-content .list-wrap li:hover span {
    background: var(--tg-black);
}

/*=============================
	11. RoadMap
===============================*/
.homepg .roadmap-area {
    overflow: hidden;
}

.homepg .roadmap-title {
    display: block;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 45px;
    margin-left: 14px;
    letter-spacing: 0.56px;
    text-transform: uppercase;
}

.homepg .roadmap-content {
    border: 1px solid rgba(43 44 56 / 46%);
    background: var(--tg-secondary-color);
    border-radius: 15px;
    padding: 75px 50px 65px;
}

.homepg .roadmap-content .title {
    display: flex;
    align-items: flex-start;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 32px;
    gap: 16px;
}

.homepg .roadmap-content .title .dot {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: block;
    position: relative;
    background: rgba(255 255 255 / 11%);
    flex: 0 0 auto;
    transform: translateY(3px);
}

.homepg .roadmap-content .title .dot::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: var(--tg-primary-color);
}

.homepg .roadmap-content .title .dot::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 24px;
    width: 1px;
    height: 120px;
    background: #292A37;
}

.homepg .roadmap-content p {
    margin-bottom: 0;
}

.homepg .roadMap-active .col-lg-4 {
    padding: 0 15px;
}

/*=============================
	12. Team
===============================*/
.homepg .team-bg {
    background-size: cover;
    background-position: center;
    border-radius: 0 0 20px 20px;
    position: relative;
    padding: 140px 0;
    overflow: hidden;
    margin: 0 20px;
}

.homepg .team-bg::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, var(--tg-primary-color) 0%, var(--tg-green) 47.60%, var(--tg-black) 91.23%);
    z-index: -1;
}

.homepg .team-item {
    text-align: center;
}

.homepg .team-thumb {
    position: relative;
    margin-bottom: 15px;
}

.homepg .team-thumb img {
    mix-blend-mode: luminosity !important;
    display: inline-block !important;
}

.homepg .team-thumb .team-social {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 30px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-white);
    border-radius: 50%;
    font-size: 15px;
}

.homepg .team-thumb .team-social:hover {
    background: var(--tg-primary-color);
    color: var(--tg-white);
}

.homepg .team-content .title {
    font-size: 22px;
    margin-bottom: 15px;
}

.homepg .team-content span {
    line-height: 1;
    display: block;
    font-size: 14px;
    font-weight: 500;
    color: #CBD0D4;
}

/*=============================
	13. Faq
===============================*/
.homepg .faq-inner-wrap {
    padding: 140px 0;
    position: relative;
}

.homepg .faq-inner-wrap::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(225deg, var(--tg-black) 0%, rgba(255, 255, 255, 0.45) 54.98%, rgba(1, 3, 20, 0.99) 100%);
    ;
}

.homepg .faq-wrap .accordion-item {
    background-color: var(--tg-secondary-color);
    border: 1px solid rgba(43 44 56 / 46%);
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 20px;
}

.homepg .faq-wrap .accordion-item.active {
    background: linear-gradient(0deg, var(--tg-primary-color) 0%, var(--tg-green) 47.60%, var(--tg-black) 98.23%);
    border: none;
}

.homepg .faq-wrap .accordion-item:last-child {
    margin-bottom: 0;
}

.homepg .faq-wrap .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}

.homepg .faq-wrap .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.homepg .faq-wrap .accordion-button {
    padding: 38px 90px 38px 55px;
    font-size: 24px;
    color: var(--tg-white);
    background-color: transparent;
    font-weight: 600;
    letter-spacing: -0.48px;
    font-family: var(--tg-heading-font-family);
    position: relative;
}

.homepg .faq-wrap .accordion-button::after {
    flex-shrink: 0;
    width: auto;
    height: auto;
    content: "\f062";
    background-image: none;
    font-size: 25px;
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    position: absolute;
    right: 45px;
    top: 38px;
    color: var(--tg-white);
    opacity: 0.3;
}

.homepg .faq-wrap .accordion-button:not(.collapsed)::after {
    background-image: none;
    opacity: 1;
}

.homepg .faq-wrap .accordion-button:not(.collapsed) {
    color: var(--tg-white);
    background-color: transparent;
    box-shadow: none;
}

.homepg .faq-wrap .accordion-button:focus {
    border-color: none;
    box-shadow: none;
}

.homepg .faq-wrap .accordion-body {
    padding: 0 55px 70px;
}

.homepg .faq-wrap .accordion-body p {
    font-size: 18px;
    margin-bottom: 0;
    color: #CBD0D4;
}

/*=============================
	14. Download
===============================*/
.homepg .download-inner-wrap {
    background: var(--tg-secondary-color);
    border-radius: 15px;
    border: 1px solid rgba(43 44 56 / 46%);
    padding: 75px 80px 0;
    position: relative;
    z-index: 1;
}

.homepg .download-content {
    padding: 40px 0 115px;
}

.homepg .download-list .list-wrap {
    display: flex;
    align-items: center;
    gap: 15px 50px;
    flex-wrap: wrap;
    margin-bottom: 50px;
}

.homepg .download-list .list-wrap li {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    color: var(--tg-white);
    gap: 10px;
}

.homepg .download-list .list-wrap li span {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    background: #1A1C2B;
    border-radius: 50%;
    box-shadow: 0px 1px 4px 0px rgba(0, 20, 90, 0.10);
    font-size: 16px;
}

.homepg .download-btn-wrap {
    display: flex;
    align-items: center;
    gap: 30px;
    flex-wrap: wrap;
}

.homepg .download-btn-wrap .download-btn img {
    max-height: 60px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.homepg .download-img {
    position: relative;
    z-index: 1;
    text-align: right;
}

.homepg .download-img img:nth-child(2) {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.homepg .download-shape-wrap img {
    position: absolute;
    z-index: -1;
}

.homepg .download-shape-wrap img:nth-child(1) {
    left: 0;
    bottom: 0;
    max-width: 181px;
}

.homepg .download-shape-wrap img:nth-child(2) {
    right: 0;
    bottom: 0;
    max-width: 226px;
}

/*=============================
	15. Document
===============================*/
.homepg .document-area {
    position: relative;
    z-index: 2;
}

.homepg .document-inner-wrap .row {
    --bs-gutter-x: 20px;
}

.homepg .document-form-wrap .row {
    --bs-gutter-x: 20px;
}

.homepg .document-form-wrap .form-grp {
    margin-bottom: 20px;
}

.homepg .document-form-wrap .form-grp textarea,
.homepg .document-form-wrap .form-grp input {
    width: 100%;
    background: rgba(255 255 255 / 7%);
    border: none;
    border-radius: 30px;
    font-size: 16px;
    color: var(--tg-white);
    padding: 15px 20px;
    line-height: 1;
    height: 50px;
    display: block;
}

.homepg .document-form-wrap .form-grp textarea::placeholder,
.homepg .document-form-wrap .form-grp input::placeholder {
    font-size: 16px;
    color: #AAABB2;
    line-height: 1;
}

.homepg .document-form-wrap .form-grp textarea {
    min-height: 200px;
    max-height: 200px;
}

.homepg .document-form-wrap .btn {
    margin-top: 15px;
}

.homepg .document-wrap .btn:hover,
.homepg .document-form-wrap .btn:hover {
    background: var(--tg-primary-color);
}

.homepg .document-wrap {
    background: var(--tg-secondary-color);
    border: 1px solid rgba(43 44 56 / 46%);
    padding: 60px 64px 50px;
    border-radius: 15px;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.homepg .document-wrap .title {
    margin-bottom: 35px;
    font-size: 28px;
}

.homepg .document-wrap .list-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 25px;
}

.homepg .document-wrap .list-wrap a {
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: var(--tg-white);
}

.homepg .document-wrap .list-wrap a:hover {
    color: var(--tg-primary-color);
}

.homepg .document-wrap .list-wrap a .icon {
    width: 130px;
    height: 104px;
    background: rgba(255 255 255 / 7%);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--tg-white);
    font-size: 35px;
    line-height: 0;
    margin-bottom: 5px;
}

.homepg .document-shape img {
    position: absolute;
    right: 3%;
    top: -2%;
    z-index: -1;
}

/*=============================
	16. Blog
===============================*/
.homepg .blog-masonry-post {
    display: flex;
    background: var(--tg-secondary-color);
    padding: 20px 40px 20px 20px;
    border-radius: 30px;
    border: 1px solid rgba(43 44 56 / 46%);
    margin-bottom: 60px;
}

.homepg .blog-masonry-thumb {
    width: 315px;
    flex: 0 0 auto;
    margin-right: 30px;
}

.homepg .blog-masonry-thumb a {
    height: 100%;
}

.homepg .blog-masonry-thumb img {
    border-radius: 30px;
    mix-blend-mode: luminosity;
    height: 100%;
    object-fit: cover;
}

.homepg .blog-meta .list-wrap {
    display: flex;
    align-items: center;
    gap: 15px 50px;
    flex-wrap: wrap;
    margin-bottom: 25px;
}

.homepg .blog-details-content .blog-meta .list-wrap {
    margin-bottom: 20px;
}

.homepg .blog-meta .list-wrap li {
    display: flex;
    align-items: center;
    font-size: 15px;
    text-transform: capitalize;
}

.homepg .blog-meta .list-wrap li i {
    color: var(--tg-white);
    font-size: 16px;
    margin-right: 10px;
}

.homepg .blog-meta .list-wrap li a {
    display: flex;
    align-items: center;
    color: var(--tg-paragraph-color);
}

.homepg .blog-meta .list-wrap li a:hover {
    color: var(--tg-primary-color);
}

.homepg .blog-masonry-content .title {
    margin-bottom: 30px;
    font-size: 28px;
    letter-spacing: -0.84px;
    width: 90%;
}

.homepg .blog-masonry-content .title a {
    display: inline;
    background-image: linear-gradient(var(--tg-white), var(--tg-white)), linear-gradient(var(--tg-white), var(--tg-white));
    background-size: 0% 1.5px, 0 1.5px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 0.4s linear;
}

.homepg .blog-masonry-content .title a:hover {
    color: var(--tg-white);
    color: inherit;
    background-size: 0 1.5px, 100% 1.5px;
}

.homepg .blog-masonry-content p {
    margin-bottom: 55px;
    width: 90%;
}

.homepg .blog-masonry-content .content-bottom {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
}

.homepg .blog-author a {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: var(--tg-white);
}

.homepg .blog-author a img {
    border-radius: 50%;
    width: 40px;
    flex: 0 0 auto;
    margin-right: 20px;
}

.homepg .blog-author a:hover {
    color: var(--tg-primary-color);
}

.homepg .blog-post-wrap .read-more-btn {
    margin-left: auto;
}

.homepg .blog-post-wrap .read-more-btn a {
    font-size: 16px;
    color: var(--tg-secondary-color);
    background: var(--tg-white);
    display: inline-flex;
    align-items: center;
    border-radius: 30px;
    gap: 10px;
    padding: 7px 29px;
}

.homepg .blog-post-wrap .read-more-btn a i {
    transform: rotate(-45deg);
    transition: all 0.3s ease-out 0s;
}

.homepg .blog-post-wrap .read-more-btn a:hover {
    background: var(--tg-primary-color);
    color: var(--tg-white);
}

.homepg .blog-post-wrap .read-more-btn a:hover i {
    transform: rotate(0);
}

.homepg .blog-standard-post {
    background: var(--tg-secondary-color);
    padding: 20px 20px 60px 20px;
    border-radius: 30px;
    border: 1px solid rgba(43 44 56 / 46%);
    margin-bottom: 60px;
}

.homepg .blog-standard-thumb {
    margin-bottom: 30px;
}

.homepg .blog-standard-thumb img {
    border-radius: 20px;
    mix-blend-mode: luminosity;
}

.homepg .blog-standard-content {
    padding: 0 40px 0 40px;
}

.homepg .blog-standard-post:not(.has-post-thumbnail) {
    padding: 50px 50px;
}

.homepg .blog-standard-post:not(.has-post-thumbnail) .blog-standard-content {
    padding: 0 0;
}

.homepg .blog-meta .list-wrap .blog-author a {
    font-size: 16px;
    font-weight: 500;
    color: var(--tg-white);
}

.homepg .blog-standard-content .title {
    font-size: 28px;
    margin-bottom: 20px;
}

.homepg .blog-standard-content .title a {
    display: inline;
    background-image: linear-gradient(var(--tg-white), var(--tg-white)), linear-gradient(var(--tg-white), var(--tg-white));
    background-size: 0% 1.5px, 0 1.5px;
    background-position: 100% 100%, 0 100%;
    background-repeat: no-repeat;
    transition: background-size 0.4s linear;
}

.homepg .blog-standard-content .title a:hover {
    color: var(--tg-white);
    color: inherit;
    background-size: 0 1.5px, 100% 1.5px;
}

/* blog-sidebar */
.homepg .blog-widget {
    border-radius: 15px;
    border: 1px solid rgba(43 44 56 / 46%);
    background: var(--tg-secondary-color);
    padding: 45px 40px 50px;
    margin-bottom: 50px;
}

.homepg .blog-widget:last-child {
    margin-bottom: 0;
}

.homepg .widget-title {
    font-size: 22px;
    padding-bottom: 12px;
    margin: 0 0 25px;
    text-transform: capitalize;
    letter-spacing: 0;
    font-weight: 700;
    font-family: var(--tg-heading-font-family);
    color: var(--tg-heading-font-color);
    position: relative;
    line-height: 1.2;
}

.homepg .widget-title::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 30px;
    height: 4px;
    border-radius: 4px;
    background: var(--tg-primary-color);
}

.homepg .sidebar-search-form form {
    position: relative;
}

.homepg .sidebar-search-form form input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #292A37;
    background: transparent;
    font-size: 18px;
    line-height: 1;
    padding: 18px 55px 18px 30px;
    height: 60px;
    color: var(--tg-white);
}

.homepg .sidebar-search-form form input::placeholder {
    font-size: 18px;
    color: var(--tg-paragraph-color);
}

.homepg .sidebar-search-form form button {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    background: transparent;
    font-size: 18px;
    padding: 0;
    line-height: 1;
    color: var(--tg-paragraph-color);
}

.homepg .sidebar-search-form form button:hover {
    color: var(--tg-white);
}

.homepg .sidebar-cat-list .list-wrap li a {
    font-size: 18px;
    font-weight: 500;
    color: var(--tg-white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 14px;
    margin-bottom: 14px;
    position: relative;
}

.homepg .sidebar-cat-list .list-wrap li a:hover {
    color: var(--tg-paragraph-color);
}

.homepg .sidebar-cat-list .list-wrap li a::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    background: #292A37;
    transition: all 0.3s ease-out 0s;
}

.homepg .sidebar-cat-list .list-wrap li a:hover::before {
    width: 100%;
}

.homepg .sidebar-cat-list .list-wrap li:last-child a {
    margin: 0;
    padding: 0;
}

.homepg .sidebar-cat-list .list-wrap li:last-child a::before {
    display: none;
}

.homepg .rc-post-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 30px;
}

.homepg .rc-post-item:last-child {
    margin-bottom: 0;
}

.homepg .rc-post-item .thumb {
    width: 80px;
    flex: 0 0 auto;
    margin-right: 20px;
}

.homepg .rc-post-item .thumb img {
    border-radius: 5px;
    mix-blend-mode: luminosity;
    height: 80px;
    width: 100%;
    object-fit: cover;
}

.homepg .rc-post-item .date {
    display: block;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    color: var(--tg-body-font-color);
    margin-bottom: 10px;
}

.homepg .rc-post-item .date i {
    margin-right: 5px;
}

.homepg .rc-post-item .content {
    overflow: hidden;
}

.homepg .rc-post-item .title {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.35;
}

.homepg .sidebar-follow-wrap .list-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #292A37;
    padding-bottom: 40px;
    margin-bottom: 40px;
}

.homepg .sidebar-follow-wrap .list-wrap li a {
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #292A37;
    border-radius: 50%;
    color: var(--tg-white);
}

.homepg .sidebar-follow-wrap .list-wrap li a:hover {
    background: var(--tg-white);
    border-color: var(--tg-white);
    color: var(--tg-secondary-color);
}

.homepg .sidebar-follow-wrap .btn:hover {
    background: var(--tg-primary-color);
}

.homepg .sidebar-tag-list .list-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.homepg .sidebar-tag-list .list-wrap li a {
    color: rgba(146, 147, 158, 0.50);
    font-size: 14px;
    letter-spacing: 0.7px;
    border: 1px solid rgba(43 44 56 / 46%);
    display: block;
    padding: 7px 18px;
    line-height: 1;
    border-radius: 3px;
}

.homepg .sidebar-tag-list .list-wrap li a:hover {
    background: var(--tg-white);
    color: var(--tg-secondary-color);
    border-color: var(--tg-white);
}

.homepg .blog-sidebar {
    margin-left: 15px;
}


/* blog-details */
.homepg .blog-standard-post.blog-details-wrap {
    background: transparent;
    padding: 0 0;
    border-radius: 0 0;
    border: none;
    margin: 0 0 60px;
}

.homepg .blog-details-thumb {
    margin-bottom: 30px;
}

.homepg .blog-details-thumb img {
    height: 420px;
    object-fit: cover;
    border-radius: 30px;
    mix-blend-mode: luminosity;
}

.homepg .blog-details-content .title {
    margin-bottom: 30px;
    font-size: 28px;
}

.homepg .blog-details-content>p {
    margin-bottom: 25px;
}

.homepg blockquote,
.homepg .wp-block-quote,
.homepg .blog-details-content blockquote {
    background: var(--tg-secondary-color);
    border-radius: 15px;
    border: 1px solid rgba(43 44 56 / 46%);
    padding: 40px 40px;
    margin: 40px 0 40px 0;
}

.homepg blockquote p,
.homepg .wp-block-quote p,
.homepg .blog-details-content blockquote p {
    font-size: 24px;
    font-weight: 600;
    font-family: var(--tg-heading-font-family);
    color: var(--tg-white);
    margin-bottom: 15px;
}

.homepg blockquote cite,
.homepg .wp-block-quote cite,
.homepg .blog-details-content blockquote cite {
    font-style: normal;
    font-size: 16px;
}

.homepg .blog-details-inner-img {
    margin-top: 20px;
}

.homepg .blog-details-inner-img img {
    border-radius: 20px;
    height: 290px;
    object-fit: cover;
    mix-blend-mode: luminosity;
    margin-bottom: 30px;
}

.homepg .blog-details-bottom {
    padding: 30px 0;
    border-top: 1px solid #292A37;
    border-bottom: 1px solid #292A37;
    margin: 30px 0 0;
    clear: both;
    overflow: hidden;
}

.homepg .tg-post-tag {
    display: flex;
    align-items: flex-start;
}

.homepg .tg-post-tag ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.homepg .blog-details-social .social-title,
.homepg .tg-post-tag .tag-title {
    font-size: 16px;
    color: var(--tg-heading-font-color);
    margin-right: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    flex: 0 0 auto;
}

.homepg .tg-post-tag ul li {
    list-style: none !important;
    margin-bottom: 0 !important;
}

.homepg .tg-post-tag ul li a {
    font-weight: 500;
    text-transform: capitalize;
    color: var(--tg-body-font-color);
    display: block;
    padding: 6px 18px 8px 18px;
    border: 1px solid rgba(43 44 56 / 46%);
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    line-height: 1.2;
}

.homepg .tg-post-tag ul li a:hover {
    background: var(--tg-primary-color);
    border-color: var(--tg-primary-color);
    color: #fff;
}

.homepg .blog-details-social {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.homepg .blog-details-social .list-wrap {
    display: flex;
    align-items: center;
    gap: 15px;
}

.homepg .blog-details-social .list-wrap li a {
    font-size: 15px;
    color: var(--tg-body-font-color);
}

.homepg .blog-details-social .list-wrap li a svg {
    display: block;
}

.homepg .blog-details-social .list-wrap li a:hover {
    color: var(--tg-white) !important;
}

.homepg .post-tags .list-wrap {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.homepg .post-tags .list-wrap li a {
    color: rgba(146, 147, 158, 0.50);
    font-size: 14px;
    letter-spacing: 0.7px;
    border: 1px solid rgba(43 44 56 / 46%);
    display: block;
    padding: 7px 18px;
    line-height: 1;
    border-radius: 3px;
}

.homepg .post-tags .list-wrap li a:hover {
    background: var(--tg-white);
    color: var(--tg-secondary-color);
    border-color: var(--tg-white);
}

.homepg .blog-post-share .list-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.homepg .blog-post-share .list-wrap li a {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid #292A37;
    font-size: 12px;
    color: var(--tg-white);
}

.homepg .blog-post-share .list-wrap li a:hover {
    background: var(--tg-white);
    color: var(--tg-secondary-color);
    border-color: var(--tg-white);
}

.homepg .blog-avatar-wrap {
    border: 1px solid rgba(43 44 56 / 46%);
    border-radius: 20px;
    display: flex;
    align-items: flex-start;
    padding: 42px 42px;
    background: var(--tg-secondary-color);
}

.homepg .blog-avatar-img {
    width: 80px;
    flex: 0 0 auto;
    margin-right: 30px;
}

.homepg .blog-avatar-img img {
    border-radius: 50%;
}

.homepg .blog-avatar-info .name {
    font-size: 20px;
    margin-bottom: 15px;
}

.homepg .blog-avatar-info p {
    margin-bottom: 0;
}

.homepg .comments-wrap {
    margin-bottom: 90px;
}

.homepg .comments-wrap-title {
    font-size: 28px;
    margin-bottom: 50px;
    letter-spacing: -0.84px;
}

.homepg .comments-box {
    display: flex;
    align-items: flex-start;
    margin-bottom: 50px;
}

.homepg .comments-avatar {
    width: 70px;
    flex: 0 0 auto;
    margin-right: 30px;
}

.homepg .comment-text .avatar-name {
    margin-bottom: 20px;
}

.homepg .comment-text .avatar-name .name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    font-size: 18px;
}

.homepg .comment-text .avatar-name .name .comment-reply-link {
    font-size: 12px;
    background: var(--tg-secondary-color);
    border: 1px solid rgba(43 44 56 / 46%);
    display: block;
    color: var(--tg-paragraph-color);
    border-radius: 15px;
    padding: 7px 18px;
}

.homepg .comment-text .avatar-name .name .comment-reply-link:hover {
    background: var(--tg-white);
    color: var(--tg-secondary-color);
    border-color: var(--tg-white);
}

.homepg .comment-text .avatar-name .date {
    font-size: 14px;
    display: block;
    line-height: 1;
}

.homepg .comments-text p {
    margin-bottom: 0;
}

.homepg .latest-comments .children {
    margin: 0 0 0 20px;
}

.homepg .comment-respond {
    border: 1px solid rgba(43 44 56 / 46%);
    border-radius: 15px;
    padding: 45px 50px 50px;
    background: var(--tg-secondary-color);
}

.homepg .comment-reply-title {
    font-size: 28px;
    margin-bottom: 20px;
    letter-spacing: -0.84px;
}

.homepg .comment-notes {
    margin-bottom: 35px;
}

.homepg .comment-form .form-grp {
    margin-bottom: 30px;
}

.homepg .comment-form .form-grp label {
    display: block;
    font-size: 18px;
    font-weight: 700;
    color: var(--tg-white);
    line-height: 1;
    font-family: var(--tg-heading-font-family);
    margin-bottom: 20px;
}

.homepg .comment-form .form-grp textarea,
.homepg .comment-form .form-grp input {
    width: 100%;
    border: none;
    border-radius: 15px;
    background: rgba(255 255 255 / 7%);
    padding: 12px 20px;
    font-size: 16px;
    color: var(--tg-white);
    height: 50px;
    display: block;
}

.homepg .comment-form .form-grp input {
    border-radius: 30px;
}

.homepg .comment-form .form-grp input::placeholder,
.homepg .comment-form .form-grp textarea::placeholder {
    color: #AAABB2;
}

.homepg .comment-form .form-grp textarea {
    max-height: 155px;
    min-height: 155px;
}

.homepg .comment-form .checkbox-grp {
    display: flex;
    align-items: center;
    margin: 40px 0;
}

.homepg .comment-form .checkbox-grp input {
    width: 16px;
    margin-right: 9px;
    height: 16px;
    cursor: pointer;
    background: transparent;
    padding: 0;
    border-radius: 2px;
    border: 1px solid #92939E;
    flex: 0 0 auto;
}

.homepg .comment-form .checkbox-grp input:focus {
    box-shadow: none;
}

.homepg .comment-form .checkbox-grp label {
    font-weight: 400;
    font-size: 14px;
    color: var(--tg-paragraph-color);
    user-select: none;
    margin-bottom: 0;
}

.homepg .comment-form .btn:hover {
    background: var(--tg-primary-color);
}

.homepg .comments-avatar img {
    border-radius: 50%;
}

/*=============================
	17. Pagination
===============================*/
.homepg .pagination-wrap ul {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px 10px;
}

.homepg .pagination-wrap ul li a {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    background: transparent;
    border-radius: 50%;
    font-size: 18px;
    color: var(--tg-white);
    font-weight: 700;
    border: 1px solid rgba(43 44 56 / 46%);
    line-height: 1;
}

.homepg .pagination-wrap ul li.active a,
.homepg .pagination-wrap ul li a:hover {
    background: var(--tg-secondary-color);
}

/*=============================
	18. Contact
===============================*/
.homepg .contact-info-item {
    text-align: center;
    background: var(--tg-secondary-color);
    border-radius: 30px;
    margin-bottom: 30px;
    border: 1px solid rgba(43 44 56 / 46%);
    padding: 60px 30px 50px;
}

.homepg .contact-info-item .icon {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #292A37;
    margin: 0 auto 20px;
    font-size: 20px;
    color: var(--tg-white);
    line-height: 1;
}

.homepg .contact-info-item .icon svg {
    fill: white;
    width: 24px;
}

.homepg .contact-info-item .content .title {
    font-size: 20px;
    margin-bottom: 20px;
}

.homepg .contact-info-item .content p {
    margin-bottom: 0;
    font-size: 20px;
}

.homepg .contact-info-item .content a {
    color: var(--tg-paragraph-color);
}

.homepg .contact-info-item .content a:hover {
    color: var(--tg-primary-color);
}

.homepg .contact-form-wrap .col-43 {
    width: 43%;
    flex: 0 0 auto;
}

.homepg .contact-form-wrap .col-57 {
    width: 57%;
    flex: 0 0 auto;
}

.homepg .contact-map {
    width: 100%;
    height: 100%;
}

.homepg .contact-map iframe {
    width: 100%;
    height: 100%;
    border-radius: 15px 0 0 15px;
}

.homepg .contact-form .title {
    font-size: 28px;
    margin-bottom: 30px;
    font-weight: 500;
}

.homepg .contact-form .row {
    --bs-gutter-x: 20px;
}

.homepg .contact-form .form-grp {
    margin-bottom: 20px;
}

.homepg .contact-form .form-grp textarea,
.homepg .contact-form .form-grp input {
    width: 100%;
    background: rgba(255 255 255 / 7%);
    display: block;
    border: none;
    padding: 15px 15px;
    border-radius: 30px;
    color: var(--tg-white);
    line-height: 1;
    height: 50px;
}

.homepg .contact-form .form-grp textarea::placeholder,
.homepg .contact-form .form-grp input::placeholder {
    color: #AAABB2;
}

.homepg .contact-form .form-grp textarea {
    min-height: 160px;
    max-height: 160px;
}

.homepg .contact-form .btn:hover {
    background: var(--tg-primary-color);
}

.homepg .ajax-response.error {
    color: red;
    margin-top: 20px;
}

.homepg .ajax-response.success {
    color: green;
}

/*=============================
	19. Footer
===============================*/
.homepg .footer-bg {
    background-size: cover;
    background-position: center;
    border-radius: 20px 20px 0 0;
    position: relative;
    margin: 0 20px 0;
    overflow: hidden;
}

.homepg .footer-bg::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, var(--tg-primary-color) 0%, var(--tg-green) 47.60%, var(--tg-black) 65.23%);
    z-index: -2;
    transform: rotate(180deg);
}

.homepg .footer-top {
    padding: 110px 0 30px;
    z-index: 2;
    position: relative;
}

.homepg .footer-widget {
    margin-bottom: 40px;
}

.homepg .footer-widget .fw-title {
    margin-bottom: 25px;
    font-size: 18px;
    text-transform: capitalize;
}

.homepg .footer-link .list-wrap li {
    margin-bottom: 15px;
}

.homepg .footer-link .list-wrap li:last-child {
    margin-bottom: 0;
}

.homepg .footer-link .list-wrap li a {
    color: #CBD0D4;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.3;
    position: relative;
}

.homepg .footer-link .list-wrap li a::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: var(--tg-white);
    -webkit-transform-origin: right top;
    -ms-transform-origin: right top;
    transform-origin: right top;
    -webkit-transform: scale(0, 1);
    -ms-transform: scale(0, 1);
    transform: scale(0, 1);
    -webkit-transition: transform 0.4s cubic-bezier(1, 0, 0, 1);
    transition: transform 0.4s cubic-bezier(.74, .72, .27, .24);
}

.homepg .footer-link .list-wrap li a:hover::before {
    -webkit-transform-origin: left top;
    -ms-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    transform: scale(1, 1);
}

.homepg .footer-newsletter {
    position: relative;
}

.homepg .footer-newsletter input {
    width: 100%;
    border: none;
    background: var(--tg-secondary-color);
    padding: 24px 80px 24px 18px;
    font-size: 15px;
    color: var(--tg-white);
    font-weight: 400;
    border-radius: 10px;
    height: 72px;
}

.homepg .footer-newsletter input::placeholder {
    font-size: 15px;
    color: #AAABB2;
    font-weight: 400;
}

.homepg .footer-newsletter button {
    width: 65px;
    height: 72px;
    border: none;
    padding: 0;
    background: var(--tg-white);
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    font-size: 24px;
    color: var(--tg-secondary-color);
}

.homepg .footer-newsletter button:hover {
    color: var(--tg-white);
    background: var(--tg-primary-color);
}

.homepg .footer-bottom,
.homepg .footer-bottom-border {
    position: relative;
}

.homepg .footer-bottom {
    padding: 30px 0;
}

.homepg .footer-bottom::before,
.homepg .footer-bottom-border::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(225deg, var(--tg-black) 0%, rgba(255, 255, 255, 0.45) 54.98%, rgba(1, 3, 20, 0.99) 100%);
}

.homepg .border-none::before {
    display: none;
}

.homepg .copyright-text p {
    margin-bottom: 0;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: var(--tg-white);
}

.homepg .footer-shape-wrap img {
    position: absolute;
}

.homepg .footer-shape-wrap img:nth-child(1) {
    left: 0;
    top: 35%;
}

.homepg .footer-shape-wrap img:nth-child(2) {
    right: 5%;
    top: 43%;
}


/*=============================
	20. Preloader
===============================*/
.homepg #preloader {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--tg-black);
    z-index: 999;
    width: 100%;
    height: 100%;
}

.homepg .spinner {
    margin: 100px auto;
    width: 50px;
    height: 40px;
    text-align: center;
    font-size: 10px;
}

.homepg .spinner>div {
    background-color: var(--tg-primary-color);
    height: 100%;
    width: 6px;
    display: inline-block;
    -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
    animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.homepg .spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.homepg .spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

.homepg .spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.homepg .spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
    0%, 40%, 100% {
        -webkit-transform: scaleY(0.4);
    }

    20% {
        -webkit-transform: scaleY(1.0);
    }

    ;
}

@keyframes sk-stretchdelay {
    0%, 40%, 100% {
        transform: scaleY(0.4);
        -webkit-transform: scaleY(0.4);
    }

    20% {
        transform: scaleY(1.0);
        -webkit-transform: scaleY(1.0);
    }

    ;
}



/* ============= Update CSS */
.homepg .header-layout1 .custom-container {
    max-width: 1692px;
}

.homepg .header-layout1 .navbar-wrap ul {
    background: transparent;
    border: 0;
    border-radius: 0;
    padding: 0;
}

.homepg .header-layout1 .menu-area {
    padding: 23px 0;
}

.homepg .header-layout1 .sticky-menu.menu-area {
    padding: 15px 0;
}

.homepg .header-layout1 .header-action>ul li {
    margin-left: 50px;
}

.homepg .header-layout1 .header-action ul li:first-child {
    margin-left: 0;
}

.homepg .header-layout1 .header-action .header-login .btn2 {
    padding: 14px 40px;
}

.homepg .dropdown-link {
    position: relative;
    display: inline-block;
}

.homepg .dropdown-link>a {
    color: var(--tg-white);
    font-size: 14px;
    font-weight: 600;
    font-family: var(--tg-body-font-color);
    letter-spacing: -0.42px;
}

.homepg .dropdown-link>a i {
    margin-right: 3px;
    transition: 0.4s;
}

.homepg .dropdown-link>a:hover i {
    color: var(--tg-white) !important;
}

.homepg .dropdown-toggle::after {
    content: "\f107";
    border: none;
    font-family: "Font Awesome 5 Free";
    vertical-align: middle;
    font-weight: 700;
    margin-left: 6px;
}

.homepg .dropdown-menu {
    width: fit-content;
    min-width: auto;
    top: calc(100% + 10px) !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
    padding: 8px 20px !important;
    border-color: #E3E3E3;
}

.homepg .dropdown-menu li {
    padding-right: 0;
    margin-right: 0;
}

.homepg .dropdown-menu li:after {
    display: none;
}

.homepg .dropdown-menu li a {
    display: block;
}

.homepg .dropdown-menu a {
    color: var(--tg-secondary-color) !important;
}

.homepg .dropdown-menu a:hover {
    color: var(--tg-primary-color) !important;
}

.homepg .dropdown-menu:before {
    content: "";
    position: absolute;
    left: 50%;
    top: -7px;
    width: 14px;
    height: 14px;
    margin-left: -7px;
    background-color: var(--tg-white);
    z-index: -1;
    transform: rotate(45deg);
    border-top: 1px solid #E3E3E3;
    border-left: 1px solid #E3E3E3;
}

.homepg .btn5,
.homepg .btn3,
.homepg .btn2 {
    position: relative;
    z-index: 2;
    overflow: hidden;
    justify-content: center;
}

.homepg .btn5:before,
.homepg .btn3:before,
.homepg .btn2:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 101%;
    height: 100%;
    border-radius: inherit;
    background: linear-gradient(82.3deg, var(--tg-primary-color) 10.8%, var(--tg-theme-color2) 94.3%);
    transition: all 0.475s;
    z-index: -1;
    opacity: 0;
}

.homepg .btn5:hover:before,
.homepg .btn3:hover:before,
.homepg .btn2:hover:before {
    opacity: 1;
}

.homepg .btn3:after {
    display: none;
}

.homepg .btn4 {
    background: transparent;
    border: 1px solid var(--tg-white);
    color: var(--tg-white);
}

.homepg .btn4:hover {
    background: var(--tg-white);
    color: var(--tg-secondary-color);
}

.homepg .btn5:after,
.homepg .btn4:after {
    display: none;
}

.homepg .btn5 {
    background: linear-gradient(93.17deg, var(--tg-primary-color) -18.55%, #7E2AFD 163.09%);
    color: var(--tg-white);
    font-weight: 600;
}

.homepg .btn5:before {
    background: linear-gradient(-93.17deg, var(--tg-primary-color) -18.55%, #7E2AFD 163.09%);
}

.homepg .btn5:focus, .homepg .btn5:hover, .homepg .btn5.active {
    color: var(--tg-white);
    background: linear-gradient(93.17deg, var(--tg-primary-color) -18.55%, #7E2AFD 163.09%);
}

.homepg .btn5:focus:before, .homepg .btn5:hover:before, .homepg .btn5.active:before {
    opacity: 1;
}

/* Hero 1 ---------------------------------- */
.homepg .hero-bg-gradient {
    position: absolute;
    left: 50%;
    z-index: -1;
    background: linear-gradient(0.41deg, var(--tg-primary-color) 19.48%, rgba(46, 32, 255, 0.27) -13.62%, var(--tg-black) 267.11%);
    filter: blur(225px);
    top: -1292px;
    width: 1873px;
    height: 2191px;
    transform: translate(-50%, 0);
    border-radius: 0 0 50% 50%;
}

@media (max-width: 1199px) {
    .homepg .hero-bg-gradient {
        top: -1400px;
    }
}

.homepg .ripple-shape {
    transform: skewX(240deg);
    position: relative;
    z-index: -1;
    opacity: 0.3;
}

.homepg .ripple-1,
.homepg .ripple-2,
.homepg .ripple-3,
.homepg .ripple-4,
.homepg .ripple-5,
.homepg .ripple-6,
.homepg .ripple-7 {
    width: 700px;
    height: 700px;
    position: absolute;
    left: 30%;
    top: 0;
    transform: translate(-50%, -50%);
    background-color: transparent;
    border: 1px solid var(--tg-white);
    border-radius: 50%;
    animation: ripple2 10s linear infinite;
    opacity: 0;
    transition: 0.4s;
}

.homepg .ripple-1 {
    animation-delay: 0;
}

.homepg .ripple-2 {
    animation-delay: 1s;
}

.homepg .ripple-3 {
    animation-delay: 3s;
}

.homepg .ripple-4 {
    animation-delay: 4s;
}

.homepg .ripple-4 {
    animation-delay: 5s;
}

.homepg .ripple-5 {
    animation-delay: 6s;
}

.homepg .ripple-6 {
    animation-delay: 7s;
}

.homepg .ripple-7 {
    animation-delay: 8s;
}

.homepg .hero-thumb {
    margin: 0 -47px;
}

@keyframes ripple {
    0% {
        transform: scale(1);
        opacity: 0;
    }

    30% {
        opacity: 1;
    }

    100% {
        transform: scale(2.1);
        opacity: 0;
    }
}

@keyframes ripple2 {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    10% {
        opacity: 0.6;
    }

    30% {
        opacity: 0.4;
    }

    40% {
        opacity: 0.2;
    }

    50% {
        opacity: 0.14;
    }

    100% {
        transform: scale(3.5);
        opacity: 0;
    }
}

.homepg .hero-title {
    margin-bottom: 0;
    color: var(--tg-white);
    font-weight: 700;
    letter-spacing: -2.8px;
    font-size: 70px;
    line-height: 1.2857;
}

@media (max-width: 1399px) {
    .homepg .hero-title {
        font-size: 60px;
    }
}

@media (max-width: 1299px) {
    .homepg .hero-title {
        font-size: 50px;
        letter-spacing: normal;
    }
}

@media (max-width: 991px) {
    .homepg .hero-title {
        font-size: 40px;
    }
}

@media (max-width: 767px) {
    .homepg .hero-title {
        font-size: 36px;
        letter-spacing: normal;
    }
}

@media (max-width: 375px) {
    .homepg .hero-title {
        font-size: 34px;
    }
}

.homepg .hero-style1 {
    position: relative;
    z-index: 6;
    padding: 220px 0 30px;
}

.homepg .hero-style1 .btn-wrap {
    margin-top: 43px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px 15px;
}

@media (max-width: 991px) {
    .homepg .hero-style1 {
        padding: 170px 0 100px;
        text-align: center;
    }

    .homepg .hero-style1 .btn-wrap {
        justify-content: center;
    }
}

@media (max-width: 767px) {
    .homepg .hero-style1 {
        padding: 150px 0 100px;
    }
}

@media (max-width: 575px) {
    .homepg .hero-style1 {
        padding: 150px 0 80px;
    }
}

.homepg .hero-countdown-wrap .banner-countdown-wrap {
    margin-top: 70px;
}

.homepg .hero-countdown-wrap .banner-countdown-wrap .time-count {
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
    background: var(--tg-secondary-color);
    border-radius: 10px;
    min-width: 110px;
    color: var(--tg-paragraph-color);
    font-weight: 500;
    font-size: 14px;
    padding: 15px 15px 20px;
    position: relative;
    line-height: 1.4;
}

.homepg .hero-countdown-wrap .banner-countdown-wrap .time-count span {
    line-height: .9;
    font-size: 35px;
    font-weight: 700;
    color: var(--tg-white);
    font-family: var(--tg-heading-font-family);
    display: block;
    margin-top: 7px;
    margin-bottom: 0;
}

@media (max-width: 991px) {
    .homepg .coming-time {
        gap: 30px 49px;
    }

    .homepg .coming-time .time-count:before,
    .homepg .coming-time .time-count:after {
        display: none;
    }
}

@media (max-width: 767px) {
    .homepg .coming-time {
        gap: 30px 30px;
    }
}

.homepg .hero-countdown-wrap .hero-countdown-wrap-title {
    color: var(--tg-white);
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: -0.25em;
}

@media (max-width: 991px) {
    .homepg .hero-countdown-wrap .hero-countdown-wrap-title {
        font-size: 30px;
    }
}

@media (max-width: 767px) {
    .homepg .hero-countdown-wrap .hero-countdown-wrap-title {
        font-size: 24px;
    }
}

.homepg .hero-countdown-wrap .skill-feature_list {
    list-style: none;
    padding: 0;
    margin: 37px 0 25px;
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.homepg .hero-countdown-wrap .skill-feature_list li {
    color: var(--tg-white);
    text-align: left;
    position: relative;
}

@media (max-width: 767px) {
    .homepg .hero-countdown-wrap .skill-feature_list li {
        font-size: 14px;
    }
}

.homepg .hero-countdown-wrap .skill-feature_list li:after {
    content: "";
    position: absolute;
    width: 22px;
    height: 19px;
    border-bottom: solid 19px var(--tg-secondary-color);
    border-left: solid 11px transparent;
    border-right: solid 11px transparent;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 26px);
}

@media (max-width: 575px) {
    .homepg .hero-countdown-wrap .skill-feature_list li:after {
        display: none;
    }
}

.homepg .hero-countdown-wrap .skill-feature_list li:first-child {
    width: 245px;
    padding-left: 24px;
}

@media (max-width: 1199px) {
    .homepg .hero-countdown-wrap .skill-feature_list li:first-child {
        width: 190px;
    }
}

@media (max-width: 991px) {
    .homepg .hero-countdown-wrap .skill-feature_list li:first-child {
        width: 170px;
        padding-left: 0;
    }
}

@media (max-width: 767px) {
    .homepg .hero-countdown-wrap .skill-feature_list li:first-child {
        width: 140px;
    }
}

@media (max-width: 575px) {
    .homepg .hero-countdown-wrap .skill-feature_list li:first-child {
        width: 100px;
    }
}

.homepg .hero-countdown-wrap .skill-feature_list li:nth-child(2) {
    width: 240px;
    text-align: center;
}

@media (max-width: 1199px) {
    .homepg .hero-countdown-wrap .skill-feature_list li:nth-child(2) {
        width: 200px;
    }
}

@media (max-width: 991px) {
    .homepg .hero-countdown-wrap .skill-feature_list li:nth-child(2) {
        width: 140px;
    }
}

@media (max-width: 575px) {
    .homepg .hero-countdown-wrap .skill-feature_list li:nth-child(2) {
        width: 100px;
    }
}

.homepg .hero-countdown-wrap .skill-feature_list li:last-child {
    margin: 0 auto;
}

.homepg .hero-countdown-wrap .skill-feature_list li span {
    display: block;
}

.homepg .hero-countdown-wrap .skill-feature_list li h4 {
    color: var(--tg-white);
    margin-bottom: 0;
}

.homepg .hero-countdown-wrap .skill-feature_list.style2 {
    margin-top: 17px;
    align-items: start;
}

.homepg .hero-countdown-wrap .skill-feature_list.style2 li:first-child {
    padding-left: 0;
    text-align: center;
}

.homepg .hero-countdown-wrap .skill-feature_list.style2 li:after {
    display: none;
}

.homepg .hero-countdown-wrap .skill-feature {
    background: var(--tg-secondary-color);
    border-radius: 8px;
    padding: 43px 36px 43px 24px;
    display: flex;
    gap: 27px;
    position: relative;
    z-index: 1;
    margin-bottom: 0 !important;
}

@media (max-width: 767px) {
    .homepg .hero-countdown-wrap .skill-feature {
        padding: 40px 20px;
    }
}

@media (max-width: 575px) {
    .homepg .hero-countdown-wrap .skill-feature {
        background: transparent;
        padding: 0;
    }
}

.homepg .hero-countdown-wrap .skill-feature:after, .homepg .hero-countdown-wrap .skill-feature:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 0.5px;
    background: #1C1D34;
    top: 0;
    left: 22%;
    z-index: -1;
    opacity: 0.5;
}

@media (max-width: 1299px) {
    .homepg .hero-countdown-wrap .skill-feature:after, .homepg .hero-countdown-wrap .skill-feature:before {
        left: 26%;
    }
}

@media (max-width: 1199px) {
    .homepg .hero-countdown-wrap .skill-feature:after, .homepg .hero-countdown-wrap .skill-feature:before {
        left: 25%;
    }
}

@media (max-width: 991px) {
    .homepg .hero-countdown-wrap .skill-feature:after, .homepg .hero-countdown-wrap .skill-feature:before {
        left: 28%;
    }
}

@media (max-width: 767px) {
    .homepg .hero-countdown-wrap .skill-feature:after, .homepg .hero-countdown-wrap .skill-feature:before {
        left: 31%;
    }
}

@media (max-width: 575px) {
    .homepg .hero-countdown-wrap .skill-feature:after, .homepg .hero-countdown-wrap .skill-feature:before {
        display: none;
    }
}

.homepg .hero-countdown-wrap .skill-feature:after {
    left: 43.5%;
}

@media (max-width: 1299px) {
    .homepg .hero-countdown-wrap .skill-feature:after {
        left: 52%;
    }
}

@media (max-width: 767px) {
    .homepg .hero-countdown-wrap .skill-feature:after {
        left: 61%;
    }
}

.homepg .hero-countdown-wrap .skill-feature .progress {
    border: 1px solid #1C1D34;
    background: transparent;
    height: 26px;
    border-radius: 13px;
    width: 100%;
}

.homepg .hero-countdown-wrap .skill-feature .progress .progress-bar {
    background: linear-gradient(270deg, var(--tg-primary-color) -3.08%, rgba(46, 32, 255, 0.27) 46.64%, var(--tg-black) 99.52%);
    height: 16px;
    border-radius: 25px;
    margin: 4px;
}

.homepg .hero-countdown-wrap .skill-feature .progress-value-max {
    flex: none;
    color: var(--tg-white);
}

@media (max-width: 767px) {
    .homepg .hero-countdown-wrap .skill-feature .progress-value-max {
        display: none;
    }
}

/* Brand */
.homepg .brand-item-wrap.style2:after {
    content: "";
    display: inline-block;
    position: absolute;
    width: 33px;
    height: 33px;
    background: var(--tg-black);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    transform: rotate(45deg) translate(-50%, 50%);
    left: 50%;
    top: -17px;
    z-index: 1;
}

.homepg .brand-item-wrap.style2:before {
    display: none;
}

/* Progressbar */
.homepg .skill-feature {
    position: relative;
}

.homepg .skill-feature:not(:last-child) {
    margin-bottom: 27px;
}

.homepg .skill-feature_title {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 7px;
}

.homepg .skill-feature .progress {
    background: rgba(255, 255, 255, 0.12);
    border-radius: 25px;
    overflow: visible;
    height: 15px;
}

.homepg .skill-feature .progress .progress-bar {
    background: linear-gradient(269.91deg, var(--tg-primary-color) 2.78%, rgba(46, 32, 255, 0.27) 47.66%, var(--tg-secondary-color) 95.38%);
    border-radius: 25px;
    height: 8px;
    margin: 3.5px 4px;
}

.homepg .skill-feature .progress .progress-value {
    font-size: 14px;
    font-weight: 600;
    font-family: var(--tg-heading-font-family);
    position: relative;
    top: -29px;
    transform: translate(-100%, 0);
}

.homepg .wcu-thumb img {
    width: 256px;
    height: 256px;
    object-fit: contain;
}

.homepg .wcu-amount-quantity {
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin-bottom: 48px;
}

.homepg .wcu-amount-quantity .title {
    font-size: 24px;
    font-weight: 600;
    color: var(--tg-white);
    margin-bottom: 10px;
}

@media (max-width: 575px) {
    .homepg .wcu-amount-quantity .title {
        font-size: 20px;
    }
}

.homepg .wcu-price-progress-wrap {
    list-style: none;
    padding: 0;
    margin: 0;
}

.homepg .wcu-price-progress-wrap li:not(:last-child) {
    margin-bottom: 82px;
}

.homepg .wcu-price-progress-wrap .progress-wrap-title {
    font-size: 16px;
    font-weight: 500;
    font-family: var(--tg-body-font-family);
    margin-bottom: 5px;
}

.homepg .wcu-price-progress-wrap .progress-wrap-text {
    font-size: 14px;
    color: var(--tg-white);
    margin-bottom: 26px;
}

.homepg .wcu-price-progress-wrap .skill-feature .progress:after {
    content: "";
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    right: 0;
    background: var(--tg-primary-color);
    transform: translate(-50%, -50%);
    top: 50%;
}

.homepg .wcu-price-progress-wrap .skill-feature .progress-value {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 400;
    color: var(--tg-white);
    margin-right: 65px;
    margin-top: 9px;
    letter-spacing: 0.02em;
    margin-bottom: -0.3em;
}

/* Gradient BG */
.homepg .bg-gradient-1:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(46, 32, 255, 0.47), transparent 50%);
    filter: blur(175px);
    inset: 0;
}

.homepg .bg-gradient-1 img {
    width: 100%;
}

.homepg .bg-gradient-2:after {
    content: '';
    position: absolute;
    background: linear-gradient(to top, var(--tg-primary-color) 2%, rgba(46, 32, 255, 0.67), transparent 25%);
    filter: blur(175px);
    inset: -100px 0 0 0;
}

@media (min-width: 1922px) {
    .homepg .bg-gradient-2:after {
        inset: 0;
    }
}

.homepg .bg-gradient-2 img {
    width: 100%;
}

.homepg .bg-gradient-3:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, var(--tg-primary-color) -5.52%, rgba(46, 32, 255, 0.27) 56.38%, var(--tg-secondary-color) 207.11%);
    filter: blur(175px);
    inset: 100px 0 0;
}

@media (min-width: 1922px) {
    .homepg .bg-gradient-3:after {
        inset: 0;
    }
}

.homepg .bg-gradient-3 img {
    width: 100%;
}

.homepg .bg-gradient-4:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, var(--tg-primary-color) -5.52%, rgba(46, 32, 255, 0.27) 46.38%, var(--tg-secondary-color) 217.11%);
    filter: blur(175px);
    inset: 350px 0 0;
}

@media (min-width: 1922px) {
    .homepg .bg-gradient-4:after {
        inset: 0;
    }
}

@media (max-width: 1500px) {
    .homepg .bg-gradient-4:after {
        inset: 0;
    }
}

@media (max-width: 525px) {
    .homepg .bg-gradient-4 {
        top: 30%;
    }
}

.homepg .bg-gradient-4 img {
    width: 100%;
}

.homepg .bg-gradient-5 {
    position: absolute;
    z-index: -1;
    inset: 0;
    background: linear-gradient(180deg, #7136B8 0%, rgba(16, 11, 33, 0.3) 100%);
}

.homepg .tg-bg-gradient {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
}

.homepg .tg-bg-gradient img {
    pointer-events: none;
}

/* Feature Card ---------------------------------- */
.homepg .feature-card {
    box-shadow: 0px 16px 18px rgba(1, 12, 50, 0.1);
    border-radius: 15px;
    max-width: 570px;
    min-height: 206px;
    margin-left: auto;
    display: flex;
    padding: 40px;
    gap: 32px;
    border: 1px solid rgba(255, 255, 255, 0.12);
}

.homepg .feature-card:not(:last-child) {
    margin-bottom: 25px;
}

@media (max-width: 991px) {
    .homepg .feature-card {
        margin-left: 0;
        max-width: none;
    }
}

.homepg .feature-card-details {
    max-width: 220px;
    margin-bottom: -0.4em;
}

.homepg .feature-card-icon {
    background: var(--tg-primary-color);
    border-radius: 50%;
    flex: none;
    width: 82px;
    height: 82px;
    line-height: 82px;
    text-align: center;
}

.homepg .feature-card-icon img {
    transition: 0.4s;
}

.homepg .feature-card-title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.03em;
    line-height: 1.5em;
    margin-bottom: 18px;
}

.homepg .feature-card-text {
    margin-bottom: 5px;
    font-weight: 500;
}

.homepg .feature-card-text:last-child {
    margin-bottom: 0;
}

.homepg .feature-card:hover .feature-card-icon img {
    transform: rotateY(180deg);
}

@media (max-width: 767px) {
    .homepg .feature-card {
        min-height: auto;
    }
}

@media (max-width: 375px) {
    .homepg .feature-card {
        padding: 30px;
        gap: 20px;
        flex-wrap: wrap;
    }
}

/* Intro Area ------ */
.homepg #intro-thumb1 {
    position: relative;
    z-index: 1;
}

.homepg #intro-thumb1:after {
    content: "";
    position: absolute;
    width: 479.43px;
    height: 71.62px;
    background: linear-gradient(0.41deg, var(--tg-primary-color) 7.48%, rgba(46, 32, 255, 0.27) 36.38%, var(--tg-secondary-color) 67.11%);
    filter: blur(23px);
    border-radius: 50%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: -1;
}

.homepg .elementor-element.intro-wrap-title {
    display: flex;
    align-items: baseline;
}

.homepg .intro-wrap-title:before {
    content: "";
    position: relative;
    display: inline-block;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background: var(--tg-primary-color);
    margin-right: 9px;
    flex: 0 0 auto;
}

/* Roadmap 2 ---------------------------------- */
.homepg .roadmap-slider1 {
    position: relative;
    left: calc((100% - 1320px) / 2);
}

.homepg .roadMap-active2 .col-lg-4 {
    padding: 0 15px;
}

@media (max-width: 1500px) {
    .homepg .roadmap-slider1 {
        left: calc((100% - 1290px) / 2);
    }
}

@media (max-width: 1199px) {
    .homepg .roadmap-slider1 {
        left: calc((100% - 960px) / 2);
    }
}

@media (max-width: 991px) {
    .homepg .roadmap-slider1 {
        left: 0;
    }
}

@media (max-width: 767px) {}

@media (max-width: 575px) {}

.homepg .slider-arrow {
    display: inline-block;
    padding: 0;
    background-color: var(--tg-white);
    border: 1px solid #EBEDF1;
    color: var(--tg-secondary-color);
    position: absolute;
    top: 50%;
    left: var(--pos-x, -120px);
    width: var(--icon-size, 55px);
    height: var(--icon-size, 55px);
    line-height: var(--icon-size, 55px);
    font-size: var(--icon-font-size, 18px);
    margin-top: calc(var(--icon-size, 55px) / -2);
    z-index: 3;
    border-radius: 50%;
}

.homepg .slider-arrow.next-btn {
    right: var(--pos-x, -120px);
    left: auto;
}

.homepg .slider-arrow:hover {
    background-color: var(--tg-primary-color);
    color: var(--tg-white);
    border-color: var(--tg-primary-color);
}

.homepg .slider-arrow.default {
    position: relative;
    --pos-x: 0;
    margin-top: 0;
}

.homepg .slick-dots {
    list-style-type: none;
    padding: 2px 0 4px;
    margin: 40px 0 0 0;
    line-height: 0;
    text-align: center;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
}

.homepg .slick-dots li {
    display: inline-block;
    margin-right: 15px;
}

.homepg .slick-dots li:last-child {
    margin-right: 0;
}

.homepg .slick-dots button {
    font-size: 0;
    padding: 0;
    background-color: rgba(255, 255, 255, 0.3);
    width: 12px;
    height: 12px;
    line-height: 0;
    border-radius: 9999px;
    border: none;
    border: 0;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s;
    position: relative;
}

.homepg .slick-dots button:hover {
    border-color: var(--tg-primary-color);
}

.homepg .slick-dots button:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 18px;
    height: 18px;
    border: 1px solid var(--tg-primary-color);
    background-color: var(--tg-primary-color);
    border-radius: 50%;
    -webkit-transition: all ease 0.4s;
    transition: all ease 0.4s;
    visibility: hidden;
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0);
    -ms-transform: translate(-50%, -50%) scale(0);
    transform: translate(-50%, -50%) scale(0);
}

.homepg .slick-dots .slick-active button::before {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
}

.homepg .icon-box {
    display: inline-flex;
    gap: 20px;
}

/* Invest -------------------*/
.homepg .invest-card {
    text-align: center;
    background: var(--tg-secondary-color);
    border-radius: 15px;
    padding: 70px 60px;
    border: 1px solid rgba(255, 255, 255, 0.12);
}

.homepg .invest-card .invest-card-icon {
    border-radius: 50%;
    width: 99px;
    height: 99px;
    margin: auto;
    margin-bottom: 70px;
}

.homepg .invest-card .btn {
    width: 100%;
}

@media (max-width: 1399px) {
    .homepg .invest-card {
        padding: 50px 40px;
    }
}

@media (max-width: 1199px) {
    .homepg .invest-card {
        padding: 50px 30px;
    }

    .homepg .invest-card .invest-card-icon {
        margin-bottom: 50px;
    }

    .homepg .invest-card .btn {
        padding-left: 30px;
        padding-right: 30px;
    }
}

/* Founder 1 ---------------------------------- */
.homepg .founder-card {
    background: var(--tg-secondary-color);
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    display: flex;
    gap: 40px;
    align-items: center;
    padding: 50px 65px;
}

.homepg .founder-card .founder-card-img {
    /* mask-image: url(../img/bg/team-mask.png); */
    mask-size: 100% 100%;
    height: 235px;
    width: 220px;
    flex: none;
}

.homepg .founder-card .founder-card-img img {
    transition: 0.4s;
}

.homepg .founder-card .founder-card-title {
    font-size: 35px;
    font-weight: 600;
    color: var(--tg-white);
    letter-spacing: -0.03em;
    margin-bottom: 15px;
}

.homepg .founder-card .founder-card-title a {
    color: inherit;
}

.homepg .founder-card .founder-card-title:hover {
    color: var(--tg-primary-color);
}

.homepg .founder-card .founder-card-desig {
    font-size: 14px;
    font-weight: 500;
}

.homepg .founder-card .social-btn a {
    background: var(--tg-white);
    color: #5729D6;
    font-size: 18px;
}

.homepg .founder-card .social-btn a:hover {
    background: rgba(255, 255, 255, 0.1);
    color: var(--tg-white);
}

.homepg .founder-card:hover .founder-card-img img {
    transform: scale(1.05);
}

@media (max-width: 1399px) {
    .homepg .founder-card {
        padding: 50px 55px;
    }

    .homepg .founder-card .founder-card-title {
        font-size: 28px;
    }
}

@media (max-width: 1199px) {
    .homepg .founder-card {
        padding: 40px 45px;
    }

    .homepg .founder-card .founder-card-img {
        height: 155px;
        width: 140px;
    }
}

@media (max-width: 575px) {
    .homepg .founder-card {
        padding: 40px;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        gap: 20px;
    }

    .homepg .founder-card .social-btn {
        justify-content: center;
    }
}

.homepg .social-btn {
    display: flex;
    gap: 12px;
}

.homepg .social-btn a {
    height: var(--icon-size, 48px);
    width: var(--icon-size, 48px);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--tg-white);
    text-align: center;
    font-size: 16px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.08);
}

.homepg .social-btn a svg {
    display: block;
    width: 16px;
    height: 16px;
    fill: currentColor;
}

.homepg .social-btn a:hover {
    background: var(--tg-primary-color);
    color: var(--tg-white);
    border-color: var(--tg-primary-color);
}

/* Team */
.homepg .team-tab-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    width: 100%;
    gap: 80px;
    flex-wrap: wrap;
    justify-content: center;
}

.homepg .team-tab-list li {
    width: calc(20% - 65px);
}

@media (max-width: 1399px) {}

.homepg .team-card {
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 100%;
}

.homepg .team-card_img {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 184px;
    height: 194px;
    /* mask-image: url(../img/bg/team-mask.png); */
    mask-size: 100% 100%;
    margin-bottom: 20px;
}

.homepg .team-card_img img {
    transition: 0.4s;
    width: 100%;
}

.homepg .team-card_img .social-btn {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 0);
}

.homepg .team-card_title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0;
}

.homepg .team-card_title a {
    color: var(--tg-white);
}

.homepg .team-card_title a:hover {
    color: var(--tg-primary-color);
}

.homepg .team-card .social-btn a {
    --icon-size: 32px;
    line-height: 32px;
    background: var(--tg-white);
    color: var(--tg-secondary-color);
}

.homepg .team-card .social-btn a:hover {
    background: var(--tg-primary-color);
    color: var(--tg-white);
}

.homepg .team-card:hover .team-card_img img {
    transform: scale(1.05);
    filter: grayscale(0);
}

@media (max-width: 1299px) {
    .homepg .team-tab-list li {
        width: calc(25% - 40px);
    }
}

@media (max-width: 991px) {
    .homepg .team-tab-list {
        gap: 60px;
    }

    .homepg .team-tab-list li {
        width: calc(33% - 42px);
    }
}

@media (max-width: 767px) {
    .homepg .team-tab-list {
        gap: 50px 30px;
    }

    .homepg .team-tab-list li {
        width: calc(45% - 42px);
    }
}

@media (max-width: 575px) {
    .homepg .team-tab-list {
        gap: 35px 30px;
    }

    .homepg .team-tab-list li {
        width: calc(50% - 15px);
    }

    .homepg .team-card_img {
        width: 155px;
        height: 170px;
        margin-bottom: 10px;
    }

    .homepg .team-card_title {
        font-size: 18px;
    }
}

/* Partner Area------------------- */
.homepg .testimonial-slider1 .slick-track>[class*=col],
.homepg .partner-slider1 .slick-track>[class*=col] {
    padding-right: 15px;
    padding-left: 15px;
}

.homepg .partner-card {
    border-radius: 8px;
    padding: 70px 90px 90px;
    background: linear-gradient(180deg, #010314 0%, rgba(1, 3, 20, 0) 100%);
    text-align: center;
    position: relative;
    transition: 0.4s;
    overflow: hidden;
    z-index: 1;
}

.homepg .partner-card:after {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(180deg, var(--tg-primary-color) -20.97%, rgba(46, 32, 255, 0.2) 65%, rgba(1, 3, 20, 0) 100%);
    border-radius: 16px;
    height: 0;
    transition: 0.4s;
    z-index: -1;
}

.homepg .partner-card .partner-card-img {
    margin-bottom: 38px;
    height: 43px;
}

.homepg .partner-card .partner-card-img img {
    margin: auto;
}

.homepg .partner-card .partner-card-text {
    margin-bottom: 112px;
    transition: 0.4s;
}

.homepg .partner-card .btn {
    min-width: 160px;
}

.homepg .partner-card:hover {
    border-radius: 16px;
    background: transparent;
}

.homepg .partner-card:hover:after {
    height: 100%;
}

.homepg .partner-card:hover .partner-card-text {
    color: var(--tg-white);
}

@media (max-width: 1399px) {
    .homepg .partner-card {
        padding: 70px;
    }

    .homepg .partner-card .partner-card-text {
        margin-bottom: 60px;
    }
}

@media (max-width: 767px) {
    .homepg .partner-card {
        background: linear-gradient(180deg, var(--tg-primary-color) -20.97%, rgba(46, 32, 255, 0.2) 65%, rgba(1, 3, 20, 0) 100%);
        border-radius: 16px;
    }

    .homepg .partner-card:after {
        display: none;
    }

    .homepg .partner-card .partner-card-text {
        color: var(--tg-white);
    }

    .homepg .partner-card:hover {
        background: linear-gradient(180deg, var(--tg-primary-color) -20.97%, rgba(46, 32, 255, 0.2) 65%, rgba(1, 3, 20, 0) 100%);
    }
}

/*Event area 1******/
.homepg .event-card-img {
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: 30px;
}

.homepg .event-card-img a {
    display: inline-block;
    width: 100%;
}

.homepg .event-card-img a img {
    transition: 0.5s;
    width: 100%;
}

.homepg .event-card-title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
}

.homepg .event-card-title a {
    color: inherit;
}

.homepg .event-card .event-meta span {
    display: flex;
    gap: 8px;
    color: var(--tg-white);
    align-items: center;
    line-height: 1;
}

.homepg .event-card .event-meta span i {
    color: var(--tg-primary-color);
}

.homepg .event-card .event-meta span:not(:last-child) {
    margin-bottom: 15px;
}

.homepg .event-card .btn {
    margin-top: 30px;
    letter-spacing: -0.01em;
    min-width: 155px;
    padding: 11.5px 40px;
}

.homepg .event-card:hover .event-card-img img {
    transform: scale(1.05);
}

@media (max-width: 1199px) {
    .homepg .event-card .event-card-title {
        font-size: 20px;
    }
}

/* CTA Area 1 ---- */
.homepg .cta-slider1 .slick-track>[class*=col] {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
    margin-top: var(--bs-gutter-y);
}

.homepg .cta-wrap1 {
    background: var(--tg-black);
    border-radius: 16px;
    padding: 70px 50px;
    display: flex;
    gap: 40px 18px;
}

.homepg .cta-wrap1 .cta-wrap-title {
    font-size: 28px;
    font-weight: 600;
    margin-top: 30px;
}

.homepg .cta-wrap1 .cta-wrap-text {
    margin-bottom: -0.4em;
}

.homepg .cta-wrap1 .btn {
    flex: none;
    align-self: start;
}

@media (max-width: 1199px) {
    .homepg .cta-wrap1 {
        flex-wrap: wrap;
        padding: 60px 40px;
    }
}

@media (max-width: 767px) {
    .homepg .cta-wrap1 {
        padding: 60px 50px;
    }
}

@media (max-width: 375px) {
    .homepg .cta-wrap1 {
        padding: 40px 30px;
    }
}

/* Faq 1 ------------*/
.homepg .accordion-card {
    transition: 0.4s ease-in-out;
    overflow: hidden;
    background: var(--tg-secondary-color2);
    border-radius: 33px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    padding: 18px 30px;
    position: relative;
}

.homepg .accordion-card:not(:last-child) {
    margin-bottom: 24px;
}

.homepg .accordion-card .accordion-header {
    flex: none;
}

.homepg .accordion-card .accordion-button {
    font-size: 18px;
    font-weight: 600;
    font-family: var(--tg-heading-font-family);
    color: var(--tg-white);
    border: 0;
    background-color: transparent;
    padding: 0px;
    gap: 15px;
    margin-bottom: 0;
    text-align: left;
    transition: 0.3s;
    align-items: start;
    position: inherit;
}

.homepg .accordion-card .accordion-button .number {
    height: 26px;
    width: 26px;
    line-height: 26px;
    border-radius: 50%;
    font-size: 16px;
    font-weight: 600;
    color: var(--tg-primary-color);
    text-align: center;
    background: linear-gradient(38deg, var(--tg-primary-color), rgba(46, 32, 255, 0.27), var(--tg-secondary-color));
    position: relative;
    z-index: 1;
    flex: none;
}

.homepg .accordion-card .accordion-button .number:after {
    content: "";
    position: absolute;
    inset: 1px;
    background: var(--tg-secondary-color);
    border-radius: 50%;
    z-index: -1;
    transition: 0.4s;
}

.homepg .accordion-card .accordion-button:after {
    content: "\f067";
    width: auto;
    background: transparent;
    border: 0;
    font-family: "Font Awesome 5 Free";
    color: var(--tg-white);
    font-weight: 700;
    font-size: 16px;
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 18px;
    right: 30px;
    transition: 0.3s ease-in-out;
    transform: rotateY(180deg);
}

.homepg .accordion-card .accordion-button .faq-number {
    font-size: 14px;
    font-weight: 600;
    margin-right: 40px;
}

.homepg .accordion-card .accordion-button:focus {
    outline: none;
    box-shadow: none;
}

.homepg .accordion-card .accordion-button:not(.collapsed):after {
    content: "\f068";
    transform: rotate(0);
}

.homepg .accordion-card .accordion-button:not(.collapsed) .number {
    background: var(--tg-white);
    color: var(--tg-white);
}

.homepg .accordion-card .accordion-collapse {
    border: none;
    position: relative;
}

.homepg .accordion-card .accordion-body {
    border-radius: 0;
    background-color: transparent;
    border: none;
    padding: 0px;
    margin: 10px 35px;
}

.homepg .accordion-card .accordion-body p {
    margin-bottom: 0;
    font-weight: 400;
    font-size: 16px;
}

.homepg .accordion-card:last-child {
    margin-bottom: 0;
}

/* Medium devices */
@media (max-width: 991px) {
    .homepg .accordion-card {
        display: block;
    }

    .homepg .accordion-card .accordion-body {
        margin-top: 20px;
        max-width: none;
    }
}

/* Extra small devices */
@media (max-width: 375px) {
    .homepg .accordion-card .accordion-body {
        margin: 10px 0;
    }
}

/************ Home Two CSS ***********/
.homepg .gradient-title {
    background: linear-gradient(90deg, var(--tg-primary-color) -18.55%, var(--tg-theme-color2) 163.09%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    margin: 0 0;
}

.homepg .bitcoin-icon-position span {
    position: relative;
    display: inline-block;
}

.homepg .bitcoin-icon-position .bitcoin {
    position: absolute;
    top: 2px;
    left: 5px;
}

@media (max-width: 1399px) {
    .homepg .bitcoin-icon-position .bitcoin {
        top: 2px;
        left: 4px;
    }
}

@media (max-width: 1199px) {
    .homepg .bitcoin-icon-position .bitcoin {
        top: 2px;
        left: 3px;
    }
}

@media (max-width: 991px) {
    .homepg .bitcoin-icon-position .bitcoin {
        top: 2px;
        left: 2px;
    }
}

@media (max-width: 575px) {
    .homepg .bitcoin-icon-position .bitcoin {
        top: 4px;
        left: 0px;
    }
}

.homepg .purple-bg-gradient {
    width: 969px;
    height: 722px;
    position: absolute;
    background: radial-gradient(48.69% 39.15% at 50.02% 50.04%, rgba(205, 34, 248, 0.5) 0.01%, rgba(200, 9, 249, 0.3) 23.96%, rgba(66, 0, 83, 0) 90%);
    top: 0;
    right: 0;
    transform: translateY(-8%) rotate(40deg);
    z-index: -1;
}

@media (max-width: 1500px) {
    .homepg .purple-bg-gradient {
        right: -175px;
        transform: translateY(-20%) rotate(40deg);
    }
}

@media (max-width: 1199px) {
    .homepg .purple-bg-gradient {
        right: 0;
        transform: translateY(-35%) translateX(40%) rotate(40deg);
    }
}

.homepg .green-bg-gradient {
    width: 721px;
    height: 539px;
    position: absolute;
    background: radial-gradient(48.69% 39.15% at 50.02% 50.04%, #53FFC1 0.01%, rgba(25, 54, 99, 0.3) 53.96%, rgba(0, 0, 0, 0) 100%);
    top: 25%;
    left: -448px;
    transform: rotate(40deg);
    opacity: 0.7;
    z-index: -1;
}

.homepg .hero-gradient-ball {
    background: radial-gradient(114.99% 94.16% at 72.92% 82.18%, #111111 47.39%, #091534 68.23%, #A24DD6 86.63%);
    height: 350px;
    width: 350px;
    border-radius: 50%;
    position: absolute;
    right: 40px;
    bottom: 10%;
    z-index: -1;
    opacity: .5;
}

@media (max-width: 1399px) {
    .homepg .hero-gradient-ball {
        height: 250px;
        width: 250px;
    }
}

@media (max-width: 767px) {
    .homepg .hero-gradient-ball {
        height: 150px;
        width: 150px;
    }
}

.homepg .ripple-shape2 {
    transform: skewX(220deg);
    position: relative;
    z-index: -1;
    top: -35px;
    left: 240px;
    opacity: 0.3;
}

.homepg .ripple-11,
.homepg .ripple-12,
.homepg .ripple-13,
.homepg .ripple-14,
.homepg .ripple-15,
.homepg .ripple-16,
.homepg .ripple-17 {
    width: 700px;
    height: 700px;
    position: absolute;
    left: 30%;
    top: 0;
    transform: translate(-50%, -50%);
    background-color: transparent;
    border: 1px solid var(--tg-primary-color);
    border-radius: 50%;
    animation: ripple2 10s linear infinite;
    opacity: 0;
    transition: 0.4s;
}

.homepg .ripple-11 {
    animation-delay: 0;
}

.homepg .ripple-12 {
    animation-delay: 1s;
}

.homepg .ripple-13 {
    animation-delay: 3s;
}

.homepg .ripple-14 {
    animation-delay: 4s;
}

.homepg .ripple-14 {
    animation-delay: 5s;
}

.homepg .ripple-15 {
    animation-delay: 6s;
}

.homepg .ripple-16 {
    animation-delay: 7s;
}

.homepg .ripple-17 {
    animation-delay: 8s;
}

@media (max-width: 1500px) {
    .homepg .ripple-shape2 {
        transform: skewX(220deg) translateX(-16%);
        top: -125px;
    }
}

.homepg .feature-area-shape .feature-shape2-2 {
    position: absolute;
    bottom: -30px;
    right: 30px;
}

.homepg .feature-area-shape .feature-shape2-3 {
    position: absolute;
    right: 0;
    top: 0;
}

.homepg .feature-shape-4-1 {
    position: absolute;
    background: radial-gradient(144.03% 144.03% at 41.08% 9.08%, #111111 47.39%, #A24F2C 100%);
    width: 135px;
    height: 135px;
    border-radius: 50%;
    right: 13%;
    top: 36%;
    z-index: -1;
}

.homepg .feature-shape-4-2 {
    position: absolute;
    right: 20px;
    bottom: 0;
    background: radial-gradient(104.92% 104.68% at 58.37% -0.84%, #EF994A 0%, rgba(255, 111, 111, 0.536826) 25.52%, rgba(212, 39, 255, 0) 55.1%);
    width: 247px;
    height: 247px;
    border-radius: 50%;
    z-index: -1;
}

.homepg .feature-shape-4-3 {
    position: absolute;
    left: -43px;
    bottom: 50px;
    background: radial-gradient(165.58% 165.58% at 54.64% -34.27%, #0C0C0C 47.39%, #2F3A55 89.06%);
    width: 215px;
    height: 215px;
    border-radius: 50%;
    z-index: -1;
}

.homepg .feature-shape-4-4 {
    position: absolute;
    left: -530px;
    bottom: -160px;
    background: radial-gradient(48.69% 39.15% at 50.02% 50.04%, #CD22F8 0.01%, rgba(200, 9, 249, 0.3) 53.96%, rgba(66, 0, 83, 0) 100%);
    height: 960px;
    width: 722px;
    border-radius: 50%;
    transform: skew(30deg, 10deg);
    z-index: -1;
}

/* Testimonial ------- */
.homepg .testimonial-wrap-3 {
    background: radial-gradient(298.93% 263.81% at 149.5% 261.83%, #111111 47.39%, #091534 68.23%, rgba(162, 77, 214, 0.5) 86.63%);
    border-radius: 30px;
    position: relative;
    padding: 135px 150px;
}

.homepg .testimonial-wrap-3 .testimonial-wrap-bg {
    position: absolute;
    bottom: 0;
    right: 0;
}

.homepg .testimonial-wrap-3 .testimonial-wrap-circle1 {
    position: absolute;
    background: radial-gradient(120.5% 130.5% at -19.95% 33.74%, #190F2E 77.39%, #7D4087 114.59%);
    width: 161px;
    height: 161px;
    border-radius: 50%;
    right: -60px;
    top: -80px;
    z-index: -1;
    filter: blur(3px);
}

.homepg .testimonial-wrap-3 .testimonial-wrap-circle2 {
    position: absolute;
    background: radial-gradient(139.08% 139.08% at 6.17% 127.08%, #130F1A 57.39%, #3B2A5E 112.32%, #8543B1 139.14%);
    width: 158px;
    height: 158px;
    border-radius: 50%;
    left: -90px;
    top: 80px;
    z-index: -1;
    filter: blur(4px);
}

.homepg .testimonial-wrap-3 .testimonial-wrap-circle3 {
    position: absolute;
    background: radial-gradient(107.91% 147.91% at 140.21% 149.09%, #AB432C 0%, #460615 10.9%, #543871 156.63%);
    filter: blur(6.66516px);
    width: 85px;
    height: 85px;
    border-radius: 50%;
    z-index: -1;
    top: 250px;
    right: 30px;
}

@media (max-width: 1399px) {
    .homepg .testimonial-wrap-3 {
        padding: 110px 120px;
    }
}

@media (max-width: 1299px) {
    .homepg .testimonial-wrap-3 {
        padding: 100px 100px;
    }
}

@media (max-width: 1199px) {
    .homepg .testimonial-wrap-3 {
        padding: 100px 80px;
    }
}

@media (max-width: 991px) {
    .homepg .testimonial-wrap-3 {
        padding: 80px;
    }
}

@media (max-width: 767px) {
    .homepg .testimonial-wrap-3 {
        padding: 60px;
    }
}

@media (max-width: 575px) {
    .homepg .testimonial-wrap-3 {
        border-radius: 15px;
        padding: 30px;
        overflow: hidden;
    }
}

.homepg .testi-box_text {
    margin-bottom: -0.1em;
    line-height: 1.357;
    font-size: 28px;
    font-family: var(--tg-heading-font-family);
    font-weight: 600;
    margin-top: 40px;
    color: var(--tg-white);
}

.homepg .testi-box-profile {
    display: flex;
    gap: 12px;
    align-items: center;
}

.homepg .testi-box-profile .testi-box-profile-thumb {
    flex: none;
}

.homepg .testi-box-profile .testi-box-profile-thumb img {
    border-radius: 50%;
    width: 65px;
    height: 65px;
    object-fit: cover;
}

.homepg .testi-box-profile .testi-box_name {
    margin-bottom: 0px;
    margin-top: -0.35em;
    font-weight: 700;
    font-size: 18px;
    font-family: var(--tg-heading-font-family);
    color: var(--tg-white);
}

.homepg .testi-box-profile .testi-box_desig {
    font-size: 16px;
    font-weight: 400;
    display: block;
    margin-bottom: -0.1em;
}

.homepg .testi-box-profile .testi-box-profile-ratting {
    margin-left: auto;
    align-self: center;
    color: #FBB138;
    font-size: 14px;
    gap: 6px;
    display: flex;
}

@media (max-width: 991px) {
    .homepg .testi-box .testi-box_text {
        margin-top: 36px;
    }
}

@media (max-width: 575px) {
    .homepg .testi-box .testi-box_text {
        margin-top: 16px;
        font-size: 20px;
    }

    .homepg .testi-box .testi-box-profile {
        flex-wrap: wrap;
    }

    .homepg .testi-box .testi-box-profile .testi-box-profile-ratting {
        margin-left: 0;
    }
}

.homepg .testimonial-slider-wrap {
    position: relative;
}

.homepg .testimonial-slider-wrap .slider-arrow {
    left: var(--pos-x, -175px);
}

.homepg .testimonial-slider-wrap .slider-arrow.next-btn {
    right: var(--pos-x, -175px);
    left: auto;
}

@media (max-width: 1500px) {
    .homepg .testimonial-slider-wrap .slider-arrow {
        --pos-x: -140px;
    }
}

@media (max-width: 1299px) {
    .homepg .testimonial-slider-wrap .slider-arrow {
        --pos-x: -125px;
    }
}

@media (max-width: 1199px) {
    .homepg .testimonial-slider-wrap .slider-arrow {
        --pos-x: -105px;
    }
}

@media (max-width: 991px) {
    .homepg .testimonial-slider-wrap .slider-arrow {
        --pos-x: -100px;
        margin-left: 0;
        margin-right: 0;
    }
}

@media (max-width: 767px) {
    .homepg .testimonial-slider-wrap .slider-arrow {
        --pos-x: -80px;
    }
}

@media (max-width: 575px) {
    .homepg .testimonial-slider-wrap .slider-arrow {
        display: none;
    }
}

.homepg .testimonial-slider1 {
    --bs-gutter-x: 150px;
}

.homepg .testimonial-slider1 .slick-track>[class*=col] {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
    margin-top: var(--bs-gutter-y);
}

.homepg .testimonial-slider-wrap .slick-list {
    padding: 0 0;
}

.homepg .play-btn {
    display: inline-block;
    position: relative;
    z-index: 1;
}

.homepg .play-btn>i {
    display: inline-block;
    width: var(--icon-size, 110px);
    height: var(--icon-size, 110px);
    line-height: 107px;
    text-align: center;
    background: linear-gradient(93.17deg, var(--tg-primary-color) -18.55%, #7E2AFD 163.09%);
    color: var(--tg-primary-color);
    font-size: var(--icon-font-size, 30px);
    border-radius: 50%;
    z-index: 1;
    transition: all ease 0.4s;
}

.homepg .play-btn:after,
.homepg .play-btn:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(223, 134, 170, 0.2);
    border: 0;
    z-index: -1;
    border-radius: 50%;
    transition: all ease 0.4s;
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-name: ripple;
}

.homepg .play-btn:after {
    animation-delay: 2s;
}

.homepg .play-btn:hover i {
    background-color: var(--tg-primary-color);
    color: var(--tg-white);
}

.homepg .play-btn.style2:before,
.homepg .play-btn.style2:after {
    background-color: transparent;
    border: 1px solid var(--tg-white);
}

.homepg .play-btn.style3>i {
    background-color: var(--tg-primary-color);
    color: var(--tg-white);
    font-size: 20px;
}

.homepg .play-btn.style3:before,
.homepg .play-btn.style3:after {
    background-color: var(--tg-white);
}

.homepg .play-btn.style3:hover>i {
    background-color: var(--tg-white);
    color: var(--tg-primary-color);
}

/* Faq 2 ------------- */
.homepg .accordion-card.style2 {
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: linear-gradient(180deg, #E5F8C6 0%, #61B998 100%);
    border-radius: 63px;
    padding: 0;
    transition: 0.4s;
}

.homepg .accordion-card.style2.active-item {
    border: none;
}

.homepg .accordion-card.style2 .accordion-button {
    font-size: 24px;
    padding: 35px 60px 35px 90px;
    margin: 0 0;
    color: var(--tg-secondary-color);
    box-shadow: none;
    outline: none;
}

.homepg .accordion-card.style2 .accordion-button:after {
    content: "\f107";
    top: 56px;
    right: 36px;
    font-size: 24px;
    color: var(--tg-secondary-color);
    width: 65px;
    height: 65px;
    line-height: 67px;
    border-radius: 50%;
    background: var(--tg-white);
    transform: rotate(180deg);
    z-index: 1;
}

.homepg .accordion-card.style2 .accordion-button.collapsed {
    color: var(--tg-white);
}

.homepg .accordion-card.style2 .accordion-button.collapsed:after {
    transform: rotate(0deg);
    background: transparent;
    color: var(--tg-body-font-color);
    top: 25px;
}

.homepg .accordion-card.style2:not(:last-child) {
    margin-bottom: 20px;
}

.homepg .accordion-card.style2 .accordion-body {
    margin: 0;
}

.homepg .accordion-card.style2 .accordion-body p {
    margin: 0 200px 33px 90px;
    color: var(--tg-secondary-color);
}

.homepg .accordion-card.style2:has(.accordion-button.collapsed) {
    background: transparent;
}

@media (max-width: 991px) {
    .homepg .accordion-card.style2 .accordion-button {
        font-size: 20px;
        padding: 31px 60px 29px 60px;
    }

    .homepg .accordion-card.style2 .accordion-body p {
        margin: 0 170px 33px 60px;
    }

    .homepg .accordion-card.style2 .accordion-button.collapsed:after {
        top: 7px;
    }
}

@media (max-width: 767px) {
    .homepg .accordion-card.style2 .accordion-body p {
        margin: 0 100px 33px 40px;
    }

    .homepg .accordion-card.style2 .accordion-button {
        padding: 31px 40px 29px 40px;
    }

    .homepg .accordion-card.style2 .accordion-button:after {
        top: 19px;
        width: 50px;
        height: 50px;
        line-height: 53px;
    }

    .homepg .accordion-card.style2 .accordion-button.collapsed:after {
        top: 15px;
    }

    .homepg .accordion-card.style2 {
        border-radius: 25px;
    }
}

@media (max-width: 575px) {
    .homepg .accordion-card.style2 .accordion-button {
        padding: 25px 60px 25px 25px;
        font-size: 17px;
    }

    .homepg .accordion-card.style2 .accordion-button:after {
        right: 15px;
    }

    .homepg .accordion-card.style2 .accordion-body p {
        margin: 0 25px 30px 25px;
    }
}

.homepg .faq-2-shape-1 {
    position: absolute;
    height: 960px;
    width: 722px;
    border-radius: 50%;
    transform: skew(40deg, 0deg);
    z-index: -1;
    background: radial-gradient(48.69% 39.15% at 50.02% 50.04%, #CD22F8 0.01%, rgba(200, 9, 249, 0.3) 53.96%, rgba(66, 0, 83, 0) 100%);
    right: -620px;
    bottom: -260px;
}

/* Newsletter */
.homepg .newsletter-form {
    position: relative;
}

.homepg .newsletter-form .form-group {
    margin-bottom: 0px;
}

.homepg .newsletter-form .form-group input {
    height: 60px;
    padding: 0 145px 0 30px;
    background: var(--tg-white);
    border: 0;
    border-radius: 50px;
    font-size: 16px;
    font-family: var(--tg-heading-font-family);
    font-weight: 400;
    letter-spacing: -0.48px;
}

.homepg .newsletter-form .form-group input::placeholder {
    color: rgba(10, 12, 0, 0.5);
}

.homepg .newsletter-form .btn {
    position: absolute;
    right: 5px;
    top: 4px;
    padding: 0 30px;
    font-size: 16px;
    font-weight: 600;
    line-height: 52px;
}

.homepg .footer-gradient-shape {
    position: absolute;
    height: 500px;
    width: 500px;
    border-radius: 50%;
    transform: skew(10deg, 0deg);
    z-index: -1;
    background: radial-gradient(48.69% 39.15% at 50.02% 50.04%, #CD22F8 0.01%, rgba(200, 9, 249, 0.3) 53.96%, rgba(66, 0, 83, 0) 100%);
    left: -360px;
    bottom: 0;
}