/*
    Table of Contents

    Name                 : Jiade
    Author               : DexignLab
    Author Portfolio     : https://themeforest.net/user/dexiglab/portfolio
    


    Table of Sass Contents

    1. Abstracts
        _bs-custom
        _inheritance
        _maps
        _mixin
        _variable

    2. bootstrap 
        -Here goes all official bootstrap scss files

    3. Global/Base
        _color
        _custom-grid
        _fonts
        _helper
        _reset

    4. layout
        +footer
        +header
        +rtl
        +sidebar
        +tables
        +theme
        +version-dark

    5. Conponents
        +app
        +charts
        +forms
        +map
        +uc
        +ui
        +widget

    6. Page
        _index-1
        _page-error
        _page-register
        _page-signin
        _page-user-lock
        _page-user-lock

*/
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600,700");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700");
@import url("https://fonts.googleapis.com/css?family=Nunito:400,600,700");
@import url("./../icons/fontawesome/css/all.min.css");
@import url("./../icons/material-design-iconic-font/css/materialdesignicons.min.css");
@import url("./../icons/themify-icons/css/themify-icons.css");
@import url("./../icons/line-awesome/css/line-awesome.min.css");
@import url("./../icons/flaticon/flaticon.css");
@import url("./../icons/flaticon-1/font/flaticon-1.css");
@import url(./../other/metismenu/css/metisMenu.min.css);

:root {
  --primary: #3736af;
  --secondary: #3693ff;
  --primary-hover: #2b2a88;
  --primary-dark: #12123a;
  --rgba-primary-1: rgba(55, 54, 175, 0.1);
  --rgba-primary-2: rgba(55, 54, 175, 0.2);
  --rgba-primary-3: rgba(55, 54, 175, 0.3);
  --rgba-primary-4: rgba(55, 54, 175, 0.4);
  --rgba-primary-5: rgba(55, 54, 175, 0.5);
  --rgba-primary-6: rgba(55, 54, 175, 0.6);
  --rgba-primary-7: rgba(55, 54, 175, 0.7);
  --rgba-primary-8: rgba(55, 54, 175, 0.8);
  --rgba-primary-9: rgba(55, 54, 175, 0.9);
  --rgba-secondary-1: rgba(54, 147, 255, 0.1);
  --rgba-secondary-2: rgba(54, 147, 255, 0.2);
  --rgba-secondary-3: rgba(54, 147, 255, 0.3);
  --rgba-secondary-4: rgba(54, 147, 255, 0.4);
  --rgba-secondary-5: rgba(54, 147, 255, 0.5);
  --rgba-secondary-6: rgba(54, 147, 255, 0.6);
  --rgba-secondary-7: rgba(54, 147, 255, 0.7);
  --rgba-secondary-8: rgba(54, 147, 255, 0.8);
  --rgba-secondary-9: rgba(54, 147, 255, 0.9);
  --font-family-base: Poppins, sans-serif;
  --font-family-title: Poppins, sans-serif;
  --font-family-base: Poppins, sans-serif;
  --font-family-title: Poppins, sans-serif;
  --title: #424562;
  --dz-list-space-x: 1.25rem;
  --dz-list-space-y: 0.5rem;
  --dz-list-size: 0.875rem;
}

:root {
  --card: #fff;
  --text-dark: #312a2a;
  --text-gray: #737b8b;
  --text: #717579;
  --body-bg: #e7e7e7;
  --light: #f3f3f3;
  --border: #e6e6e6;
  --card-element-color: #f3f3f3;
  --bs-border-color: #e6e6e6;
  --bs-tertiary-bg: #f3f3f3;
}

.gradient_one {
  background-image: linear-gradient(to right,
      rgba(186, 1, 181, 0.85) 0%,
      rgba(103, 25, 255, 0.85) 100%);
}

.gradient-1 {
  background: #f0a907;
  background: -moz-linear-gradient(top, #f0a907 0%, #f53c79 100%);
  background: -webkit-linear-gradient(top, #f0a907 0%, #f53c79 100%);
  background: linear-gradient(to bottom, #f0a907 0%, #f53c79 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f0a907', endColorstr='#f53c79', GradientType=0);
}

.gradient-2 {
  background: #4dedf5;
  background: -moz-linear-gradient(top, #4dedf5 0%, #480ceb 100%);
  background: -webkit-linear-gradient(top, #4dedf5 0%, #480ceb 100%);
  background: linear-gradient(to bottom, #4dedf5 0%, #480ceb 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4dedf5', endColorstr='#480ceb', GradientType=0);
}

.gradient-3 {
  background: #51f5ae;
  background: -moz-linear-gradient(top, #51f5ae 0%, #3fbcda 100%);
  background: -webkit-linear-gradient(top, #51f5ae 0%, #3fbcda 100%);
  background: linear-gradient(to bottom, #51f5ae 0%, #3fbcda 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#51f5ae', endColorstr='#3fbcda', GradientType=0);
}

.gradient-4 {
  background: #f25521;
  background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: linear-gradient(to right, #f25521 0%, #f9c70a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f25521', endColorstr='#f9c70a', GradientType=1);
}

.gradient-5 {
  background: #f53c79;
  background: -moz-linear-gradient(left, #f53c79 0%, #f0a907 100%);
  background: -webkit-linear-gradient(left, #f53c79 0%, #f0a907 100%);
  background: linear-gradient(to right, #f53c79 0%, #f0a907 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f53c79', endColorstr='#f0a907', GradientType=1);
}

.gradient-6 {
  background: #36b9d8;
  background: -moz-linear-gradient(left, #36b9d8 0%, #4bffa2 100%);
  background: -webkit-linear-gradient(left, #36b9d8 0%, #4bffa2 100%);
  background: linear-gradient(to right, #36b9d8 0%, #4bffa2 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#36b9d8', endColorstr='#4bffa2', GradientType=1);
}

.gradient-7 {
  background: #4400eb;
  background: -moz-linear-gradient(left, #4400eb 0%, #44e7f5 100%);
  background: -webkit-linear-gradient(left, #4400eb 0%, #44e7f5 100%);
  background: linear-gradient(to right, #4400eb 0%, #44e7f5 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4400eb', endColorstr='#44e7f5', GradientType=1);
}

.gradient-8 {
  background: #f7b00f;
  background: -moz-linear-gradient(top, #f7b00f 0%, #f25521 100%);
  background: -webkit-linear-gradient(top, #f7b00f 0%, #f25521 100%);
  background: linear-gradient(to bottom, #f7b00f 0%, #f25521 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#F7B00F', endColorstr='#F25521', GradientType=1);
}

.gradient-9,
.datepicker.datepicker-dropdown td.day:hover,
.datepicker.datepicker-dropdown th.next:hover,
.datepicker.datepicker-dropdown th.prev:hover,
.datepicker table tr td.selected,
.datepicker table tr td.active,
.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background: #f31e7a !important;
  background: -moz-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
  background: -webkit-linear-gradient(left, #f31e7a 0%, #fd712c 100%);
  background: linear-gradient(to right, #f31e7a 0%, #fd712c 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f31e7a', endColorstr='#fd712c', GradientType=1);
}

.gradient-10 {
  background: #f25521 !important;
  background: -moz-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: -webkit-linear-gradient(left, #f25521 0%, #f9c70a 100%);
  background: linear-gradient(to top, #f25521 0%, #f9c70a 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f25521', endColorstr='#f9c70a', GradientType=1);
}

.gradient-11 {
  background: #3398fb;
  background: -moz-linear-gradient(left, #3398fb 0%, #8553ee 100%);
  background: -webkit-linear-gradient(left, #3398fb 0%, #8553ee 100%);
  background: linear-gradient(to right, #3398fb 0%, #8553ee 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3398fb', endColorstr='#8553ee', GradientType=1);
}

.gradient-12 {
  background: #36e1b4;
  background: -moz-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
  background: -webkit-linear-gradient(left, #36e1b4 0%, #11cae7 100%);
  background: linear-gradient(to right, #36e1b4 0%, #11cae7 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#36e1b4', endColorstr='#11cae7', GradientType=1);
}

.gradient-13 {
  background: #ffbf31;
  background: -moz-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
  background: -webkit-linear-gradient(left, #ffbf31 0%, #ff890e 100%);
  background: linear-gradient(to right, #ffbf31 0%, #ff890e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffbf31', endColorstr='#ff890e', GradientType=1);
}

.gradient-14 {
  background: #23bdb8;
  background: -moz-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
  background: -webkit-linear-gradient(-45deg, #23bdb8 0%, #43e794 100%);
  background: linear-gradient(135deg, #23bdb8 0%, #43e794 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#23bdb8', endColorstr='#43e794', GradientType=1);
}

.gradient-15 {
  background: #9a56ff;
  background: -moz-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
  background: -webkit-linear-gradient(-45deg, #9a56ff 0%, #e36cd9 100%);
  background: linear-gradient(135deg, #9a56ff 0%, #e36cd9 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#9a56ff', endColorstr='#e36cd9', GradientType=1);
}

.gradient-16 {
  background: #f48665;
  background: -moz-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
  background: -webkit-linear-gradient(-45deg, #f48665 0%, #fda23f 100%);
  background: linear-gradient(135deg, #f48665 0%, #fda23f 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f48665', endColorstr='#fda23f', GradientType=1);
}

.gradient-17 {
  background: #e36cd9;
  background: -moz-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
  background: -webkit-linear-gradient(-45deg, #e36cd9 0%, #fe60ae 100%);
  background: linear-gradient(135deg, #e36cd9 0%, #fe60ae 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e36cd9', endColorstr='#fe60ae', GradientType=1);
}

.gradient-18 {
  background: #a15cff;
  background: -moz-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
  background: -webkit-linear-gradient(left, #a15cff 0%, #ce82fd 100%);
  background: linear-gradient(to right, #a15cff 0%, #ce82fd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a15cff', endColorstr='#ce82fd', GradientType=1);
}

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
/*!
   * Bootstrap  v5.3.1 (https://getbootstrap.com/)
   * Copyright 2011-2023 The Bootstrap Authors
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
:root,
[data-bs-theme="light"] {
  --bs-blue: #374c98;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #ac4cbc;
  --bs-red: #ee3232;
  --bs-orange: #ff9900;
  --bs-yellow: #fffa6f;
  --bs-green: #297f00;
  --bs-teal: #20c997;
  --bs-cyan: #3065d0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #3736af;
  --bs-secondary: #3693ff;
  --bs-success: #3ab67a;
  --bs-info: #00ada3;
  --bs-warning: #ffab2d;
  --bs-danger: #fd5353;
  --bs-light: #dedeed;
  --bs-dark: #5b5e81;
  --bs-primary-rgb: 55, 54, 175;
  --bs-secondary-rgb: 54, 147, 255;
  --bs-success-rgb: 58, 182, 122;
  --bs-info-rgb: 0, 173, 163;
  --bs-warning-rgb: 255, 171, 45;
  --bs-danger-rgb: 253, 83, 83;
  --bs-light-rgb: 222, 222, 237;
  --bs-dark-rgb: 91, 94, 129;
  --bs-primary-text-emphasis: #161646;
  --bs-secondary-text-emphasis: #163b66;
  --bs-success-text-emphasis: #174931;
  --bs-info-text-emphasis: #004541;
  --bs-warning-text-emphasis: #664412;
  --bs-danger-text-emphasis: #652121;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #d7d7ef;
  --bs-secondary-bg-subtle: #d7e9ff;
  --bs-success-bg-subtle: #d8f0e4;
  --bs-info-bg-subtle: #ccefed;
  --bs-warning-bg-subtle: #ffeed5;
  --bs-danger-bg-subtle: #ffdddd;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #afafdf;
  --bs-secondary-border-subtle: #afd4ff;
  --bs-success-border-subtle: #b0e2ca;
  --bs-info-border-subtle: #99deda;
  --bs-warning-border-subtle: #ffddab;
  --bs-danger-border-subtle: #febaba;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0));
  --bs-body-font-family: "Poppins", sans-serif;
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #717579;
  --bs-body-color-rgb: 113, 117, 121;
  --bs-body-bg: #e7e7e7;
  --bs-body-bg-rgb: 231, 231, 231;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(113, 117, 121, 0.75);
  --bs-secondary-color-rgb: 113, 117, 121;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(113, 117, 121, 0.5);
  --bs-tertiary-color-rgb: 113, 117, 121;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: #424562;
  --bs-link-color: #3736af;
  --bs-link-color-rgb: 55, 54, 175;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #2c2b8c;
  --bs-link-hover-color-rgb: 44, 43, 140;
  --bs-code-color: #ac4cbc;
  --bs-highlight-bg: #fffee2;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #eeeeee;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.25rem;
  --bs-border-radius-sm: 0.2rem;
  --bs-border-radius-lg: 0.3rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(55, 54, 175, 0.25);
  --bs-form-valid-color: #3ab67a;
  --bs-form-valid-border-color: #3ab67a;
  --bs-form-invalid-color: #fd5353;
  --bs-form-invalid-border-color: #fd5353;
}

[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #8786cf;
  --bs-secondary-text-emphasis: #86beff;
  --bs-success-text-emphasis: #89d3af;
  --bs-info-text-emphasis: #66cec8;
  --bs-warning-text-emphasis: #ffcd81;
  --bs-danger-text-emphasis: #fe9898;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #0b0b23;
  --bs-secondary-bg-subtle: #0b1d33;
  --bs-success-bg-subtle: #0c2418;
  --bs-info-bg-subtle: #002321;
  --bs-warning-bg-subtle: #332209;
  --bs-danger-bg-subtle: #331111;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #212069;
  --bs-secondary-border-subtle: #205899;
  --bs-success-border-subtle: #236d49;
  --bs-info-border-subtle: #006862;
  --bs-warning-border-subtle: #99671b;
  --bs-danger-border-subtle: #983232;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #8786cf;
  --bs-link-hover-color: #9f9ed9;
  --bs-link-color-rgb: 135, 134, 207;
  --bs-link-hover-color-rgb: 159, 158, 217;
  --bs-code-color: #cd94d7;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #7fb266;
  --bs-form-valid-border-color: #7fb266;
  --bs-form-invalid-color: #f58484;
  --bs-form-invalid-border-color: #f58484;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

.noap::after {
  display: none !important;
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1,
.h1 {
  font-size: calc(1.35rem + 1.2vw);
}

@media (min-width: 1200px) {

  h1,
  .h1 {
    font-size: 2.25rem;
  }
}

h2,
.h2 {
  font-size: calc(1.3125rem + 0.75vw);
}

@media (min-width: 1200px) {

  h2,
  .h2 {
    font-size: 1.875rem;
  }
}

h3,
.h3 {
  font-size: calc(1.275rem + 0.3vw);
}

@media (min-width: 1200px) {

  h3,
  .h3 {
    font-size: 1.5rem;
  }
}

h4,
.h4 {
  font-size: 1.125rem;
}

h5,
.h5 {
  font-size: 1rem;
}

h6,
.h6 {
  font-size: 0.938rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}

a>code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}

legend+* {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}

@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}

.blockquote> :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {

  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1440) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1440;
}

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto>* {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1>* {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2>* {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3>* {
  flex: 0 0 auto;
  width: 33.33333%;
}

.row-cols-4>* {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5>* {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6>* {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-sm-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333%;
  }

  .offset-sm-2 {
    margin-left: 16.66667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333%;
  }

  .offset-sm-5 {
    margin-left: 41.66667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333%;
  }

  .offset-sm-8 {
    margin-left: 66.66667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333%;
  }

  .offset-sm-11 {
    margin-left: 91.66667%;
  }

  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-md-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333%;
  }

  .offset-md-2 {
    margin-left: 16.66667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333%;
  }

  .offset-md-5 {
    margin-left: 41.66667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333%;
  }

  .offset-md-8 {
    margin-left: 66.66667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333%;
  }

  .offset-md-11 {
    margin-left: 91.66667%;
  }

  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-lg-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333%;
  }

  .offset-lg-2 {
    margin-left: 16.66667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333%;
  }

  .offset-lg-5 {
    margin-left: 41.66667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333%;
  }

  .offset-lg-8 {
    margin-left: 66.66667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333%;
  }

  .offset-lg-11 {
    margin-left: 91.66667%;
  }

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-xl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333%;
  }

  .offset-xl-2 {
    margin-left: 16.66667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333%;
  }

  .offset-xl-5 {
    margin-left: 41.66667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333%;
  }

  .offset-xl-8 {
    margin-left: 66.66667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333%;
  }

  .offset-xl-11 {
    margin-left: 91.66667%;
  }

  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 1440) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3>* {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .row-cols-xxl-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6>* {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66667%;
  }

  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}

.table> :not(caption)>*>* {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state,
      var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}

.table>tbody {
  vertical-align: inherit;
}

.table>thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm> :not(caption)>*>* {
  padding: 0.25rem 0.25rem;
}

.table-bordered> :not(caption)>* {
  border-width: var(--bs-border-width) 0;
}

.table-bordered> :not(caption)>*>* {
  border-width: 0 var(--bs-border-width);
}

.table-borderless> :not(caption)>*>* {
  border-bottom-width: 0;
}

.table-borderless> :not(:first-child) {
  border-top-width: 0;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns> :not(caption)>tr> :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover>tbody>tr:hover>* {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #d7d7ef;
  --bs-table-border-color: #c2c2d7;
  --bs-table-striped-bg: #cccce3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c2c2d7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c7c7dd;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #d7e9ff;
  --bs-table-border-color: #c2d2e6;
  --bs-table-striped-bg: #ccddf2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c2d2e6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c7d8ec;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d8f0e4;
  --bs-table-border-color: #c2d8cd;
  --bs-table-striped-bg: #cde4d9;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c2d8cd;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c8ded3;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #ccefed;
  --bs-table-border-color: #b8d7d5;
  --bs-table-striped-bg: #c2e3e1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #b8d7d5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bddddb;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #ffeed5;
  --bs-table-border-color: #e6d6c0;
  --bs-table-striped-bg: #f2e2ca;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6d6c0;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ecdcc5;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #ffdddd;
  --bs-table-border-color: #e6c7c7;
  --bs-table-striped-bg: #f2d2d2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6c7c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #eccccc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #dedeed;
  --bs-table-border-color: #c8c8d5;
  --bs-table-striped-bg: #d3d3e1;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #c8c8d5;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #cdcddb;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #5b5e81;
  --bs-table-border-color: #6b6e8e;
  --bs-table-striped-bg: #636687;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #6b6e8e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #676a8a;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

@media (max-width: 1439.98) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: 1.09375rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.76563rem;
}

@media (min-width: 1200px) {
  .col-form-label-sm {
    font-size: 0.76562rem;
  }
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid #eeeeee;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control[type="file"] {
  overflow: hidden;
}

.form-control[type="file"]:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #9b9bd7;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(55, 54, 175, 0.25);
}

.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}

.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}

.form-control::placeholder {
  color: var(--bs-secondary-color);
  opacity: 1;
}

.form-control:disabled {
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}

.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}

.form-control-plaintext:focus {
  outline: 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: var(--bs-border-radius-sm);
}

@media (min-width: 1200px) {
  .form-control-sm {
    font-size: 0.76562rem;
  }
}

.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: var(--bs-border-radius-lg);
}

.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}

textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}

.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}

.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}

.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img),
    var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid #eeeeee;
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}

.form-select:focus {
  border-color: #9b9bd7;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(55, 54, 175, 0.25);
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}

.form-select:disabled {
  background-color: var(--bs-secondary-bg);
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.76563rem;
  border-radius: var(--bs-border-radius-sm);
}

@media (min-width: 1200px) {
  .form-select-sm {
    font-size: 0.76562rem;
  }
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.09375rem;
  border-radius: var(--bs-border-radius-lg);
}

[data-bs-theme="dark"] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}

.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
}

.form-check-input[type="radio"] {
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(90%);
}

.form-check-input:focus {
  border-color: #9b9bd7;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(55, 54, 175, 0.25);
}

.form-check-input:checked {
  background-color: #3736af;
  border-color: #3736af;
}

.form-check-input:checked[type="checkbox"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:indeterminate {
  background-color: #3736af;
  border-color: #3736af;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}

.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%239b9bd7'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}

.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.btn-check[disabled]+.btn,
.btn-check:disabled+.btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme="dark"] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}

.form-range:focus {
  outline: 0;
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #e7e7e7, 0 0 0 0.25rem rgba(55, 54, 175, 0.25);
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #e7e7e7, 0 0 0 0.25rem rgba(55, 54, 175, 0.25);
}

.form-range::-moz-focus-outer {
  border: 0;
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #3736af;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #c3c3e7;
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #3736af;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #c3c3e7;
}

.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
}

.form-range:disabled {
  pointer-events: none;
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}

.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext,
.form-floating>.form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}

.form-floating>label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-floating>label {
    transition: none;
  }
}

.form-floating>.form-control,
.form-floating>.form-control-plaintext {
  padding: 1rem 0.75rem;
}

.form-floating>.form-control::placeholder,
.form-floating>.form-control-plaintext::placeholder {
  color: transparent;
}

.form-floating>.form-control:focus,
.form-floating>.form-control:not(:placeholder-shown),
.form-floating>.form-control-plaintext:focus,
.form-floating>.form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:-webkit-autofill,
.form-floating>.form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}

.form-floating>.form-control:focus~label,
.form-floating>.form-control:not(:placeholder-shown)~label,
.form-floating>.form-control-plaintext~label,
.form-floating>.form-select~label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control:focus~label::after,
.form-floating>.form-control:not(:placeholder-shown)~label::after,
.form-floating>.form-control-plaintext~label::after,
.form-floating>.form-select~label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}

.form-floating>.form-control:-webkit-autofill~label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.form-floating>.form-control-plaintext~label {
  border-width: var(--bs-border-width) 0;
}

.form-floating> :disabled~label,
.form-floating>.form-control:disabled~label {
  color: #6c757d;
}

.form-floating> :disabled~label::after,
.form-floating>.form-control:disabled~label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group>.form-control,
.input-group>.form-select,
.input-group>.form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.input-group>.form-control:focus,
.input-group>.form-select:focus,
.input-group>.form-floating:focus-within {
  z-index: 5;
}

.input-group .btn {
  position: relative;
  z-index: 2;
}

.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid #eeeeee;
  border-radius: var(--bs-border-radius);
}

.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text,
.input-group-lg>.btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  border-radius: var(--bs-border-radius-lg);
}

.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text,
.input-group-sm>.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76563rem;
  border-radius: var(--bs-border-radius-sm);
}

@media (min-width: 1200px) {

  .input-group-sm>.form-control,
  .input-group-sm>.form-select,
  .input-group-sm>.input-group-text,
  .input-group-sm>.btn {
    font-size: 0.76562rem;
  }
}

.input-group-lg>.form-select,
.input-group-sm>.form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3),
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
.input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation> :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation>.dropdown-toggle:nth-last-child(n + 4),
.input-group.has-validation>.form-floating:nth-last-child(n + 3)>.form-control,
.input-group.has-validation>.form-floating:nth-last-child(n + 3)>.form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group>.form-floating:not(:first-child)>.form-control,
.input-group>.form-floating:not(:first-child)>.form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.76563rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: var(--bs-border-radius);
}

@media (min-width: 1200px) {
  .valid-tooltip {
    font-size: 0.76562rem;
  }
}

.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip,
.is-valid~.valid-feedback,
.is-valid~.valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233ab67a' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid,
.form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233ab67a' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-control-color:valid,
.form-control-color.is-valid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}

.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}

.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input~.valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group>.form-control:not(:focus):valid,
.input-group>.form-control:not(:focus).is-valid,
.was-validated .input-group>.form-select:not(:focus):valid,
.input-group>.form-select:not(:focus).is-valid,
.was-validated .input-group>.form-floating:not(:focus-within):valid,
.input-group>.form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.76563rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: var(--bs-border-radius);
}

@media (min-width: 1200px) {
  .invalid-tooltip {
    font-size: 0.76562rem;
  }
}

.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip,
.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23fd5353'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fd5353' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23fd5353'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23fd5353' stroke='none'/%3e%3c/svg%3e");
  padding-right: 4.125rem;
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-control-color:invalid,
.form-control-color.is-invalid {
  width: calc(3rem + calc(1.5em + 0.75rem));
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input~.invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group>.form-control:not(:focus):invalid,
.input-group>.form-control:not(:focus).is-invalid,
.was-validated .input-group>.form-select:not(:focus):invalid,
.input-group>.form-select:not(:focus).is-invalid,
.was-validated .input-group>.form-floating:not(:focus-within):invalid,
.input-group>.form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 0.75rem;
  --bs-btn-padding-y: 0.375rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}

.btn-check+.btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:focus-visible+.btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}

.btn-check:checked+.btn:focus-visible,
:not(.btn-check)+.btn:active:focus-visible,
.btn:first-child:active:focus-visible,
.btn.active:focus-visible,
.btn.show:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow);
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #3736af;
  --bs-btn-border-color: #3736af;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2f2e95;
  --bs-btn-hover-border-color: #2c2b8c;
  --bs-btn-focus-shadow-rgb: 85, 84, 187;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2c2b8c;
  --bs-btn-active-border-color: #292983;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #3736af;
  --bs-btn-disabled-border-color: #3736af;
}

.btn-secondary {
  --bs-btn-color: #000;
  --bs-btn-bg: #3693ff;
  --bs-btn-border-color: #3693ff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #54a3ff;
  --bs-btn-hover-border-color: #4a9eff;
  --bs-btn-focus-shadow-rgb: 46, 125, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #5ea9ff;
  --bs-btn-active-border-color: #4a9eff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #3693ff;
  --bs-btn-disabled-border-color: #3693ff;
}

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #3ab67a;
  --bs-btn-border-color: #3ab67a;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #58c18e;
  --bs-btn-hover-border-color: #4ebd87;
  --bs-btn-focus-shadow-rgb: 49, 155, 104;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #61c595;
  --bs-btn-active-border-color: #4ebd87;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #3ab67a;
  --bs-btn-disabled-border-color: #3ab67a;
}

.btn-info {
  --bs-btn-color: #000;
  --bs-btn-bg: #00ada3;
  --bs-btn-border-color: #00ada3;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #26b9b1;
  --bs-btn-hover-border-color: #1ab5ac;
  --bs-btn-focus-shadow-rgb: 0, 147, 139;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #33bdb5;
  --bs-btn-active-border-color: #1ab5ac;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #00ada3;
  --bs-btn-disabled-border-color: #00ada3;
}

.btn-warning {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffab2d;
  --bs-btn-border-color: #ffab2d;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffb84d;
  --bs-btn-hover-border-color: #ffb342;
  --bs-btn-focus-shadow-rgb: 217, 145, 38;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffbc57;
  --bs-btn-active-border-color: #ffb342;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffab2d;
  --bs-btn-disabled-border-color: #ffab2d;
}

.btn-danger {
  --bs-btn-color: #000;
  --bs-btn-bg: #fd5353;
  --bs-btn-border-color: #fd5353;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fd6d6d;
  --bs-btn-hover-border-color: #fd6464;
  --bs-btn-focus-shadow-rgb: 215, 71, 71;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fd7575;
  --bs-btn-active-border-color: #fd6464;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #fd5353;
  --bs-btn-disabled-border-color: #fd5353;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #dedeed;
  --bs-btn-border-color: #dedeed;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #bdbdc9;
  --bs-btn-hover-border-color: #b2b2be;
  --bs-btn-focus-shadow-rgb: 189, 189, 201;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #b2b2be;
  --bs-btn-active-border-color: #a7a7b2;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #dedeed;
  --bs-btn-disabled-border-color: #dedeed;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #5b5e81;
  --bs-btn-border-color: #5b5e81;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #747694;
  --bs-btn-hover-border-color: #6b6e8e;
  --bs-btn-focus-shadow-rgb: 116, 118, 148;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #7c7e9a;
  --bs-btn-active-border-color: #6b6e8e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #5b5e81;
  --bs-btn-disabled-border-color: #5b5e81;
}

.btn-outline-primary {
  --bs-btn-color: #3736af;
  --bs-btn-border-color: #3736af;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3736af;
  --bs-btn-hover-border-color: #3736af;
  --bs-btn-focus-shadow-rgb: 55, 54, 175;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3736af;
  --bs-btn-active-border-color: #3736af;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3736af;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3736af;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #3693ff;
  --bs-btn-border-color: #3693ff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #3693ff;
  --bs-btn-hover-border-color: #3693ff;
  --bs-btn-focus-shadow-rgb: 54, 147, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3693ff;
  --bs-btn-active-border-color: #3693ff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3693ff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3693ff;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #3ab67a;
  --bs-btn-border-color: #3ab67a;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #3ab67a;
  --bs-btn-hover-border-color: #3ab67a;
  --bs-btn-focus-shadow-rgb: 58, 182, 122;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #3ab67a;
  --bs-btn-active-border-color: #3ab67a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #3ab67a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3ab67a;
  --bs-gradient: none;
}

.btn-outline-info {
  --bs-btn-color: #00ada3;
  --bs-btn-border-color: #00ada3;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #00ada3;
  --bs-btn-hover-border-color: #00ada3;
  --bs-btn-focus-shadow-rgb: 0, 173, 163;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #00ada3;
  --bs-btn-active-border-color: #00ada3;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #00ada3;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00ada3;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #ffab2d;
  --bs-btn-border-color: #ffab2d;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffab2d;
  --bs-btn-hover-border-color: #ffab2d;
  --bs-btn-focus-shadow-rgb: 255, 171, 45;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffab2d;
  --bs-btn-active-border-color: #ffab2d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffab2d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffab2d;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #fd5353;
  --bs-btn-border-color: #fd5353;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fd5353;
  --bs-btn-hover-border-color: #fd5353;
  --bs-btn-focus-shadow-rgb: 253, 83, 83;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fd5353;
  --bs-btn-active-border-color: #fd5353;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fd5353;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fd5353;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #dedeed;
  --bs-btn-border-color: #dedeed;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #dedeed;
  --bs-btn-hover-border-color: #dedeed;
  --bs-btn-focus-shadow-rgb: 222, 222, 237;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #dedeed;
  --bs-btn-active-border-color: #dedeed;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #dedeed;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #dedeed;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #5b5e81;
  --bs-btn-border-color: #5b5e81;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5b5e81;
  --bs-btn-hover-border-color: #5b5e81;
  --bs-btn-focus-shadow-rgb: 91, 94, 129;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5b5e81;
  --bs-btn-active-border-color: #5b5e81;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #5b5e81;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5b5e81;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 85, 84, 187;
  text-decoration: underline;
}

.btn-link:focus-visible {
  color: var(--bs-btn-color);
}

.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg,
.btn-group-lg>.btn {
  --bs-btn-padding-y: 0.5rem;
  --bs-btn-padding-x: 1rem;
  --bs-btn-font-size: 1.09375rem;
  --bs-btn-border-radius: var(--bs-border-radius-lg);
}

.btn-sm,
.btn-group-sm>.btn {
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-padding-x: 0.5rem;
  --bs-btn-font-size: 0.76563rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

@media (min-width: 1200px) {

  .btn-sm,
  .btn-group-sm>.btn {
    --bs-btn-font-size: 0.76562rem;
  }
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.875rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #3736af;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}

.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}

.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }

  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }

  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }

  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }

  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }

  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }

  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }

  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }

  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1440) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }

  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }

  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}

.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}

.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropstart .dropdown-toggle::after {
  display: none;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.76563rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

@media (min-width: 1200px) {
  .dropdown-header {
    font-size: 0.76562rem;
  }
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #3736af;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group>.btn,
.btn-group-vertical>.btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn:hover,
.btn-group>.btn:focus,
.btn-group>.btn:active,
.btn-group>.btn.active,
.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn:hover,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: var(--bs-border-radius);
}

.btn-group> :not(.btn-check:first-child)+.btn,
.btn-group>.btn-group:not(:first-child) {
  margin-left: calc(var(--bs-border-width) * -1);
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group>.btn.dropdown-toggle-split:first-child,
.btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn:nth-child(n + 3),
.btn-group> :not(.btn-check)+.btn,
.btn-group>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm+.dropdown-toggle-split,
.btn-group-sm>.btn+.dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg+.dropdown-toggle-split,
.btn-group-lg>.btn+.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group {
  width: 100%;
}

.btn-group-vertical>.btn:not(:first-child),
.btn-group-vertical>.btn-group:not(:first-child) {
  margin-top: calc(var(--bs-border-width) * -1);
}

.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical>.btn-group:not(:last-child)>.btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn~.btn,
.btn-group-vertical>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  text-decoration: none;
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

.nav-link:hover,
.nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}

.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(55, 54, 175, 0.25);
}

.nav-link.disabled,
.nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}

.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}

.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #3736af;
}

.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}

.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}

.nav-underline .nav-link:hover,
.nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}

.nav-underline .nav-link.active,
.nav-underline .show>.nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill>.nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified>.nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.33594rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.09375rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.09375rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28113, 117, 121, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: var(--bs-border-radius);
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}

.navbar>.container,
.navbar>.container-fluid,
.navbar>.container-sm,
.navbar>.container-md,
.navbar>.container-lg,
.navbar>.container-xl,
.navbar>.container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  text-decoration: none;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}

.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}

.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}

.navbar-toggler:hover {
  text-decoration: none;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

@media (min-width: 1440) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }

  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }

  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xxl .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    transition: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-header {
    display: none;
  }

  .navbar-expand-xxl .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  transition: none;
}

.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}

.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme="dark"] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme="dark"] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
}

.card>hr {
  margin-right: 0;
  margin-left: 0;
}

.card>.list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card>.list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card>.list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card>.card-header+.list-group,
.card>.list-group+.card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link+.card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}

.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}

.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group>.card {
  margin-bottom: var(--bs-card-group-margin);
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }

  .card-group>.card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .card-group>.card+.card {
    margin-left: 0;
    border-left: 0;
  }

  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-top,
  .card-group>.card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }

  .card-group>.card:not(:last-child) .card-img-bottom,
  .card-group>.card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }

  .card-group>.card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-top,
  .card-group>.card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }

  .card-group>.card:not(:first-child) .card-img-bottom,
  .card-group>.card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23717579'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23161646'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #9b9bd7;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(55, 54, 175, 0.25);
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 0.875rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}

@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
  border-top: 0;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
  border-top: 0;
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button,
.accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme="dark"] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238786cf'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%238786cf'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item+.breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}

.breadcrumb-item+.breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/")
    /* rtl: var(--bs-breadcrumb-divider, "/") */
  ;
}

.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 0.875rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(55, 54, 175, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #3736af;
  --bs-pagination-active-border-color: #3736af;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  text-decoration: none;
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}

.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}

.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}

.page-link.active,
.active>.page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}

.page-link.disabled,
.disabled>.page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}

.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: 1.09375rem;
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.76563rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

@media (min-width: 1200px) {
  .pagination-sm {
    --bs-pagination-font-size: 0.76562rem;
  }
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.75em;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}

.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-info {
  --bs-alert-color: var(--bs-info-text-emphasis);
  --bs-alert-bg: var(--bs-info-bg-subtle);
  --bs-alert-border-color: var(--bs-info-border-subtle);
  --bs-alert-link-color: var(--bs-info-text-emphasis);
}

.alert-warning {
  --bs-alert-color: var(--bs-warning-text-emphasis);
  --bs-alert-bg: var(--bs-warning-bg-subtle);
  --bs-alert-border-color: var(--bs-warning-border-subtle);
  --bs-alert-link-color: var(--bs-warning-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.65625rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #3736af;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked>.progress {
  overflow: visible;
}

.progress-stacked>.progress>.progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #3736af;
  --bs-list-group-active-border-color: #3736af;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}

.list-group-numbered>.list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}

.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  text-decoration: none;
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}

.list-group-item+.list-group-item {
  border-top-width: 0;
}

.list-group-item+.list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal>.list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}

.list-group-horizontal>.list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}

.list-group-horizontal>.list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-sm>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-md>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-md>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-lg>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

@media (min-width: 1440) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl>.list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }

  .list-group-horizontal-xxl>.list-group-item.active {
    margin-top: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }

  .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush>.list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}

.list-group-flush>.list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-info {
  --bs-list-group-color: var(--bs-info-text-emphasis);
  --bs-list-group-bg: var(--bs-info-bg-subtle);
  --bs-list-group-border-color: var(--bs-info-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-info-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-info-border-subtle);
  --bs-list-group-active-color: var(--bs-info-bg-subtle);
  --bs-list-group-active-bg: var(--bs-info-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-info-text-emphasis);
}

.list-group-item-warning {
  --bs-list-group-color: var(--bs-warning-text-emphasis);
  --bs-list-group-bg: var(--bs-warning-bg-subtle);
  --bs-list-group-border-color: var(--bs-warning-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-warning-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-warning-border-subtle);
  --bs-list-group-active-color: var(--bs-warning-bg-subtle);
  --bs-list-group-active-bg: var(--bs-warning-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-warning-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(55, 54, 175, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: var(--bs-btn-close-opacity);
}

.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}

.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}

.btn-close:disabled,
.btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme="dark"] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 30px;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}

.toast.showing {
  opacity: 0;
}

.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}

.toast-container> :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}

.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: var(--bs-body-bg);
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: var(--bs-border-width);
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: var(--bs-border-width);
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: var(--bs-border-width);
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}

.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}

.modal-footer>* {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }

  .modal-sm {
    --bs-modal-width: 300px;
  }
}

@media (min-width: 992px) {

  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}

.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}

@media (max-width: 1439.98) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}

.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 200px;
  --bs-tooltip-padding-x: 0.5rem;
  --bs-tooltip-padding-y: 0.25rem;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.76563rem;
  --bs-tooltip-color: var(--bs-body-bg);
  --bs-tooltip-bg: var(--bs-emphasis-color);
  --bs-tooltip-border-radius: var(--bs-border-radius);
  --bs-tooltip-opacity: 0.9;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}

@media (min-width: 1200px) {
  .tooltip {
    --bs-tooltip-font-size: 0.76562rem;
  }
}

.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}

.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.76563rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 0.875rem;
  --bs-popover-header-color: #424562;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
}

@media (min-width: 1200px) {
  .popover {
    --bs-popover-font-size: 0.76562rem;
  }
}

.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}

.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top>.popover-arrow,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::before,
.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}

.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end>.popover-arrow,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::before,
.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}

.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom>.popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::before,
.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}

.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start>.popover-arrow,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::before,
.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}

.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
}

.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}

.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-control-prev-icon,
[data-bs-theme="dark"] .carousel .carousel-control-next-icon,
[data-bs-theme="dark"].carousel .carousel-control-prev-icon,
[data-bs-theme="dark"].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}

[data-bs-theme="dark"] .carousel .carousel-indicators [data-bs-target],
[data-bs-theme="dark"].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}

[data-bs-theme="dark"] .carousel .carousel-caption,
[data-bs-theme="dark"].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg)
      /* rtl:ignore */
    ;
  }
}

.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {

  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}

.offcanvas-sm,
.offcanvas-md,
.offcanvas-lg,
.offcanvas-xl,
.offcanvas-xxl,
.offcanvas {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 400px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: var(--bs-border-width);
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}

@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-sm.showing,
  .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-sm.showing,
  .offcanvas-sm.hiding,
  .offcanvas-sm.show {
    visibility: visible;
  }
}

@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-sm .offcanvas-header {
    display: none;
  }

  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-md.showing,
  .offcanvas-md.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-md.showing,
  .offcanvas-md.hiding,
  .offcanvas-md.show {
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-md .offcanvas-header {
    display: none;
  }

  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}

@media (max-width: 991.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-lg.showing,
  .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-lg.showing,
  .offcanvas-lg.hiding,
  .offcanvas-lg.show {
    visibility: visible;
  }
}

@media (min-width: 992px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-lg .offcanvas-header {
    display: none;
  }

  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-xl {
    transition: none;
  }
}

@media (max-width: 1199.98px) {
  .offcanvas-xl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-xl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-xl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-xl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-xl.showing,
  .offcanvas-xl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xl.showing,
  .offcanvas-xl.hiding,
  .offcanvas-xl.show {
    visibility: visible;
  }
}

@media (min-width: 1200px) {
  .offcanvas-xl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-xl .offcanvas-header {
    display: none;
  }

  .offcanvas-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1439.98) {
  .offcanvas-xxl {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    transition: var(--bs-offcanvas-transition);
  }
}

@media (max-width: 1439.98) and (prefers-reduced-motion: reduce) {
  .offcanvas-xxl {
    transition: none;
  }
}

@media (max-width: 1439.98) {
  .offcanvas-xxl.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }

  .offcanvas-xxl.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }

  .offcanvas-xxl.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }

  .offcanvas-xxl.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }

  .offcanvas-xxl.showing,
  .offcanvas-xxl.show:not(.hiding) {
    transform: none;
  }

  .offcanvas-xxl.showing,
  .offcanvas-xxl.hiding,
  .offcanvas-xxl.show {
    visibility: visible;
  }
}

@media (min-width: 1440) {
  .offcanvas-xxl {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }

  .offcanvas-xxl .offcanvas-header {
    display: none;
  }

  .offcanvas-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  transition: var(--bs-offcanvas-transition);
}

@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}

.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}

.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}

.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}

.offcanvas.showing,
.offcanvas.show:not(.hiding) {
  transform: none;
}

.offcanvas.showing,
.offcanvas.hiding,
.offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.offcanvas-backdrop.fade {
  opacity: 0;
}

.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}

.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}

.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

.placeholder-wave {
  mask-image: linear-gradient(130deg,
      #000 55%,
      rgba(0, 0, 0, 0.8) 75%,
      #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb),
      var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(var(--bs-secondary-rgb),
      var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(var(--bs-success-rgb),
      var(--bs-bg-opacity, 1)) !important;
}

.text-bg-info {
  color: #000 !important;
  background-color: RGBA(var(--bs-info-rgb),
      var(--bs-bg-opacity, 1)) !important;
}

.text-bg-warning {
  color: #000 !important;
  background-color: RGBA(var(--bs-warning-rgb),
      var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #000 !important;
  background-color: RGBA(var(--bs-danger-rgb),
      var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb),
      var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb),
      var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-primary:hover,
.link-primary:focus {
  color: RGBA(44, 43, 140, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(44,
      43,
      140,
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary:hover,
.link-secondary:focus {
  color: RGBA(94, 169, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(94,
      169,
      255,
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-success:hover,
.link-success:focus {
  color: RGBA(97, 197, 149, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(97,
      197,
      149,
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-info {
  color: RGBA(var(--bs-info-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-info-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-info:hover,
.link-info:focus {
  color: RGBA(51, 189, 181, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(51,
      189,
      181,
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning {
  color: RGBA(var(--bs-warning-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-warning-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-warning:hover,
.link-warning:focus {
  color: RGBA(255, 188, 87, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255,
      188,
      87,
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger:hover,
.link-danger:focus {
  color: RGBA(253, 117, 117, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(253,
      117,
      117,
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-light:hover,
.link-light:focus {
  color: RGBA(229, 229, 241, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(229,
      229,
      241,
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark:hover,
.link-dark:focus {
  color: RGBA(73, 75, 103, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(73,
      75,
      103,
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb),
      var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis:hover,
.link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb),
      var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb),
      var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb),
      var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}

.icon-link>.bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}

@media (prefers-reduced-motion: reduce) {
  .icon-link>.bi {
    transition: none;
  }
}

.icon-link-hover:hover>.bi,
.icon-link-hover:focus-visible>.bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}

.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}

.ratio>* {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

@media (min-width: 1440) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }

  .sticky-xxl-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}

.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb),
      var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb),
      var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb),
      var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb),
      var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb),
      var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb),
      var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb),
      var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb),
      var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb),
      var(--bs-border-opacity)) !important;
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity)) !important;
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb),
      var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-1 {
  row-gap: 0.25rem !important;
}

.row-gap-2 {
  row-gap: 0.5rem !important;
}

.row-gap-3 {
  row-gap: 1rem !important;
}

.row-gap-4 {
  row-gap: 1.5rem !important;
}

.row-gap-5 {
  row-gap: 3rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-1 {
  column-gap: 0.25rem !important;
}

.column-gap-2 {
  column-gap: 0.5rem !important;
}

.column-gap-3 {
  column-gap: 1rem !important;
}

.column-gap-4 {
  column-gap: 1.5rem !important;
}

.column-gap-5 {
  column-gap: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.35rem + 1.2vw) !important;
}

.fs-2 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}

.fs-3 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-4 {
  font-size: 1.125rem !important;
}

.fs-5 {
  font-size: 1rem !important;
}

.fs-6 {
  font-size: 0.938rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb),
      var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb),
      var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb),
      var(--bs-link-underline-opacity)) !important;
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb),
      var(--bs-link-underline-opacity)) !important;
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb),
      var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb),
      var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb),
      var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb),
      var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb),
      var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb),
      var(--bs-bg-opacity)) !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .object-fit-sm-contain {
    object-fit: contain !important;
  }

  .object-fit-sm-cover {
    object-fit: cover !important;
  }

  .object-fit-sm-fill {
    object-fit: fill !important;
  }

  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }

  .object-fit-sm-none {
    object-fit: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-inline-grid {
    display: inline-grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .row-gap-sm-0 {
    row-gap: 0 !important;
  }

  .row-gap-sm-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-sm-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-sm-3 {
    row-gap: 1rem !important;
  }

  .row-gap-sm-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-sm-5 {
    row-gap: 3rem !important;
  }

  .column-gap-sm-0 {
    column-gap: 0 !important;
  }

  .column-gap-sm-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-sm-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-sm-3 {
    column-gap: 1rem !important;
  }

  .column-gap-sm-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-sm-5 {
    column-gap: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .object-fit-md-contain {
    object-fit: contain !important;
  }

  .object-fit-md-cover {
    object-fit: cover !important;
  }

  .object-fit-md-fill {
    object-fit: fill !important;
  }

  .object-fit-md-scale {
    object-fit: scale-down !important;
  }

  .object-fit-md-none {
    object-fit: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-inline-grid {
    display: inline-grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .row-gap-md-0 {
    row-gap: 0 !important;
  }

  .row-gap-md-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-md-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-md-3 {
    row-gap: 1rem !important;
  }

  .row-gap-md-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-md-5 {
    row-gap: 3rem !important;
  }

  .column-gap-md-0 {
    column-gap: 0 !important;
  }

  .column-gap-md-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-md-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-md-3 {
    column-gap: 1rem !important;
  }

  .column-gap-md-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-md-5 {
    column-gap: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .object-fit-lg-contain {
    object-fit: contain !important;
  }

  .object-fit-lg-cover {
    object-fit: cover !important;
  }

  .object-fit-lg-fill {
    object-fit: fill !important;
  }

  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }

  .object-fit-lg-none {
    object-fit: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-inline-grid {
    display: inline-grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .row-gap-lg-0 {
    row-gap: 0 !important;
  }

  .row-gap-lg-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-lg-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-lg-3 {
    row-gap: 1rem !important;
  }

  .row-gap-lg-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-lg-5 {
    row-gap: 3rem !important;
  }

  .column-gap-lg-0 {
    column-gap: 0 !important;
  }

  .column-gap-lg-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-lg-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-lg-3 {
    column-gap: 1rem !important;
  }

  .column-gap-lg-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-lg-5 {
    column-gap: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .object-fit-xl-contain {
    object-fit: contain !important;
  }

  .object-fit-xl-cover {
    object-fit: cover !important;
  }

  .object-fit-xl-fill {
    object-fit: fill !important;
  }

  .object-fit-xl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xl-none {
    object-fit: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-inline-grid {
    display: inline-grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .row-gap-xl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xl-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-xl-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-xl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xl-5 {
    column-gap: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1440) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .object-fit-xxl-contain {
    object-fit: contain !important;
  }

  .object-fit-xxl-cover {
    object-fit: cover !important;
  }

  .object-fit-xxl-fill {
    object-fit: fill !important;
  }

  .object-fit-xxl-scale {
    object-fit: scale-down !important;
  }

  .object-fit-xxl-none {
    object-fit: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-inline-grid {
    display: inline-grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .row-gap-xxl-0 {
    row-gap: 0 !important;
  }

  .row-gap-xxl-1 {
    row-gap: 0.25rem !important;
  }

  .row-gap-xxl-2 {
    row-gap: 0.5rem !important;
  }

  .row-gap-xxl-3 {
    row-gap: 1rem !important;
  }

  .row-gap-xxl-4 {
    row-gap: 1.5rem !important;
  }

  .row-gap-xxl-5 {
    row-gap: 3rem !important;
  }

  .column-gap-xxl-0 {
    column-gap: 0 !important;
  }

  .column-gap-xxl-1 {
    column-gap: 0.25rem !important;
  }

  .column-gap-xxl-2 {
    column-gap: 0.5rem !important;
  }

  .column-gap-xxl-3 {
    column-gap: 1rem !important;
  }

  .column-gap-xxl-4 {
    column-gap: 1.5rem !important;
  }

  .column-gap-xxl-5 {
    column-gap: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.25rem !important;
  }

  .fs-2 {
    font-size: 1.875rem !important;
  }

  .fs-3 {
    font-size: 1.5rem !important;
  }
}

@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-inline-grid {
    display: inline-grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}

.c-pointer {
  cursor: pointer;
}

* {
  outline: none;
  padding: 0;
}

*::after {
  margin: 0;
  padding: 0;
}

*::before {
  margin: 0;
  padding: 0;
}


.dropzone .dz-message {
  margin: 5em 0;
}

h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
h5 a,
.h5 a,
h6 a,
.h6 a {
  color: #424562;
}

h1 a:hover,
.h1 a:hover,
h2 a:hover,
.h2 a:hover,
h3 a:hover,
.h3 a:hover,
h4 a:hover,
.h4 a:hover,
h5 a:hover,
.h5 a:hover,
h6 a:hover,
.h6 a:hover {
  color: #424562;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: var(--body-bg);
}

body::-webkit-scrollbar {
  display: none;
}

div,
span,
ol,
ul {
  scrollbar-width: thin;
}

@media only screen and (max-width: 100rem) {
  :root {
    font-size: 0.875rem;
  }
}

body {
  overflow-x: hidden;
  height: 100%;
  position: relative;
  max-width: 100%;
  font-size: 0.875rem;
  color: var(--text);
}

@media only screen and (max-width: 61.9375rem) {
  body {
    font-size: 0.875rem;
  }
}

textarea.form-control {
  min-height: auto !important;
  height: auto !important;
}

p {
  line-height: 1.8;
}

.box-shadow-none {
  box-shadow: none !important;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

#main-wrapper {
  opacity: 0;
  transition: all 0.25s ease-in;
  position: relative;
}

#main-wrapper.show {
  opacity: 1;
}

.rounded-lg {
  border-radius: 0.5rem;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  color: #717579;
  text-decoration: none;
}

a:hover,
a:focus,
a.active {
  text-decoration: none;
}

.btn-link:hover,
.btn-link:focus,
.btn-link.active {
  text-decoration: none;
}

.bg-primary {
  background-color: var(--primary) !important;
}

.text-primary {
  color: var(--primary) !important;
}

.fs-12 {
  font-size: 0.75rem !important;
  line-height: 1.5;
}

.fs-13 {
  font-size: 0.8125rem !important;
  line-height: 1.5;
}

.fs-14 {
  font-size: 0.875rem !important;
  line-height: 1.6;
}

.fs-15 {
  font-size: 0.875rem !important;
  line-height: 1.5;
}

.fs-16 {
  font-size: 1rem !important;
  line-height: 1.6;
}

@media only screen and (max-width: 35.9375rem) {
  .fs-16 {
    font-size: 0.875rem !important;
  }
}

.fs-18 {
  font-size: 1.125rem !important;
  line-height: 1.5;
}

@media only screen and (max-width: 35.9375rem) {
  .fs-18 {
    font-size: 1rem !important;
  }
}

.fs-20 {
  font-size: 1.25rem !important;
  line-height: 1.5;
}

.fs-22 {
  font-size: 1.375rem !important;
  line-height: 1.5;
}

.fs-24 {
  font-size: 1.5rem !important;
  line-height: 1.4;
}

.fs-26 {
  font-size: 1.625rem !important;
  line-height: 1.4;
}

.fs-28 {
  font-size: 1.75rem !important;
  line-height: 1.4;
}

@media only screen and (max-width: 35.9375rem) {
  .fs-28 {
    font-size: 1.5rem !important;
  }
}

.fs-30 {
  font-size: 1.875rem !important;
  line-height: 1.4;
}

.fs-32 {
  font-size: 2rem !important;
  line-height: 1.25;
}

.fs-34 {
  font-size: 2.125rem !important;
  line-height: 1.25;
}

.fs-35 {
  font-size: 2.1875rem !important;
  line-height: 1.25;
}

.fs-36 {
  font-size: 2.25rem !important;
  line-height: 1.25;
}

.fs-38 {
  font-size: 2.375rem !important;
  line-height: 1.25;
}

.fs-46 {
  font-size: 2.875rem !important;
  line-height: 1.25;
}

.fs-48 {
  font-size: 3rem !important;
  line-height: 1.25;
}

.font-w100 {
  font-weight: 100;
}

.font-w200 {
  font-weight: 200;
}

.font-w300 {
  font-weight: 300;
}

.font-w400 {
  font-weight: 400;
}

.font-w500 {
  font-weight: 500;
}

.font-w600 {
  font-weight: 600;
}

.font-w700 {
  font-weight: 700;
}

.font-w800 {
  font-weight: 800;
}

.font-w900 {
  font-weight: 900;
}

h1,
.h1,
.h1,
h2,
.h2,
.h2,
h3,
.h3,
.h3,
h4,
.h4,
.h4,
h5,
.h5,
.h5,
h6,
.h6,
.h6 {
  line-height: 1.5;
  color: var(--text-dark);
}

.w-space-no {
  white-space: nowrap;
}

.content-body .container {
  margin-top: 2.5rem;
}

.content-body .container-fluid,
.content-body .container-sm,
.content-body .container-md,
.content-body .container-lg,
.content-body .container-xl,
.content-body .container-xxl {
  padding-top: 2.5rem;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

@media only screen and (max-width: 75rem) {

  .content-body .container-fluid,
  .content-body .container-sm,
  .content-body .container-md,
  .content-body .container-lg,
  .content-body .container-xl,
  .content-body .container-xxl {
    padding-top: 1.875rem;
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }
}

@media only screen and (max-width: 47.9375rem) {

  .content-body .container-fluid,
  .content-body .container-sm,
  .content-body .container-md,
  .content-body .container-lg,
  .content-body .container-xl,
  .content-body .container-xxl {
    padding-top: 1.25rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

@media only screen and (max-width: 35.9375rem) {

  .content-body .container-fluid,
  .content-body .container-sm,
  .content-body .container-md,
  .content-body .container-lg,
  .content-body .container-xl,
  .content-body .container-xxl {
    padding-top: 15px;
    padding-right: 15px;
    padding-left: 15px;
  }
}

/*sp*/
.row.sp4,
.sp4 {
  margin-left: -0.125rem;
  margin-right: -0.125rem;
}

.row.sp4 [class*="col-"],
.sp4 [class*="col-"] {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}

.op1 {
  opacity: 0.1;
}

.op2 {
  opacity: 0.2;
}

.op3 {
  opacity: 0.3;
}

.op4 {
  opacity: 0.4;
}

.op5 {
  opacity: 0.5;
}

.op6 {
  opacity: 0.6;
}

.op7 {
  opacity: 0.7;
}

.op8 {
  opacity: 0.8;
}

.op9 {
  opacity: 0.9;
}

.content-heading {
  font-size: 1rem;
  margin-bottom: 1.875rem;
  margin-top: 3.125rem;
  border-bottom: 0.0625rem solid #e6e6e6;
  padding-bottom: 0.625rem;
}

[direction="rtl"] .content-heading {
  text-align: right;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: none;
}

.vh-100 {
  height: 100vh !important;
}

.support-ticket {
  position: fixed;
  bottom: 1.875rem;
  right: 0.9375rem;
  z-index: 999999;
}

.support-ticket-btn {
  width: 6.25rem;
  background: #7cb442;
  animation: 0.7s ease-in-out 0s infinite alternate none running crescendo;
  border-radius: 3.125rem;
  color: #fff;
  font-size: 0.5rem;
  font-size: 1rem;
  padding: 0.3125rem 0.625rem 0.4375rem;
  text-align: center;
  display: inline-block;
  box-shadow: rgba(124, 180, 66, 0.7) 0rem 0.5rem 2.1875rem 0rem;
}

.support-ticket-btn:hover,
.support-ticket-btn:focus {
  color: #fff;
}

.text-blue {
  color: #374c98;
}

.text-indigo {
  color: #6610f2;
}

.text-purple {
  color: #6f42c1;
}

.text-pink {
  color: var(--primary) !important;
}

.text-red {
  color: #ee3232;
}

.text-orange {
  color: #ff9900;
}

.text-yellow {
  color: #fffa6f;
}

.text-green {
  color: #297f00;
}

.text-teal {
  color: #20c997;
}

.text-cyan {
  color: #3065d0;
}

.bg-blue {
  background: #496ecc !important;
}

.bg-orange {
  background: #ed8030 !important;
}

.bg-green {
  background: #299e4a !important;
}

.bg-purpel {
  background: #9517c1 !important;
}

.bg-dark-blue {
  background: #251e71 !important;
}

.bg-black {
  background: #000;
}

.text-black {
  color: #000 !important;
}

.dlab-scroll {
  position: relative;
}

.scale1 {
  transform: scale(1.1);
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  display: inline-block;
}

.scale2 {
  transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  display: inline-block;
}

.scale3 {
  transform: scale(1.3);
  -moz-transform: scale(1.3);
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  -o-transform: scale(1.3);
  display: inline-block;
}

.scale4 {
  transform: scale(1.4);
  -moz-transform: scale(1.4);
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  -o-transform: scale(1.4);
  display: inline-block;
}

.scale5 {
  transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  -o-transform: scale(1.5);
  display: inline-block;
}

.scale-2 {
  transform: scale(2);
  -moz-transform: scale(2);
  -webkit-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
  display: inline-block;
}

@-webkit-keyframes crescendo {
  from {
    -webkit-transform: translateY(0.3125rem) scale(0.8);
    -ms-transform: translateY(0.3125rem) scale(0.8);
    transform: translateY(0.3125rem) scale(0.8);
  }

  to {
    -webkit-transform: translateY(0rem) scale(1);
    -ms-transform: translateY(0rem) scale(1);
    transform: translateY(0rem) scale(1);
  }
}

.height10 {
  height: 0.625rem;
}

.height20 {
  height: 1.25rem;
}

.height30 {
  height: 1.875rem;
}

.height40 {
  height: 2.5rem;
}

.height50 {
  height: 3.125rem;
}

.height60 {
  height: 3.75rem;
}

.height70 {
  height: 4.375rem;
}

.height80 {
  height: 5rem;
}

.height90 {
  height: 5.625rem;
}

.height100 {
  height: 6.25rem;
}

.height110 {
  height: 6.875rem;
}

.height120 {
  height: 7.5rem;
}

.height130 {
  height: 8.125rem;
}

.height140 {
  height: 8.75rem;
}

.height150 {
  height: 9.375rem;
}

.height160 {
  height: 10rem;
}

.height170 {
  height: 10.625rem;
}

.height180 {
  height: 11.25rem;
}

.height190 {
  height: 11.875rem;
}

.height200 {
  height: 12.5rem;
}

.height210 {
  height: 13.125rem;
}

.height220 {
  height: 13.75rem;
}

.height230 {
  height: 14.375rem;
}

.height240 {
  height: 15rem;
}

.height250 {
  height: 15.625rem;
}

.height260 {
  height: 16.25rem;
}

.height270 {
  height: 16.875rem;
}

.height280 {
  height: 17.5rem;
}

.height290 {
  height: 18.125rem;
}

.height300 {
  height: 18.75rem;
}

.height310 {
  height: 19.375rem;
}

.height320 {
  height: 20rem;
}

.height330 {
  height: 20.625rem;
}

.height340 {
  height: 21.25rem;
}

.height350 {
  height: 21.875rem;
}

.height360 {
  height: 22.5rem;
}

.height370 {
  height: 23.125rem;
}

.height380 {
  height: 23.75rem;
}

.height390 {
  height: 24.375rem;
}

.height400 {
  height: 25rem;
}

.height415 {
  height: 25.9375rem;
}

.height500 {
  height: 31.25rem;
}

.height550 {
  height: 34.375rem;
}

.height600 {
  height: 37.5rem;
}

.height630 {
  height: 39.375rem;
}

.height720 {
  height: 45rem;
}

.height750 {
  height: 46.875rem;
}

.height800 {
  height: 50rem;
}

.width10 {
  width: 0.625rem;
}

.width20 {
  width: 1.25rem;
}

.width30 {
  width: 1.875rem;
}

.width40 {
  width: 2.5rem;
}

.width50 {
  width: 3.125rem;
}

.width60 {
  width: 3.75rem;
}

.width70 {
  width: 4.375rem;
}

.width80 {
  width: 5rem;
}

.width90 {
  width: 5.625rem;
}

.width100 {
  width: 6.25rem;
}

.width110 {
  width: 6.875rem;
}

.width120 {
  width: 7.5rem;
}

.width130 {
  width: 8.125rem;
}

.width140 {
  width: 8.75rem;
}

.width150 {
  width: 9.375rem;
}

.width160 {
  width: 10rem;
}

.width170 {
  width: 10.625rem;
}

.width180 {
  width: 11.25rem;
}

.width190 {
  width: 11.875rem;
}

.width200 {
  width: 12.5rem;
}

.width210 {
  width: 13.125rem;
}

.width220 {
  width: 13.75rem;
}

.width230 {
  width: 14.375rem;
}

.width240 {
  width: 15rem;
}

.width250 {
  width: 15.625rem;
}

.width260 {
  width: 16.25rem;
}

.width270 {
  width: 16.875rem;
}

.width280 {
  width: 17.5rem;
}

.width290 {
  width: 18.125rem;
}

.width300 {
  width: 18.75rem;
}

.width310 {
  width: 19.375rem;
}

.width320 {
  width: 20rem;
}

.width330 {
  width: 20.625rem;
}

.width340 {
  width: 21.25rem;
}

.width350 {
  width: 21.875rem;
}

.width360 {
  width: 22.5rem;
}

.width370 {
  width: 23.125rem;
}

.width380 {
  width: 23.75rem;
}

.width390 {
  width: 24.375rem;
}

.width400 {
  width: 25rem;
}

.rounded {
  border-radius: 0.5rem !important;
}

@keyframes gXGDoR {
  from {
    -webkit-transform: translateY(0.3125rem) scale(0.8);
    -ms-transform: translateY(0.3125rem) scale(0.8);
    transform: translateY(0.3125rem) scale(0.8);
  }

  to {
    -webkit-transform: translateY(0rem) scale(1);
    -ms-transform: translateY(0rem) scale(1);
    transform: translateY(0rem) scale(1);
  }
}

@keyframes crescendo {
  from {
    -webkit-transform: translateY(0.3125rem) scale(0.8);
    -ms-transform: translateY(0.3125rem) scale(0.8);
    transform: translateY(0.3125rem) scale(0.8);
  }

  to {
    -webkit-transform: translateY(0rem) scale(1);
    -ms-transform: translateY(0rem) scale(1);
    transform: translateY(0rem) scale(1);
  }
}

@keyframes gXGDoR {
  from {
    -webkit-transform: translateY(0.3125rem) scale(0.8);
    -ms-transform: translateY(0.3125rem) scale(0.8);
    transform: translateY(0.3125rem) scale(0.8);
  }

  to {
    -webkit-transform: translateY(0rem) scale(1);
    -ms-transform: translateY(0rem) scale(1);
    transform: translateY(0rem) scale(1);
  }
}

.bg-pink {
  background-color: #ac4cbc !important;
}

.required {
  position: relative;
}

.required:after {
  content: "\f621";
  position: absolute;
  font-size: 6px;
  font-weight: 900;
  color: #fd5353;
  font-family: "Font Awesome 6 Free";
  padding-left: 3px;
}

.form-label {
  color: var(--text-dark);
  margin-bottom: 0.5rem;
}

.text-dark {
  color: var(--text-dark) !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }

  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }

  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }

  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }

  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
}

/* Editable */
#preloader {
  background-color: #fff;
  padding: 0;
  margin: 0;
  height: 100%;
  position: fixed;
  z-index: 99999;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#preloader .lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

#preloader .lds-ripple div {
  position: absolute;
  border: 4px solid var(--primary);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

#preloader .lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

[data-theme-version="dark"] #preloader {
  background-color: #09153f;
}

.footer {
  padding-left: 16.1875rem;
  background-color: var(--card);
  font-size: 0.875rem;
}

.footer .copyright {
  padding: 0.5375rem;
  margin-top: 1.875rem;
}

.footer .copyright p {
  text-align: center;
  margin: 0;
  color: var(--text-dark);
}

.footer .copyright a {
  color: var(--primary);
}

@media only screen and (max-width: 61.9375rem) {
  .footer {
    padding-right: 0;
  }
}

.footer.style-1 .copyright {
  width: calc(100% - 180px);
}

@media only screen and (max-width: 100rem) {
  .footer.style-1 .copyright {
    width: 100%;
  }
}

[data-sidebar-style="mini"] .nav-control,
[data-layout="horizontal"] .nav-control {
  display: none;
}

@media only screen and (max-width: 47.9375rem) {
  [data-sidebar-style="overlay"] .nav-header .logo-abbr {
    display: block;
  }
}

[data-header-position="fixed"] .nav-header {
  position: fixed;
}

.nav-header {
  height: 82px;
  width: 16.2rem;
  display: inline-block;
  background-color: var(--nav-headbg);
  text-align: left;
  position: absolute;
  top: 0;
  transition: all 0.2s ease;
  z-index: 5;
}

.nav-header .logo-abbr {
  max-width: 2.9375rem;
}

@media only screen and (max-width: 87.5rem) {
  .nav-header .logo-abbr {
    max-width: 2.8125rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .nav-header .logo-abbr {
    width: 2.1875rem;
    height: 2.1875rem;
  }
}

.nav-header .logo-compact {
  display: none;
}

@media only screen and (max-width: 87.5rem) {
  .nav-header {
    height: 5.5rem;
    width: 17rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .nav-header {
    height: 4.5rem;
  }
}

.nav-header .brand-logo {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.125rem;
  color: #fff;
  text-decoration: none;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  font-weight: 700;
}

@media only screen and (max-width: 87.5rem) {
  .nav-header .brand-logo {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

[data-sidebar-style="compact"] .nav-header .brand-logo,
[data-sidebar-style="mini"] .nav-header .brand-logo {
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
}

@media only screen and (max-width: 47.9375rem) {
  .nav-header .brand-logo {
    padding-left: 0;
    padding-right: 0;
    justify-content: center;
  }
}

.nav-header .brand-title {
  margin-left: 0.9375rem;
  max-width: 8.75rem;
  color: #000;
}

[data-theme-version="dark"] .nav-header .brand-title {
  background-position: 0 120%;
}

.nav-header .brand-title h2,
.nav-header .brand-title .h2 {
  line-height: 0;
  font-weight: bold;
  font-size: 1.75rem;
  margin-bottom: 12px;
}

.nav-header .brand-title .brand-sub-title {
  font-size: 0.875rem;
  font-weight: 400;
  white-space: nowrap;
  font-family: Nunito;
}

@media only screen and (max-width: 47.9375rem) {
  .nav-header {
    top: 5px;
    background: transparent;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .nav-header {
    top: 0px;
  }
}

.nav-header .rect-primary-rect {
  fill: var(--primary);
}

@media only screen and (max-width: 63.9375rem) {
  .nav-header {
    width: 5rem;
  }

  .nav-header .brand-title {
    display: none;
  }
}

.nav-control {
  cursor: pointer;
  position: absolute;
  right: -5.1rem;
  text-align: center;
  top: 46%;
  transform: translateY(-43%);
  z-index: 9999;
  font-size: 1.4rem;
  background: rgba(255, 255, 255, 0.12);
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
}

@media only screen and (max-width: 100rem) {
  .nav-control {
    right: -5.5rem;
    transform: translateY(-56%);
  }
}

@media only screen and (max-width: 87.5rem) {
  .nav-control {
    right: -5.956rem;
    top: 50%;
    width: 43px;
    height: 43px;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .nav-control {
    top: 56%;
    right: -5.156rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .nav-control {
    right: -3rem;
    top: 51%;
    height: 43px;
    width: 43px;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .nav-control {
    top: 53%;
    height: 35px;
    width: 35px;
    right: -1.6rem;
  }
}

.hamburger {
  display: inline-block;
  left: 0.2rem;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  width: 1.625rem;
  z-index: 999;
}

.hamburger .line {
  background: #fff;
  display: block;
  height: 0.188rem;
  border-radius: 0.1875rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  margin-right: auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.hamburger .line:nth-child(1) {
  width: 1.375rem;
}

.hamburger .line:nth-child(2) {
  width: 1.375rem;
}

.hamburger .line:nth-child(3) {
  width: 0.875rem;
}

.hamburger:hover {
  cursor: pointer;
}

.hamburger:hover .line {
  width: 1.625rem;
}

.hamburger.is-active .line:nth-child(1),
.hamburger.is-active .line:nth-child(3) {
  width: 0.625rem;
  height: 0.125rem;
}

.hamburger.is-active .line:nth-child(2) {
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  width: 1.375rem;
  height: 0.125rem;
}

.hamburger.is-active .line:nth-child(1) {
  -webkit-transform: translateY(2px) translateX(12px) rotate(45deg);
  transform: translateY(2px) translateX(12px) rotate(45deg);
}

.hamburger.is-active .line:nth-child(3) {
  -webkit-transform: translateY(-2px) translateX(12px) rotate(-45deg);
  transform: translateY(-2px) translateX(12px) rotate(-45deg);
}

@media (min-width: 47.9375rem) {
  [data-sidebar-style="compact"] .nav-control {
    display: none;
  }

  [data-sidebar-style="compact"] .nav-header {
    width: 11.25rem;
  }
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .brand-title {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .logo-abbr {
  display: block;
}

[data-sidebar-style="full"][data-layout="horizontal"] .logo-compact {
  display: none;
}

[data-sidebar-style="mini"] .nav-header .logo-abbr {
  display: block;
}

[data-sidebar-style="compact"][data-layout="vertical"] .nav-header .brand-title {
  display: none;
}

[data-sidebar-style="compact"][data-layout="vertical"] .nav-header .logo-compact {
  max-width: 4.6875rem;
}

[data-sidebar-style="compact"][data-layout="horizontal"] .nav-header .brand-logo {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  justify-content: start;
}

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header {
  width: 10.625rem;
}

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header .brand-title {
  display: none;
}

[data-sidebar-style="modern"][data-layout="vertical"] .nav-header .logo-compact {
  display: none;
}

[data-header-position="fixed"] .nav-header,
[data-sidebar-position="fixed"][data-layout="vertical"] .nav-header,
[data-sidebar-position="fixed"][data-header-position="fixed"] .nav-header {
  position: fixed;
}

.header {
  z-index: 1;
  position: relative;
  padding: 0rem;
  z-index: 3;
  padding-left: 17rem;
  padding-top: 1px;
  background-position: center;
  background-size: cover;
}

.header .header-content {
  height: 82px;
  padding-left: 6rem;
  padding-right: 1.875rem;
  align-items: center;
  display: flex;
  left: 16.2rem;
  position: fixed;
  top: 0;
  width: calc(100% - 16.2rem);
}

@media only screen and (max-width: 100rem) {
  .header .header-content {
    height: 70.5px;
  }
}

@media only screen and (max-width: 87.5rem) {
  .header .header-content {
    padding-left: 8rem;
    left: 16rem;
  }
}

@media only screen and (max-width: 64rem) {
  .header .header-content {
    left: 6.3rem;
    width: calc(100% - 6.3rem);
    height: 5.5rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .header .header-content {
    padding-left: 3.75rem;
    padding-right: 0.938rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .header .header-content {
    left: 0rem;
    width: calc(100% - 0rem);
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header .header-content {
    height: 4.5rem;
  }
}

.header .navbar {
  padding: 0;
  height: 100%;
  width: 100%;
}

.header .navbar .navbar-collapse {
  height: 100%;
  width: 100%;
}

.header.is-fixed .header-content {
  background-color: var(--headerbg);
}

[data-headerbg="color_2"] .header:not(.home) {
  background-color: var(--headerbg);
}

[data-headerbg="color_2"] .header:not(.home):after {
  background-image: url(../images/bg-1.png);
  content: "";
  position: absolute;
  mix-blend-mode: luminosity;
  z-index: -2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 70px -50px;
}

[data-headerbg="color_3"] .header:not(.home) {
  background-color: var(--headerbg);
}

[data-headerbg="color_3"] .header:not(.home):after {
  background-image: url(../images/bg-1.png);
  content: "";
  position: absolute;
  mix-blend-mode: luminosity;
  z-index: -2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 70px -50px;
}

[data-headerbg="color_4"] .header:not(.home) {
  background-color: var(--headerbg);
}

[data-headerbg="color_4"] .header:not(.home):after {
  background-image: url(../images/bg-1.png);
  content: "";
  position: absolute;
  mix-blend-mode: luminosity;
  z-index: -2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 70px -50px;
}

[data-headerbg="color_5"] .header:not(.home) {
  background-color: var(--headerbg);
}

[data-headerbg="color_5"] .header:not(.home):after {
  background-image: url(../images/bg-1.png);
  content: "";
  position: absolute;
  mix-blend-mode: luminosity;
  z-index: -2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 70px -50px;
}

[data-headerbg="color_6"] .header:not(.home) {
  background-color: var(--headerbg);
}

[data-headerbg="color_6"] .header:not(.home):after {
  background-image: url(../images/bg-1.png);
  content: "";
  position: absolute;
  mix-blend-mode: luminosity;
  z-index: -2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 70px -50px;
}

[data-headerbg="color_7"] .header:not(.home) {
  background-color: var(--headerbg);
}

[data-headerbg="color_7"] .header:not(.home):after {
  background-image: url(../images/bg-1.png);
  content: "";
  position: absolute;
  mix-blend-mode: luminosity;
  z-index: -2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 70px -50px;
}

[data-headerbg="color_8"] .header:not(.home) {
  background-color: var(--headerbg);
}

[data-headerbg="color_8"] .header:not(.home):after {
  background-image: url(../images/bg-1.png);
  content: "";
  position: absolute;
  mix-blend-mode: luminosity;
  z-index: -2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 70px -50px;
}

[data-headerbg="color_9"] .header:not(.home) {
  background-color: var(--headerbg);
}

[data-headerbg="color_9"] .header:not(.home):after {
  background-image: url(../images/bg-1.png);
  content: "";
  position: absolute;
  mix-blend-mode: luminosity;
  z-index: -2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 70px -50px;
}

[data-headerbg="color_10"] .header:not(.home) {
  background-color: var(--headerbg);
}

[data-headerbg="color_10"] .header:not(.home):after {
  background-image: url(../images/bg-1.png);
  content: "";
  position: absolute;
  mix-blend-mode: luminosity;
  z-index: -2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 70px -50px;
}

[data-headerbg="color_11"] .header:not(.home) {
  background-color: var(--headerbg);
}

[data-headerbg="color_11"] .header:not(.home):after {
  background-image: url(../images/bg-1.png);
  content: "";
  position: absolute;
  mix-blend-mode: luminosity;
  z-index: -2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 70px -50px;
}

[data-headerbg="color_12"] .header:not(.home) {
  background-color: var(--headerbg);
}

[data-headerbg="color_12"] .header:not(.home):after {
  background-image: url(../images/bg-1.png);
  content: "";
  position: absolute;
  mix-blend-mode: luminosity;
  z-index: -2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 70px -50px;
}

[data-headerbg="color_13"] .header:not(.home) {
  background-color: var(--headerbg);
}

[data-headerbg="color_13"] .header:not(.home):after {
  background-image: url(../images/bg-1.png);
  content: "";
  position: absolute;
  mix-blend-mode: luminosity;
  z-index: -2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 70px -50px;
}

[data-headerbg="color_14"] .header:not(.home) {
  background-color: var(--headerbg);
}

[data-headerbg="color_14"] .header:not(.home):after {
  background-image: url(../images/bg-1.png);
  content: "";
  position: absolute;
  mix-blend-mode: luminosity;
  z-index: -2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 70px -50px;
}

[data-headerbg="color_15"] .header:not(.home) {
  background-color: var(--headerbg);
}

[data-headerbg="color_15"] .header:not(.home):after {
  background-image: url(../images/bg-1.png);
  content: "";
  position: absolute;
  mix-blend-mode: luminosity;
  z-index: -2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 70px -50px;
}

[data-headerbg="color_2"] .header.is-fixed {
  background: var(--headerbg);
  z-index: 4;
}

[data-headerbg="color_3"] .header.is-fixed {
  background: var(--headerbg);
  z-index: 4;
}

[data-headerbg="color_4"] .header.is-fixed {
  background: var(--headerbg);
  z-index: 4;
}

[data-headerbg="color_5"] .header.is-fixed {
  background: var(--headerbg);
  z-index: 4;
}

[data-headerbg="color_6"] .header.is-fixed {
  background: var(--headerbg);
  z-index: 4;
}

[data-headerbg="color_7"] .header.is-fixed {
  background: var(--headerbg);
  z-index: 4;
}

[data-headerbg="color_8"] .header.is-fixed {
  background: var(--headerbg);
  z-index: 4;
}

[data-headerbg="color_9"] .header.is-fixed {
  background: var(--headerbg);
  z-index: 4;
}

[data-headerbg="color_10"] .header.is-fixed {
  background: var(--headerbg);
  z-index: 4;
}

[data-headerbg="color_11"] .header.is-fixed {
  background: var(--headerbg);
  z-index: 4;
}

[data-headerbg="color_12"] .header.is-fixed {
  background: var(--headerbg);
  z-index: 4;
}

[data-headerbg="color_13"] .header.is-fixed {
  background: var(--headerbg);
  z-index: 4;
}

[data-headerbg="color_14"] .header.is-fixed {
  background: var(--headerbg);
  z-index: 4;
}

[data-headerbg="color_15"] .header.is-fixed {
  background: var(--headerbg);
  z-index: 4;
}

[data-nav-headbg="color_2"] .nav-header {
  background: var(--nav-headbg);
}

[data-nav-headbg="color_3"] .nav-header {
  background: var(--nav-headbg);
}

[data-nav-headbg="color_4"] .nav-header {
  background: var(--nav-headbg);
}

[data-nav-headbg="color_5"] .nav-header {
  background: var(--nav-headbg);
}

[data-nav-headbg="color_6"] .nav-header {
  background: var(--nav-headbg);
}

[data-nav-headbg="color_7"] .nav-header {
  background: var(--nav-headbg);
}

[data-nav-headbg="color_8"] .nav-header {
  background: var(--nav-headbg);
}

[data-nav-headbg="color_9"] .nav-header {
  background: var(--nav-headbg);
}

[data-nav-headbg="color_10"] .nav-header {
  background: var(--nav-headbg);
}

[data-nav-headbg="color_11"] .nav-header {
  background: var(--nav-headbg);
}

[data-nav-headbg="color_12"] .nav-header {
  background: var(--nav-headbg);
}

[data-nav-headbg="color_13"] .nav-header {
  background: var(--nav-headbg);
}

[data-nav-headbg="color_14"] .nav-header {
  background: var(--nav-headbg);
}

[data-nav-headbg="color_15"] .nav-header {
  background: var(--nav-headbg);
}

[data-headerbg="color_1"] .header:not(.home) {
  background-color: var(--headerbg);
}

[data-headerbg="color_1"] .header:not(.home):after {
  background-image: url(../images/bg-1.png);
  content: "";
  position: absolute;
  mix-blend-mode: luminosity;
  z-index: -2;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 70px -50px;
}

/* pulse in SVG */
svg.pulse-svg {
  overflow: visible;
}

svg.pulse-svg .first-circle,
svg.pulse-svg .second-circle,
svg.pulse-svg .third-circle {
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-animation: pulse-me 3s linear infinite;
  animation: pulse-me 3s linear infinite;
  fill: var(--primary);
}

svg.pulse-svg .second-circle {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

svg.pulse-svg .third-circle {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

/* pulse in CSS */
.pulse-css {
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  border-radius: 3.5rem;
  height: 1.25rem;
  position: absolute;
  background: #fe8630;
  right: 0.375rem;
  top: 0.3125rem;
  border: 0.25rem solid #fff;
  width: 1.25rem;
}

.pulse-css:after,
.pulse-css:before {
  content: "";
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -0.2rem;
  background-color: #d8b9c3;
  margin: auto;
  -webkit-transform: scale(0.3);
  transform: scale(0.3);
  -webkit-transform-origin: center center;
  transform-origin: center center;
  -webkit-animation: pulse-me 3s linear infinite;
  animation: pulse-me 3s linear infinite;
}

@media only screen and (max-width: 87.5rem) {
  .pulse-css {
    height: 1rem;
    width: 1rem;
  }
}

@-webkit-keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0;
  }

  50% {
    opacity: 0.1;
  }

  70% {
    opacity: 0.09;
  }

  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}

@keyframes pulse-me {
  0% {
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
    opacity: 0;
  }

  50% {
    opacity: 0.1;
  }

  70% {
    opacity: 0.09;
  }

  100% {
    -webkit-transform: scale(3);
    transform: scale(3);
    opacity: 0;
  }
}

[data-sidebar-style="full"] .header,
[data-sidebar-style="overlay"] .header {
  width: 100%;
  padding-left: 0;
}

@media only screen and (max-width: 87.5rem) {

  [data-sidebar-style="full"] .header,
  [data-sidebar-style="overlay"] .header {
    width: 100%;
    padding-left: 17rem;
  }
}

@media only screen and (max-width: 63.9375rem) {

  [data-sidebar-style="full"] .header,
  [data-sidebar-style="overlay"] .header {
    width: 100%;
    padding-left: 0rem;
  }
}

[data-sidebar-style="mini"] .header {
  width: 100%;
  padding-left: 6.32rem;
}

@media only screen and (max-width: 63.9375rem) {
  [data-sidebar-style="mini"] .header {
    padding-left: 6.5rem;
  }
}

[data-sidebar-style="compact"] .header {
  width: 100%;
  padding-left: 11.2rem;
}

[data-sidebar-style="compact"] .header .header-content {
  padding-left: 2.1rem;
  left: 11.1rem;
  width: calc(100% - 11.1rem);
}

[data-sidebar-style="compact"] .dlabnav {
  height: calc(100% - 5.1rem);
}

[data-header-position="fixed"] .header {
  top: 0;
  width: 100%;
}

@media only screen and (max-width: 35.9375rem) {
  [data-header-position="fixed"] .content-body {
    padding-top: 0;
  }
}

[data-header-position="fixed"] .dlabnav {
  margin-top: 0;
}

[data-sidebar-style="compact"][data-header-position="fixed"][data-container="boxed"][data-layout="vertical"] .header {
  width: 74.9375rem;
}

[data-sidebar-style="modern"] .header {
  width: 100%;
  padding-left: 9.9rem;
}

[data-sidebar-style="modern"][data-layout="horizontal"] .nav-header .brand-logo {
  justify-content: start;
}

[data-sidebar-style="modern"][data-layout="horizontal"] .header .header-content {
  padding-left: 1.875rem;
}

.sm-search {
  display: none !important;
}

.sm-search .search-area-2 {
  padding: 16px 10px;
}

.sm-search .search-area-2 .input-group-text {
  border-color: var(--primary);
  border-left: 0;
  background: transparent;
}

.sm-search .search-area-2 .input-group-text a svg path {
  fill: var(--primary);
}

.sm-search .search-area-2 .form-control {
  border-color: var(--primary);
  border-right: 0;
}

@media only screen and (max-width: 68.75rem) {
  .sm-search {
    display: inline-flex !important;
  }

  .sm-search .dropdown-menu.show {
    right: -25px;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .sm-search .dropdown-menu.show {
    right: -118px;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .sm-search .dropdown-menu.show {
    right: 0;
  }
}

.sm-search .widget-media {
  height: 270px !important;
}

.sm-search .search-p .one path {
  fill: var(--primary);
}

.sm-search .search-p .two path {
  fill: #ffab2d;
}

.sm-search .search-p .three path {
  fill: #3ab67a;
}

.sm-search .search-p .four path {
  fill: #fd5353;
}

.sm-search .timeline-panel {
  padding-bottom: 0 !important;
  border-bottom: 0 !important;
}

@keyframes headerSlideDown {
  0% {
    margin-top: -150px;
  }

  100% {
    margin-top: 0;
  }
}

.header-left {
  height: 100%;
  display: flex;
  align-items: center;
}

.header-left .input-group.search-area {
  width: 350px;
}

@media only screen and (max-width: 74.9375rem) {
  .header-left .input-group.search-area {
    width: 250px;
  }
}

@media only screen and (max-width: 68.75rem) {
  .header-left .input-group.search-area {
    display: none;
  }
}

.header-left .input-group.search-area .form-control {
  border-right: 0;
  padding-left: 16px;
  border-radius: 0.5rem;
  height: 44px;
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  color: #fff;
}

.header-left .input-group.search-area .form-control::placeholder {
  color: #fff;
}

@media only screen and (max-width: 87.5rem) {
  .header-left .input-group.search-area .form-control {
    height: 40px;
  }
}

.header-left .input-group.search-area .input-group-text {
  background-color: rgba(255, 255, 255, 0.12) !important;
  border-color: rgba(255, 255, 255, 0.12) !important;
  border-left: 0;
  margin-left: 0px !important;
}

.header-left .input-group.search-area .input-group-text svg path {
  fill: #fff;
}

.header-left .breadcrumb {
  margin-bottom: 0;
}

.header-left .agenda a {
  border-radius: 1.75rem;
}

.header-left .agenda a i {
  margin-right: 0.75rem;
}

@media only screen and (max-width: 87.5rem) {
  .header-left .agenda a i {
    margin-right: 0rem;
  }
}

@media only screen and (max-width: 93.75rem) {
  .header-left .agenda a {
    font-size: 0;
    border-radius: 0.75rem;
    padding: 0.8125rem 0.9375rem;
  }

  .header-left .agenda a i {
    font-size: 1.25rem;
    margin-right: 0;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-left .agenda {
    display: none;
  }
}

.header-left .dashboard_bar {
  font-size: 2rem;
  font-weight: 800;
  color: #000;
}

@media only screen and (max-width: 74.9375rem) {
  .header-left .dashboard_bar {
    font-size: 2.125rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .header-left .dashboard_bar {
    display: none;
  }
}

.header-left .dashboard_bar.sub-bar {
  font-size: 1.875rem;
}

[data-sidebar-style="compact"] .header-left {
  margin-left: 0;
}

.header-right {
  height: 100%;
  align-items: center;
}

.header-right .nav-item {
  height: 100%;
  display: flex;
  align-items: center;
}

.header-right .nav-item .nav-link {
  color: #464a53;
  font-size: 1.125rem;
}

.header-right .right-sidebar {
  margin-right: -1.875rem;
}

.header-right .right-sidebar a {
  height: 5rem;
  width: 5rem;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  border-left: 0.0625rem solid #dedeed;
}

@media only screen and (max-width: 74.9375rem) {
  .header-right>li:not(:first-child) {
    padding-left: 1rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .header-right>li:not(:first-child) {
    padding-left: 0;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .header-right .notification_dropdown {
    position: static;
  }
}

.header-right .notification_dropdown .nav-link {
  position: relative;
  color: var(--primary);
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.12);
  padding: 0.4rem 0.4rem;
  line-height: 1;
  margin-right: 12px;
}

@media only screen and (max-width: 87.5rem) {
  .header-right .notification_dropdown .nav-link {
    padding: 0.625rem;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .header-right .notification_dropdown .nav-link {
    margin-right: 0;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .header-right .notification_dropdown .nav-link {
    margin-right: 12px;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-right .notification_dropdown .nav-link {
    padding: 0.225rem;
    margin-right: 0.3rem !important;
  }
}

.header-right .notification_dropdown .nav-link i {
  font-size: 1.5rem;
}

@media only screen and (max-width: 87.5rem) {
  .header-right .notification_dropdown .nav-link i {
    font-size: 1.125rem;
  }
}

@media only screen and (max-width: 87.5rem) {
  .header-right .notification_dropdown .nav-link svg {
    width: 1.75rem;
    height: 1.75rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-right .notification_dropdown .nav-link svg {
    width: 1.7rem;
    height: 1.7rem;
  }
}

.header-right .notification_dropdown .nav-link .badge {
  position: absolute;
  font-size: 0.625rem;
  border-radius: 50%;
  right: 5px;
  top: 0px;
  font-weight: normal;
  height: 25px;
  width: 25px;
  line-height: 16px;
  text-align: center;
  padding: 4px;
  font-size: 11px;
}

@media only screen and (max-width: 87.5rem) {
  .header-right .notification_dropdown .nav-link .badge {
    height: 1.25rem;
    width: 1.25rem;
    border-width: 0.125rem;
    line-height: 0.4375rem;
    font-size: 0.5625rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-right .notification_dropdown .nav-link .badge {
    height: 1.125rem;
    width: 1.125rem;
    padding: 0;
    line-height: 0.9375rem;
    right: 0rem;
    top: -0.125rem;
  }
}

.header-right .notification_dropdown .dropdown-item:focus a,
.header-right .notification_dropdown .dropdown-item:active a {
  color: #fff;
}

.header-right .notification_dropdown .dropdown-item a {
  color: #5b5e81;
}

.header-right .notification_dropdown .dropdown-item a:hover {
  text-decoration: none;
}

.header-right .dropdown-menu {
  border-width: 0;
  box-shadow: 0 0 2.3125rem rgba(8, 21, 66, 0.05);
}

[data-theme-version="dark"] .header-right .dropdown-menu {
  box-shadow: none;
}

.header-right .search-area {
  width: 19.75rem;
  border-radius: 0.5rem;
}

.header-right .search-area .form-control {
  height: 3rem;
  border-right: 0;
  border-color: #eeeeee;
}

.header-right .search-area .form-control:hover,
.header-right .search-area .form-control:focus {
  border-color: #eeeeee;
}

.header-right .search-area .input-group-text {
  height: 3rem;
  border-radius: 0.5rem;
  background: transparent;
  padding: 0 1.25rem;
  border-color: #eeeeee;
}

.header-right .search-area .input-group-text i {
  font-size: 1.5rem;
}

@media only screen and (max-width: 100rem) {
  .header-right .search-area {
    width: 15.625rem;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .header-right .search-area {
    display: none;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-right .invoices-btn {
    display: none;
  }
}

.dlab-fullscreen #icon-minimize {
  display: none;
}

.dlab-fullscreen.active #icon-full {
  display: none;
}

.dlab-fullscreen.active #icon-minimize {
  display: inline-block;
}

.notification_dropdown .dropdown-menu-end {
  min-width: 19.375rem;
  padding: 0rem 0 1rem;
  width: 19.375rem;
  top: 100%;
}

.notification_dropdown .dropdown-menu-end .notification_title {
  background: var(--primary);
  color: #fff;
  padding: 0.625rem 1.25rem;
}

.notification_dropdown .dropdown-menu-end .notification_title h5,
.notification_dropdown .dropdown-menu-end .notification_title .h5 {
  color: #fff;
  margin-bottom: 0.1875rem;
}

.notification_dropdown .dropdown-menu-end .media {
  width: 2.8125rem;
  height: 2.8125rem;
  font-size: 1.125rem;
}

[data-theme-version="dark"] .notification_dropdown .dropdown-menu-end .media {
  border-color: #2a3969;
}

.notification_dropdown .dropdown-menu-end .media>span {
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 3.125rem;
  display: inline-block;
  padding: 0.4375rem 0.5625rem;
  margin-right: 0.625rem;
}

.notification_dropdown .dropdown-menu-end .media>span.success {
  background: #e0f5eb;
  color: #3ab67a;
}

.notification_dropdown .dropdown-menu-end .media>span.primary {
  background: var(--rgba-primary-1);
  color: var(--primary);
}

.notification_dropdown .dropdown-menu-end .media>span.danger {
  background: #ffeaea;
  color: #fd5353;
}

.notification_dropdown .dropdown-menu-end .media .notify-time {
  width: 100%;
  margin-right: 0;
  color: #828690;
}

.notification_dropdown .dropdown-menu-end .media p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 12.5rem;
  margin-bottom: 0;
  margin-top: 0.3125rem;
}

@media only screen and (max-width: 35.9375rem) {
  .notification_dropdown .dropdown-menu-end .media p {
    max-width: 6.25rem;
  }
}

.notification_dropdown .dropdown-menu-end .all-notification {
  display: block;
  padding: 0.9375rem 1.875rem 0;
  text-align: center;
  border-top: 0.0625rem solid var(--border);
}

.notification_dropdown .dropdown-menu-end .all-notification i {
  margin-left: 0.625rem;
}

.dz-theme-mode #icon-light {
  display: none;
}

.dz-theme-mode.active #icon-light {
  display: inline-block;
}

.dz-theme-mode.active #icon-dark {
  display: none;
}

.header-profile>a.nav-link {
  padding: 0;
  display: flex;
  align-items: center;
  margin-left: 0.9375rem;
}

.header-profile>a.nav-link i {
  font-weight: 700;
}

.header-profile>a.nav-link .header-info {
  padding-left: 0rem;
  text-align: left;
}

@media only screen and (max-width: 87.5rem) {
  .header-profile>a.nav-link .header-info {
    padding-left: 0.625rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-profile>a.nav-link .header-info {
    display: none;
  }
}

.header-profile>a.nav-link .header-info span {
  font-size: 1rem;
  color: #000;
  display: block;
  font-weight: 600;
}

.header-profile>a.nav-link .header-info strong {
  color: #5b5e81;
}

.header-profile>a.nav-link .header-info small,
.header-profile>a.nav-link .header-info .small {
  display: block;
  font-size: 0.8125rem;
  color: #89879f;
  font-weight: 400;
  line-height: 1.2;
}

@media only screen and (max-width: 87.5rem) {
  .header-profile>a.nav-link {
    margin-left: 0rem;
    padding-left: 0rem;
  }

  .header-profile>a.nav-link .header-info span {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-profile>a.nav-link {
    margin-left: 0rem;
    padding-left: 0rem;
    border-left: 0;
  }
}

.header-profile .dropdown-menu {
  padding: 0.9375rem 0;
  min-width: 12.5rem;
}

.header-profile .dropdown-menu a:hover,
.header-profile .dropdown-menu a:focus,
.header-profile .dropdown-menu a.active {
  color: var(--primary);
}

.header-profile img {
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 0.5rem;
}

@media only screen and (max-width: 87.5rem) {
  .header-profile img {
    width: 2.4375rem;
    height: 2.4375rem;
  }
}

.header-profile .dropdown-toggle i {
  font-size: 1.25rem;
}

@media only screen and (max-width: 35.9375rem) {
  .header-profile .dropdown-toggle span {
    display: none;
  }
}

.header-profile .profile_title {
  background: var(--primary);
  color: #fff;
  padding: 0.625rem 1.25rem;
}

.header-profile .profile_title h5,
.header-profile .profile_title .h5 {
  color: #fff;
  margin-bottom: 0.1875rem;
}

.header-profile .dropdown-item {
  padding: 0.5rem 1.5rem;
}

.header-profile2.nav-label {
  margin: 0.625rem 1.875rem 0;
  padding: 1.5625rem 0 0.625rem;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.05rem;
  border-top: 0.0625rem solid #eaeaea;
  color: #999999;
}

[data-theme-version="dark"] .header-profile2.nav-label {
  border-color: #2a3969;
}

.header-profile2.nav-label.first {
  border: 0rem;
  margin-top: 0rem;
}

.nav-badge {
  position: absolute;
  right: 2.8125rem;
  top: 0.625rem;
}

.content-body {
  margin-left: 16rem;
  z-index: 0;
  transition: all 0.2s ease;
}

@media only screen and (max-width: 87.5rem) {
  .content-body {
    margin-left: 17rem;
  }
}

.bell img {
  -webkit-animation: ring 8s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 0.25rem;
  -moz-animation: ring 8s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 0.25rem;
  animation: ring 8s 0.7s ease-in-out infinite;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }

  1% {
    -webkit-transform: rotateZ(30deg);
  }

  3% {
    -webkit-transform: rotateZ(-28deg);
  }

  5% {
    -webkit-transform: rotateZ(34deg);
  }

  7% {
    -webkit-transform: rotateZ(-32deg);
  }

  9% {
    -webkit-transform: rotateZ(30deg);
  }

  11% {
    -webkit-transform: rotateZ(-28deg);
  }

  13% {
    -webkit-transform: rotateZ(26deg);
  }

  15% {
    -webkit-transform: rotateZ(-24deg);
  }

  17% {
    -webkit-transform: rotateZ(22deg);
  }

  19% {
    -webkit-transform: rotateZ(-20deg);
  }

  21% {
    -webkit-transform: rotateZ(18deg);
  }

  23% {
    -webkit-transform: rotateZ(-16deg);
  }

  25% {
    -webkit-transform: rotateZ(14deg);
  }

  27% {
    -webkit-transform: rotateZ(-12deg);
  }

  29% {
    -webkit-transform: rotateZ(10deg);
  }

  31% {
    -webkit-transform: rotateZ(-8deg);
  }

  33% {
    -webkit-transform: rotateZ(6deg);
  }

  35% {
    -webkit-transform: rotateZ(-4deg);
  }

  37% {
    -webkit-transform: rotateZ(2deg);
  }

  39% {
    -webkit-transform: rotateZ(-1deg);
  }

  41% {
    -webkit-transform: rotateZ(1deg);
  }

  43% {
    -webkit-transform: rotateZ(0);
  }

  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }

  1% {
    -moz-transform: rotate(30deg);
  }

  3% {
    -moz-transform: rotate(-28deg);
  }

  5% {
    -moz-transform: rotate(34deg);
  }

  7% {
    -moz-transform: rotate(-32deg);
  }

  9% {
    -moz-transform: rotate(30deg);
  }

  11% {
    -moz-transform: rotate(-28deg);
  }

  13% {
    -moz-transform: rotate(26deg);
  }

  15% {
    -moz-transform: rotate(-24deg);
  }

  17% {
    -moz-transform: rotate(22deg);
  }

  19% {
    -moz-transform: rotate(-20deg);
  }

  21% {
    -moz-transform: rotate(18deg);
  }

  23% {
    -moz-transform: rotate(-16deg);
  }

  25% {
    -moz-transform: rotate(14deg);
  }

  27% {
    -moz-transform: rotate(-12deg);
  }

  29% {
    -moz-transform: rotate(10deg);
  }

  31% {
    -moz-transform: rotate(-8deg);
  }

  33% {
    -moz-transform: rotate(6deg);
  }

  35% {
    -moz-transform: rotate(-4deg);
  }

  37% {
    -moz-transform: rotate(2deg);
  }

  39% {
    -moz-transform: rotate(-1deg);
  }

  41% {
    -moz-transform: rotate(1deg);
  }

  43% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }

  1% {
    transform: rotate(30deg);
  }

  3% {
    transform: rotate(-28deg);
  }

  5% {
    transform: rotate(34deg);
  }

  7% {
    transform: rotate(-32deg);
  }

  9% {
    transform: rotate(30deg);
  }

  11% {
    transform: rotate(-28deg);
  }

  13% {
    transform: rotate(26deg);
  }

  15% {
    transform: rotate(-24deg);
  }

  17% {
    transform: rotate(22deg);
  }

  19% {
    transform: rotate(-20deg);
  }

  21% {
    transform: rotate(18deg);
  }

  23% {
    transform: rotate(-16deg);
  }

  25% {
    transform: rotate(14deg);
  }

  27% {
    transform: rotate(-12deg);
  }

  29% {
    transform: rotate(10deg);
  }

  31% {
    transform: rotate(-8deg);
  }

  33% {
    transform: rotate(6deg);
  }

  35% {
    transform: rotate(-4deg);
  }

  37% {
    transform: rotate(2deg);
  }

  39% {
    transform: rotate(-1deg);
  }

  41% {
    transform: rotate(1deg);
  }

  43% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

.dlabnav {
  width: 16.2rem;
  padding-bottom: 0;
  height: calc(100% - 5.1rem);
  position: absolute;
  top: 5.1rem;
  padding-top: 0;
  z-index: 3;
  background-color: var(--sidebar-bg);
  transition: all 0.2s ease;
  box-shadow: 0rem 0.9375rem 1.875rem 0rem rgba(0, 0, 0, 0.02);
}

@media (max-width: 100rem) {
  .dlabnav {
    top: 5.8rem;
  }
}

@media only screen and (max-width: 87.5rem) {
  .dlabnav {
    top: 5.5rem;
    height: calc(100% - 78px);
  }
}

@media only screen and (max-width: 74.9375rem) {
  .dlabnav {
    top: 5rem;
    height: calc(100% - 70px);
  }
}

@media only screen and (max-width: 47.9375rem) {
  .dlabnav {
    width: 18rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .dlabnav {
    height: calc(100% - 63.2px);
    top: 4.5rem !important;
  }
}

.dlabnav .menu-scroll {
  height: calc(100% - 7.5rem);
}

.dlabnav .dlabnav-scroll {
  position: relative;
  height: 100%;
  overflow-y: scroll;
}

@media only screen and (max-width: 87.5rem) {
  .dlabnav {
    width: 17rem;
  }
}

.dlabnav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.dlabnav .metismenu {
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
}

.dlabnav .metismenu.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
}

.dlabnav .metismenu>li {
  display: flex;
  flex-direction: column;
}

.dlabnav .metismenu>li a>i {
  font-size: 1.3rem;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  top: 0;
  height: auto;
  width: auto;
  text-align: center;
  margin-right: 0.75rem;
  border-radius: 0.125rem;
  line-height: 1;
  border-radius: 0.125rem;
}

[data-sidebar-style="compact"] .dlabnav .metismenu>li a>i {
  display: block;
  padding: 0;
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.3);
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 0.5rem;
  line-height: 3.25rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.3125rem;
}

[data-sidebar-style="compact"] .dlabnav .metismenu>li a>i[data-theme-version="dark"] {
  color: #fff;
}

@media only screen and (max-width: 84.375rem) {
  .dlabnav .metismenu>li a>i {
    height: auto;
    line-height: 0.0625rem;
    width: auto;
    font-size: 1.5rem;
    padding: 0;
    color: #969ba0;
  }
}

.dlabnav .metismenu>li>a {
  font-weight: 500;
  display: inline-block;
  font-size: 0.9rem;
  color: var(--text);
}

.dlabnav .metismenu>li>a i {
  color: #c8c8c8;
}

.dlabnav .metismenu>li>a svg {
  max-width: 1.5rem;
  max-height: 1.5rem;
  height: 100%;
  margin-right: 0.3125rem;
  margin-top: -0.1875rem;
  color: var(--primary);
}

.dlabnav .metismenu>li>a g [fill] {
  fill: #8088a1;
}

.dlabnav .metismenu>li:hover>a,
.dlabnav .metismenu>li:focus>a {
  color: var(--primary);
}

.dlabnav .metismenu>li:hover>a g [fill],
.dlabnav .metismenu>li:focus>a g [fill] {
  fill: var(--primary);
}

.dlabnav .metismenu>li.mm-active>a {
  color: #fff;
  background: var(--primary);
  font-weight: 600;
  box-shadow: none;
}

.dlabnav .metismenu>li.mm-active>a g [fill] {
  fill: var(--primary);
}

.dlabnav .metismenu>li.mm-active>a:after {
  border-top: 0.3125rem solid #fff;
  border-left: 0.3125rem solid #fff;
}

[data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a i {
  background: var(--primary);
  color: #fff;
}

.dlabnav .metismenu li {
  position: relative;
}

.dlabnav .metismenu ul {
  transition: all 0.2s ease-in-out;
  position: relative;
  z-index: 1;
  padding: 0.5rem 0;
}

.dlabnav .metismenu ul a {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: relative;
  font-size: 0.9375rem;
  color: var(--text);
  padding-left: 3.2rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (max-width: 84.375rem) {
  .dlabnav .metismenu ul a {
    padding-left: 3.6rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .dlabnav .metismenu ul a {
    padding-left: 3.5rem;
    font-size: 0.875rem;
  }
}

.dlabnav .metismenu ul a:hover,
.dlabnav .metismenu ul a:focus,
.dlabnav .metismenu ul a.mm-active {
  text-decoration: none;
  color: var(--primary);
}

.dlabnav .metismenu ul a:hover:before,
.dlabnav .metismenu ul a:focus:before,
.dlabnav .metismenu ul a.mm-active:before {
  border-color: var(--primary);
  transform: translateY(-50%) rotate(225deg);
}

.dlabnav .metismenu ul a:before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  border: 2px solid #cccccc;
  top: 50%;
  left: 22px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  transform: translateY(-50%) rotate(45deg);
}

@media only screen and (max-width: 87.5rem) {
  .dlabnav .metismenu ul a:before {
    left: 30px;
  }
}

.dlabnav .metismenu a {
  position: relative;
  display: block;
  padding: 0.625rem 1.875rem;
  outline-width: 0;
  color: #373a54;
  text-decoration: none;
}

@media only screen and (max-width: 47.9375rem) {
  .dlabnav .metismenu a {
    padding: 0.625rem 1.25rem;
  }
}

.dlabnav .metismenu .has-arrow:after {
  border-top: 0.3125rem solid #c8c8c8;
  border-left: 0.3125rem solid #c8c8c8;
  border-bottom: 0.3125rem solid transparent;
  border-right: 0.3125rem solid transparent;
  right: 1.875rem;
  top: 48%;
  -webkit-transform: rotate(-225deg) translateY(-50%);
  transform: rotate(-225deg) translateY(-50%);
}

.dlabnav .metismenu .has-arrow[aria-expanded="true"]:after,
.dlabnav .metismenu .mm-active>.has-arrow:after {
  -webkit-transform: rotate(-135deg) translateY(-50%);
  transform: rotate(-135deg) translateY(-50%);
}

.dlabnav .main-menu {
  padding: 1.025rem 1.875rem;
  display: block;
  padding-bottom: 0;
  color: var(--primary);
  text-transform: capitalize;
}

.dlabnav.follow-info .menu-scroll {
  height: calc(100% - 15.5rem);
}

.dlabnav .copyright {
  margin-top: 1.875rem;
}

.header-profile2 .nav-link {
  background-color: transparent !important;
  padding-right: 0 !important;
}

.header-profile2 img {
  height: 3.125rem;
  width: 3.125rem;
  border-radius: 50%;
  margin-left: 0.8rem;
}

@media only screen and (max-width: 47.9375rem) {
  .header-profile2 img {
    margin-left: 0;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-profile2 img {
    height: 2.525rem;
    width: 2.525rem;
    margin-left: 0;
  }
}

.header-profile2 span {
  font-size: 0.75rem;
  color: #aaa9ff;
}

@media only screen and (max-width: 87.5rem) {
  .header-profile2 span {
    font-size: 0.9rem;
  }
}

.header-profile2 i {
  font-size: 1rem;
}

@media only screen and (max-width: 47.9375rem) {
  .header-profile2 {
    margin-left: 2px !important;
  }

  .header-profile2 .sidebar-info {
    display: none !important;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-profile2 {
    padding-left: 0;
  }
}

.header-profile2 .dropdown-menu a svg path {
  fill: var(--primary);
}

.header-profile2 .dropdown-menu a .logout path {
  fill: #fd5353;
}

.header-profile2 .dropdown-menu a span {
  font-size: 15px;
  font-weight: 400;
  color: #717579;
}

.header-profile2 .dropdown-menu .dropdown-item {
  padding: 0.5rem 0.75rem;
}

.copyright {
  padding: 0 1rem;
  color: #9fa4a6;
}

.copyright p {
  font-size: 0.875rem;
}

.copyright strong {
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
  color: var(--text-dark);
}

@media only screen and (max-width: 63.9375rem) {
  .nav-header {
    width: 5rem;
  }
}

@media (max-width: 47.9375rem) {
  .brand-title {
    display: none;
  }

  .footer {
    padding-left: 0;
  }

  .dlabnav {
    left: 0;
    top: 5rem;
  }
}

.header-drop .header-drop-toggle {
  padding: 3px 0px 1px 1px;
  color: var(--text-dark);
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.header-drop .dropdown-toggle::after {
  position: absolute;
  right: 20px;
}

.header-drop .menu-blog {
  width: 95%;
  inset: 4px auto auto -5px !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu>ul.collapse:not(.in),
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu>ul.collapse:not(.in) {
  height: 15.75rem !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu:hover>a {
  width: calc(70vw + 3.75rem);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 13.75rem;
  width: 70vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu-xl:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu-xl:hover>a {
  width: calc(70vw + 3rem);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu-xl:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 12.5rem;
  width: 70vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu-xl:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu-xl:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu-lg:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu-lg:hover>a {
  width: calc(55vw + 3rem);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu-lg:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 12.5rem;
  width: 55vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu-lg:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu-lg:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu-md:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu-md:hover>a {
  width: calc(45vw + 3);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu-md:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 18.75rem;
  width: 45vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu-md:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu-md:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu-sm:hover>a,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu-sm:hover>a {
  width: calc(30vw + 3);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu-sm:hover>ul,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 18.125rem;
  width: 30vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mega-menu-sm:hover>ul ul a,
[data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mega-menu-sm:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu:hover>a {
  width: calc(60vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 25rem;
  width: 60vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu:hover>ul ul a {
  width: 101%;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu-xl:hover>a {
  width: calc(60vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 25.625rem;
  width: 60vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu-lg:hover>a {
  width: calc(50vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 16.25rem;
  width: 50vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu-md:hover>a {
  width: calc(40vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 18.75rem;
  width: 40vw;
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu-sm:hover>a {
  width: calc(22vw + 3.75rem);
}

[data-sidebar-style="mini"][data-layout="vertical"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 18.125rem;
  width: 22vw;
}

[data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:not(:last-child) {
  position: static;
}

[data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul {
  left: 0;
  right: 0;
}

[data-theme-version="dark"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul li {
  border-color: #2a3969;
}

[data-sibebarbg="color_2"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul li {
  border-color: #3d0894;
}

[data-sibebarbg="color_3"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul li {
  border-color: #1f1e61;
}

[data-sibebarbg="color_4"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul li {
  border-color: #1f0243;
}

[data-sibebarbg="color_5"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul li {
  border-color: #001ecb;
}

[data-sibebarbg="color_6"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul li {
  border-color: #0057d8;
}

[data-sibebarbg="color_7"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul li {
  border-color: #1971a3;
}

[data-sibebarbg="color_8"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul li {
  border-color: #7b3581;
}

[data-sibebarbg="color_9"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul li {
  border-color: #56704e;
}

[data-sibebarbg="color_10"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul li {
  border-color: #0d1547;
}

[data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul li a {
  transition: all 0.4s ease-in-out;
}

[data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu ul li a:hover {
  border-radius: 0.25rem;
}

[data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-height: 13.75rem;
  width: 70vw;
  z-index: 99;
}

[data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul::after {
  content: "";
  height: 100%;
  width: 0.0625rem;
  position: absolute;
  background-color: #fff;
  right: 2.8125rem;
  top: 0;
}

[data-theme-version="dark"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #0b1a4d;
}

[data-sibebarbg="color_2"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #510bc4;
}

[data-sibebarbg="color_3"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #2b2a88;
}

[data-sibebarbg="color_4"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #360474;
}

[data-sibebarbg="color_5"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #0025fe;
}

[data-sibebarbg="color_6"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #0c6dff;
}

[data-sibebarbg="color_7"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #208fcf;
}

[data-sibebarbg="color_8"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #9e44a5;
}

[data-sibebarbg="color_9"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #6d8f62;
}

[data-sibebarbg="color_10"][data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul::after {
  background-color: #142173;
}

[data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu:hover>ul ul a {
  width: 101%;
}

[data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 13.125rem;
  width: 70vw;
}

[data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 13.125rem;
  width: 43.75rem;
  height: 13.125rem !important;
}

@media only screen and (min-width: 75rem) and (max-width: 93.75rem) {
  [data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu-lg:hover>ul {
    width: 43.75rem;
  }
}

[data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 20rem;
  width: 54vw;
}

@media only screen and (min-width: 75rem) and (max-width: 93.75rem) {
  [data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu-md:hover>ul {
    width: 60vw;
  }
}

[data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 20rem;
  width: 25vw;
}

@media only screen and (min-width: 75rem) and (max-width: 93.75rem) {
  [data-layout="horizontal"] .dlabnav .metismenu>li.mega-menu-sm:hover>ul {
    width: 35vw;
  }
}

[data-layout="horizontal"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu:hover>ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

[data-layout="horizontal"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu-xl:hover>ul {
  max-height: 21.875rem;
  width: 100%;
}

[data-layout="horizontal"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu-lg:hover>ul {
  max-height: 21.875rem;
  width: 55vw;
}

[data-layout="horizontal"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu-md:hover>ul {
  max-height: 18.75rem;
  width: 45vw;
}

[data-layout="horizontal"][data-container="boxed"] .dlabnav .metismenu>li.mega-menu-sm:hover>ul {
  max-height: 18.125rem;
  width: 50vw;
}

[data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li {
  padding: 0 1rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li>a {
  font-size: 0.938rem;
  padding: 0.7375rem 1rem;
  font-weight: 400;
  border-radius: 0.5rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  margin: 5px 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a {
  font-weight: 500;
  background: var(--primary);
  color: white;
}

[data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a:before {
  width: 0.375rem;
}

@media only screen and (max-width: 87.5rem) {
  [data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a:before {
    width: 0.6rem;
  }
}

[data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: white;
  font-weight: 500;
}

[data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li .has-arrow:after {
  right: 1.5rem;
}

@media only screen and (max-width: 87.5rem) {
  [data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li {
    padding: 0 10px;
  }

  [data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li>a {
    font-size: 1rem;
  }
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header {
  width: 5.5rem;
  z-index: 999;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .brand-logo {
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control {
  right: -5.3rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header .nav-control .hamburger .line {
  background-color: #fff;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header {
  padding-left: 5.5rem;
  width: 100%;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header .header-content {
  width: calc(100% - 5.5rem);
  left: 5.5rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header .page-titles {
  padding-left: 2.6rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav {
  width: 5.5rem;
  overflow: visible;
  position: absolute;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .nav-text,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .badge {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .slimScrollDiv,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .dlabnav-scroll {
  overflow: visible !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .header-info2 {
  padding: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .header-info2 img {
  margin-left: -1rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .sidebar-info {
  display: none !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .header-profile2 {
  margin: 0 0.5rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu li {
  position: relative;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu li a {
  background: transparent;
  margin: 0.125rem 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu li a svg {
  max-width: 1.5rem;
  max-height: 1.5rem;
  margin-right: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu li a:before {
  content: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu li a i {
  margin: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu li>ul {
  position: absolute;
  left: 5.4rem;
  top: 0;
  width: 12rem;
  z-index: 1001;
  display: none;
  padding-left: 0.0625rem;
  height: auto !important;
  box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
  border-radius: 0.375rem;
  margin-left: 0;
  border: 0;
  background: #fff;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu li>ul li:hover ul {
  left: 11.8125rem;
  top: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu li>ul li:hover ul:after {
  content: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu li:hover>ul {
  display: block;
  height: auto;
  overflow: visible;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li {
  transition: all 0.4s ease-in-out;
  padding: 0 1.125rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li>a {
  padding: 0.75rem 0.925rem;
  text-align: center;
  border-radius: 0.5rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li>a.has-arrow:after {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mm-active>a {
  background: var(--primary);
  border-radius: 0.5rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
  padding: 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li:hover:nth-last-child(-n + 1)>ul {
  bottom: 0;
  top: auto;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li:hover>a {
  border-radius: 0.5rem;
  background: var(--primary);
  color: #fff;
}

[data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li:hover>a {
  background: var(--primary);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li:hover>a i {
  color: #fff;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li:hover>ul {
  height: auto !important;
  padding: 0.625rem 0;
  background: var(--card);
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li:hover>ul a {
  padding: 0.375rem 1.25rem 0.375rem 1.25rem;
  margin-left: -0.1rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li:hover>ul ul {
  padding: 0.625rem 0;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu>li:hover>ul ul a {
  padding: 0.375rem 1.25rem 0.375rem 1.25rem;
  margin-left: -0.1rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu .nav-label,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .metismenu .nav-badge {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .feature-box {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .support-box {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .copyright,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .plus-box,
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .dlabnav .main-menu {
  display: none;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .content-body {
  margin-left: 5.5rem;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle+.footer {
  padding-left: 5.7rem;
}

[data-sidebar-style="full"][data-layout="horizontal"] .header .header-content {
  padding-left: 1.875rem;
}

@media only screen and (min-width: 48rem) {
  [data-sidebar-style="mini"] .nav-header {
    width: 6.29rem;
  }

  [data-sidebar-style="mini"] .nav-header .nav-control {
    z-index: -1;
  }

  [data-sidebar-style="mini"] .nav-header .nav-control .hamburger {
    left: 6.25rem !important;
  }

  [data-sidebar-style="mini"] .nav-header .nav-control .hamburger .line {
    background-color: #5b5e81 !important;
  }

  [data-sidebar-style="mini"] .nav-header .brand-title {
    display: none;
  }

  [data-sidebar-style="mini"] .nav-header .hamburger {
    display: none;
  }

  [data-sidebar-style="mini"] .header .header-content {
    padding-left: 2.375rem;
    left: 6.32rem;
    width: calc(100% - 6.32rem);
  }
}

@media only screen and (min-width: 48rem) and (max-width: 61.9375rem) {
  [data-sidebar-style="mini"] .header .header-content {
    padding-left: 1.675rem;
  }
}

@media only screen and (min-width: 48rem) {
  [data-sidebar-style="mini"] .header .page-titles {
    padding-left: 2.375rem;
  }
}

@media only screen and (min-width: 48rem) and (max-width: 61.9375rem) {
  [data-sidebar-style="mini"] .header .page-titles {
    padding-left: 1.675rem;
  }
}

@media only screen and (min-width: 48rem) {
  [data-sidebar-style="mini"] .dlabnav {
    width: 6.25rem;
    overflow: visible;
    position: absolute;
    z-index: 3;
  }

  [data-sidebar-style="mini"] .dlabnav .copyright,
  [data-sidebar-style="mini"] .dlabnav .plus-box {
    display: none;
  }

  [data-sidebar-style="mini"] .dlabnav .nav-text {
    display: none;
  }

  [data-sidebar-style="mini"] .dlabnav .slimScrollDiv,
  [data-sidebar-style="mini"] .dlabnav .dlabnav-scroll {
    overflow: visible !important;
  }

  [data-sidebar-style="mini"] .dlabnav .nav-user {
    padding: 0.6875rem;
  }

  [data-sidebar-style="mini"] .dlabnav .nav-user .media-body {
    display: none;
  }

  [data-sidebar-style="mini"] .dlabnav .header-profile {
    margin-bottom: 0;
    margin-top: 0.75rem;
  }

  [data-sidebar-style="mini"] .dlabnav .header-profile:hover>a.nav-link {
    border-radius: 3rem;
  }

  [data-sidebar-style="mini"] .dlabnav .header-profile img {
    width: 3rem;
    height: 3rem;
  }

  [data-sidebar-style="mini"] .dlabnav .header-profile>a.nav-link {
    border-radius: 3rem;
    padding: 0.3125rem 0.3125rem;
  }

  [data-sidebar-style="mini"] .dlabnav .header-profile .header-info {
    display: none;
  }

  [data-sidebar-style="mini"] .dlabnav .metismenu li a {
    padding: 0.813rem 0.875rem;
  }

  [data-sidebar-style="mini"] .dlabnav .metismenu li a svg {
    margin-right: 0;
  }

  [data-sidebar-style="mini"] .dlabnav .metismenu li a i {
    height: auto;
    width: auto;
    line-height: 1;
    margin: 0;
  }

  [data-sidebar-style="mini"] .dlabnav .metismenu li>ul {
    position: absolute;
    left: 6.25rem;
    top: 2.9375rem;
    width: 11.875rem;
    z-index: 1001;
    display: none;
    padding-left: 0.0625rem;
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
    height: auto !important;
    border-radius: 0.5rem;
    background: #fff;
  }

  [direction="rtl"]:not([data-layout="horizontal"])[data-sidebar-style="mini"] .dlabnav .metismenu li>ul {
    left: auto;
    right: 6.25rem;
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
  }

  [data-sidebar-style="mini"] .dlabnav .metismenu>li {
    padding: 0.125rem 1.55rem;
  }

  [data-sidebar-style="mini"] .dlabnav .metismenu>li>a {
    padding: 0.825rem 0.875rem;
    text-align: center;
    line-height: 1;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
  }

  [data-sidebar-style="mini"] .dlabnav .metismenu>li>a.has-arrow:after {
    display: none;
  }

  [data-sidebar-style="mini"] .dlabnav .metismenu .nav-label,
  [data-sidebar-style="mini"] .dlabnav .metismenu .nav-badge {
    display: none;
  }

  [data-sidebar-style="mini"] .dlabnav .header-info2 {
    padding: 0;
  }

  [data-sidebar-style="mini"] .dlabnav .header-info2 img {
    margin-left: -1rem;
  }

  [data-sidebar-style="mini"] .dlabnav .sidebar-info {
    display: none !important;
  }

  [data-sidebar-style="mini"] .dlabnav .header-profile2 {
    margin: 0 0.5rem;
  }

  [data-sidebar-style="mini"] .dlabnav .main-menu {
    display: none;
  }

  [data-sidebar-style="mini"] .content-body {
    margin-left: 6.3rem;
  }

  [data-sidebar-style="mini"] .footer {
    padding-left: 6.3rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav {
    position: absolute;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu li:hover>ul {
    display: block;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li {
    position: relative;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background: var(--primary);
    width: 3px;
    height: 0;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:nth-last-child(-n + 1)>ul {
    bottom: 0;
    top: auto !important;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:nth-last-child(-n + 1)>ul:after {
    top: auto;
    bottom: 1.25rem;
  }
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:nth-last-child(-n + 1)>ul {
    bottom: 0;
    top: auto !important;
  }
}

@media only screen and (min-width: 48rem) {
  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li>ul {
    overflow: visible;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li>ul:after {
    content: none;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li>ul li:hover ul {
    padding: 0.625rem 0;
    width: 13rem;
    left: 13rem;
    top: -0.625rem;
    border: 0;
    margin: 0;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li>ul li:hover ul:after {
    content: none;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active:after {
    height: 100%;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a {
    background: var(--primary);
    color: #fff;
    border-radius: 0.5rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
    color: #fff;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
    background: var(--primary);
    color: #fff;
    border-radius: 0.5rem;
    position: unset;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
    color: #fff;
  }

  [direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a .nav-text {
    padding-left: auto;
    padding-right: 1.6875rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:hover>ul {
    height: auto !important;
    overflow: visible;
    border: 0;
    margin-left: 0;
    left: 6.25rem;
    width: 13rem;
    border-radius: 0.5rem;
    border: 0;
    padding: 0.625rem 0;
    top: 0;
  }

  [data-theme-version="dark"][data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:hover>ul {
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
  }

  [direction="rtl"][data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:hover>ul {
    left: auto;
    right: 6.25rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:hover>ul a {
    padding: 0.375rem 1.25rem 0.375rem 1.25rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:hover>ul a:before {
    content: none;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:hover>ul a.has-arrow:after {
    right: 1.25rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:hover>ul ul a {
    padding: 0.375rem 1.25rem 0.375rem 1.25rem;
    margin-left: -0.1rem;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .metismenu>li:hover>ul ul a:before {
    content: none;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .feature-box {
    display: none;
  }

  [data-sidebar-style="mini"][data-layout="vertical"] .dlabnav .support-box {
    display: none;
  }

  [data-sidebar-style="mini"][data-header-position="fixed"][data-container="boxed"][data-layout="vertical"] .header {
    width: 74.9375rem;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .dlabnav .metismenu>li {
    padding: 0;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .dlabnav .metismenu>li>a {
    padding: 0.725rem;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .dlabnav .metismenu>li>a i {
    padding: 0;
    margin: 0;
  }

  [direction="rtl"][data-sidebar-style="mini"][data-layout="horizontal"] .dlabnav .metismenu>li>a {
    padding: 1.125rem;
  }

  [direction="rtl"][data-sidebar-style="mini"][data-layout="horizontal"] .dlabnav .metismenu>li>a svg {
    margin-left: 0;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .dlabnav .metismenu>li>a svg {
    margin-right: 0;
    margin-top: 0;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .dlabnav .metismenu>li>ul li a {
    padding: 0.5rem 1.25rem 0.5rem 1.25rem;
  }
}

@media only screen and (max-width: 74.9375rem) {
  [data-sidebar-style="mini"] .dlabnav li.mm-active ul {
    height: auto !important;
  }
}

@media (min-width: 64rem) {
  [data-layout="horizontal"] .dlabnav .metismenu .has-arrow:after {
    top: 44%;
  }

  [data-layout="horizontal"] .nav-header {
    width: 16.2rem;
    height: 5.1rem;
    top: 0;
  }

  [data-layout="horizontal"] .nav-header .nav-control {
    display: none;
  }

  [data-layout="horizontal"] .nav-header .brand-logo {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  [data-layout="horizontal"] .header {
    width: 100%;
    height: 5.1rem;
    padding-left: 18.563rem;
    padding-top: 0;
    background: var(--headerbg);
  }

  [data-layout="horizontal"] .dlabnav {
    width: 100%;
    position: relative;
    height: auto;
    padding-bottom: 0;
    top: 0;
    z-index: 2;
  }

  [data-layout="horizontal"] .dlabnav .slimScrollDiv {
    overflow: visible !important;
  }

  [data-layout="horizontal"] .dlabnav .slimScrollDiv .dlabnav-scroll {
    overflow: visible !important;
  }

  [data-layout="horizontal"] .dlabnav .dlabnav-scroll {
    overflow: visible !important;
  }

  [data-layout="horizontal"] .dlabnav .slimScrollBar {
    display: none !important;
  }

  [data-layout="horizontal"] .dlabnav .header-profile {
    margin-right: 0.9375rem;
    margin-bottom: 0;
    display: none;
  }

  [data-layout="horizontal"] .dlabnav .header-profile:hover>a.nav-link {
    border-radius: 3rem;
  }

  [data-layout="horizontal"] .dlabnav .header-profile img {
    height: 2.8125rem;
    width: 2.8125rem;
  }

  [data-layout="horizontal"] .dlabnav .header-profile>a.nav-link {
    border-radius: 3rem;
    padding: 0.3125rem 0.3125rem;
  }

  [data-layout="horizontal"] .dlabnav .header-profile .header-info {
    display: none;
  }

  [data-layout="horizontal"] .dlabnav .header-profile2 {
    display: none;
  }

  [data-layout="horizontal"] .dlabnav .nav-user,
  [data-layout="horizontal"] .dlabnav .nav-label {
    display: none;
  }

  [data-layout="horizontal"] .dlabnav .metismenu {
    flex-direction: row;
    padding: 0.925rem 2.35rem;
    margin-bottom: 0;
    display: inline-flex;
    flex-wrap: wrap;
  }

  [data-layout="horizontal"] .dlabnav .metismenu .collapse.in {
    display: none;
  }

  [data-layout="horizontal"] .dlabnav .metismenu ul {
    border-left: 0;
  }

  [data-theme-version="dark"][data-layout="horizontal"] .dlabnav .metismenu ul {
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
  }

  [data-layout="horizontal"] .dlabnav .metismenu li {
    flex-direction: column;
    position: relative;
  }

  [data-layout="horizontal"] .dlabnav .metismenu li:hover>ul {
    display: block;
  }

  [data-layout="horizontal"] .dlabnav .metismenu li>ul {
    position: absolute;
    height: auto !important;
    top: 100%;
    width: 100%;
    min-width: 13.75rem;
    z-index: 999;
    left: auto;
    right: auto;
    padding: 0.5rem 0;
    display: none;
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
    margin: 0;
    background: #fff;
    border-radius: 0.5rem;
  }

  [data-theme-version="dark"][data-layout="horizontal"] .dlabnav .metismenu li>ul {
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
    background: #18254f;
  }

  [data-layout="horizontal"] .dlabnav .metismenu li>ul li {
    padding: 0;
  }

  [data-layout="horizontal"] .dlabnav .metismenu li>ul li a {
    transition: all 0.4s ease-in-out;
    padding: 0.5rem 1.25rem 0.5rem 1.25rem;
    margin-left: -0.1rem;
  }

  [data-layout="horizontal"] .dlabnav .metismenu li>ul li a:hover {
    border-radius: 0.4rem;
    padding-left: 1.25rem;
  }

  [data-layout="horizontal"] .dlabnav .metismenu li>ul li a:before {
    content: none;
    left: 1.375rem;
  }

  [data-layout="horizontal"] .dlabnav .metismenu li>ul ul {
    left: 100%;
    top: 0;
    box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li {
    flex: 0 0 auto;
    position: relative;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li>a i {
    margin-right: -0.1875rem;
  }

  [data-theme-version="dark"][data-layout="horizontal"] .dlabnav .metismenu>li {
    border-color: rgba(255, 255, 255, 0.07);
  }

  [data-theme-version="dark"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active {
    border-color: transparent;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li:hover,
  [data-layout="horizontal"] .dlabnav .metismenu>li.mm-active {
    padding: 0;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
  [data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a {
    background: var(--primary);
    color: #fff;
    border-radius: 0.5rem;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li:hover>a i,
  [data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a i {
    color: #fff;
    background: transparent;
    box-shadow: none;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li>a {
    padding: 0.7375rem 2.5rem 0.7375rem 0.7375rem;
    margin: 0.125rem 0.125rem;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li>a i {
    padding: 0 0.4375rem 0 0;
    height: auto;
    width: auto;
    line-height: 1;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li>a .nav-badge {
    display: none;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li>a:after {
    right: 1.25rem;
    transform: rotate(-135deg) translateY(-50%);
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li:hover {
    border-color: transparent;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li:hover>ul {
    display: flex !important;
    flex-direction: column;
    flex-wrap: wrap;
    height: auto !important;
    box-shadow: 0.3125rem 0.3125rem 1.875rem 0rem rgba(20, 0, 30, 0.1);
    border-radius: 0.5rem;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li>ul>li:hover ul.collapse {
    display: block !important;
    position: absolute;
    left: auto !important;
    right: -100% !important;
    top: 0 !important;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li:nth-last-child(-n + 5)>ul {
    left: auto;
    right: 0;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li:nth-last-child(-n + 5)>ul>li:hover ul.collapse {
    right: auto !important;
    left: -100% !important;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li:nth-last-child(-n + 5)>ul.left {
    left: 0;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li:nth-last-child(-n + 5)>ul.left>li:hover ul.collapse {
    left: 100% !important;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li:last-child>ul ul {
    left: -100%;
  }

  [data-layout="horizontal"] .dlabnav .metismenu>li:last-child>ul.left ul {
    left: 100%;
  }

  [data-layout="horizontal"] .dlabnav .copyright,
  [data-layout="horizontal"] .dlabnav .feature-box,
  [data-layout="horizontal"] .dlabnav .support-box,
  [data-layout="horizontal"] .dlabnav .main-menu {
    display: none;
  }

  [data-layout="horizontal"] .wallet-bar {
    top: 174px;
    z-index: 0;
    height: calc(100% - 13rem);
  }
}

@media only screen and (min-width: 64rem) and (max-width: 115.625rem) {
  [data-layout="horizontal"] .wallet-bar {
    top: 221px;
  }
}

@media (min-width: 64rem) {
  [data-layout="horizontal"] .wallet-open.active .header .header-content {
    padding-right: 20px;
  }

  [data-layout="horizontal"] .content-body {
    margin-left: 0;
  }

  [data-layout="horizontal"] .content-body .container-fluid,
  [data-layout="horizontal"] .content-body .container-sm,
  [data-layout="horizontal"] .content-body .container-md,
  [data-layout="horizontal"] .content-body .container-lg,
  [data-layout="horizontal"] .content-body .container-xl,
  [data-layout="horizontal"] .content-body .container-xxl {
    padding-top: 2.5rem;
  }

  [data-layout="horizontal"] .content-body .page-titles {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-bottom: 1.875rem;
  }

  [data-layout="horizontal"] .footer {
    margin-left: 0;
    margin: 0 auto;
    padding-left: 0;
  }

  [data-layout="horizontal"] .main-card {
    margin-top: 0;
  }
}

@media only screen and (min-width: 64rem) and (max-width: 115.625rem) {
  [data-sidebar-style="full"][data-layout="horizontal"] .content-body {
    padding-top: 8.1rem !important;
  }
}

@media (min-width: 64rem) {
  [data-header-position="fixed"][data-sidebar-position="static"][data-layout="horizontal"] .dlabnav {
    top: 0;
  }

  [data-header-position="fixed"][data-sidebar-position="static"][data-layout="horizontal"] .content-body {
    padding-top: 0rem;
  }

  [data-header-position="fixed"][data-layout="horizontal"] .dlabnav {
    top: 5.1rem;
  }

  [data-header-position="fixed"][data-layout="horizontal"] .header {
    height: 5.1rem;
    border: 0 !important;
  }

  [data-header-position="fixed"][data-layout="horizontal"] .content-body {
    padding-top: 5.1rem;
  }

  [data-header-position="fixed"][data-sidebar-position="fixed"] .dlabnav {
    position: fixed;
  }

  [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"][data-sidebar-style="modern"] .content-body {
    padding-top: 6.6rem;
  }

  [data-layout="horizontal"][data-container="boxed"] .footer {
    margin-left: 0;
    max-width: 74.9375rem;
    margin: 0 auto;
  }

  [data-layout="horizontal"][data-container="wide"] .page-titles {
    margin-left: -1.875rem;
    margin-right: -1.875rem;
    display: none;
  }

  [data-layout="horizontal"][data-container="wide"] .header-banner {
    display: none;
  }

  [data-layout="horizontal"][data-sidebar-style="modern"] .dlabnav .header-profile {
    margin-bottom: 0;
  }

  [data-layout="horizontal"][data-sidebar-style="modern"] .dlabnav .header-profile img {
    height: 3.75rem;
    width: 3.75rem;
    margin-bottom: 0 !important;
  }

  [data-layout="horizontal"][data-sidebar-style="modern"] .dlabnav .header-profile>a.nav-link {
    border: 0.0625rem solid #eeeeee;
    padding: 0.25rem 0.25rem;
    border-radius: 3rem;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .page-titles {
    margin-top: 0;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .dlabnav .menu-scroll {
    height: 6.5rem;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .dlabnav .header-profile {
    margin-bottom: 0;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .dlabnav .header-profile img {
    height: 3.75rem;
    width: 3.75rem;
    margin-bottom: 0 !important;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .dlabnav .header-profile>a.nav-link {
    border: 0.0625rem solid #eeeeee;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .dlabnav .metismenu>li>ul {
    top: 5.1rem;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .dlabnav .metismenu>li>a {
    padding: 1.125rem 1.25rem 0.625rem 1.25rem;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .dlabnav .metismenu>li>a::after {
    display: none;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .dlabnav .metismenu>li>a .nav-text {
    margin-top: 0.3125rem;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .dlabnav .metismenu>li>a>i {
    width: auto;
    margin: 0;
    height: auto;
    line-height: 1;
    padding: 0;
    background: transparent;
    border-radius: 0;
    margin-bottom: 0;
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] .dlabnav .metismenu>li li {
    text-align: left;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .nav-header {
    width: 7.75rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .nav-header .brand-logo {
    justify-content: start;
    padding-left: 0;
    padding-right: 0;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .header {
    width: 100%;
    padding-left: 7.75rem;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .metismenu>li a {
    width: auto;
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] .metismenu>li:hover a .nav-text {
    display: none;
  }

  [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"][data-sidebar-style="compact"] .content-body {
    padding-top: 6.5rem;
  }

  [data-sidebar-position="fixed"][data-layout="horizontal"] .dlabnav.fixed {
    position: fixed;
    padding: 0 0.9375rem;
    left: 0;
    top: 0;
    border-radius: 0;
    width: 100%;
  }
}

@media (min-width: 47.9375rem) {
  [data-sidebar-style="compact"] .nav-header {
    width: 11.25rem;
  }

  [data-sidebar-style="compact"] .dlabnav.follow-info .menu-scroll {
    height: calc(100% - 4.5rem);
  }

  [data-sidebar-style="compact"] .dlabnav .header-profile {
    margin-bottom: 0.3125rem;
  }

  [data-sidebar-style="compact"] .dlabnav .header-profile>a.nav-link {
    display: block;
    text-align: center;
    border: 0;
  }

  [data-sidebar-style="compact"] .dlabnav .header-profile>a.nav-link img {
    margin-bottom: 0.3125rem;
  }

  [data-sidebar-style="compact"] .dlabnav .header-profile>a.nav-link .header-info {
    margin-left: 0 !important;
    text-align: center;
    display: none;
  }

  [data-sidebar-style="compact"] .dlabnav .header-profile>a.nav-link .header-info small,
  [data-sidebar-style="compact"] .dlabnav .header-profile>a.nav-link .header-info .small {
    text-align: center !important;
  }

  [data-sidebar-style="compact"] .dlabnav .header-profile .dropdown-menu {
    min-width: 11rem;
  }

  [data-sidebar-style="compact"] .dlabnav .header-profile a svg {
    display: unset !important;
  }

  [data-sidebar-style="compact"] .dlabnav .main-menu {
    text-align: center;
  }

  [data-sidebar-style="compact"] .dlabnav .nav-user {
    display: none;
  }

  [data-sidebar-style="compact"] .dlabnav .metismenu li {
    text-align: center;
  }

  [data-sidebar-style="compact"] .dlabnav .metismenu li a {
    padding: 0.7rem 0.5rem;
  }

  [data-sidebar-style="compact"] .dlabnav .metismenu li a svg {
    max-width: 1.3125rem;
    max-height: 1.3125rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  [data-sidebar-style="compact"] .dlabnav .metismenu li a i {
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
  }

  [data-sidebar-style="compact"] .dlabnav .metismenu li ul:after {
    content: none;
  }

  [data-sidebar-style="compact"] .dlabnav .metismenu li>a {
    box-shadow: none;
    font-size: 0.9375rem;
  }

  [data-sidebar-style="compact"] .dlabnav .plus-box,
  [data-sidebar-style="compact"] .dlabnav .copyright {
    display: none;
  }

  [data-sidebar-style="compact"] .dlabnav .copyright {
    padding: 0 1.25rem;
    margin-top: 1.25rem;
  }

  [data-sidebar-style="compact"] .dlabnav .header-info2 {
    padding: 0;
  }

  [data-sidebar-style="compact"] .dlabnav .header-info2 img {
    margin-left: -1rem;
  }

  [data-sidebar-style="compact"] .dlabnav .sidebar-info {
    display: none !important;
  }

  [data-sidebar-style="compact"] .dlabnav .header-profile2 {
    margin: 0 3rem;
  }

  [data-sidebar-style="compact"] .dlabnav .feature-box,
  [data-sidebar-style="compact"] .dlabnav .support-box {
    display: none;
  }

  [data-sidebar-style="compact"] .header .page-titles {
    padding-left: 2.1rem;
  }

  [data-sidebar-style="compact"] .nav-text {
    display: inline-block;
    margin-top: 0.3125rem;
  }

  [data-sidebar-style="compact"] .nav-label.first {
    display: none;
  }

  [data-sidebar-style="compact"] .nav-badge {
    display: none;
  }

  [data-sidebar-style="compact"] .footer {
    padding-left: 11.3rem;
  }

  [data-sidebar-style="compact"] .content-body {
    margin-left: 10.5rem;
  }

  [data-sidebar-style="compact"][data-theme-version="dark"][data-layout="horizontal"] .dlabnav .metismenu li>a i {
    color: #fff;
  }

  [data-sidebar-style="compact"][data-theme-version="dark"][data-layout="vertical"] .dlabnav .metismenu li.mm-active>a i {
    background: var(--primary);
    color: #fff;
  }

  [data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu li:hover>a i {
    background: var(--primary);
    color: #fff;
  }

  [data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu .has-arrow[aria-expanded="true"]:after,
  [data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu .mm-active>.has-arrow:after {
    margin-right: 0;
    border-color: var(--primary);
  }
}

[data-layout="horizontal"][data-sidebar-style="compact"] .footer {
  padding-left: 0;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .content-body {
  margin-left: 0;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .dlabnav {
  margin-bottom: 0;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .nav-header {
  width: 13.25rem;
}

[data-layout="horizontal"][data-sidebar-style="compact"] .nav-header .brand-logo {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

[data-layout="vertical"][data-sidebar-style="compact"] .dlabnav {
  width: 11.25rem;
  z-index: 3;
}

[data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu ul {
  margin-left: 0;
  border: 0;
  background: rgba(0, 0, 0, 0.02);
  padding: 0.625rem 0;
}

[data-theme-version="dark"][data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu ul {
  background: 255, 255, 255, 0.05;
}

[data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu ul a:before {
  content: none;
}

[data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu ul ul a {
  padding: 0.625rem 0.9375rem;
}

[data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu>li>a {
  padding: 1.2rem 0.5rem;
}

[data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu>li>a.has-arrow::after {
  top: 0.0625rem;
  display: inline-block;
  right: auto;
  margin-left: 0.3125rem;
  position: relative;
  width: 0.4375rem;
  height: 0.4375rem;
  border-width: 0.125rem 0rem 0rem 0.125rem;
}

[data-layout="vertical"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a {
  background: transparent;
  color: var(--primary);
}

@media (min-width: 47.9375rem) {
  [data-sidebar-style="icon-hover"][data-layout="horizontal"] .header .header-content {
    padding-left: 1.875rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu ul a:before {
    left: 15px;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav.follow-info .menu-scroll {
    height: calc(100% - 1.5rem);
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .menu-scroll {
    height: calc(100% - 1.5rem);
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .header .page-titles {
    padding-left: 3.1rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .feature-box {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu li.mm-active>ul {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu a {
    padding: 0.625rem 0.875rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .feature-box .wallet-box,
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .main-menu {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .header-info2 {
    justify-content: space-between;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .header-info2 img {
    order: 1;
    margin-right: 0;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .dlabnav .metismenu li.mm-active>ul {
    display: block;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header {
    width: 5.5rem;
    border-radius: 0 0 0 0 !important;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo {
    padding-left: 1.875rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo .logo-abbr {
    display: block;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .brand-logo .brand-title {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .nav-header .nav-control {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .header {
    padding-left: 5rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .header .header-content {
    padding-left: 2.7rem;
    left: 5.4rem;
    width: calc(100% - 5rem);
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav {
    overflow: visible;
    position: absolute;
    left: -10.7rem;
    z-index: 3;
  }
}

@media only screen and (min-width: 47.9375rem) and (min-width: 47.9375rem) and (max-width: 87.5rem) {
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav {
    left: -11.5rem;
  }
}

@media (min-width: 47.9375rem) {
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .nav-label {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .header-profile img {
    order: 1;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .header-profile .header-info {
    margin-left: 0 !important;
    padding-left: 0 !important;
    margin-right: 0.625rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu>li {
    padding: 0 1rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu>li>a {
    display: flex;
    justify-content: space-between;
    font-size: 0.938rem;
    border-radius: 0.5rem;
    align-items: center;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu>li>a>svg,
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
    order: 1;
    margin-right: 0;
    margin-top: 0;
    padding-right: 0;
    height: auto;
    width: auto;
    line-height: 1;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a {
    background: var(--primary);
    color: #fff;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
    color: #fff;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu ul {
    border-left: 0;
    padding-left: 0;
    padding-right: 0;
  }

  [data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu ul {
    border-color: #2a3969;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu ul:after {
    left: auto;
    right: 0.75rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu ul a {
    position: relative;
    padding-left: 1.8rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu ul a::before {
    left: 0.25rem;
    right: auto;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .metismenu .has-arrow::after {
    right: 5rem;
    opacity: 0;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .copyright,
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .plus-box {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav.mm-show {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .dlabnav .support-box {
    display: none;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .nav-header {
    width: 16.2rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .nav-header .brand-logo {
    padding-left: 1.6rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .nav-header .brand-logo .brand-title {
    display: block;
  }
}

@media only screen and (min-width: 47.9375rem) and (max-width: 87.5rem) {
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .nav-header {
    width: 17rem;
  }
}

@media (min-width: 47.9375rem) {
  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle.mm-show {
    display: block;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .header {
    padding-left: 4.38rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .dlabnav {
    left: 0;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .dlabnav .feature-box {
    display: block;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .dlabnav .metismenu .has-arrow:after {
    opacity: 1;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .iconhover-toggle .dlabnav .support-box {
    display: block;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .content-body {
    margin-left: 5.5rem;
  }

  [data-sidebar-style="icon-hover"][data-layout="vertical"] .footer {
    padding-left: 5.5rem;
  }
}

@media (min-width: 47.9375rem) {
  [data-sidebar-style="modern"] .nav-header {
    width: 10.625rem;
  }

  [data-sidebar-style="modern"] .nav-header .brand-logo {
    justify-content: center;
  }

  [data-sidebar-style="modern"] .header .page-titles {
    padding-left: 3.7rem;
  }

  [data-sidebar-style="modern"] .dlabnav .header-profile {
    margin-bottom: 0.3125rem;
  }

  [data-sidebar-style="modern"] .dlabnav .header-profile>a.nav-link {
    display: block;
    text-align: center;
    margin: 0 -0.625rem 0.9375rem;
    padding: 0.9375rem 0.625rem;
    border-radius: 0.5rem;
  }

  [data-sidebar-style="modern"] .dlabnav .header-profile>a.nav-link img {
    margin-bottom: 0.3125rem;
  }

  [data-sidebar-style="modern"] .dlabnav .header-profile>a.nav-link .header-info {
    margin-left: 0 !important;
    text-align: center;
    display: none;
  }

  [data-sidebar-style="modern"] .dlabnav .header-profile>a.nav-link .header-info small,
  [data-sidebar-style="modern"] .dlabnav .header-profile>a.nav-link .header-info .small {
    text-align: center !important;
  }

  [data-sidebar-style="modern"] .dlabnav .main-menu {
    text-align: center;
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu>li {
    text-align: center;
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu>li>a {
    padding: 1.25rem 0.9375rem 1.25rem 0.9375rem;
    margin: 0.125rem 0;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu>li>a::after {
    display: none;
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu>li>a:hover>a,
  [data-sidebar-style="modern"] .dlabnav .metismenu>li>a:focus>a,
  [data-sidebar-style="modern"] .dlabnav .metismenu>li>a:active>a,
  [data-sidebar-style="modern"] .dlabnav .metismenu>li>a.mm-active>a {
    background-color: var(--primary-dark);
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu>li:hover,
  [data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active {
    padding: 0;
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
  [data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a {
    background: var(--primary);
    color: #fff;
    border-radius: 0.5rem;
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a i,
  [data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a i {
    color: #fff;
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu>li:hover .mm-collapse li a,
  [data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active .mm-collapse li a {
    padding: 0.3rem 1rem;
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu>li li {
    text-align: left;
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu li a {
    padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
    font-size: 0.9375rem;
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu li ul:after {
    content: none;
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu li>ul {
    height: auto !important;
  }

  [data-sidebar-style="modern"] .dlabnav .metismenu .nav-label {
    display: none;
  }

  [data-sidebar-style="modern"] .dlabnav .nav-label {
    display: none;
  }

  [data-sidebar-style="modern"] .dlabnav .nav-text {
    display: block;
    margin-top: 0.3125rem;
  }

  [data-sidebar-style="modern"] .dlabnav .feature-box,
  [data-sidebar-style="modern"] .dlabnav .support-box,
  [data-sidebar-style="modern"] .dlabnav .copyright {
    display: none;
  }

  [data-sidebar-style="modern"] .footer {
    padding-left: 11.9rem;
  }

  [data-sidebar-style="modern"] .content-body {
    margin-left: 10.6rem;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .dlabnav .metismenu li>a i {
    padding: 0;
    margin: 0;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav {
    width: 10.625rem;
    left: 0;
    z-index: 3;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .slimScrollDiv,
  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .dlabnav-scroll {
    overflow: visible !important;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .metismenu {
    padding: 0.625rem 0.875rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .metismenu>li>a {
    padding: 1.375rem 0.6em 0.9375rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .metismenu>li>a i {
    padding: 0;
    height: auto;
    width: auto;
    margin: 0;
    line-height: 1;
    margin-bottom: 0.3125rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .metismenu>li>ul {
    display: none;
    padding: 1.875rem 0.9375rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .metismenu li {
    position: relative;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .metismenu li a {
    padding: 1rem 1rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .metismenu li a:before {
    content: none;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .metismenu li ul {
    position: absolute;
    left: 105%;
    top: 0;
    bottom: auto;
    background-color: #fff;
    border: 0.0625rem solid var(--border);
    width: 12.5rem;
  }

  [data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .metismenu li ul {
    background: #18254f;
    box-shadow: 0rem 0rem 0.8125rem 0rem rgba(0, 0, 0, 0.1);
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .metismenu li:hover>ul {
    display: block;
    left: 100%;
    padding: 1rem 0;
    margin-left: 0;
    border: 0;
    box-shadow: 0.3125rem 0.3125rem 1.875rem 0rem rgba(20, 0, 30, 0.1);
    border-radius: 0.5rem;
  }

  [data-theme-version="dark"][data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .metismenu li:hover>ul {
    box-shadow: 0.3125rem 0.3125rem 1.875rem 0rem rgba(20, 0, 30, 0.1);
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .nav-label {
    display: none;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .nav-text {
    display: block;
    margin-top: 0;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .dlabnav .header-profile2 {
    padding: 0 2.5rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .nav-control {
    right: -5.75rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .header .header-content {
    padding-left: 8rem;
    left: 10.6rem;
    width: calc(100% - 10.6rem);
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .dlabnav,
  [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .nav-header {
    left: -10.625rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .header {
    padding-left: 0;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .header .page-titles {
    padding-left: 2.5rem;
  }

  [data-sidebar-style="modern"][data-layout="vertical"] .menu-toggle .content-body {
    margin-left: 0;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .nav-header {
    width: 17.05rem;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .header {
    padding-left: 21.75rem;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .footer,
  [data-sidebar-style="modern"][data-layout="horizontal"] .content-body {
    margin-left: 0;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .dlabnav .metismenu>li>a {
    padding: 0.9375rem 1.5625rem 0.75rem 1.5625rem;
    margin: 0 0.125rem;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .dlabnav .metismenu>li>ul {
    top: 4.8rem;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"] .dlabnav .menu-scroll {
    height: 6.6rem;
  }

  [data-sidebar-style="modern"][data-layout="horizontal"][data-container="boxed"] .dlabnav .metismenu>li>a {
    padding: 0.8125rem 1.25rem;
  }
}

[data-sidebar-style="overlay"][data-layout="vertical"] .dlabnav {
  border-radius: 0 0 0 0 !important;
}

@media only screen and (max-width: 47.9375rem) {
  [data-sidebar-style="overlay"][data-layout="vertical"] .dlabnav {
    border-radius: 0 0 0 0 !important;
  }
}

[data-sidebar-style="overlay"][data-layout="vertical"] .nav-header {
  border-radius: 0;
}

[data-sidebar-style="overlay"][data-layout="vertical"] .menu-toggle .nav-header {
  border-radius: 0 0 0 0;
}

[data-sidebar-style="overlay"][data-layout="vertical"] .header .page-titles {
  padding-left: 2.9rem;
}

@media only screen and (max-width: 35.9375rem) {
  [data-sidebar-style="overlay"][data-layout="vertical"] .header .page-titles {
    padding-left: 2.3rem;
  }
}

[data-sidebar-style="overlay"][data-layout="vertical"] .header:not(.home):after {
  background-position: unset;
}

[data-sidebar-style="overlay"] .dlabnav {
  left: -100%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.2);
}

[data-sidebar-style="overlay"] .dlabnav .metismenu>li {
  padding: 0 1rem;
}

[data-sidebar-style="overlay"] .dlabnav .metismenu>li>a {
  font-size: 0.938rem;
  padding: 0.7375rem 1rem;
  color: #7f7184;
  border-radius: 0.5rem;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

[data-sidebar-style="overlay"] .dlabnav .metismenu>li>a i {
  height: auto;
  width: auto;
  line-height: 1;
}

[data-sidebar-style="overlay"] .dlabnav .metismenu>li:hover>a {
  color: var(--primary);
}

[data-sidebar-style="overlay"] .dlabnav .metismenu>li:hover>a i {
  color: var(--primary);
}

[data-sidebar-style="overlay"] .dlabnav .metismenu>li.mm-active>a {
  background: var(--primary);
  color: #fff;
}

[data-sidebar-style="overlay"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

@media only screen and (max-width: 35.9375rem) {
  [data-sidebar-style="overlay"] .dlabnav .metismenu>li {
    padding: 0rem 0.9375rem;
  }
}

[data-sidebar-style="overlay"] .dlabnav .metismenu ul a {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: relative;
  font-size: 0.9375rem;
  padding-left: 3.2rem;
}

[data-sidebar-style="overlay"] .dlabnav .metismenu ul a:before {
  left: 1.5625rem;
}

[direction="rtl"][data-sidebar-style="overlay"] .dlabnav {
  left: auto;
  right: -100%;
}

@media only screen and (max-width: 47.9375rem) {
  [data-sidebar-style="overlay"] .dlabnav .metismenu>li>a {
    font-size: 0.875rem;
    padding: 0.75rem 0.875rem;
    margin: 5px 0;
  }

  [data-sidebar-style="overlay"] .dlabnav .metismenu>li>a i {
    font-size: 1.125rem;
  }

  [data-sidebar-style="overlay"] .dlabnav .metismenu ul li a {
    padding-left: 2.8rem;
  }
}

[data-sidebar-style="overlay"] .content-body {
  margin-left: 0;
}

[data-sidebar-style="overlay"] .nav-header {
  position: absolute;
}

[data-sidebar-style="overlay"] .nav-header .hamburger.is-active {
  left: 0;
}

[data-sidebar-style="overlay"] .nav-header .hamburger.is-active .line {
  background-color: var(--primary);
}

[data-sidebar-style="overlay"] .menu-toggle .nav-header {
  position: absolute;
  left: auto;
}

[data-sidebar-style="overlay"] .menu-toggle .dlabnav {
  left: 0;
  z-index: 3;
}

[data-sidebar-style="overlay"] .footer {
  padding-left: 0;
}

[data-sidebar-style="overlay"][data-header-position="fixed"] .nav-header {
  position: fixed;
}

[data-sidebar-position="fixed"][data-header-position="fixed"] .nav-header {
  position: fixed;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .nav-header {
  position: fixed;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .dlabnav {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .dlabnav .dlabnav-scroll {
  border-top-left-radius: 0rem;
  border-top-right-radius: 0rem;
}

[data-layout="vertical"] .nav-header {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

[data-layout="vertical"] .dlabnav {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

[data-layout="vertical"][data-sidebar-position="fixed"] .dlabnav {
  position: fixed;
}

@media only screen and (max-width: 61.9375rem) {
  [data-layout="vertical"][data-sidebar-position="fixed"] .dlabnav {
    position: absolute;
  }
}

@media only screen and (max-width: 47.9375rem) {
  [data-layout="vertical"][data-sidebar-position="fixed"] .dlabnav {
    position: fixed;
  }
}

[data-layout="horizontal"][data-sidebar-position="static"][data-header-position="static"] .header.is-fixed .header-content {
  position: relative;
}

[data-layout="horizontal"][data-sidebar-position="fixed"][data-header-position="static"] .header.is-fixed .header-content {
  position: relative;
}

[data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="wide-boxed"] .dlabnav,
[data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="boxed"] .dlabnav,
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="wide-boxed"] .dlabnav,
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="boxed"] .dlabnav,
[data-sidebar-style="overlay"][data-layout="vertical"][data-container="wide-boxed"] .dlabnav,
[data-sidebar-style="overlay"][data-layout="vertical"][data-container="boxed"] .dlabnav,
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="overlay"][data-layout="vertical"][data-container="wide-boxed"] .dlabnav,
[data-header-position="fixed"][data-sidebar-position="fixed"][data-sidebar-style="overlay"][data-layout="vertical"][data-container="boxed"] .dlabnav {
  position: absolute;
}

.sidebar-right-trigger {
  position: absolute;
  z-index: 9;
  top: 14.75rem;
  right: 100%;
  background-color: var(--primary);
  color: #fff;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  text-align: center;
  font-size: 1.75rem;
  line-height: 3rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  box-shadow: -0.3125rem 0.1875rem 0.3125rem 0rem rgba(119, 119, 119, 0.15);
}

[data-theme-version="dark"] .sidebar-right-trigger {
  color: #fff;
}

@media only screen and (max-width: 64rem) {
  .sidebar-right-trigger {
    display: none;
  }
}

.sidebar-right-trigger:hover {
  color: #fff;
}

.sidebar-right {
  right: -50rem;
  position: fixed;
  top: 0;
  width: 50rem;
  background-color: #fff;
  margin-top: 3.5rem;
  transition: all 0.5s ease-in-out;
  border-radius: 0.5rem;
  z-index: 9999;
}

.sidebar-right .dropdown-menu {
  background-color: #fff !important;
}

.sidebar-right .dropdown-menu .dropdown-item:hover {
  background-color: #e7e7e7;
}

.sidebar-right .note-text {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  opacity: 0.7;
  font-size: 12px;
}

.sidebar-right .bg-label-pattern {
  background: transparent;
  background-image: url("../images/pattern/pattern5.png");
  background-size: 130%;
}

.sidebar-right .bootstrap-select {
  height: 3rem;
  border-radius: 0.375rem;
}

.sidebar-right .bootstrap-select .btn {
  padding: 0.75rem 0.9375rem;
  font-size: 0.9375rem;
  border-color: #d1d1d1 !important;
  border-radius: 0.375rem;
}

[direction="rtl"] .sidebar-right .slimScrollDiv {
  overflow: visible !important;
}

.sidebar-right .sidebar-close-trigger {
  position: absolute;
  z-index: 2;
  font-size: 1.75rem;
  top: 0;
  right: -3rem;
  height: 3rem;
  width: 3rem;
  line-height: 3rem;
  text-align: center;
  background: black;
  color: #fff;
}

.sidebar-right.show {
  right: 5.25rem;
  box-shadow: 0rem 0rem 3.125rem rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.sidebar-right.show .bg-overlay {
  position: fixed;
  width: 100%;
  cursor: pointer;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: -1;
}

.sidebar-right .card-tabs .nav-tabs {
  justify-content: space-between;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 2;
  border-bottom: 4px solid var(--rgba-primary-1);
}

.sidebar-right .card-tabs .nav-tabs .nav-item {
  margin-bottom: 0;
  flex: 1;
}

.sidebar-right .card-tabs .nav-tabs .nav-item .nav-link {
  border: 0;
  font-size: 1.125rem;
  position: relative;
  text-align: center;
  background-color: #fff;
  border-radius: 0;
  margin: 0;
}

.sidebar-right .card-tabs .nav-tabs .nav-item .nav-link.active {
  background: var(--rgba-primary-1);
  color: #000;
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link.active {
  border-right: none;
  border-left: none;
  border-top: none;
}

.sidebar-right .sidebar-right-inner>h4,
.sidebar-right .sidebar-right-inner>.h4 {
  padding: 0.625rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
  background: #fff;
  margin: 0rem 0rem 0rem 0rem;
}

.sidebar-right .tab-content {
  padding: 1.25rem 1.25rem 0;
  min-height: 480px;
  background: #fff;
}

.sidebar-right .tab-content .tab-pane .admin-settings .row>div {
  margin-bottom: 1.25rem;
}

.sidebar-right .tab-content .tab-pane .admin-settings p {
  color: #353535;
  font-weight: 500;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"] {
  display: none;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]+label {
  display: inline-block;
  width: 2.1875rem;
  height: 2.1875rem;
  cursor: pointer;
  transition: all 0.1s ease;
  border-radius: 0.25rem;
  margin-right: 0.3125rem;
  margin-bottom: 0.1875rem;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]:checked+label {
  position: relative;
}

.sidebar-right .tab-content .tab-pane .admin-settings input[type="radio"]:checked+label::after {
  height: 2.0625rem;
  width: 2.0625rem;
  left: -0.25rem;
  top: -0.25rem;
  content: "";
  position: absolute;
  background-color: inherit;
  border-radius: 0.375rem;
  opacity: 0.4;
}

.sidebar-right #nav_header_color_1+label,
.sidebar-right #header_color_1+label,
.sidebar-right #sidebar_color_1+label,
.sidebar-right #primary_color_1+label {
  background-color: #fff;
}

.sidebar-right #nav_header_color_2+label,
.sidebar-right #header_color_2+label,
.sidebar-right #sidebar_color_2+label,
.sidebar-right #primary_color_2+label {
  background-color: #6610f2;
}

.sidebar-right #nav_header_color_3+label,
.sidebar-right #header_color_3+label,
.sidebar-right #sidebar_color_3+label,
.sidebar-right #primary_color_3+label {
  background-color: #3736af;
}

.sidebar-right #nav_header_color_4+label,
.sidebar-right #header_color_4+label,
.sidebar-right #sidebar_color_4+label,
.sidebar-right #primary_color_4+label {
  background-color: #4d06a5;
}

.sidebar-right #nav_header_color_5+label,
.sidebar-right #header_color_5+label,
.sidebar-right #sidebar_color_5+label,
.sidebar-right #primary_color_5+label {
  background-color: #3250ff;
}

.sidebar-right #nav_header_color_6+label,
.sidebar-right #header_color_6+label,
.sidebar-right #sidebar_color_6+label,
.sidebar-right #primary_color_6+label {
  background-color: #3f8cff;
}

.sidebar-right #nav_header_color_7+label,
.sidebar-right #header_color_7+label,
.sidebar-right #sidebar_color_7+label,
.sidebar-right #primary_color_7+label {
  background-color: #40a7e2;
}

.sidebar-right #nav_header_color_8+label,
.sidebar-right #header_color_8+label,
.sidebar-right #sidebar_color_8+label,
.sidebar-right #primary_color_8+label {
  background-color: #b65fbd;
}

.sidebar-right #nav_header_color_9+label,
.sidebar-right #header_color_9+label,
.sidebar-right #sidebar_color_9+label,
.sidebar-right #primary_color_9+label {
  background-color: #88a67e;
}

.sidebar-right #nav_header_color_10+label,
.sidebar-right #header_color_10+label,
.sidebar-right #sidebar_color_10+label,
.sidebar-right #primary_color_10+label {
  background-color: #1c2e9e;
}

.sidebar-right #nav_header_color_11+label,
.sidebar-right #header_color_11+label,
.sidebar-right #sidebar_color_11+label,
.sidebar-right #primary_color_11+label {
  background-color: #c6164f;
}

.sidebar-right #nav_header_color_12+label,
.sidebar-right #header_color_12+label,
.sidebar-right #sidebar_color_12+label,
.sidebar-right #primary_color_12+label {
  background-color: #343a40;
}

.sidebar-right #nav_header_color_13+label,
.sidebar-right #header_color_13+label,
.sidebar-right #sidebar_color_13+label,
.sidebar-right #primary_color_13+label {
  background-color: #056468;
}

.sidebar-right #nav_header_color_14+label,
.sidebar-right #header_color_14+label,
.sidebar-right #sidebar_color_14+label,
.sidebar-right #primary_color_14+label {
  background-color: #4885ed;
}

.sidebar-right #nav_header_color_15+label,
.sidebar-right #header_color_15+label,
.sidebar-right #sidebar_color_15+label,
.sidebar-right #primary_color_15+label {
  background-color: #4cb32b;
}

.sidebar-right #nav_header_color_1+label,
.sidebar-right #header_color_1+label,
.sidebar-right #sidebar_color_1+label,
.sidebar-right #primary_color_1+label {
  border: 0.0625rem solid #c4c4c4;
}

.sidebar-right.style-1 {
  height: 100vh;
  width: 15.625rem;
  margin-top: 0;
  right: -15.625rem;
}

.sidebar-right.style-1 .sidebar-right-inner {
  background: #fff;
}

.sidebar-right.style-1 .sidebar-right-trigger {
  top: 12.4rem;
}

.sidebar-right.style-1.show {
  right: 0;
}

.sidebar-right.style-1.show .sidebar-right-trigger {
  display: block;
}

.nice-select.wide {
  line-height: 2.8125rem;
}

@keyframes bounce {
  0% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
  }

  50% {
    transform: translateX(8%);
    -webkit-transform: translateX(8%);
  }

  100% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
  }
}

@-webkit-keyframes bounce {
  0% {
    transform: translateX(-8%);
    -webkit-transform: translateX(-8%);
  }

  50% {
    transform: translateX(8%);
    -webkit-transform: translateX(8%);
  }

  100% {
    transform: translateY(-8%);
    -webkit-transform: translateY(-8%);
  }
}

@media only screen and (max-width: 87.5rem) {
  .nice-select.wide {
    line-height: 2rem;
  }
}

.nav-user {
  background: var(--primary);
  margin-bottom: 0.625rem;
  padding: 1.25rem 1.5625rem 0.9375rem;
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
  .nav-user {
    padding: 1.25rem 0.9375rem 0.9375rem;
  }
}

.nav-user img {
  width: 2.1875rem;
  height: 2.1875rem;
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
  .nav-user img {
    width: 2.1875rem;
    height: 2.1875rem;
    margin-bottom: 0.625rem;
  }
}

.nav-user h5,
.nav-user .h5 {
  margin-left: 0.625rem;
  margin-bottom: 0.1875rem;
  color: #fff;
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {

  .nav-user h5,
  .nav-user .h5 {
    display: none;
  }
}

[data-sibebarbg="color_2"] .nav-user h5,
[data-sibebarbg="color_2"] .nav-user .h5 {
  color: #fff;
}

.nav-user p {
  margin-left: 0.625rem;
  margin-bottom: 0.5rem;
  color: #afcff7;
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
  .nav-user p {
    display: none;
  }
}

@media only screen and (min-width: 48rem) and (max-width: 74.9375rem) {
  .nav-user i {
    margin-top: 0.9375rem;
    display: block;
  }
}

.menu-toggle .nav-user {
  padding: 1.25rem 0.9375rem 0.9375rem;
}

.menu-toggle .nav-user img {
  width: 2.1875rem;
  height: 2.1875rem;
  margin-bottom: 0.625rem;
}

.menu-toggle .nav-user h5,
.menu-toggle .nav-user .h5,
.menu-toggle .nav-user p {
  display: none;
}

.menu-toggle .nav-user i {
  margin-top: 0.9375rem;
  display: block;
}

.menu-toggle .nav-user .dropdown-menu {
  left: 2.8125rem !important;
  top: 1.375rem !important;
}

.chatbox {
  width: 21.25rem;
  height: 100vh;
  position: fixed;
  right: -31.25rem;
  top: 0;
  z-index: 1004;
  background: var(--card);
  box-shadow: 0rem 0rem 1.875rem 0rem rgba(82, 63, 105, 0.15);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.chatbox .chatbox-close {
  position: absolute;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  width: 0;
  height: 100%;
  right: 21.25rem;
  background: #000;
  z-index: 1;
  opacity: 0.1;
  cursor: pointer;
}

.chatbox .card-fotter {
  padding: 0.75rem 1rem;
}

.chatbox .card-body {
  padding: 1rem;
}

.chatbox.active {
  right: 0;
}

.chatbox.active .chatbox-close {
  width: 100vw;
}

.chatbox .type_msg {
  padding-top: 0.625rem;
}

.chatbox .nav {
  padding: 1rem 1rem 0 1rem;
  background: var(--rgba-primary-1);
  border: 0;
  justify-content: space-between;
}

.chatbox .nav .nav-link {
  color: var(--primary);
  opacity: 0.7;
  text-transform: uppercase;
}

.chatbox .nav .nav-link:hover,
.chatbox .nav .nav-link.active {
  background: transparent;
  color: var(--primary);
  opacity: 1;
  border-color: var(--primary);
}

.chatbox .img_cont {
  width: 2.5rem;
  border-radius: 2.5rem;
  margin-right: 0.625rem;
  position: relative;
  height: 2.5rem;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 0.875rem;
  min-width: 2.5rem;
  min-height: 2.5rem;
}

.chatbox .img_cont .icon {
  color: #fff;
}

.chatbox .img_cont.primary {
  background: var(--rgba-primary-1);
  color: var(--primary);
}

[data-theme-version="dark"] .chatbox .img_cont.primary {
  background: var(--rgba-primary-1);
}

.chatbox .img_cont.warning {
  background: #fff2e0;
  color: #ffab2d;
}

[data-theme-version="dark"] .chatbox .img_cont.warning {
  background: rgba(255, 171, 45, 0.1);
}

.chatbox .img_cont.success {
  background: #e0f5eb;
  color: #3ab67a;
}

[data-theme-version="dark"] .chatbox .img_cont.success {
  background: rgba(58, 182, 122, 0.1);
}

.chatbox .img_cont.info {
  background: #94fff9;
  color: #00ada3;
}

[data-theme-version="dark"] .chatbox .img_cont.info {
  background: rgba(0, 173, 163, 0.1);
}

.chatbox .img_cont img {
  width: 100%;
}

.chatbox .img_cont .online_icon {
  background: #3ab67a;
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 0.9375rem;
  right: -0.0625rem;
  bottom: 0rem;
  border: 0.125rem solid #fff;
}

.chatbox .img_cont .online_icon.offline {
  background: #fd5353;
}

.chatbox .card {
  box-shadow: none;
}

.chatbox .search {
  height: 2.5rem;
}

.chatbox .user_info span {
  font-size: 0.9375rem;
  color: #000;
  display: block;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 0.3125rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  max-width: 10.625rem;
}

[data-theme-version="dark"] .chatbox .user_info span {
  color: #fff;
}

.chatbox .user_info p {
  font-size: 0.8125rem;
  margin-bottom: 0;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  max-width: 10.625rem;
}

.chatbox .contacts li {
  padding: 0.4375rem 1rem;
  border-bottom: 0.0625rem solid var(--border);
  cursor: pointer;
}

.chatbox .contacts li>div {
  display: flex;
  align-items: center;
}

.chatbox .contacts li:hover {
  background: #f4f7ff;
}

[data-theme-version="dark"] .chatbox .contacts li:hover {
  background-color: #09153f;
}

.chatbox .contacts .name-first-letter {
  background: var(--body-bg);
  padding: 0.25rem 1rem;
  font-weight: 700;
  color: var(--text-dark);
  position: sticky;
  top: 0;
  z-index: 1;
}

.chatbox .msg_card_body {
  height: calc(100vh - 195px);
}

.chatbox .contacts_body {
  height: calc(100vh - 120px);
}

.chatbox .card-header {
  background: #f4f7ff;
  padding: 0.9375rem 1.25rem;
  justify-content: center;
}

.chatbox .card-header h6,
.chatbox .card-header .h6 {
  font-size: 0.9375rem;
}

.chatbox .card-header p {
  line-height: 1.2;
  font-size: 0.75rem;
  color: #717579;
}

.chatbox .chat-list-header {
  justify-content: space-between;
  background: var(--card);
}

.chatbox .chat-list-header a {
  text-align: center;
  width: 1.875rem;
  height: 1.875rem;
  background: #e7e7e7;
  border-radius: 0.375rem;
  line-height: 1.875rem;
  display: block;
}

[data-theme-version="dark"] .chatbox .chat-list-header a {
  background: var(--rgba-primary-1);
}

[data-theme-version="dark"] .chatbox .chat-list-header a svg g [fill] {
  fill: #fff;
}

.chatbox .img_cont_msg {
  width: 1.875rem;
  height: 1.875rem;
  display: block;
  max-width: 1.875rem;
  min-width: 1.875rem;
}

.chatbox .img_cont_msg img {
  width: 100%;
}

.chatbox .msg_cotainer {
  background: var(--primary);
  margin-left: 0.625rem;
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  padding: 0.625rem 0.9375rem;
  color: #fff;
  position: relative;
}

.chatbox .msg_cotainer .msg_time {
  display: block;
  font-size: 0.6875rem;
  color: #fff;
  margin-top: 0.3125rem;
  opacity: 0.5;
}

.chatbox .msg_cotainer:after {
  content: "";
  position: absolute;
  left: -0.625rem;
  border-right: 0.625rem solid var(--primary);
  border-bottom: 0.625rem solid transparent;
  border-top: 0rem solid;
  top: 0;
}

.chatbox .msg_cotainer_send {
  background: var(--body-bg);
  padding: 0.625rem 0.9375rem;
  border-radius: 0.375rem 0rem 0.375rem 0.375rem;
  margin-right: 0.625rem;
  color: var(--text-dark);
  position: relative;
  text-align: left;
}

.chatbox .msg_cotainer_send .msg_time_send {
  display: block;
  font-size: 0.6875rem;
  text-align: right;
  margin-top: 0.3125rem;
  opacity: 0.6;
}

.chatbox .msg_cotainer_send:after {
  content: "";
  position: absolute;
  right: -0.625rem;
  border-left: 0.625rem solid var(--body-bg);
  border-bottom: 0.625rem solid transparent;
  border-top: 0rem solid;
  top: 0;
}

.chatbox .type_msg .form-control {
  padding: 0.625rem 14px;
  height: 3.125rem;
  border: 0;
  resize: none;
}

.chatbox .type_msg .form-control:focus {
  z-index: 0;
}

.chatbox .type_msg .btn {
  font-size: 1.125rem;
  border-radius: 2.375rem !important;
  width: 2.375rem;
  height: 2.375rem;
  padding: 0;
  margin-top: 0.375rem;
}

.chatbox .video_cam {
  margin-left: 0.9375rem;
}

.chatbox .video_cam span {
  width: 2.1875rem;
  height: 2.1875rem;
  background: #10ca93;
  text-align: center;
  line-height: 2.1875rem;
  border-radius: 2.1875rem;
  color: #fff;
  margin-right: 0.3125rem;
  align-self: center;
  font-size: 1rem;
  padding: 0 0.1875rem;
  display: inline-block;
}

.chatbox .note_card .contacts li {
  padding: 0.75rem 1rem;
}

@media only screen and (max-width: 36rem) {
  .chatbox {
    width: 17.5rem;
  }

  .chatbox .chatbox-close {
    right: 17.5rem;
  }
}

.dlab-demo-panel {
  right: -100%;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  transition: all 0.5s ease-in-out;
  z-index: 9999;
}

.dlab-demo-panel .dlab-demo-trigger {
  position: absolute;
  z-index: 9;
  top: 21.75rem;
  right: 100%;
  background-color: #3693ff;
  color: #fff;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  text-align: center;
  font-size: 1.75rem;
  line-height: 3rem;
  border-radius: 0;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  box-shadow: -5px 3px 5px 0px rgba(119, 119, 119, 0.15);
}

@media only screen and (max-width: 1023px) {
  .dlab-demo-panel .dlab-demo-trigger {
    display: none;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .dlab-demo-panel .dlab-demo-trigger {
    height: 35px;
    width: 35px;
    line-height: 35px;
    font-size: 22px;
  }
}

.dlab-demo-panel .dlab-demo-close {
  height: 30px;
  color: #000;
  width: 30px;
  border-radius: 0.5rem;
  background: #fff;
  line-height: 30px;
  text-align: center;
}

.dlab-demo-panel .dlab-demo-close:hover {
  color: #000;
}

.dlab-demo-panel.show {
  right: 0;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.2);
  z-index: 99999;
  overflow: hidden;
}

.dlab-demo-panel.show .sidebar-right-trigger {
  display: none;
}

.dlab-demo-panel.show .bg-close {
  position: fixed;
  z-index: -2;
  cursor: pointer;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}

.dlab-demo-panel .dlab-demo-inner {
  padding: 30px 30px;
  background: #331e1e9e;
}

.dlab-demo-panel .dlab-demo-content {
  height: calc(100vh - 200px);
  padding: 0 15px;
  margin: 0 -15px;
  overflow-y: auto;
}

.dlab-demo-panel .dlab-demo-content .text-black {
  color: #000 !important;
}

.dlab-demo-panel .dlab-demo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.dlab-demo-panel .dlab-demo-header h4,
.dlab-demo-panel .dlab-demo-header .h4 {
  margin-bottom: 0;
  color: #000 !important;
}

.dlab-demo-panel .dlab-demo-bx {
  height: 360px;
  overflow: hidden;
  border: 2px solid #efefef;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  border-radius: 5px;
}

.dlab-demo-panel .dlab-demo-bx.demo-active {
  border-color: #3693ff;
}

.dlab-demo-panel .dlab-demo-bx.demo-active .overlay-layer {
  opacity: 1;
}

.dlab-demo-panel .overlay-bx {
  position: relative;
}

.dlab-demo-panel .overlay-bx .overlay-layer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 0;
}

.dlab-demo-panel .overlay-bx:hover .overlay-layer {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 1;
}

@media only screen and (max-width: 35.9375rem) {
  .dlab-demo-panel .dlab-demo-bx {
    height: 230px;
  }
}

.wallet-open .header .page-titles,
.wallet-open .header .header-content {
  padding-right: 40px;
  transition: all 0.5s;
}

@media only screen and (max-width: 35.9375rem) {

  .wallet-open .header .page-titles,
  .wallet-open .header .header-content {
    padding-right: 12px;
  }
}

.wallet-open .content-body {
  padding-right: 0;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.wallet-open .wallet-bar {
  right: -26.25rem;
}

.wallet-open.active .header .page-titles,
.wallet-open.active .header .header-content {
  padding-right: 25.75rem;
  transition: all ease 0.5s;
}

@media only screen and (max-width: 106.25rem) {

  .wallet-open.active .header .page-titles,
  .wallet-open.active .header .header-content {
    padding-right: 40px;
  }
}

@media only screen and (max-width: 35.9375rem) {

  .wallet-open.active .header .page-titles,
  .wallet-open.active .header .header-content {
    padding-right: 0;
  }
}

.wallet-open.active .content-body {
  padding-right: calc(25.75rem - 40px);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

@media only screen and (max-width: 106.25rem) {
  .wallet-open.active .content-body {
    padding-right: 0;
  }
}

.wallet-open.active .wallet-bar {
  right: 0rem;
}

.wallet-open.active .wallet-bar-close {
  display: none;
}

@media only screen and (max-width: 100rem) {
  .wallet-open.active .wallet-bar-close {
    display: block;
    position: fixed;
    width: 100%;
    cursor: pointer;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 7;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .wallet-open.active .wallet-bar-close {
    display: block;
    z-index: 1;
  }
}

.header-banner {
  width: 100%;
  height: 286px;
  background: var(--headerbg);
  background-blend-mode: luminosity;
  background-size: cover;
  background-position: top;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
}

@media only screen and (max-width: 100.063rem) {
  .header-banner {
    height: 262px;
  }
}

@media only screen and (max-width: 87.5rem) {
  .header-banner {
    height: 267px;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .header-banner {
    height: 260px;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .header-banner {
    height: 200px;
  }
}

.wallet-bar {
  position: fixed;
  box-sizing: content-box;
  width: 21.875rem;
  padding: 1.875rem 25px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  top: -12px;
  z-index: 10;
  height: 100vh;
  scrollbar-width: none;
}

@media only screen and (max-width: 47.9375rem) {
  .wallet-bar {
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(5px);
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.25);
    height: 100vh;
    overflow-y: scroll;
  }
}

.wallet-bar .closed-icon {
  height: 25px;
  width: 25px;
  color: white;
  font-size: 20px;
  display: block;
  margin-bottom: 17px;
  cursor: pointer;
  display: none;
}

@media only screen and (max-width: 35.9375rem) {
  .wallet-bar .closed-icon {
    display: block;
  }
}

:root {
  --nav-headbg: #ffffff;
  --sidebar-bg: #ffffff;
  --headerbg: #3736af;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"],
[data-nav-headerbg="color_2"] {
  --nav-headbg: #6610f2;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_2"] .nav-header {
  border-color: rgba(255, 255, 255, 0.5);
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header .brand-logo .brand-title svg path,
[data-nav-headerbg="color_2"] .nav-header .brand-logo .brand-title svg path {
  fill: #fff;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path,
[data-nav-headerbg="color_2"] .nav-header .brand-logo .logo-abbr path {
  fill: #fff;
  stroke: #fff;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_2"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .header-left .input-group.search-area .form-control,
[data-nav-headerbg="color_2"] .header-left .input-group.search-area .form-control {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  color: #fff;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .header-left .input-group.search-area .form-control::placeholder,
[data-nav-headerbg="color_2"] .header-left .input-group.search-area .form-control::placeholder {
  color: #fff;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text,
[data-nav-headerbg="color_2"] .header-left .input-group.search-area .input-group-text {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  border-left: 0;
  margin-left: 0px !important;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text .svg-main-icon path,
[data-nav-headerbg="color_2"] .header-left .input-group.search-area .input-group-text .svg-main-icon path {
  fill: #fff;
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-nav-headerbg="color_2"] .header-right .notification_dropdown .nav-link {
  background-color: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_2"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
  [data-nav-headerbg="color_2"] .header-right .notification_dropdown .nav-link {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-control,
[data-nav-headerbg="color_2"] .nav-control {
  background: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_2"][data-theme-version="dark"] .nav-control,
  [data-nav-headerbg="color_2"] .nav-control {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_2"][data-theme-version="dark"] .breadcrumb .breadcrumb-item svg path,
[data-nav-headerbg="color_2"] .breadcrumb .breadcrumb-item svg path {
  fill: #fff;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"],
[data-nav-headerbg="color_3"] {
  --nav-headbg: #3736af;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_3"] .nav-header {
  border-color: rgba(255, 255, 255, 0.5);
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header .brand-logo .brand-title svg path,
[data-nav-headerbg="color_3"] .nav-header .brand-logo .brand-title svg path {
  fill: #fff;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path,
[data-nav-headerbg="color_3"] .nav-header .brand-logo .logo-abbr path {
  fill: #fff;
  stroke: #fff;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_3"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .header-left .input-group.search-area .form-control,
[data-nav-headerbg="color_3"] .header-left .input-group.search-area .form-control {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  color: #fff;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .header-left .input-group.search-area .form-control::placeholder,
[data-nav-headerbg="color_3"] .header-left .input-group.search-area .form-control::placeholder {
  color: #fff;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text,
[data-nav-headerbg="color_3"] .header-left .input-group.search-area .input-group-text {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  border-left: 0;
  margin-left: 0px !important;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text .svg-main-icon path,
[data-nav-headerbg="color_3"] .header-left .input-group.search-area .input-group-text .svg-main-icon path {
  fill: #fff;
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-nav-headerbg="color_3"] .header-right .notification_dropdown .nav-link {
  background-color: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_3"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
  [data-nav-headerbg="color_3"] .header-right .notification_dropdown .nav-link {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-control,
[data-nav-headerbg="color_3"] .nav-control {
  background: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_3"][data-theme-version="dark"] .nav-control,
  [data-nav-headerbg="color_3"] .nav-control {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_3"][data-theme-version="dark"] .breadcrumb .breadcrumb-item svg path,
[data-nav-headerbg="color_3"] .breadcrumb .breadcrumb-item svg path {
  fill: #fff;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"],
[data-nav-headerbg="color_4"] {
  --nav-headbg: #4d06a5;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_4"] .nav-header {
  border-color: rgba(255, 255, 255, 0.5);
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header .brand-logo .brand-title svg path,
[data-nav-headerbg="color_4"] .nav-header .brand-logo .brand-title svg path {
  fill: #fff;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path,
[data-nav-headerbg="color_4"] .nav-header .brand-logo .logo-abbr path {
  fill: #fff;
  stroke: #fff;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_4"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .header-left .input-group.search-area .form-control,
[data-nav-headerbg="color_4"] .header-left .input-group.search-area .form-control {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  color: #fff;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .header-left .input-group.search-area .form-control::placeholder,
[data-nav-headerbg="color_4"] .header-left .input-group.search-area .form-control::placeholder {
  color: #fff;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text,
[data-nav-headerbg="color_4"] .header-left .input-group.search-area .input-group-text {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  border-left: 0;
  margin-left: 0px !important;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text .svg-main-icon path,
[data-nav-headerbg="color_4"] .header-left .input-group.search-area .input-group-text .svg-main-icon path {
  fill: #fff;
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-nav-headerbg="color_4"] .header-right .notification_dropdown .nav-link {
  background-color: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_4"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
  [data-nav-headerbg="color_4"] .header-right .notification_dropdown .nav-link {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-control,
[data-nav-headerbg="color_4"] .nav-control {
  background: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_4"][data-theme-version="dark"] .nav-control,
  [data-nav-headerbg="color_4"] .nav-control {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_4"][data-theme-version="dark"] .breadcrumb .breadcrumb-item svg path,
[data-nav-headerbg="color_4"] .breadcrumb .breadcrumb-item svg path {
  fill: #fff;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"],
[data-nav-headerbg="color_5"] {
  --nav-headbg: #3250ff;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_5"] .nav-header {
  border-color: rgba(255, 255, 255, 0.5);
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header .brand-logo .brand-title svg path,
[data-nav-headerbg="color_5"] .nav-header .brand-logo .brand-title svg path {
  fill: #fff;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path,
[data-nav-headerbg="color_5"] .nav-header .brand-logo .logo-abbr path {
  fill: #fff;
  stroke: #fff;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_5"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .header-left .input-group.search-area .form-control,
[data-nav-headerbg="color_5"] .header-left .input-group.search-area .form-control {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  color: #fff;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .header-left .input-group.search-area .form-control::placeholder,
[data-nav-headerbg="color_5"] .header-left .input-group.search-area .form-control::placeholder {
  color: #fff;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text,
[data-nav-headerbg="color_5"] .header-left .input-group.search-area .input-group-text {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  border-left: 0;
  margin-left: 0px !important;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text .svg-main-icon path,
[data-nav-headerbg="color_5"] .header-left .input-group.search-area .input-group-text .svg-main-icon path {
  fill: #fff;
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-nav-headerbg="color_5"] .header-right .notification_dropdown .nav-link {
  background-color: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_5"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
  [data-nav-headerbg="color_5"] .header-right .notification_dropdown .nav-link {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-control,
[data-nav-headerbg="color_5"] .nav-control {
  background: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_5"][data-theme-version="dark"] .nav-control,
  [data-nav-headerbg="color_5"] .nav-control {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_5"][data-theme-version="dark"] .breadcrumb .breadcrumb-item svg path,
[data-nav-headerbg="color_5"] .breadcrumb .breadcrumb-item svg path {
  fill: #fff;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"],
[data-nav-headerbg="color_6"] {
  --nav-headbg: #3f8cff;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_6"] .nav-header {
  border-color: rgba(255, 255, 255, 0.5);
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header .brand-logo .brand-title svg path,
[data-nav-headerbg="color_6"] .nav-header .brand-logo .brand-title svg path {
  fill: #fff;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path,
[data-nav-headerbg="color_6"] .nav-header .brand-logo .logo-abbr path {
  fill: #fff;
  stroke: #fff;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_6"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .header-left .input-group.search-area .form-control,
[data-nav-headerbg="color_6"] .header-left .input-group.search-area .form-control {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  color: #fff;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .header-left .input-group.search-area .form-control::placeholder,
[data-nav-headerbg="color_6"] .header-left .input-group.search-area .form-control::placeholder {
  color: #fff;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text,
[data-nav-headerbg="color_6"] .header-left .input-group.search-area .input-group-text {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  border-left: 0;
  margin-left: 0px !important;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text .svg-main-icon path,
[data-nav-headerbg="color_6"] .header-left .input-group.search-area .input-group-text .svg-main-icon path {
  fill: #fff;
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-nav-headerbg="color_6"] .header-right .notification_dropdown .nav-link {
  background-color: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_6"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
  [data-nav-headerbg="color_6"] .header-right .notification_dropdown .nav-link {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-control,
[data-nav-headerbg="color_6"] .nav-control {
  background: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_6"][data-theme-version="dark"] .nav-control,
  [data-nav-headerbg="color_6"] .nav-control {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_6"][data-theme-version="dark"] .breadcrumb .breadcrumb-item svg path,
[data-nav-headerbg="color_6"] .breadcrumb .breadcrumb-item svg path {
  fill: #fff;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"],
[data-nav-headerbg="color_7"] {
  --nav-headbg: #40a7e2;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_7"] .nav-header {
  border-color: rgba(255, 255, 255, 0.5);
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header .brand-logo .brand-title svg path,
[data-nav-headerbg="color_7"] .nav-header .brand-logo .brand-title svg path {
  fill: #fff;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path,
[data-nav-headerbg="color_7"] .nav-header .brand-logo .logo-abbr path {
  fill: #fff;
  stroke: #fff;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_7"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .header-left .input-group.search-area .form-control,
[data-nav-headerbg="color_7"] .header-left .input-group.search-area .form-control {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  color: #fff;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .header-left .input-group.search-area .form-control::placeholder,
[data-nav-headerbg="color_7"] .header-left .input-group.search-area .form-control::placeholder {
  color: #fff;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text,
[data-nav-headerbg="color_7"] .header-left .input-group.search-area .input-group-text {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  border-left: 0;
  margin-left: 0px !important;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text .svg-main-icon path,
[data-nav-headerbg="color_7"] .header-left .input-group.search-area .input-group-text .svg-main-icon path {
  fill: #fff;
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-nav-headerbg="color_7"] .header-right .notification_dropdown .nav-link {
  background-color: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_7"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
  [data-nav-headerbg="color_7"] .header-right .notification_dropdown .nav-link {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-control,
[data-nav-headerbg="color_7"] .nav-control {
  background: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_7"][data-theme-version="dark"] .nav-control,
  [data-nav-headerbg="color_7"] .nav-control {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_7"][data-theme-version="dark"] .breadcrumb .breadcrumb-item svg path,
[data-nav-headerbg="color_7"] .breadcrumb .breadcrumb-item svg path {
  fill: #fff;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"],
[data-nav-headerbg="color_8"] {
  --nav-headbg: #b65fbd;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_8"] .nav-header {
  border-color: rgba(255, 255, 255, 0.5);
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header .brand-logo .brand-title svg path,
[data-nav-headerbg="color_8"] .nav-header .brand-logo .brand-title svg path {
  fill: #fff;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path,
[data-nav-headerbg="color_8"] .nav-header .brand-logo .logo-abbr path {
  fill: #fff;
  stroke: #fff;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_8"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .header-left .input-group.search-area .form-control,
[data-nav-headerbg="color_8"] .header-left .input-group.search-area .form-control {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  color: #fff;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .header-left .input-group.search-area .form-control::placeholder,
[data-nav-headerbg="color_8"] .header-left .input-group.search-area .form-control::placeholder {
  color: #fff;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text,
[data-nav-headerbg="color_8"] .header-left .input-group.search-area .input-group-text {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  border-left: 0;
  margin-left: 0px !important;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text .svg-main-icon path,
[data-nav-headerbg="color_8"] .header-left .input-group.search-area .input-group-text .svg-main-icon path {
  fill: #fff;
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-nav-headerbg="color_8"] .header-right .notification_dropdown .nav-link {
  background-color: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_8"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
  [data-nav-headerbg="color_8"] .header-right .notification_dropdown .nav-link {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-control,
[data-nav-headerbg="color_8"] .nav-control {
  background: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_8"][data-theme-version="dark"] .nav-control,
  [data-nav-headerbg="color_8"] .nav-control {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_8"][data-theme-version="dark"] .breadcrumb .breadcrumb-item svg path,
[data-nav-headerbg="color_8"] .breadcrumb .breadcrumb-item svg path {
  fill: #fff;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"],
[data-nav-headerbg="color_9"] {
  --nav-headbg: #88a67e;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_9"] .nav-header {
  border-color: rgba(255, 255, 255, 0.5);
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header .brand-logo .brand-title svg path,
[data-nav-headerbg="color_9"] .nav-header .brand-logo .brand-title svg path {
  fill: #fff;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path,
[data-nav-headerbg="color_9"] .nav-header .brand-logo .logo-abbr path {
  fill: #fff;
  stroke: #fff;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_9"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .header-left .input-group.search-area .form-control,
[data-nav-headerbg="color_9"] .header-left .input-group.search-area .form-control {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  color: #fff;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .header-left .input-group.search-area .form-control::placeholder,
[data-nav-headerbg="color_9"] .header-left .input-group.search-area .form-control::placeholder {
  color: #fff;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text,
[data-nav-headerbg="color_9"] .header-left .input-group.search-area .input-group-text {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  border-left: 0;
  margin-left: 0px !important;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text .svg-main-icon path,
[data-nav-headerbg="color_9"] .header-left .input-group.search-area .input-group-text .svg-main-icon path {
  fill: #fff;
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-nav-headerbg="color_9"] .header-right .notification_dropdown .nav-link {
  background-color: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_9"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
  [data-nav-headerbg="color_9"] .header-right .notification_dropdown .nav-link {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-control,
[data-nav-headerbg="color_9"] .nav-control {
  background: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_9"][data-theme-version="dark"] .nav-control,
  [data-nav-headerbg="color_9"] .nav-control {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_9"][data-theme-version="dark"] .breadcrumb .breadcrumb-item svg path,
[data-nav-headerbg="color_9"] .breadcrumb .breadcrumb-item svg path {
  fill: #fff;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"],
[data-nav-headerbg="color_10"] {
  --nav-headbg: #1c2e9e;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_10"] .nav-header {
  border-color: rgba(255, 255, 255, 0.5);
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header .brand-logo .brand-title svg path,
[data-nav-headerbg="color_10"] .nav-header .brand-logo .brand-title svg path {
  fill: #fff;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path,
[data-nav-headerbg="color_10"] .nav-header .brand-logo .logo-abbr path {
  fill: #fff;
  stroke: #fff;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_10"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .header-left .input-group.search-area .form-control,
[data-nav-headerbg="color_10"] .header-left .input-group.search-area .form-control {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  color: #fff;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .header-left .input-group.search-area .form-control::placeholder,
[data-nav-headerbg="color_10"] .header-left .input-group.search-area .form-control::placeholder {
  color: #fff;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text,
[data-nav-headerbg="color_10"] .header-left .input-group.search-area .input-group-text {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  border-left: 0;
  margin-left: 0px !important;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text .svg-main-icon path,
[data-nav-headerbg="color_10"] .header-left .input-group.search-area .input-group-text .svg-main-icon path {
  fill: #fff;
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-nav-headerbg="color_10"] .header-right .notification_dropdown .nav-link {
  background-color: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_10"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
  [data-nav-headerbg="color_10"] .header-right .notification_dropdown .nav-link {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-control,
[data-nav-headerbg="color_10"] .nav-control {
  background: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_10"][data-theme-version="dark"] .nav-control,
  [data-nav-headerbg="color_10"] .nav-control {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_10"][data-theme-version="dark"] .breadcrumb .breadcrumb-item svg path,
[data-nav-headerbg="color_10"] .breadcrumb .breadcrumb-item svg path {
  fill: #fff;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"],
[data-nav-headerbg="color_11"] {
  --nav-headbg: #c6164f;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_11"] .nav-header {
  border-color: rgba(255, 255, 255, 0.5);
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header .brand-logo .brand-title svg path,
[data-nav-headerbg="color_11"] .nav-header .brand-logo .brand-title svg path {
  fill: #fff;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path,
[data-nav-headerbg="color_11"] .nav-header .brand-logo .logo-abbr path {
  fill: #fff;
  stroke: #fff;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_11"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .header-left .input-group.search-area .form-control,
[data-nav-headerbg="color_11"] .header-left .input-group.search-area .form-control {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  color: #fff;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .header-left .input-group.search-area .form-control::placeholder,
[data-nav-headerbg="color_11"] .header-left .input-group.search-area .form-control::placeholder {
  color: #fff;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text,
[data-nav-headerbg="color_11"] .header-left .input-group.search-area .input-group-text {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  border-left: 0;
  margin-left: 0px !important;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text .svg-main-icon path,
[data-nav-headerbg="color_11"] .header-left .input-group.search-area .input-group-text .svg-main-icon path {
  fill: #fff;
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-nav-headerbg="color_11"] .header-right .notification_dropdown .nav-link {
  background-color: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_11"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
  [data-nav-headerbg="color_11"] .header-right .notification_dropdown .nav-link {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-control,
[data-nav-headerbg="color_11"] .nav-control {
  background: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_11"][data-theme-version="dark"] .nav-control,
  [data-nav-headerbg="color_11"] .nav-control {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_11"][data-theme-version="dark"] .breadcrumb .breadcrumb-item svg path,
[data-nav-headerbg="color_11"] .breadcrumb .breadcrumb-item svg path {
  fill: #fff;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"],
[data-nav-headerbg="color_12"] {
  --nav-headbg: #343a40;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_12"] .nav-header {
  border-color: rgba(255, 255, 255, 0.5);
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header .brand-logo .brand-title svg path,
[data-nav-headerbg="color_12"] .nav-header .brand-logo .brand-title svg path {
  fill: #fff;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path,
[data-nav-headerbg="color_12"] .nav-header .brand-logo .logo-abbr path {
  fill: #fff;
  stroke: #fff;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_12"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .header-left .input-group.search-area .form-control,
[data-nav-headerbg="color_12"] .header-left .input-group.search-area .form-control {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  color: #fff;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .header-left .input-group.search-area .form-control::placeholder,
[data-nav-headerbg="color_12"] .header-left .input-group.search-area .form-control::placeholder {
  color: #fff;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text,
[data-nav-headerbg="color_12"] .header-left .input-group.search-area .input-group-text {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  border-left: 0;
  margin-left: 0px !important;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text .svg-main-icon path,
[data-nav-headerbg="color_12"] .header-left .input-group.search-area .input-group-text .svg-main-icon path {
  fill: #fff;
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-nav-headerbg="color_12"] .header-right .notification_dropdown .nav-link {
  background-color: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_12"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
  [data-nav-headerbg="color_12"] .header-right .notification_dropdown .nav-link {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-control,
[data-nav-headerbg="color_12"] .nav-control {
  background: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_12"][data-theme-version="dark"] .nav-control,
  [data-nav-headerbg="color_12"] .nav-control {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_12"][data-theme-version="dark"] .breadcrumb .breadcrumb-item svg path,
[data-nav-headerbg="color_12"] .breadcrumb .breadcrumb-item svg path {
  fill: #fff;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"],
[data-nav-headerbg="color_13"] {
  --nav-headbg: #056468;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_13"] .nav-header {
  border-color: rgba(255, 255, 255, 0.5);
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header .brand-logo .brand-title svg path,
[data-nav-headerbg="color_13"] .nav-header .brand-logo .brand-title svg path {
  fill: #fff;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path,
[data-nav-headerbg="color_13"] .nav-header .brand-logo .logo-abbr path {
  fill: #fff;
  stroke: #fff;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_13"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .header-left .input-group.search-area .form-control,
[data-nav-headerbg="color_13"] .header-left .input-group.search-area .form-control {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  color: #fff;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .header-left .input-group.search-area .form-control::placeholder,
[data-nav-headerbg="color_13"] .header-left .input-group.search-area .form-control::placeholder {
  color: #fff;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text,
[data-nav-headerbg="color_13"] .header-left .input-group.search-area .input-group-text {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  border-left: 0;
  margin-left: 0px !important;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text .svg-main-icon path,
[data-nav-headerbg="color_13"] .header-left .input-group.search-area .input-group-text .svg-main-icon path {
  fill: #fff;
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-nav-headerbg="color_13"] .header-right .notification_dropdown .nav-link {
  background-color: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_13"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
  [data-nav-headerbg="color_13"] .header-right .notification_dropdown .nav-link {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-control,
[data-nav-headerbg="color_13"] .nav-control {
  background: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_13"][data-theme-version="dark"] .nav-control,
  [data-nav-headerbg="color_13"] .nav-control {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_13"][data-theme-version="dark"] .breadcrumb .breadcrumb-item svg path,
[data-nav-headerbg="color_13"] .breadcrumb .breadcrumb-item svg path {
  fill: #fff;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"],
[data-nav-headerbg="color_14"] {
  --nav-headbg: #4885ed;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_14"] .nav-header {
  border-color: rgba(255, 255, 255, 0.5);
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .nav-header .brand-logo .brand-title svg path,
[data-nav-headerbg="color_14"] .nav-header .brand-logo .brand-title svg path {
  fill: #fff;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path,
[data-nav-headerbg="color_14"] .nav-header .brand-logo .logo-abbr path {
  fill: #fff;
  stroke: #fff;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_14"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .header-left .input-group.search-area .form-control,
[data-nav-headerbg="color_14"] .header-left .input-group.search-area .form-control {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  color: #fff;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .header-left .input-group.search-area .form-control::placeholder,
[data-nav-headerbg="color_14"] .header-left .input-group.search-area .form-control::placeholder {
  color: #fff;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text,
[data-nav-headerbg="color_14"] .header-left .input-group.search-area .input-group-text {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  border-left: 0;
  margin-left: 0px !important;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text .svg-main-icon path,
[data-nav-headerbg="color_14"] .header-left .input-group.search-area .input-group-text .svg-main-icon path {
  fill: #fff;
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-nav-headerbg="color_14"] .header-right .notification_dropdown .nav-link {
  background-color: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_14"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
  [data-nav-headerbg="color_14"] .header-right .notification_dropdown .nav-link {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .nav-control,
[data-nav-headerbg="color_14"] .nav-control {
  background: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_14"][data-theme-version="dark"] .nav-control,
  [data-nav-headerbg="color_14"] .nav-control {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_14"][data-theme-version="dark"] .breadcrumb .breadcrumb-item svg path,
[data-nav-headerbg="color_14"] .breadcrumb .breadcrumb-item svg path {
  fill: #fff;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"],
[data-nav-headerbg="color_15"] {
  --nav-headbg: #4cb32b;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .nav-header,
[data-nav-headerbg="color_15"] .nav-header {
  border-color: rgba(255, 255, 255, 0.5);
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .nav-header .brand-logo .brand-title svg path,
[data-nav-headerbg="color_15"] .nav-header .brand-logo .brand-title svg path {
  fill: #fff;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .nav-header .brand-logo .logo-abbr path,
[data-nav-headerbg="color_15"] .nav-header .brand-logo .logo-abbr path {
  fill: #fff;
  stroke: #fff;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .nav-header .hamburger .line,
[data-nav-headerbg="color_15"] .nav-header .hamburger .line {
  background: #fff;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .header-left .input-group.search-area .form-control,
[data-nav-headerbg="color_15"] .header-left .input-group.search-area .form-control {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  color: #fff;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .header-left .input-group.search-area .form-control::placeholder,
[data-nav-headerbg="color_15"] .header-left .input-group.search-area .form-control::placeholder {
  color: #fff;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text,
[data-nav-headerbg="color_15"] .header-left .input-group.search-area .input-group-text {
  background-color: rgba(255, 255, 255, 0.12);
  border-color: rgba(255, 255, 255, 0.12);
  border-left: 0;
  margin-left: 0px !important;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .header-left .input-group.search-area .input-group-text .svg-main-icon path,
[data-nav-headerbg="color_15"] .header-left .input-group.search-area .input-group-text .svg-main-icon path {
  fill: #fff;
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
[data-nav-headerbg="color_15"] .header-right .notification_dropdown .nav-link {
  background-color: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_15"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link,
  [data-nav-headerbg="color_15"] .header-right .notification_dropdown .nav-link {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .nav-control,
[data-nav-headerbg="color_15"] .nav-control {
  background: rgba(255, 255, 255, 0.12);
}

@media only screen and (max-width: 35.9375rem) {

  [data-nav-headerbg="color_15"][data-theme-version="dark"] .nav-control,
  [data-nav-headerbg="color_15"] .nav-control {
    background-color: transparent;
  }
}

[data-nav-headerbg="color_15"][data-theme-version="dark"] .breadcrumb .breadcrumb-item svg path,
[data-nav-headerbg="color_15"] .breadcrumb .breadcrumb-item svg path {
  fill: #fff;
}

[data-sidebarbg="color_2"][data-theme-version="dark"],
[data-sidebarbg="color_2"] {
  --sidebar-bg: #6610f2;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .dlabnav .main-menu,
[data-sidebarbg="color_2"] .dlabnav .main-menu {
  color: #fff;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .feature-box .wallet-box,
[data-sidebarbg="color_2"] .feature-box .wallet-box {
  background-color: #8540f5;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .feature-box .item-1:hover,
[data-sidebarbg="color_2"] .feature-box .item-1:hover {
  background: #8540f5;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_2"][data-theme-version="dark"] .feature-box small,
[data-sidebarbg="color_2"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_2"] .feature-box .small,
[data-sidebarbg="color_2"] .feature-box small,
[data-sidebarbg="color_2"] .feature-box .small {
  color: #fff;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sidebarbg="color_2"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #6610f2 !important;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sidebarbg="color_2"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .dlabnav .header-info2,
[data-sidebarbg="color_2"] .dlabnav .header-info2 {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .menu-scroll .copyright strong,
[data-sidebarbg="color_2"] .menu-scroll .copyright strong {
  color: #fff;
}

[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_2"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_2"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_2"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_2"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_2"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_2"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #8540f5;
}

[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_2"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_2"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_2"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_2"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_2"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sidebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sidebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #6f1ff3 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_2"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sidebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_2"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #8540f5 !important;
  color: #fff !important;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sidebarbg="color_2"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_2"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_2"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sidebarbg="color_2"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_2"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_2"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sidebarbg="color_2"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sidebarbg="color_2"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_2"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_2"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_2"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sidebarbg="color_2"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sidebarbg="color_2"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sidebarbg="color_2"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sidebarbg="color_2"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_2"] .plus-box {
  background-color: #8540f5;
}

[data-sidebarbg="color_2"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_2"] .copyright {
  color: #fff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"],
[data-sidebarbg="color_3"] {
  --sidebar-bg: #3736af;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .dlabnav .main-menu,
[data-sidebarbg="color_3"] .dlabnav .main-menu {
  color: #fff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .feature-box .wallet-box,
[data-sidebarbg="color_3"] .feature-box .wallet-box {
  background-color: #504fc9;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .feature-box .item-1:hover,
[data-sidebarbg="color_3"] .feature-box .item-1:hover {
  background: #504fc9;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_3"][data-theme-version="dark"] .feature-box small,
[data-sidebarbg="color_3"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_3"] .feature-box .small,
[data-sidebarbg="color_3"] .feature-box small,
[data-sidebarbg="color_3"] .feature-box .small {
  color: #fff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sidebarbg="color_3"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #3736af !important;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sidebarbg="color_3"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .dlabnav .header-info2,
[data-sidebarbg="color_3"] .dlabnav .header-info2 {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .menu-scroll .copyright strong,
[data-sidebarbg="color_3"] .menu-scroll .copyright strong {
  color: #fff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_3"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_3"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_3"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_3"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_3"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_3"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #504fc9;
}

[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_3"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_3"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_3"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_3"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_3"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sidebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sidebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #3b3abb !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_3"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sidebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_3"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #504fc9 !important;
  color: #fff !important;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sidebarbg="color_3"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_3"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_3"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sidebarbg="color_3"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_3"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_3"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sidebarbg="color_3"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sidebarbg="color_3"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_3"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_3"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_3"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sidebarbg="color_3"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sidebarbg="color_3"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sidebarbg="color_3"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sidebarbg="color_3"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_3"] .plus-box {
  background-color: #504fc9;
}

[data-sidebarbg="color_3"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_3"] .copyright {
  color: #fff;
}

[data-sidebarbg="color_4"][data-theme-version="dark"],
[data-sidebarbg="color_4"] {
  --sidebar-bg: #4d06a5;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .dlabnav .main-menu,
[data-sidebarbg="color_4"] .dlabnav .main-menu {
  color: #fff;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .feature-box .wallet-box,
[data-sidebarbg="color_4"] .feature-box .wallet-box {
  background-color: #6408d6;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .feature-box .item-1:hover,
[data-sidebarbg="color_4"] .feature-box .item-1:hover {
  background: #6408d6;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_4"][data-theme-version="dark"] .feature-box small,
[data-sidebarbg="color_4"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_4"] .feature-box .small,
[data-sidebarbg="color_4"] .feature-box small,
[data-sidebarbg="color_4"] .feature-box .small {
  color: #fff;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sidebarbg="color_4"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #4d06a5 !important;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sidebarbg="color_4"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .dlabnav .header-info2,
[data-sidebarbg="color_4"] .dlabnav .header-info2 {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .menu-scroll .copyright strong,
[data-sidebarbg="color_4"] .menu-scroll .copyright strong {
  color: #fff;
}

[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_4"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_4"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_4"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_4"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_4"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_4"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #6408d6;
}

[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_4"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_4"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_4"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_4"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_4"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sidebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sidebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #5407b4 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_4"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sidebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_4"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #6408d6 !important;
  color: #fff !important;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sidebarbg="color_4"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_4"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_4"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sidebarbg="color_4"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_4"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_4"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sidebarbg="color_4"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sidebarbg="color_4"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_4"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_4"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_4"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sidebarbg="color_4"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sidebarbg="color_4"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sidebarbg="color_4"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sidebarbg="color_4"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_4"] .plus-box {
  background-color: #6408d6;
}

[data-sidebarbg="color_4"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_4"] .copyright {
  color: #fff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"],
[data-sidebarbg="color_5"] {
  --sidebar-bg: #3250ff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .dlabnav .main-menu,
[data-sidebarbg="color_5"] .dlabnav .main-menu {
  color: #fff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .feature-box .wallet-box,
[data-sidebarbg="color_5"] .feature-box .wallet-box {
  background-color: #657cff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .feature-box .item-1:hover,
[data-sidebarbg="color_5"] .feature-box .item-1:hover {
  background: #657cff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_5"][data-theme-version="dark"] .feature-box small,
[data-sidebarbg="color_5"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_5"] .feature-box .small,
[data-sidebarbg="color_5"] .feature-box small,
[data-sidebarbg="color_5"] .feature-box .small {
  color: #fff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sidebarbg="color_5"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #3250ff !important;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sidebarbg="color_5"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .dlabnav .header-info2,
[data-sidebarbg="color_5"] .dlabnav .header-info2 {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .menu-scroll .copyright strong,
[data-sidebarbg="color_5"] .menu-scroll .copyright strong {
  color: #fff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_5"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_5"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_5"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_5"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_5"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_5"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #657cff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_5"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_5"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_5"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_5"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_5"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sidebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sidebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #415dff !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_5"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sidebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_5"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #657cff !important;
  color: #fff !important;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sidebarbg="color_5"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_5"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_5"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sidebarbg="color_5"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_5"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_5"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sidebarbg="color_5"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sidebarbg="color_5"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_5"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_5"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_5"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sidebarbg="color_5"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sidebarbg="color_5"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sidebarbg="color_5"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sidebarbg="color_5"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_5"] .plus-box {
  background-color: #657cff;
}

[data-sidebarbg="color_5"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_5"] .copyright {
  color: #fff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"],
[data-sidebarbg="color_6"] {
  --sidebar-bg: #3f8cff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .dlabnav .main-menu,
[data-sidebarbg="color_6"] .dlabnav .main-menu {
  color: #fff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .feature-box .wallet-box,
[data-sidebarbg="color_6"] .feature-box .wallet-box {
  background-color: #72abff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .feature-box .item-1:hover,
[data-sidebarbg="color_6"] .feature-box .item-1:hover {
  background: #72abff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_6"][data-theme-version="dark"] .feature-box small,
[data-sidebarbg="color_6"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_6"] .feature-box .small,
[data-sidebarbg="color_6"] .feature-box small,
[data-sidebarbg="color_6"] .feature-box .small {
  color: #fff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sidebarbg="color_6"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #3f8cff !important;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sidebarbg="color_6"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .dlabnav .header-info2,
[data-sidebarbg="color_6"] .dlabnav .header-info2 {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .menu-scroll .copyright strong,
[data-sidebarbg="color_6"] .menu-scroll .copyright strong {
  color: #fff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_6"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_6"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_6"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_6"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_6"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_6"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #72abff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_6"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_6"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_6"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_6"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_6"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sidebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sidebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #4e95ff !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_6"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sidebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_6"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #72abff !important;
  color: #fff !important;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sidebarbg="color_6"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_6"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_6"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sidebarbg="color_6"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_6"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_6"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sidebarbg="color_6"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sidebarbg="color_6"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_6"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_6"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_6"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sidebarbg="color_6"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sidebarbg="color_6"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sidebarbg="color_6"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sidebarbg="color_6"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_6"] .plus-box {
  background-color: #72abff;
}

[data-sidebarbg="color_6"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_6"] .copyright {
  color: #fff;
}

[data-sidebarbg="color_7"][data-theme-version="dark"],
[data-sidebarbg="color_7"] {
  --sidebar-bg: #40a7e2;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .dlabnav .main-menu,
[data-sidebarbg="color_7"] .dlabnav .main-menu {
  color: #fff;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .feature-box .wallet-box,
[data-sidebarbg="color_7"] .feature-box .wallet-box {
  background-color: #6cbbe9;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .feature-box .item-1:hover,
[data-sidebarbg="color_7"] .feature-box .item-1:hover {
  background: #6cbbe9;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_7"][data-theme-version="dark"] .feature-box small,
[data-sidebarbg="color_7"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_7"] .feature-box .small,
[data-sidebarbg="color_7"] .feature-box small,
[data-sidebarbg="color_7"] .feature-box .small {
  color: #fff;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sidebarbg="color_7"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #40a7e2 !important;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sidebarbg="color_7"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .dlabnav .header-info2,
[data-sidebarbg="color_7"] .dlabnav .header-info2 {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .menu-scroll .copyright strong,
[data-sidebarbg="color_7"] .menu-scroll .copyright strong {
  color: #fff;
}

[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_7"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_7"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_7"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_7"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_7"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_7"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #6cbbe9;
}

[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_7"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_7"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_7"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_7"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_7"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sidebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sidebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #4dade4 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_7"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sidebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_7"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #6cbbe9 !important;
  color: #fff !important;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sidebarbg="color_7"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_7"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_7"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sidebarbg="color_7"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_7"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_7"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sidebarbg="color_7"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sidebarbg="color_7"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_7"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_7"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_7"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sidebarbg="color_7"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sidebarbg="color_7"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sidebarbg="color_7"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sidebarbg="color_7"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_7"] .plus-box {
  background-color: #6cbbe9;
}

[data-sidebarbg="color_7"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_7"] .copyright {
  color: #fff;
}

[data-sidebarbg="color_8"][data-theme-version="dark"],
[data-sidebarbg="color_8"] {
  --sidebar-bg: #b65fbd;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .dlabnav .main-menu,
[data-sidebarbg="color_8"] .dlabnav .main-menu {
  color: #fff;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .feature-box .wallet-box,
[data-sidebarbg="color_8"] .feature-box .wallet-box {
  background-color: #c683cc;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .feature-box .item-1:hover,
[data-sidebarbg="color_8"] .feature-box .item-1:hover {
  background: #c683cc;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_8"][data-theme-version="dark"] .feature-box small,
[data-sidebarbg="color_8"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_8"] .feature-box .small,
[data-sidebarbg="color_8"] .feature-box small,
[data-sidebarbg="color_8"] .feature-box .small {
  color: #fff;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sidebarbg="color_8"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #b65fbd !important;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sidebarbg="color_8"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .dlabnav .header-info2,
[data-sidebarbg="color_8"] .dlabnav .header-info2 {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .menu-scroll .copyright strong,
[data-sidebarbg="color_8"] .menu-scroll .copyright strong {
  color: #fff;
}

[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_8"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_8"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_8"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_8"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_8"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_8"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #c683cc;
}

[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_8"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_8"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_8"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_8"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_8"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sidebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sidebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #bb6ac1 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_8"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sidebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_8"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #c683cc !important;
  color: #fff !important;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sidebarbg="color_8"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_8"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_8"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sidebarbg="color_8"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_8"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_8"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sidebarbg="color_8"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sidebarbg="color_8"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_8"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_8"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_8"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sidebarbg="color_8"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sidebarbg="color_8"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sidebarbg="color_8"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sidebarbg="color_8"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_8"] .plus-box {
  background-color: #c683cc;
}

[data-sidebarbg="color_8"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_8"] .copyright {
  color: #fff;
}

[data-sidebarbg="color_9"][data-theme-version="dark"],
[data-sidebarbg="color_9"] {
  --sidebar-bg: #88a67e;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .dlabnav .main-menu,
[data-sidebarbg="color_9"] .dlabnav .main-menu {
  color: #fff;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .feature-box .wallet-box,
[data-sidebarbg="color_9"] .feature-box .wallet-box {
  background-color: #a4bb9c;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .feature-box .item-1:hover,
[data-sidebarbg="color_9"] .feature-box .item-1:hover {
  background: #a4bb9c;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_9"][data-theme-version="dark"] .feature-box small,
[data-sidebarbg="color_9"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_9"] .feature-box .small,
[data-sidebarbg="color_9"] .feature-box small,
[data-sidebarbg="color_9"] .feature-box .small {
  color: #fff;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sidebarbg="color_9"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #88a67e !important;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sidebarbg="color_9"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .dlabnav .header-info2,
[data-sidebarbg="color_9"] .dlabnav .header-info2 {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .menu-scroll .copyright strong,
[data-sidebarbg="color_9"] .menu-scroll .copyright strong {
  color: #fff;
}

[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_9"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_9"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_9"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_9"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_9"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_9"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #a4bb9c;
}

[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_9"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_9"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_9"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_9"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_9"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sidebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sidebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #90ac87 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_9"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sidebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_9"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #a4bb9c !important;
  color: #fff !important;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sidebarbg="color_9"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_9"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_9"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sidebarbg="color_9"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_9"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_9"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sidebarbg="color_9"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sidebarbg="color_9"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_9"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_9"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_9"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sidebarbg="color_9"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sidebarbg="color_9"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sidebarbg="color_9"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sidebarbg="color_9"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_9"] .plus-box {
  background-color: #a4bb9c;
}

[data-sidebarbg="color_9"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_9"] .copyright {
  color: #fff;
}

[data-sidebarbg="color_10"][data-theme-version="dark"],
[data-sidebarbg="color_10"] {
  --sidebar-bg: #1c2e9e;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .dlabnav .main-menu,
[data-sidebarbg="color_10"] .dlabnav .main-menu {
  color: #fff;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .feature-box .wallet-box,
[data-sidebarbg="color_10"] .feature-box .wallet-box {
  background-color: #243bc9;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .feature-box .item-1:hover,
[data-sidebarbg="color_10"] .feature-box .item-1:hover {
  background: #243bc9;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_10"][data-theme-version="dark"] .feature-box small,
[data-sidebarbg="color_10"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_10"] .feature-box .small,
[data-sidebarbg="color_10"] .feature-box small,
[data-sidebarbg="color_10"] .feature-box .small {
  color: #fff;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sidebarbg="color_10"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #1c2e9e !important;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sidebarbg="color_10"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .dlabnav .header-info2,
[data-sidebarbg="color_10"] .dlabnav .header-info2 {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .menu-scroll .copyright strong,
[data-sidebarbg="color_10"] .menu-scroll .copyright strong {
  color: #fff;
}

[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_10"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_10"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_10"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_10"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_10"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_10"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #243bc9;
}

[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_10"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_10"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_10"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_10"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_10"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sidebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sidebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #1e32ab !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_10"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sidebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_10"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #243bc9 !important;
  color: #fff !important;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sidebarbg="color_10"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_10"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_10"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sidebarbg="color_10"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_10"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_10"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sidebarbg="color_10"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sidebarbg="color_10"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_10"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_10"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_10"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sidebarbg="color_10"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sidebarbg="color_10"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sidebarbg="color_10"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sidebarbg="color_10"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_10"] .plus-box {
  background-color: #243bc9;
}

[data-sidebarbg="color_10"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_10"] .copyright {
  color: #fff;
}

[data-sidebarbg="color_11"][data-theme-version="dark"],
[data-sidebarbg="color_11"] {
  --sidebar-bg: #c6164f;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .dlabnav .main-menu,
[data-sidebarbg="color_11"] .dlabnav .main-menu {
  color: #fff;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .feature-box .wallet-box,
[data-sidebarbg="color_11"] .feature-box .wallet-box {
  background-color: #e72866;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .feature-box .item-1:hover,
[data-sidebarbg="color_11"] .feature-box .item-1:hover {
  background: #e72866;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_11"][data-theme-version="dark"] .feature-box small,
[data-sidebarbg="color_11"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_11"] .feature-box .small,
[data-sidebarbg="color_11"] .feature-box small,
[data-sidebarbg="color_11"] .feature-box .small {
  color: #fff;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sidebarbg="color_11"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #c6164f !important;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sidebarbg="color_11"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .dlabnav .header-info2,
[data-sidebarbg="color_11"] .dlabnav .header-info2 {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .menu-scroll .copyright strong,
[data-sidebarbg="color_11"] .menu-scroll .copyright strong {
  color: #fff;
}

[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_11"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_11"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_11"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_11"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_11"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_11"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #e72866;
}

[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_11"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_11"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_11"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_11"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_11"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sidebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sidebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #d41854 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_11"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sidebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_11"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #e72866 !important;
  color: #fff !important;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sidebarbg="color_11"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_11"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_11"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sidebarbg="color_11"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_11"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_11"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sidebarbg="color_11"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sidebarbg="color_11"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_11"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_11"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_11"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sidebarbg="color_11"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sidebarbg="color_11"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sidebarbg="color_11"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sidebarbg="color_11"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_11"] .plus-box {
  background-color: #e72866;
}

[data-sidebarbg="color_11"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_11"] .copyright {
  color: #fff;
}

[data-sidebarbg="color_12"][data-theme-version="dark"],
[data-sidebarbg="color_12"] {
  --sidebar-bg: #343a40;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .dlabnav .main-menu,
[data-sidebarbg="color_12"] .dlabnav .main-menu {
  color: #fff;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .feature-box .wallet-box,
[data-sidebarbg="color_12"] .feature-box .wallet-box {
  background-color: #4b545c;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .feature-box .item-1:hover,
[data-sidebarbg="color_12"] .feature-box .item-1:hover {
  background: #4b545c;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_12"][data-theme-version="dark"] .feature-box small,
[data-sidebarbg="color_12"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_12"] .feature-box .small,
[data-sidebarbg="color_12"] .feature-box small,
[data-sidebarbg="color_12"] .feature-box .small {
  color: #fff;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sidebarbg="color_12"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #343a40 !important;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sidebarbg="color_12"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .dlabnav .header-info2,
[data-sidebarbg="color_12"] .dlabnav .header-info2 {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .menu-scroll .copyright strong,
[data-sidebarbg="color_12"] .menu-scroll .copyright strong {
  color: #fff;
}

[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_12"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_12"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_12"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_12"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_12"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_12"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #4b545c;
}

[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_12"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_12"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_12"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_12"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_12"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sidebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sidebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #3b4248 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_12"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sidebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_12"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #4b545c !important;
  color: #fff !important;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sidebarbg="color_12"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_12"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_12"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sidebarbg="color_12"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_12"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_12"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sidebarbg="color_12"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sidebarbg="color_12"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_12"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_12"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_12"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sidebarbg="color_12"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sidebarbg="color_12"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sidebarbg="color_12"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sidebarbg="color_12"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_12"] .plus-box {
  background-color: #4b545c;
}

[data-sidebarbg="color_12"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_12"] .copyright {
  color: #fff;
}

[data-sidebarbg="color_13"][data-theme-version="dark"],
[data-sidebarbg="color_13"] {
  --sidebar-bg: #056468;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .dlabnav .main-menu,
[data-sidebarbg="color_13"] .dlabnav .main-menu {
  color: #fff;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .feature-box .wallet-box,
[data-sidebarbg="color_13"] .feature-box .wallet-box {
  background-color: #079399;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .feature-box .item-1:hover,
[data-sidebarbg="color_13"] .feature-box .item-1:hover {
  background: #079399;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_13"][data-theme-version="dark"] .feature-box small,
[data-sidebarbg="color_13"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_13"] .feature-box .small,
[data-sidebarbg="color_13"] .feature-box small,
[data-sidebarbg="color_13"] .feature-box .small {
  color: #fff;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sidebarbg="color_13"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #056468 !important;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sidebarbg="color_13"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .dlabnav .header-info2,
[data-sidebarbg="color_13"] .dlabnav .header-info2 {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .menu-scroll .copyright strong,
[data-sidebarbg="color_13"] .menu-scroll .copyright strong {
  color: #fff;
}

[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_13"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_13"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_13"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_13"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_13"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_13"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #079399;
}

[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_13"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_13"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_13"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_13"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_13"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sidebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sidebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #067277 !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_13"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sidebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_13"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #079399 !important;
  color: #fff !important;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sidebarbg="color_13"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_13"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_13"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sidebarbg="color_13"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_13"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_13"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sidebarbg="color_13"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sidebarbg="color_13"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_13"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_13"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_13"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sidebarbg="color_13"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sidebarbg="color_13"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sidebarbg="color_13"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sidebarbg="color_13"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_13"] .plus-box {
  background-color: #079399;
}

[data-sidebarbg="color_13"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_13"] .copyright {
  color: #fff;
}

[data-sidebarbg="color_14"][data-theme-version="dark"],
[data-sidebarbg="color_14"] {
  --sidebar-bg: #4885ed;
}

[data-sidebarbg="color_14"][data-theme-version="dark"] .dlabnav .main-menu,
[data-sidebarbg="color_14"] .dlabnav .main-menu {
  color: #fff;
}

[data-sidebarbg="color_14"][data-theme-version="dark"] .feature-box .wallet-box,
[data-sidebarbg="color_14"] .feature-box .wallet-box {
  background-color: #76a4f2;
}

[data-sidebarbg="color_14"][data-theme-version="dark"] .feature-box .item-1:hover,
[data-sidebarbg="color_14"] .feature-box .item-1:hover {
  background: #76a4f2;
}

[data-sidebarbg="color_14"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_14"][data-theme-version="dark"] .feature-box small,
[data-sidebarbg="color_14"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_14"] .feature-box .small,
[data-sidebarbg="color_14"] .feature-box small,
[data-sidebarbg="color_14"] .feature-box .small {
  color: #fff;
}

[data-sidebarbg="color_14"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sidebarbg="color_14"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #4885ed !important;
}

[data-sidebarbg="color_14"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sidebarbg="color_14"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_14"][data-theme-version="dark"] .dlabnav .header-info2,
[data-sidebarbg="color_14"] .dlabnav .header-info2 {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

[data-sidebarbg="color_14"][data-theme-version="dark"] .menu-scroll .copyright strong,
[data-sidebarbg="color_14"] .menu-scroll .copyright strong {
  color: #fff;
}

[data-sidebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_14"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_14"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_14"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_14"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_14"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_14"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #76a4f2;
}

[data-sidebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_14"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_14"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_14"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_14"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_14"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_14"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_14"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_14"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_14"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_14"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sidebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sidebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #568eee !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_14"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sidebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_14"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #76a4f2 !important;
  color: #fff !important;
}

[data-sidebarbg="color_14"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sidebarbg="color_14"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_14"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_14"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_14"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sidebarbg="color_14"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_14"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_14"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_14"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sidebarbg="color_14"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_14"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sidebarbg="color_14"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_14"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_14"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sidebarbg="color_14"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_14"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_14"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_14"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_14"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sidebarbg="color_14"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_14"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sidebarbg="color_14"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_14"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sidebarbg="color_14"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_14"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sidebarbg="color_14"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_14"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_14"] .plus-box {
  background-color: #76a4f2;
}

[data-sidebarbg="color_14"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_14"] .copyright {
  color: #fff;
}

[data-sidebarbg="color_15"][data-theme-version="dark"],
[data-sidebarbg="color_15"] {
  --sidebar-bg: #4cb32b;
}

[data-sidebarbg="color_15"][data-theme-version="dark"] .dlabnav .main-menu,
[data-sidebarbg="color_15"] .dlabnav .main-menu {
  color: #fff;
}

[data-sidebarbg="color_15"][data-theme-version="dark"] .feature-box .wallet-box,
[data-sidebarbg="color_15"] .feature-box .wallet-box {
  background-color: #63d140;
}

[data-sidebarbg="color_15"][data-theme-version="dark"] .feature-box .item-1:hover,
[data-sidebarbg="color_15"] .feature-box .item-1:hover {
  background: #63d140;
}

[data-sidebarbg="color_15"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_15"][data-theme-version="dark"] .feature-box small,
[data-sidebarbg="color_15"][data-theme-version="dark"] .feature-box .small,
[data-sidebarbg="color_15"] .feature-box .small,
[data-sidebarbg="color_15"] .feature-box small,
[data-sidebarbg="color_15"] .feature-box .small {
  color: #fff;
}

[data-sidebarbg="color_15"][data-theme-version="dark"] .menu-toggle .dlabnav .metismenu li>ul,
[data-sidebarbg="color_15"] .menu-toggle .dlabnav .metismenu li>ul {
  background: #4cb32b !important;
}

[data-sidebarbg="color_15"][data-theme-version="dark"] .dlabnav .metismenu ul a:before,
[data-sidebarbg="color_15"] .dlabnav .metismenu ul a:before {
  background: rgba(255, 255, 255, 0.5);
}

[data-sidebarbg="color_15"][data-theme-version="dark"] .dlabnav .header-info2,
[data-sidebarbg="color_15"] .dlabnav .header-info2 {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

[data-sidebarbg="color_15"][data-theme-version="dark"] .menu-scroll .copyright strong,
[data-sidebarbg="color_15"] .menu-scroll .copyright strong {
  color: #fff;
}

[data-sidebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_15"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff;
}

[data-sidebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_15"][data-sidebar-style="mini"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_15"][data-sidebar-style="modern"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_15"][data-sidebar-style="compact"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_15"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu li ul,
[data-sidebarbg="color_15"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu li ul {
  background-color: #63d140;
}

[data-sidebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_15"][data-sidebar-style="mini"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_15"][data-sidebar-style="mini"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_15"][data-sidebar-style="modern"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_15"][data-sidebar-style="modern"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_15"][data-sidebar-style="compact"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_15"][data-sidebar-style="compact"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_15"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_15"][data-sidebar-style="icon-hover"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_15"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_15"][data-sidebar-style="full"][data-layout="horizontal"] .dlabnav .metismenu>li:hover>a {
  background: rgba(255, 255, 255, 0.15) !important;
}

[data-sidebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover,
[data-sidebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li a:hover {
  color: #fff;
}

[data-sidebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i,
[data-sidebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li>a>i {
  background: #51bf2e !important;
  color: rgba(255, 255, 255, 0.7);
}

[data-sidebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a,
[data-sidebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a {
  box-shadow: none;
  background: transparent !important;
  color: #fff !important;
}

[data-sidebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_15"][data-theme-version="dark"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i,
[data-sidebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_15"][data-sidebar-style="compact"][data-layout="vertical"] .dlabnav .metismenu>li:hover>a i {
  background: #63d140 !important;
  color: #fff !important;
}

[data-sidebarbg="color_15"][data-theme-version="dark"] .dlabnav .metismenu a,
[data-sidebarbg="color_15"] .dlabnav .metismenu a {
  color: rgba(255, 255, 255, 0.8) !important;
}

[data-sidebarbg="color_15"][data-theme-version="dark"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_15"][data-theme-version="dark"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_15"][data-theme-version="dark"] .dlabnav .metismenu li ul a.mm-active,
[data-sidebarbg="color_15"] .dlabnav .metismenu li ul a:hover,
[data-sidebarbg="color_15"] .dlabnav .metismenu li ul a:focus,
[data-sidebarbg="color_15"] .dlabnav .metismenu li ul a.mm-active {
  color: #fff;
}

[data-sidebarbg="color_15"][data-theme-version="dark"] .dlabnav .metismenu>li>a,
[data-sidebarbg="color_15"] .dlabnav .metismenu>li>a {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_15"][data-theme-version="dark"] .dlabnav .metismenu>li>a i,
[data-sidebarbg="color_15"] .dlabnav .metismenu>li>a i {
  color: rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_15"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a,
[data-sidebarbg="color_15"] .dlabnav .metismenu>li.mm-active>a {
  background: rgba(255, 255, 255, 0.15) !important;
  color: #fff !important;
}

[data-sidebarbg="color_15"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a i,
[data-sidebarbg="color_15"] .dlabnav .metismenu>li.mm-active>a i {
  color: #fff !important;
}

[data-sidebarbg="color_15"][data-theme-version="dark"] .dlabnav .metismenu>li.mm-active>a:before,
[data-sidebarbg="color_15"] .dlabnav .metismenu>li.mm-active>a:before {
  background: #fff !important;
}

[data-sidebarbg="color_15"][data-theme-version="dark"] .dlabnav .metismenu .has-arrow:after,
[data-sidebarbg="color_15"] .dlabnav .metismenu .has-arrow:after {
  border-color: rgba(255, 255, 255, 0.85) transparent transparent rgba(255, 255, 255, 0.85) !important;
}

[data-sidebarbg="color_15"][data-theme-version="dark"] .dlabnav .header-info2 span,
[data-sidebarbg="color_15"] .dlabnav .header-info2 span {
  color: #fff;
}

[data-sidebarbg="color_15"][data-theme-version="dark"] .dlabnav .sidebar-info,
[data-sidebarbg="color_15"] .dlabnav .sidebar-info {
  color: #fff;
}

[data-sidebarbg="color_15"][data-theme-version="dark"] .dlabnav .sidebar-info i,
[data-sidebarbg="color_15"] .dlabnav .sidebar-info i {
  color: #fff;
}

[data-sidebarbg="color_15"][data-theme-version="dark"] .plus-box,
[data-sidebarbg="color_15"] .plus-box {
  background-color: #63d140;
}

[data-sidebarbg="color_15"][data-theme-version="dark"] .copyright,
[data-sidebarbg="color_15"] .copyright {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"],
[data-headerbg="color_2"] {
  --headerbg: #6610f2;
}

[data-headerbg="color_2"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_2"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .dashboard_bar,
[data-headerbg="color_2"] .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-profile2 span,
[data-headerbg="color_2"] .header-profile2 span {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_2"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_2"] .header-right .search-area .form-control,
[data-headerbg="color_2"] .header-right .search-area .input-group-text {
  background-color: #8540f5;
  border-color: #8540f5;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_2"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_2"] .header-right .search-area .form-control i,
[data-headerbg="color_2"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .plus-icon a,
[data-headerbg="color_2"] .header-right .plus-icon a {
  background-color: #8540f5;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link .badge,
[data-headerbg="color_2"] .header-right .notification_dropdown .nav-link .badge {
  border-color: #8540f5;
}

[data-headerbg="color_2"][data-theme-version="dark"] .sidebar-info h4,
[data-headerbg="color_2"][data-theme-version="dark"] .sidebar-info .h4,
[data-headerbg="color_2"] .sidebar-info h4,
[data-headerbg="color_2"] .sidebar-info .h4 {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right svg path,
[data-headerbg="color_2"] .header-right svg path {
  fill: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-right .logout path,
[data-headerbg="color_2"] .header-right .logout path {
  fill: #fd5353;
}

[data-headerbg="color_2"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_2"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_2"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_2"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_2"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_2"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_2"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_2"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_3"][data-theme-version="dark"],
[data-headerbg="color_3"] {
  --headerbg: #3736af;
}

[data-headerbg="color_3"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_3"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .dashboard_bar,
[data-headerbg="color_3"] .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-profile2 span,
[data-headerbg="color_3"] .header-profile2 span {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_3"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_3"] .header-right .search-area .form-control,
[data-headerbg="color_3"] .header-right .search-area .input-group-text {
  background-color: #504fc9;
  border-color: #504fc9;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_3"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_3"] .header-right .search-area .form-control i,
[data-headerbg="color_3"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .plus-icon a,
[data-headerbg="color_3"] .header-right .plus-icon a {
  background-color: #504fc9;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link .badge,
[data-headerbg="color_3"] .header-right .notification_dropdown .nav-link .badge {
  border-color: #504fc9;
}

[data-headerbg="color_3"][data-theme-version="dark"] .sidebar-info h4,
[data-headerbg="color_3"][data-theme-version="dark"] .sidebar-info .h4,
[data-headerbg="color_3"] .sidebar-info h4,
[data-headerbg="color_3"] .sidebar-info .h4 {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right svg path,
[data-headerbg="color_3"] .header-right svg path {
  fill: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-right .logout path,
[data-headerbg="color_3"] .header-right .logout path {
  fill: #fd5353;
}

[data-headerbg="color_3"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_3"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_3"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_3"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_3"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_3"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_3"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_3"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_4"][data-theme-version="dark"],
[data-headerbg="color_4"] {
  --headerbg: #4d06a5;
}

[data-headerbg="color_4"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_4"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .dashboard_bar,
[data-headerbg="color_4"] .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-profile2 span,
[data-headerbg="color_4"] .header-profile2 span {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_4"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_4"] .header-right .search-area .form-control,
[data-headerbg="color_4"] .header-right .search-area .input-group-text {
  background-color: #6408d6;
  border-color: #6408d6;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_4"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_4"] .header-right .search-area .form-control i,
[data-headerbg="color_4"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .plus-icon a,
[data-headerbg="color_4"] .header-right .plus-icon a {
  background-color: #6408d6;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link .badge,
[data-headerbg="color_4"] .header-right .notification_dropdown .nav-link .badge {
  border-color: #6408d6;
}

[data-headerbg="color_4"][data-theme-version="dark"] .sidebar-info h4,
[data-headerbg="color_4"][data-theme-version="dark"] .sidebar-info .h4,
[data-headerbg="color_4"] .sidebar-info h4,
[data-headerbg="color_4"] .sidebar-info .h4 {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right svg path,
[data-headerbg="color_4"] .header-right svg path {
  fill: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-right .logout path,
[data-headerbg="color_4"] .header-right .logout path {
  fill: #fd5353;
}

[data-headerbg="color_4"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_4"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_4"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_4"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_4"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_4"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_4"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_4"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_5"][data-theme-version="dark"],
[data-headerbg="color_5"] {
  --headerbg: #3250ff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_5"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .dashboard_bar,
[data-headerbg="color_5"] .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-profile2 span,
[data-headerbg="color_5"] .header-profile2 span {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_5"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_5"] .header-right .search-area .form-control,
[data-headerbg="color_5"] .header-right .search-area .input-group-text {
  background-color: #657cff;
  border-color: #657cff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_5"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_5"] .header-right .search-area .form-control i,
[data-headerbg="color_5"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .plus-icon a,
[data-headerbg="color_5"] .header-right .plus-icon a {
  background-color: #657cff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link .badge,
[data-headerbg="color_5"] .header-right .notification_dropdown .nav-link .badge {
  border-color: #657cff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .sidebar-info h4,
[data-headerbg="color_5"][data-theme-version="dark"] .sidebar-info .h4,
[data-headerbg="color_5"] .sidebar-info h4,
[data-headerbg="color_5"] .sidebar-info .h4 {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right svg path,
[data-headerbg="color_5"] .header-right svg path {
  fill: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-right .logout path,
[data-headerbg="color_5"] .header-right .logout path {
  fill: #fd5353;
}

[data-headerbg="color_5"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_5"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_5"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_5"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_5"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_5"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_5"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_5"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_6"][data-theme-version="dark"],
[data-headerbg="color_6"] {
  --headerbg: #3f8cff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_6"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .dashboard_bar,
[data-headerbg="color_6"] .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-profile2 span,
[data-headerbg="color_6"] .header-profile2 span {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_6"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_6"] .header-right .search-area .form-control,
[data-headerbg="color_6"] .header-right .search-area .input-group-text {
  background-color: #72abff;
  border-color: #72abff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_6"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_6"] .header-right .search-area .form-control i,
[data-headerbg="color_6"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .plus-icon a,
[data-headerbg="color_6"] .header-right .plus-icon a {
  background-color: #72abff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link .badge,
[data-headerbg="color_6"] .header-right .notification_dropdown .nav-link .badge {
  border-color: #72abff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .sidebar-info h4,
[data-headerbg="color_6"][data-theme-version="dark"] .sidebar-info .h4,
[data-headerbg="color_6"] .sidebar-info h4,
[data-headerbg="color_6"] .sidebar-info .h4 {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right svg path,
[data-headerbg="color_6"] .header-right svg path {
  fill: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-right .logout path,
[data-headerbg="color_6"] .header-right .logout path {
  fill: #fd5353;
}

[data-headerbg="color_6"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_6"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_6"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_6"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_6"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_6"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_6"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_6"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_7"][data-theme-version="dark"],
[data-headerbg="color_7"] {
  --headerbg: #40a7e2;
}

[data-headerbg="color_7"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_7"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .dashboard_bar,
[data-headerbg="color_7"] .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-profile2 span,
[data-headerbg="color_7"] .header-profile2 span {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_7"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_7"] .header-right .search-area .form-control,
[data-headerbg="color_7"] .header-right .search-area .input-group-text {
  background-color: #6cbbe9;
  border-color: #6cbbe9;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_7"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_7"] .header-right .search-area .form-control i,
[data-headerbg="color_7"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .plus-icon a,
[data-headerbg="color_7"] .header-right .plus-icon a {
  background-color: #6cbbe9;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link .badge,
[data-headerbg="color_7"] .header-right .notification_dropdown .nav-link .badge {
  border-color: #6cbbe9;
}

[data-headerbg="color_7"][data-theme-version="dark"] .sidebar-info h4,
[data-headerbg="color_7"][data-theme-version="dark"] .sidebar-info .h4,
[data-headerbg="color_7"] .sidebar-info h4,
[data-headerbg="color_7"] .sidebar-info .h4 {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right svg path,
[data-headerbg="color_7"] .header-right svg path {
  fill: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-right .logout path,
[data-headerbg="color_7"] .header-right .logout path {
  fill: #fd5353;
}

[data-headerbg="color_7"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_7"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_7"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_7"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_7"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_7"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_7"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_7"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_8"][data-theme-version="dark"],
[data-headerbg="color_8"] {
  --headerbg: #b65fbd;
}

[data-headerbg="color_8"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_8"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .dashboard_bar,
[data-headerbg="color_8"] .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-profile2 span,
[data-headerbg="color_8"] .header-profile2 span {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_8"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_8"] .header-right .search-area .form-control,
[data-headerbg="color_8"] .header-right .search-area .input-group-text {
  background-color: #c683cc;
  border-color: #c683cc;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_8"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_8"] .header-right .search-area .form-control i,
[data-headerbg="color_8"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .plus-icon a,
[data-headerbg="color_8"] .header-right .plus-icon a {
  background-color: #c683cc;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link .badge,
[data-headerbg="color_8"] .header-right .notification_dropdown .nav-link .badge {
  border-color: #c683cc;
}

[data-headerbg="color_8"][data-theme-version="dark"] .sidebar-info h4,
[data-headerbg="color_8"][data-theme-version="dark"] .sidebar-info .h4,
[data-headerbg="color_8"] .sidebar-info h4,
[data-headerbg="color_8"] .sidebar-info .h4 {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right svg path,
[data-headerbg="color_8"] .header-right svg path {
  fill: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-right .logout path,
[data-headerbg="color_8"] .header-right .logout path {
  fill: #fd5353;
}

[data-headerbg="color_8"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_8"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_8"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_8"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_8"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_8"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_8"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_8"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_9"][data-theme-version="dark"],
[data-headerbg="color_9"] {
  --headerbg: #88a67e;
}

[data-headerbg="color_9"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_9"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .dashboard_bar,
[data-headerbg="color_9"] .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-profile2 span,
[data-headerbg="color_9"] .header-profile2 span {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_9"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_9"] .header-right .search-area .form-control,
[data-headerbg="color_9"] .header-right .search-area .input-group-text {
  background-color: #a4bb9c;
  border-color: #a4bb9c;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_9"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_9"] .header-right .search-area .form-control i,
[data-headerbg="color_9"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .plus-icon a,
[data-headerbg="color_9"] .header-right .plus-icon a {
  background-color: #a4bb9c;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link .badge,
[data-headerbg="color_9"] .header-right .notification_dropdown .nav-link .badge {
  border-color: #a4bb9c;
}

[data-headerbg="color_9"][data-theme-version="dark"] .sidebar-info h4,
[data-headerbg="color_9"][data-theme-version="dark"] .sidebar-info .h4,
[data-headerbg="color_9"] .sidebar-info h4,
[data-headerbg="color_9"] .sidebar-info .h4 {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right svg path,
[data-headerbg="color_9"] .header-right svg path {
  fill: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-right .logout path,
[data-headerbg="color_9"] .header-right .logout path {
  fill: #fd5353;
}

[data-headerbg="color_9"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_9"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_9"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_9"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_9"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_9"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_9"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_9"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_10"][data-theme-version="dark"],
[data-headerbg="color_10"] {
  --headerbg: #1c2e9e;
}

[data-headerbg="color_10"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_10"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .dashboard_bar,
[data-headerbg="color_10"] .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-profile2 span,
[data-headerbg="color_10"] .header-profile2 span {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_10"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_10"] .header-right .search-area .form-control,
[data-headerbg="color_10"] .header-right .search-area .input-group-text {
  background-color: #243bc9;
  border-color: #243bc9;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_10"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_10"] .header-right .search-area .form-control i,
[data-headerbg="color_10"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .plus-icon a,
[data-headerbg="color_10"] .header-right .plus-icon a {
  background-color: #243bc9;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link .badge,
[data-headerbg="color_10"] .header-right .notification_dropdown .nav-link .badge {
  border-color: #243bc9;
}

[data-headerbg="color_10"][data-theme-version="dark"] .sidebar-info h4,
[data-headerbg="color_10"][data-theme-version="dark"] .sidebar-info .h4,
[data-headerbg="color_10"] .sidebar-info h4,
[data-headerbg="color_10"] .sidebar-info .h4 {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right svg path,
[data-headerbg="color_10"] .header-right svg path {
  fill: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-right .logout path,
[data-headerbg="color_10"] .header-right .logout path {
  fill: #fd5353;
}

[data-headerbg="color_10"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_10"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_10"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_10"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_10"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_10"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_10"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_10"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_11"][data-theme-version="dark"],
[data-headerbg="color_11"] {
  --headerbg: #c6164f;
}

[data-headerbg="color_11"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_11"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .dashboard_bar,
[data-headerbg="color_11"] .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-profile2 span,
[data-headerbg="color_11"] .header-profile2 span {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_11"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_11"] .header-right .search-area .form-control,
[data-headerbg="color_11"] .header-right .search-area .input-group-text {
  background-color: #e72866;
  border-color: #e72866;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_11"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_11"] .header-right .search-area .form-control i,
[data-headerbg="color_11"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .plus-icon a,
[data-headerbg="color_11"] .header-right .plus-icon a {
  background-color: #e72866;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link .badge,
[data-headerbg="color_11"] .header-right .notification_dropdown .nav-link .badge {
  border-color: #e72866;
}

[data-headerbg="color_11"][data-theme-version="dark"] .sidebar-info h4,
[data-headerbg="color_11"][data-theme-version="dark"] .sidebar-info .h4,
[data-headerbg="color_11"] .sidebar-info h4,
[data-headerbg="color_11"] .sidebar-info .h4 {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right svg path,
[data-headerbg="color_11"] .header-right svg path {
  fill: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-right .logout path,
[data-headerbg="color_11"] .header-right .logout path {
  fill: #fd5353;
}

[data-headerbg="color_11"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_11"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_11"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_11"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_11"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_11"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_11"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_11"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_12"][data-theme-version="dark"],
[data-headerbg="color_12"] {
  --headerbg: #343a40;
}

[data-headerbg="color_12"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_12"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .dashboard_bar,
[data-headerbg="color_12"] .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-profile2 span,
[data-headerbg="color_12"] .header-profile2 span {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_12"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_12"] .header-right .search-area .form-control,
[data-headerbg="color_12"] .header-right .search-area .input-group-text {
  background-color: #4b545c;
  border-color: #4b545c;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_12"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_12"] .header-right .search-area .form-control i,
[data-headerbg="color_12"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .plus-icon a,
[data-headerbg="color_12"] .header-right .plus-icon a {
  background-color: #4b545c;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link .badge,
[data-headerbg="color_12"] .header-right .notification_dropdown .nav-link .badge {
  border-color: #4b545c;
}

[data-headerbg="color_12"][data-theme-version="dark"] .sidebar-info h4,
[data-headerbg="color_12"][data-theme-version="dark"] .sidebar-info .h4,
[data-headerbg="color_12"] .sidebar-info h4,
[data-headerbg="color_12"] .sidebar-info .h4 {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right svg path,
[data-headerbg="color_12"] .header-right svg path {
  fill: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-right .logout path,
[data-headerbg="color_12"] .header-right .logout path {
  fill: #fd5353;
}

[data-headerbg="color_12"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_12"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_12"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_12"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_12"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_12"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_12"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_12"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_13"][data-theme-version="dark"],
[data-headerbg="color_13"] {
  --headerbg: #056468;
}

[data-headerbg="color_13"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_13"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .dashboard_bar,
[data-headerbg="color_13"] .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-profile2 span,
[data-headerbg="color_13"] .header-profile2 span {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_13"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_13"] .header-right .search-area .form-control,
[data-headerbg="color_13"] .header-right .search-area .input-group-text {
  background-color: #079399;
  border-color: #079399;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_13"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_13"] .header-right .search-area .form-control i,
[data-headerbg="color_13"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .plus-icon a,
[data-headerbg="color_13"] .header-right .plus-icon a {
  background-color: #079399;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link .badge,
[data-headerbg="color_13"] .header-right .notification_dropdown .nav-link .badge {
  border-color: #079399;
}

[data-headerbg="color_13"][data-theme-version="dark"] .sidebar-info h4,
[data-headerbg="color_13"][data-theme-version="dark"] .sidebar-info .h4,
[data-headerbg="color_13"] .sidebar-info h4,
[data-headerbg="color_13"] .sidebar-info .h4 {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right svg path,
[data-headerbg="color_13"] .header-right svg path {
  fill: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-right .logout path,
[data-headerbg="color_13"] .header-right .logout path {
  fill: #fd5353;
}

[data-headerbg="color_13"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_13"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_13"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_13"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_13"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_13"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_13"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_13"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_14"][data-theme-version="dark"],
[data-headerbg="color_14"] {
  --headerbg: #4885ed;
}

[data-headerbg="color_14"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_14"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .dashboard_bar,
[data-headerbg="color_14"] .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-profile2 span,
[data-headerbg="color_14"] .header-profile2 span {
  color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_14"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_14"] .header-right .search-area .form-control,
[data-headerbg="color_14"] .header-right .search-area .input-group-text {
  background-color: #76a4f2;
  border-color: #76a4f2;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_14"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_14"] .header-right .search-area .form-control i,
[data-headerbg="color_14"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-right .plus-icon a,
[data-headerbg="color_14"] .header-right .plus-icon a {
  background-color: #76a4f2;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link .badge,
[data-headerbg="color_14"] .header-right .notification_dropdown .nav-link .badge {
  border-color: #76a4f2;
}

[data-headerbg="color_14"][data-theme-version="dark"] .sidebar-info h4,
[data-headerbg="color_14"][data-theme-version="dark"] .sidebar-info .h4,
[data-headerbg="color_14"] .sidebar-info h4,
[data-headerbg="color_14"] .sidebar-info .h4 {
  color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-right svg path,
[data-headerbg="color_14"] .header-right svg path {
  fill: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-right .logout path,
[data-headerbg="color_14"] .header-right .logout path {
  fill: #fd5353;
}

[data-headerbg="color_14"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_14"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_14"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_14"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_14"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_14"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_14"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_14"] .hamburger .line {
  background: #fff !important;
}

[data-headerbg="color_15"][data-theme-version="dark"],
[data-headerbg="color_15"] {
  --headerbg: #4cb32b;
}

[data-headerbg="color_15"][data-theme-version="dark"] .search-area .input-group-append .input-group-text i,
[data-headerbg="color_15"] .search-area .input-group-append .input-group-text i {
  color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .dashboard_bar,
[data-headerbg="color_15"] .dashboard_bar {
  color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-profile2 span,
[data-headerbg="color_15"] .header-profile2 span {
  color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-right .search-area .form-control,
[data-headerbg="color_15"][data-theme-version="dark"] .header-right .search-area .input-group-text,
[data-headerbg="color_15"] .header-right .search-area .form-control,
[data-headerbg="color_15"] .header-right .search-area .input-group-text {
  background-color: #63d140;
  border-color: #63d140;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-right .search-area .form-control i,
[data-headerbg="color_15"][data-theme-version="dark"] .header-right .search-area .input-group-text i,
[data-headerbg="color_15"] .header-right .search-area .form-control i,
[data-headerbg="color_15"] .header-right .search-area .input-group-text i {
  color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-right .plus-icon a,
[data-headerbg="color_15"] .header-right .plus-icon a {
  background-color: #63d140;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-right .notification_dropdown .nav-link .badge,
[data-headerbg="color_15"] .header-right .notification_dropdown .nav-link .badge {
  border-color: #63d140;
}

[data-headerbg="color_15"][data-theme-version="dark"] .sidebar-info h4,
[data-headerbg="color_15"][data-theme-version="dark"] .sidebar-info .h4,
[data-headerbg="color_15"] .sidebar-info h4,
[data-headerbg="color_15"] .sidebar-info .h4 {
  color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-right svg path,
[data-headerbg="color_15"] .header-right svg path {
  fill: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-right .logout path,
[data-headerbg="color_15"] .header-right .logout path {
  fill: #fd5353;
}

[data-headerbg="color_15"][data-theme-version="dark"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_15"][data-theme-version="dark"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_15"][data-theme-version="dark"] .header-profile>a.nav-link .header-info span,
[data-headerbg="color_15"] .header-profile>a.nav-link .header-info small,
[data-headerbg="color_15"] .header-profile>a.nav-link .header-info .small,
[data-headerbg="color_15"] .header-profile>a.nav-link .header-info span {
  color: #fff;
}

[data-headerbg="color_15"][data-theme-version="dark"] .hamburger .line,
[data-headerbg="color_15"] .hamburger .line {
  background: #fff !important;
}

[data-primary="color_2"] {
  --primary: #6610f2;
  --secondary: #3693ff;
  --primary-hover: #510bc4;
  --primary-dark: #290564;
  --rgba-primary-1: rgba(102, 16, 242, 0.1);
  --rgba-primary-2: rgba(102, 16, 242, 0.2);
  --rgba-primary-3: rgba(102, 16, 242, 0.3);
  --rgba-primary-4: rgba(102, 16, 242, 0.4);
  --rgba-primary-5: rgba(102, 16, 242, 0.5);
  --rgba-primary-6: rgba(102, 16, 242, 0.6);
  --rgba-primary-7: rgba(102, 16, 242, 0.7);
  --rgba-primary-8: rgba(102, 16, 242, 0.8);
  --rgba-primary-9: rgba(102, 16, 242, 0.9);
}

[data-primary="color_3"] {
  --primary: #3736af;
  --secondary: #3693ff;
  --primary-hover: #2b2a88;
  --primary-dark: #12123a;
  --rgba-primary-1: rgba(55, 54, 175, 0.1);
  --rgba-primary-2: rgba(55, 54, 175, 0.2);
  --rgba-primary-3: rgba(55, 54, 175, 0.3);
  --rgba-primary-4: rgba(55, 54, 175, 0.4);
  --rgba-primary-5: rgba(55, 54, 175, 0.5);
  --rgba-primary-6: rgba(55, 54, 175, 0.6);
  --rgba-primary-7: rgba(55, 54, 175, 0.7);
  --rgba-primary-8: rgba(55, 54, 175, 0.8);
  --rgba-primary-9: rgba(55, 54, 175, 0.9);
}

[data-primary="color_4"] {
  --primary: #4d06a5;
  --secondary: #3693ff;
  --primary-hover: #360474;
  --primary-dark: #080111;
  --rgba-primary-1: rgba(77, 6, 165, 0.1);
  --rgba-primary-2: rgba(77, 6, 165, 0.2);
  --rgba-primary-3: rgba(77, 6, 165, 0.3);
  --rgba-primary-4: rgba(77, 6, 165, 0.4);
  --rgba-primary-5: rgba(77, 6, 165, 0.5);
  --rgba-primary-6: rgba(77, 6, 165, 0.6);
  --rgba-primary-7: rgba(77, 6, 165, 0.7);
  --rgba-primary-8: rgba(77, 6, 165, 0.8);
  --rgba-primary-9: rgba(77, 6, 165, 0.9);
}

[data-primary="color_5"] {
  --primary: #3250ff;
  --secondary: #3693ff;
  --primary-hover: #0025fe;
  --primary-dark: #001698;
  --rgba-primary-1: rgba(50, 80, 255, 0.1);
  --rgba-primary-2: rgba(50, 80, 255, 0.2);
  --rgba-primary-3: rgba(50, 80, 255, 0.3);
  --rgba-primary-4: rgba(50, 80, 255, 0.4);
  --rgba-primary-5: rgba(50, 80, 255, 0.5);
  --rgba-primary-6: rgba(50, 80, 255, 0.6);
  --rgba-primary-7: rgba(50, 80, 255, 0.7);
  --rgba-primary-8: rgba(50, 80, 255, 0.8);
  --rgba-primary-9: rgba(50, 80, 255, 0.9);
}

[data-primary="color_6"] {
  --primary: #3f8cff;
  --secondary: #3693ff;
  --primary-hover: #0c6dff;
  --primary-dark: #0042a5;
  --rgba-primary-1: rgba(63, 140, 255, 0.1);
  --rgba-primary-2: rgba(63, 140, 255, 0.2);
  --rgba-primary-3: rgba(63, 140, 255, 0.3);
  --rgba-primary-4: rgba(63, 140, 255, 0.4);
  --rgba-primary-5: rgba(63, 140, 255, 0.5);
  --rgba-primary-6: rgba(63, 140, 255, 0.6);
  --rgba-primary-7: rgba(63, 140, 255, 0.7);
  --rgba-primary-8: rgba(63, 140, 255, 0.8);
  --rgba-primary-9: rgba(63, 140, 255, 0.9);
}

[data-primary="color_7"] {
  --primary: #40a7e2;
  --secondary: #3693ff;
  --primary-hover: #208fcf;
  --primary-dark: #125277;
  --rgba-primary-1: rgba(64, 167, 226, 0.1);
  --rgba-primary-2: rgba(64, 167, 226, 0.2);
  --rgba-primary-3: rgba(64, 167, 226, 0.3);
  --rgba-primary-4: rgba(64, 167, 226, 0.4);
  --rgba-primary-5: rgba(64, 167, 226, 0.5);
  --rgba-primary-6: rgba(64, 167, 226, 0.6);
  --rgba-primary-7: rgba(64, 167, 226, 0.7);
  --rgba-primary-8: rgba(64, 167, 226, 0.8);
  --rgba-primary-9: rgba(64, 167, 226, 0.9);
}

[data-primary="color_8"] {
  --primary: #b65fbd;
  --secondary: #3693ff;
  --primary-hover: #9e44a5;
  --primary-dark: #59265d;
  --rgba-primary-1: rgba(182, 95, 189, 0.1);
  --rgba-primary-2: rgba(182, 95, 189, 0.2);
  --rgba-primary-3: rgba(182, 95, 189, 0.3);
  --rgba-primary-4: rgba(182, 95, 189, 0.4);
  --rgba-primary-5: rgba(182, 95, 189, 0.5);
  --rgba-primary-6: rgba(182, 95, 189, 0.6);
  --rgba-primary-7: rgba(182, 95, 189, 0.7);
  --rgba-primary-8: rgba(182, 95, 189, 0.8);
  --rgba-primary-9: rgba(182, 95, 189, 0.9);
}

[data-primary="color_9"] {
  --primary: #88a67e;
  --secondary: #3693ff;
  --primary-hover: #6d8f62;
  --primary-dark: #3f5239;
  --rgba-primary-1: rgba(136, 166, 126, 0.1);
  --rgba-primary-2: rgba(136, 166, 126, 0.2);
  --rgba-primary-3: rgba(136, 166, 126, 0.3);
  --rgba-primary-4: rgba(136, 166, 126, 0.4);
  --rgba-primary-5: rgba(136, 166, 126, 0.5);
  --rgba-primary-6: rgba(136, 166, 126, 0.6);
  --rgba-primary-7: rgba(136, 166, 126, 0.7);
  --rgba-primary-8: rgba(136, 166, 126, 0.8);
  --rgba-primary-9: rgba(136, 166, 126, 0.9);
}

[data-primary="color_10"] {
  --primary: #1c2e9e;
  --secondary: #3693ff;
  --primary-hover: #142173;
  --primary-dark: #05081c;
  --rgba-primary-1: rgba(28, 46, 158, 0.1);
  --rgba-primary-2: rgba(28, 46, 158, 0.2);
  --rgba-primary-3: rgba(28, 46, 158, 0.3);
  --rgba-primary-4: rgba(28, 46, 158, 0.4);
  --rgba-primary-5: rgba(28, 46, 158, 0.5);
  --rgba-primary-6: rgba(28, 46, 158, 0.6);
  --rgba-primary-7: rgba(28, 46, 158, 0.7);
  --rgba-primary-8: rgba(28, 46, 158, 0.8);
  --rgba-primary-9: rgba(28, 46, 158, 0.9);
}

[data-primary="color_11"] {
  --primary: #c6164f;
  --secondary: #3693ff;
  --primary-hover: #98113d;
  --primary-dark: #3c0718;
  --rgba-primary-1: rgba(198, 22, 79, 0.1);
  --rgba-primary-2: rgba(198, 22, 79, 0.2);
  --rgba-primary-3: rgba(198, 22, 79, 0.3);
  --rgba-primary-4: rgba(198, 22, 79, 0.4);
  --rgba-primary-5: rgba(198, 22, 79, 0.5);
  --rgba-primary-6: rgba(198, 22, 79, 0.6);
  --rgba-primary-7: rgba(198, 22, 79, 0.7);
  --rgba-primary-8: rgba(198, 22, 79, 0.8);
  --rgba-primary-9: rgba(198, 22, 79, 0.9);
}

[data-primary="color_12"] {
  --primary: #343a40;
  --secondary: #3693ff;
  --primary-hover: #1d2124;
  --primary-dark: black;
  --rgba-primary-1: rgba(52, 58, 64, 0.1);
  --rgba-primary-2: rgba(52, 58, 64, 0.2);
  --rgba-primary-3: rgba(52, 58, 64, 0.3);
  --rgba-primary-4: rgba(52, 58, 64, 0.4);
  --rgba-primary-5: rgba(52, 58, 64, 0.5);
  --rgba-primary-6: rgba(52, 58, 64, 0.6);
  --rgba-primary-7: rgba(52, 58, 64, 0.7);
  --rgba-primary-8: rgba(52, 58, 64, 0.8);
  --rgba-primary-9: rgba(52, 58, 64, 0.9);
}

[data-primary="color_13"] {
  --primary: #056468;
  --secondary: #3693ff;
  --primary-hover: #033537;
  --primary-dark: black;
  --rgba-primary-1: rgba(5, 100, 104, 0.1);
  --rgba-primary-2: rgba(5, 100, 104, 0.2);
  --rgba-primary-3: rgba(5, 100, 104, 0.3);
  --rgba-primary-4: rgba(5, 100, 104, 0.4);
  --rgba-primary-5: rgba(5, 100, 104, 0.5);
  --rgba-primary-6: rgba(5, 100, 104, 0.6);
  --rgba-primary-7: rgba(5, 100, 104, 0.7);
  --rgba-primary-8: rgba(5, 100, 104, 0.8);
  --rgba-primary-9: rgba(5, 100, 104, 0.9);
}

[data-primary="color_14"] {
  --primary: #4885ed;
  --secondary: #3693ff;
  --primary-hover: #1a66e8;
  --primary-dark: #0e3d8e;
  --rgba-primary-1: rgba(72, 133, 237, 0.1);
  --rgba-primary-2: rgba(72, 133, 237, 0.2);
  --rgba-primary-3: rgba(72, 133, 237, 0.3);
  --rgba-primary-4: rgba(72, 133, 237, 0.4);
  --rgba-primary-5: rgba(72, 133, 237, 0.5);
  --rgba-primary-6: rgba(72, 133, 237, 0.6);
  --rgba-primary-7: rgba(72, 133, 237, 0.7);
  --rgba-primary-8: rgba(72, 133, 237, 0.8);
  --rgba-primary-9: rgba(72, 133, 237, 0.9);
}

[data-primary="color_15"] {
  --primary: #4cb32b;
  --secondary: #3693ff;
  --primary-hover: #3b8a21;
  --primary-dark: #18380d;
  --rgba-primary-1: rgba(76, 179, 43, 0.1);
  --rgba-primary-2: rgba(76, 179, 43, 0.2);
  --rgba-primary-3: rgba(76, 179, 43, 0.3);
  --rgba-primary-4: rgba(76, 179, 43, 0.4);
  --rgba-primary-5: rgba(76, 179, 43, 0.5);
  --rgba-primary-6: rgba(76, 179, 43, 0.6);
  --rgba-primary-7: rgba(76, 179, 43, 0.7);
  --rgba-primary-8: rgba(76, 179, 43, 0.8);
  --rgba-primary-9: rgba(76, 179, 43, 0.9);
}

[data-typography="opensans"] {
  font-family: "Open Sans", sans-serif;
}

[data-typography="Nunito"] {
  font-family: "Nunito Sans", sans-serif;
}

[data-typography="helvetica"] {
  font-family: "HelveticaNeue";
}

[data-typography="roboto"] {
  font-family: "Roboto", sans-serif;
}

[data-typography="poppins"] {
  font-family: "poppins", sans-serif;
}

[data-theme-version="dark"] {
  --nav-headbg: #18254f;
  --sidebar-bg: #18254f;
  --headerbg: #3736af;
  --card: #18254f;
  --text-dark: #fff;
  --text-gray: #b3b3b3;
  --text: #8896b9;
  --body-bg: #09153f;
  --light: #09153f;
  --border: #2a3969;
  --bs-border-color: #2a3969;
  --bs-tertiary-bg: #2a3969;
}

[data-theme-version="dark"][data-primary="color_1"],
[data-theme-version="dark"][data-primary="color_3"] {
  --primary: #7978ff;
  --rgba-primary-1: rgba(121, 120, 255, 0.1);
  --rgba-primary-2: rgba(121, 120, 255, 0.2);
  --rgba-primary-3: rgba(121, 120, 255, 0.3);
  --rgba-primary-4: rgba(121, 120, 255, 0.4);
  --rgba-primary-5: rgba(121, 120, 255, 0.5);
  --rgba-primary-6: rgba(121, 120, 255, 0.6);
  --rgba-primary-7: rgba(121, 120, 255, 0.7);
  --rgba-primary-8: rgba(121, 120, 255, 0.8);
  --rgba-primary-9: rgba(121, 120, 255, 0.9);
}

[data-theme-version="dark"] a {
  color: #fff;
}

[data-theme-version="dark"] a:hover {
  color: #fff;
}

[data-theme-version="dark"] .form-control.custom-image-select-2.bit {
  background-color: #09153f;
}

[data-theme-version="dark"] .brand-logo.invoice-logo .logo-abbr {
  filter: brightness(10.5);
}

[data-theme-version="dark"] .morris_chart_height text tspan {
  fill: #fff;
}

[data-theme-version="dark"] .apexcharts-gridlines-horizontal line {
  stroke: #2a3969;
}

[data-theme-version="dark"] .stacked-2:after {
  background-color: #18254f;
}

[data-theme-version="dark"] .picker .picker__frame .picker__box .picker__footer .picker__button--clear,
[data-theme-version="dark"] .picker .picker__frame .picker__box .picker__footer .picker__button--close,
[data-theme-version="dark"] .picker .picker__frame .picker__box .picker__footer .picker__button--today {
  color: #fff;
}

[data-theme-version="dark"] .apexcharts-xaxis line {
  stroke: #2a3969;
}

[data-theme-version="dark"] .ct-grid {
  stroke: rgba(255, 255, 255, 0.1);
}

[data-theme-version="dark"] .custom-react-select>div:nth-child(4)>div {
  border: 2px solid var(--border) !important;
}

[data-theme-version="dark"] .css-b62m3t-container>div {
  border-color: var(--border);
  background: var(--card);
}

[data-theme-version="dark"] .css-b62m3t-container [class*="-singleValue"],
[data-theme-version="dark"] .css-b62m3t-container [class*="-placeholder"] {
  color: var(--text);
}

[data-theme-version="dark"] .css-b62m3t-container [class*="-multiValue"] {
  background: var(--card);
}

[data-theme-version="dark"] .css-b62m3t-container [class*="-multiValue"] div {
  color: var(--text);
}

[data-theme-version="dark"] .css-b62m3t-container [class*="-IndicatorsContainer"] div {
  color: var(--text);
}

[data-theme-version="dark"] .css-b62m3t-container [class*="-menu"] {
  background: var(--card);
}

[data-theme-version="dark"] .css-b62m3t-container [class*="-menu"]>div>*:hover {
  background: var(--card);
}

[data-theme-version="dark"] .sidebar-right .sidebar-right-inner h4,
[data-theme-version="dark"] .sidebar-right .sidebar-right-inner .h4 {
  background-color: var(--card);
  color: #fff !important;
}

[data-theme-version="dark"] .sidebar-right .tab-content {
  background: var(--card);
}

[data-theme-version="dark"] .sidebar-right .tab-content .tab-pane .admin-settings p {
  color: var(--text);
}

[data-theme-version="dark"] .sidebar-right .sidebar-right-inner .admin-title {
  color: var(--text) !important;
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs {
  background: var(--card);
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link {
  background: var(--card);
  color: var(--text);
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link.active {
  background: var(--card);
}

[data-theme-version="dark"] .daterangepicker {
  background-color: var(--card);
  border-color: var(--border);
  color: var(--text);
}

[data-theme-version="dark"] .table.dataTable.display>tbody>tr>td {
  border-color: rgba(225, 225, 225, 0.15) !important;
}

[data-theme-version="dark"] .fc .fc-daygrid-day.fc-day-today {
  background-color: var(--card);
}

[data-theme-version="dark"] .fc-timegrid-slot fc-timegrid-slot-lane {
  background-color: var(--card);
}

[data-theme-version="dark"] .react-datepicker {
  background-color: var(--card);
  color: #fff;
  border-color: var(--border);
}

[data-theme-version="dark"] .react-datepicker .react-datepicker__current-month {
  color: #fff;
}

[data-theme-version="dark"] .react-datepicker .react-datepicker__header {
  background-color: var(--card);
  border-color: var(--border);
}

[data-theme-version="dark"] .react-datepicker .react-datepicker__day-name,
[data-theme-version="dark"] .react-datepicker .react-datepicker__day {
  color: #fff;
}

[data-theme-version="dark"] .react-datepicker .react-datepicker__navigation-icon {
  top: 6px;
}

[data-theme-version="dark"] .nav-header .brand-title path {
  fill: var(--text-dark);
}

[data-theme-version="dark"] .nav-header .brand-title path {
  fill: var(--text-dark);
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs {
  border-color: var(--rgba-primary-1) !important;
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link {
  color: var(--text) !important;
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link.active {
  background-color: var(--rgba-primary-1);
}

[data-theme-version="dark"] .sidebar-right .form-control {
  background: #fff;
  color: #000;
  border-color: #eeeeee;
}

[data-theme-version="dark"] .sidebar-right .default-select .list {
  background: #fff;
}

[data-theme-version="dark"] .sidebar-right .default-select .list .option.selected,
[data-theme-version="dark"] .sidebar-right .default-select .list .option.focus,
[data-theme-version="dark"] .sidebar-right .default-select .list .option:hover {
  background: rgba(0, 0, 0, 0.05) !important;
}

[data-theme-version="dark"] .sidebar-right .nice-select .option {
  background: #fff;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
  -moz-tab-size: 16;
  tab-size: 2;
  padding: 0 30px;
  padding-bottom: 30px;
}

@media only screen and (max-width: 100rem) {
  pre {
    padding: 0 25.6px;
    padding-bottom: 25.6px;
  }
}

@media only screen and (max-width: 35.9375rem) {
  pre {
    padding: 0 15.5px;
    padding-bottom: 15.5px;
  }
}

pre code.hljs {
  display: block;
  overflow-x: auto;
  color: #fff;
  padding: 20px;
  border-radius: 0.5rem;
}

pre code {
  background-color: #1d1329 !important;
}

.hljs-tag .hljs-attr,
.hljs-tag .hljs-name {
  color: #779de1;
}

.hljs-punctuation,
.hljs-tag {
  color: #779de1;
}

.hljs-deletion,
.hljs-number,
.hljs-quote,
.hljs-selector-class,
.hljs-selector-id,
.hljs-string,
.hljs-template-tag,
.hljs-type {
  color: #fd7e7e;
}

.code-area {
  position: relative;
}

.code-area .btn-hover {
  position: absolute;
  top: 10px;
  right: 28px;
  background-color: transparent;
  opacity: 0;
  transition: all 0.5s;
}

.code-area:hover .btn-hover {
  opacity: 1;
}

/* =============
   Calendar
============= */
.app-fullcalender button {
  border-radius: 0rem;
  color: #5b5e81;
}

.app-fullcalender td {
  border-color: #e6e6e6;
}

.calendar {
  float: left;
  margin-bottom: 0;
}

.fc-view {
  margin-top: 1.875rem;
}

.fc-toolbar {
  margin-bottom: 0.3125rem;
  margin-top: 0.9375rem;
}

.fc-toolbar .fc-left {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.625rem;
  float: none;
}

.fc-toolbar .fc-right {
  display: flex;
  justify-content: center;
  float: none;
  margin-bottom: 0.3125rem;
}

.fc-toolbar .fc-center {
  display: flex;
  justify-content: center;
}

.fc-toolbar .fc-center * {
  float: none;
}

.fc-toolbar h2,
.fc-toolbar .h2 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.875rem;
  text-transform: uppercase;
}

.fc-toolbar .fc-state-active,
.fc-toolbar .ui-state-active,
.fc-toolbar .ui-state-hover,
.fc-toolbar button:focus,
.fc-toolbar button:hover {
  z-index: 0;
  box-shadow: none;
}

.fc-widget-header {
  border: 0.0625rem solid #e6e6e6;
  border-bottom: 0 !important;
}

.fc th.fc-widget-header {
  background: #e6e6e6 !important;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.625rem 0;
  text-transform: uppercase;
}

.fc-button {
  border: 0.0625rem solid #e6e6e6;
  text-transform: capitalize;
}

.fc-button.active {
  box-shadow: none !important;
}

.fc-text-arrow {
  font-family: inherit;
  font-size: 1rem;
}

.fc-event,
.external-event {
  border-radius: 0.125rem;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 0.3125rem 0.4375rem;
  padding: 0.3125rem;
  text-align: center;
}

.external-event {
  cursor: move;
  margin: 0.625rem 0;
  padding: 0.125rem 0;
}

.fc-basic-view td.fc-week-number span {
  padding-right: 0.3125rem;
}

.fc-basic-view td.fc-day-number {
  padding-right: 0.3125rem;
}

#drop-remove {
  margin-right: 0.5rem;
  top: 0.1875rem;
}

#add-category .modal-dialog,
#event-modal .modal-dialog {
  max-width: 37.5rem;
}

.fc-content {
  color: #fff;
}

.fc th.fc-widget-header {
  background: transparent !important;
}

.fc-button {
  background: #fff;
}

.fc-state-hover {
  background: #fff !important;
}

.fc-state-highlight {
  background: #f5f5f5 !important;
}

[data-theme-version="dark"] .fc-state-highlight {
  color: #fff !important;
}

.fc-cell-overlay {
  background: #fff !important;
}

.fc-unthemed .fc-today {
  background: #f5f5f5 !important;
}

.fc-day-top {
  color: #5b5e81 !important;
}

[data-theme-version="dark"] .fc-day-top {
  color: #fff !important;
}

.external-event {
  color: #fff;
}

[data-theme-version="dark"] .external-event {
  color: #fff !important;
}

.fc-basic-view .fc-body .fc-row {
  min-height: 1rem;
}

.fc-scroller.fc-day-grid-container {
  height: 30.625rem !important;
}

.fc-row.fc-week.fc-widget-content.fc-rigid {
  height: 5.0625rem !important;
}

@media only screen and (max-width: 90rem) {
  .email_left_pane {
    display: none;
  }
}

#external-events .external-event:before {
  content: "";
  display: block;
  width: 0.875rem;
  min-width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
  margin-right: 0.9rem;
  position: relative;
  top: 0.125rem;
}

[data-theme-version="dark"] #external-events [data-class="bg-primary"] {
  color: #fff !important;
}

#external-events [data-class="bg-primary"]::before {
  background: var(--primary);
}

#external-events [data-class="bg-success"]::before {
  background: #3ab67a;
}

#external-events [data-class="bg-warning"]::before {
  background: #ffab2d;
}

#external-events [data-class="bg-dark"]::before {
  background: #5b5e81;
}

#external-events [data-class="bg-danger"]::before {
  background: #fd5353;
}

#external-events [data-class="bg-info"]::before {
  background: #00ada3;
}

#external-events [data-class="bg-pink"]::before {
  background: #ac4cbc;
}

#external-events [data-class="bg-secondary"]::before {
  background: #3693ff;
}

.fc .fc-row .fc-content-skeleton table,
.fc .fc-row .fc-content-skeleton td,
.fc .fc-row .fc-helper-skeleton td {
  border-color: var(--border);
}

[data-theme-version="dark"] .fc-unthemed .fc-content,
[data-theme-version="dark"] .fc-unthemed .fc-divider,
[data-theme-version="dark"] .fc-unthemed .fc-list-heading td,
[data-theme-version="dark"] .fc-unthemed .fc-list-view,
[data-theme-version="dark"] .fc-unthemed .fc-popover,
[data-theme-version="dark"] .fc-unthemed .fc-row,
[data-theme-version="dark"] .fc-unthemed tbody,
[data-theme-version="dark"] .fc-unthemed td,
[data-theme-version="dark"] .fc-unthemed th,
[data-theme-version="dark"] .fc-unthemed thead {
  border-color: #2a3969;
}

@media only screen and (max-width: 47.9375rem) {
  .email-left-box {
    width: 100%;
    float: none;
    border: none;
    padding-bottom: 1.875rem !important;
  }
}

.email-left-box .intro-title {
  background: var(--rgba-primary-1);
  padding: 1rem;
  margin: 1.875rem 0 1.25rem 0;
}

.email-left-box .intro-title h5,
.email-left-box .intro-title .h5 {
  margin-bottom: 0;
  color: #6a707e;
  font-size: 0.875rem;
}

.email-left-box .intro-title h5 i,
.email-left-box .intro-title .h5 i {
  font-size: 0.75rem;
  position: relative;
  bottom: 0.0625rem;
}

.email-left-box .intro-title i {
  color: var(--primary);
}

.email-right-box {
  padding-left: 15rem;
  padding-right: 1rem;
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .email-right-box {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0 !important;
    clear: both;
  }
}

.email-right-box .right-box-border {
  border-right: 0.125rem solid var(--rgba-primary-1);
}

@media screen and (min-width: 40.5625rem) and (max-width: 75rem) {
  .email-right-box .right-box-padding {
    padding-left: 1.25rem;
  }
}

@media (min-width: 106.25rem) {
  .email-right-box .right-box-padding {
    padding-left: 0.9375rem;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .email-right-box .right-box-padding {
    padding-left: 0;
  }
}

.toolbar .btn-group .btn {
  border: 0;
}

.toolbar .btn-group input {
  position: relative;
  top: 0.125rem;
}

.read-content textarea {
  height: 9.375rem;
  padding: 0.9375rem 1.25rem;
  border-radius: 0.5rem;
}

.read-content-email {
  font-size: 0.875rem;
}

.read-content h5,
.read-content .h5 {
  color: #6a707e;
}

.read-content p strong {
  color: #6a707e;
}

.read-content-body p {
  margin-bottom: 1.875rem;
}

.read-content-attachment {
  padding: 0.5rem 0;
}

.read-content-attachment h6,
.read-content-attachment .h6 {
  font-size: 1.125rem;
  color: #6a707e;
}

.read-content-attachment h6 i,
.read-content-attachment .h6 i {
  padding-right: 0.3125rem;
}

.read-content-attachment .attachment>div:not(:last-child) {
  border-right: 0.0625rem solid var(--border);
}

.compose-content .wysihtml5-toolbar {
  border-color: var(--border);
}

.compose-content .dropzone {
  background: var(--body-bg) !important;
}

.compose-content h5,
.compose-content .h5 {
  font-size: 1.0625rem;
  color: #6a707e;
}

.compose-content h5 i,
.compose-content .h5 i {
  font-size: 1.125rem;
  transform: rotate(90deg);
}

.compose-content .dropzone {
  border: 0.0625rem dashed var(--border);
  min-height: 13.125rem;
  position: relative;
  border-radius: 0.5rem;
}

.compose-content .dropzone .dlab-message {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.email-list {
  display: block;
  padding-left: 0;
}

.email-list .message {
  position: relative;
  display: block;
  height: 3.125rem;
  line-height: 3.125rem;
  cursor: default;
  transition-duration: 0.3s;
}

.email-list .message a {
  color: #828690;
}

.email-list .message-single .custom-checkbox {
  margin-top: 0.125rem;
}

.email-list .message-single i {
  color: #89879f;
  font-size: 1.125rem;
  padding-left: 0.4rem;
}

.email-list .message:hover {
  transition-duration: 0.05s;
  background: rgba(152, 166, 173, 0.15);
}

.email-list .message .col-mail {
  float: left;
  position: relative;
}

.email-list .message .col-mail-1 {
  width: 5.625rem;
}

.email-list .message .col-mail-1 .star-toggle {
  display: block;
  float: left;
  margin-top: 1.125rem;
  font-size: 1rem;
  margin-left: 0.3125rem;
}

.email-list .message .col-mail-1 .email-checkbox {
  display: block;
  float: left;
  margin: 0.9375rem 0.625rem 0 1.25rem;
}

.email-list .message .col-mail-1 .dot {
  display: block;
  float: left;
  border: 0.4rem solid transparent;
  border-radius: 6.25rem;
  margin: 1.375rem 1.625rem 0;
  height: 0;
  width: 0;
  line-height: 0;
  font-size: 0;
}

.email-list .message .col-mail-2 {
  position: absolute;
  top: 0;
  left: 5.625rem;
  right: 0;
  bottom: 0;
}

.email-list .message .col-mail-2 .subject {
  position: absolute;
  top: 0;
  left: 0;
  right: 5.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.email-list .message .col-mail-2 .date {
  position: absolute;
  top: 0;
  right: 0;
}

.email-checkbox {
  cursor: pointer;
  height: 0.9375rem;
  width: 0.9375rem;
  position: relative;
  display: inline-block;
  border-radius: 0.1rem;
  position: relative;
  top: 0.3125rem;
  box-shadow: inset 0 0 0 0.1rem #828690;
}

.email-checkbox input {
  opacity: 0;
  cursor: pointer;
}

.email-checkbox input:checked label {
  opacity: 1;
}

.email-checkbox label {
  position: absolute;
  top: 0.3rem;
  left: 0.3rem;
  right: 0.3rem;
  bottom: 0.3rem;
  cursor: pointer;
  opacity: 0;
  margin-bottom: 0 !important;
  transition-duration: 0.05s;
}

.mail-list a {
  vertical-align: middle;
  padding: 0.625rem 0.9375rem;
  display: block;
  background: transparent;
  color: #464a53;
  font-weight: 600;
}

.mail-list .list-group-item {
  color: #6a707e;
  padding: 0.75rem 1.0625rem;
}

.mail-list .list-group-item i {
  font-size: 1rem;
  padding-right: 0.625rem;
  color: #cccccc;
}

.mail-list .list-group-item.active {
  color: #fff;
}

.mail-list .list-group-item.active i {
  color: #fff;
}

.chat-wrap {
  padding: 1.0625rem 1.875rem;
}

.chat-wrap .media .media-body h6,
.chat-wrap .media .media-body .h6 {
  font-size: 1.0625rem;
  color: #6a707e;
}

.chat-wrap .media .media-body p {
  font-size: 0.875rem;
}

@media (min-width: 40.5rem) {
  .email-filter {
    padding-left: 1.25rem;
  }
}

@media (min-width: 106.25rem) {
  .email-filter {
    padding-left: 1.875rem;
  }
}

.email-filter .input-group-prepend i {
  font-size: 0.875rem;
  color: #89879f;
}

.email-filter .input-group-prepend .input-group-text {
  border: 0;
  border-bottom: 0.0625rem solid #dddfe1 !important;
  background: transparent;
}

.email-filter .input-group .form-control {
  padding: 0 0 0 0.3125rem;
  border: 0;
  font-size: 0.875rem;
  height: 1.875rem;
  color: #89879f;
  border-bottom: 0.0625rem solid #dddfe1;
}

.email-filter .input-group .form-control::placeholder {
  font-size: 0.875rem;
  color: #89879f;
}

.email-filter .input-group>.form-control {
  min-height: 1.875rem;
}

.single-mail {
  display: block;
  padding: 1.5625rem 0;
}

.single-mail .media {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

@media (min-width: 106.25rem) {
  .single-mail .media {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}

.single-mail .media img {
  width: 3.4375rem;
  height: 3.4375rem;
  border-radius: 50%;
  margin-right: 0.9375rem;
}

@media (min-width: 106.25rem) {
  .single-mail .media img {
    margin-right: 1.875rem;
  }
}

.single-mail .media-body h6,
.single-mail .media-body .h6 {
  color: #abafb3;
}

.single-mail .media-body h4,
.single-mail .media-body .h4 {
  font-size: 1rem;
  color: #6a707e;
}

.single-mail .media-body h4 button i,
.single-mail .media-body .h4 button i {
  font-size: 1.125rem;
  color: #abafb3;
  font-weight: bold;
  transform: rotate(90deg);
}

.single-mail .media-body p {
  font-size: 0.875rem;
  color: #abafb3;
}

.single-mail.active {
  background: var(--primary);
}

.single-mail.active h6,
.single-mail.active .h6,
.single-mail.active h4,
.single-mail.active .h4,
.single-mail.active p,
.single-mail.active i {
  color: #fff !important;
}

@media only screen and (max-width: 35.9375rem) {
  .email-left-body {
    width: 18rem;
    position: fixed;
    height: 100vh;
    z-index: 2;
    transition: all 0.5s;
    left: -22rem;
    top: 0px;
    z-index: 999;
  }

  .email-left-body.active {
    left: 0;
    background: var(--card);
    transition: all 0.5s;
    overflow-y: scroll;
  }
}

.email-tools-box {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  background-color: var(--rgba-primary-1);
  border-radius: 0.5rem;
  color: var(--primary);
  display: none !important;
}

.email-tools-box.active {
  background-color: #3736af;
  color: #fff;
}

@media only screen and (max-width: 35.9375rem) {
  .email-tools-box {
    display: inline-block !important;
    margin-left: auto;
  }
}

.email-tools-box.active:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.2;
  border-radius: 0.75rem;
  z-index: 4;
}

@media only screen and (max-width: 74.9375rem) {
  .email-left-box {
    margin-right: -10px;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .email-left-box {
    margin: 0;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .email-left-box {
    margin-top: 20px;
  }
}

.dropzone.compose .rs-uploader-trigger {
  text-align: center;
  margin: 7rem 0;
}

.dropzone.compose .rs-uploader-trigger .rs-uploader-trigger-btn {
  background: transparent;
  border: none;
}

.rs-uploader-trigger input[type="file"] {
  left: 0;
}

.photo-content {
  position: relative;
}

.photo-content .cover-photo {
  background: url(../images/profile/cover.jpg);
  background-size: cover;
  background-position: center;
  min-height: 15.625rem;
  width: 100%;
}

.profile .profile-photo {
  max-width: 6.25rem;
  position: relative;
  z-index: 1;
  margin-top: -4.5rem;
  margin-right: 0.625rem;
}

@media only screen and (max-width: 35.9375rem) {
  .profile .profile-photo {
    width: 5rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.25rem;
    margin-top: -2.5rem;
  }
}

.profile-info {
  padding: 0.9375rem 1.25rem;
}

@media only screen and (max-width: 35.9375rem) {
  .profile-info {
    padding: 0 0 1.25rem;
    text-align: center;
  }
}

.profile-info h4,
.profile-info .h4 {
  color: #464a53 !important;
}

.profile-info h4.text-primary,
.profile-info .text-primary.h4 {
  color: var(--primary) !important;
}

.profile-info p {
  color: #828690;
}

.profile-info .prf-col {
  min-width: 15.625rem;
  padding: 0.625rem 3.125rem 0;
}

.profile-interest .row {
  margin: 0 -0.0437rem;
}

.profile-interest .row .int-col {
  padding: 0 0.0437rem;
}

.profile-interest .row .int-col .interest-cat {
  margin-bottom: 0.0875rem;
  position: relative;
  display: block;
}

.profile-interest .row .int-col .interest-cat:after {
  background: #000;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

.profile-interest .row .int-col .interest-cat p {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0.3125rem;
  left: 0;
  margin: 0;
  z-index: 1;
  color: white;
  font-size: 0.075rem;
}

.profile-tab .nav-item .nav-link {
  font-size: 1rem;
  margin-right: 1.875rem;
  transition: all 0.5s ease-in-out;
  border: none;
  border-bottom: 0.0125rem solid transparent;
  color: #828690;
}

.profile-tab .nav-item .nav-link:hover,
.profile-tab .nav-item .nav-link.active {
  border: 0;
  background: transparent;
  background: transparent;
  border-bottom: 0.0125rem solid var(--primary);
  color: var(--primary);
}

@media only screen and (max-width: 35.9375rem) {
  .profile-tab .nav-item .nav-link {
    margin-right: 0rem;
  }
}

.profile-info {
  display: flex;
}

@media only screen and (max-width: 35.9375rem) {
  .profile-info {
    display: block;
  }
}

.profile-info .profile-details {
  display: flex;
  width: 100%;
}

@media only screen and (max-width: 35.9375rem) {
  .profile-info .profile-details {
    display: block;
  }

  .profile-info .profile-details .dropdown {
    position: absolute;
    top: 1.875rem;
    right: 1.875rem;
  }
}

.post-input {
  margin-bottom: 1.875rem;
}

.post-input .form-control {
  height: 4.6875rem;
  font-weight: 400;
  margin: 0.9375rem 0;
}

.post-input .btn-social {
  font-size: 1.25rem;
  height: 3.4375rem;
  display: inline-block;
  padding: 0;
  text-align: center;
  border-radius: 0.5rem;
  color: #fff;
  width: 3.4375rem;
  line-height: 3.375rem;
}

.post-input .btn-social.facebook {
  background-color: #3b5998;
}

.post-input .btn-social.google-plus {
  background-color: #de4e43;
}

.post-input .btn-social.linkedin {
  background-color: #007bb6;
}

.post-input .btn-social.instagram {
  background-color: #8a5a4e;
}

.post-input .btn-social.twitter {
  background-color: #1ea1f3;
}

.post-input .btn-social.youtube {
  background-color: #ce201f;
}

.post-input .btn-social.whatsapp {
  background-color: #01c854;
}

.post-input .btn-social i {
  margin: 0 !important;
}

.profile-uoloaded-post img {
  margin-bottom: 1.25rem;
}

.profile-uoloaded-post a h4,
.profile-uoloaded-post a .h4 {
  margin-bottom: 0.625rem;
  color: #464a53;
}

.event-chat-ryt .chat-area .chat-reciver,
.event-chat-ryt .chat-area .chat-sender {
  margin-bottom: 1.875rem;
  padding: 0;
}

.event-chat-ryt .chat-area .chat-reciver img,
.event-chat-ryt .chat-area .chat-sender img {
  border-radius: 1.875rem;
}

.event-chat-ryt .chat-area .media {
  position: relative;
}

.event-chat-ryt .chat-area .media-body p {
  margin: 0;
  max-width: 100%;
  display: inline-block;
  position: relative;
}

.event-chat-ryt .chat-area .media-body p span {
  padding: 1rem;
  display: inline-block;
  top: 103%;
  position: relative;
  border: 0.0625rem solid #e6e6e6;
}

.event-chat-ryt .chat-reciver {
  padding: 0.5rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.event-chat-ryt .chat-reciver .media {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.event-chat-ryt .chat-reciver .media .media-body {
  margin-right: 2rem;
  text-align: right;
}

.event-chat-ryt .chat-reciver .media .media-body p {
  background: #fff;
  margin-bottom: 0;
  border-radius: 0.3125rem 0.3125rem 0 0.3125rem;
}

.event-chat-ryt .chat-reciver .media .media-body p span {
  text-align: left;
  border: 0.0625rem solid #e6e6e6;
}

.event-chat-ryt .chat-reciver .media .media-body p span::after {
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  border-bottom: 0.0625rem solid #e6e6e6;
  border-right: 0.0625rem solid #e6e6e6;
  position: absolute;
  right: 0;
  bottom: 0;
  background: #fff;
  -webkit-transform: rotate(-45deg) translateX(15px);
  transform: rotate(-45deg) translateX(15px);
}

[direction="rtl"] .event-chat-ryt .chat-reciver .media .media-body p span::after {
  left: 0;
  right: auto;
  -webkit-transform: rotate(135deg) translateY(15px);
  transform: rotate(135deg) translateY(15px);
}

.event-chat-ryt .chat-reciver .media .media-body p .time {
  position: absolute;
  font-size: 0.75rem;
  color: #717579;
  font-weight: 400;
  bottom: 0;
  left: -5rem;
}

.event-chat-ryt .chat-sender {
  text-align: left;
  padding: 0.5rem 1rem;
}

.event-chat-ryt .chat-sender .media .media-body {
  margin-left: 2rem;
}

.event-chat-ryt .chat-sender .media .media-body p {
  background-color: #fff;
  margin-bottom: 0;
}

.event-chat-ryt .chat-sender .media .media-body p span::after {
  content: "";
  width: 1.25rem;
  height: 1.25rem;
  border-bottom: 0.0625rem solid #e6e6e6;
  border-left: 0.0625rem solid #e6e6e6;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fff;
  -webkit-transform: rotate(45deg) translateX(-15px);
  transform: rotate(45deg) translateX(-15px);
}

.event-chat-ryt .chat-sender .media .media-body p .time {
  position: absolute;
  font-size: 0.625rem;
  color: #717579;
  font-weight: 400;
  bottom: 0;
  right: -5rem;
}

.char-type {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}

.char-type form .form-control {
  height: 2.8125rem;
  padding-left: 1.125rem;
  background: #e7e7e7;
  border-right: 0;
}

.char-type form .input-group-append i {
  color: #898989;
  font-size: 1.125rem;
}

.char-type form .input-group-append .input-group-text {
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  background: #e7e7e7;
  border-color: #e6e6e6;
  border-left: 0;
}

.char-type form .input-group-append .input-group-text:last-child {
  padding-right: 1.8rem;
}

.media-avatar {
  padding: 1.5625rem 0;
  border-bottom: 0.0625rem solid #e6e6e6;
}

.media-avatar:last-child {
  border-bottom: 0rem;
}

.media-avatar p {
  margin-bottom: 0;
}

.media-avatar .avatar-status {
  position: relative;
}

.media-avatar .avatar-status i {
  position: absolute;
  right: 0;
  bottom: 0;
}

.ct-golden-section:before {
  float: none;
}

.ct-chart {
  max-height: 15.7rem;
}

@media only screen and (max-width: 74.9375rem) {
  .ct-chart {
    max-height: 100%;
  }
}

.ct-chart .ct-label {
  fill: #a3afb7;
  color: #a3afb7;
  font-size: 0.75rem;
  line-height: 1;
}

.ct-grid {
  stroke: rgba(49, 58, 70, 0.1);
}

.ct-chart.simple-pie-chart-chartist .ct-label {
  color: #ffffff;
  fill: #ffffff;
  font-size: 0.625rem;
}

.ct-chart .ct-series.ct-series-a .ct-bar,
.ct-chart .ct-series.ct-series-a .ct-line,
.ct-chart .ct-series.ct-series-a .ct-point,
.ct-chart .ct-series.ct-series-a .ct-slice-donut {
  stroke: var(--primary);
}

.ct-chart .ct-series.ct-series-b .ct-bar,
.ct-chart .ct-series.ct-series-b .ct-line,
.ct-chart .ct-series.ct-series-b .ct-point,
.ct-chart .ct-series.ct-series-b .ct-slice-donut {
  stroke: #3ab67a;
}

.ct-chart .ct-series.ct-series-c .ct-bar,
.ct-chart .ct-series.ct-series-c .ct-line,
.ct-chart .ct-series.ct-series-c .ct-point,
.ct-chart .ct-series.ct-series-c .ct-slice-donut {
  stroke: #ffab2d;
}

.ct-chart .ct-series.ct-series-d .ct-bar,
.ct-chart .ct-series.ct-series-d .ct-line,
.ct-chart .ct-series.ct-series-d .ct-point,
.ct-chart .ct-series.ct-series-d .ct-slice-donut {
  stroke: #fd5353;
}

.ct-chart .ct-series.ct-series-e .ct-bar,
.ct-chart .ct-series.ct-series-e .ct-line,
.ct-chart .ct-series.ct-series-e .ct-point,
.ct-chart .ct-series.ct-series-e .ct-slice-donut {
  stroke: #00ada3;
}

.ct-chart .ct-series.ct-series-f .ct-bar,
.ct-chart .ct-series.ct-series-f .ct-line,
.ct-chart .ct-series.ct-series-f .ct-point,
.ct-chart .ct-series.ct-series-f .ct-slice-donut {
  stroke: #5b5e81;
}

.ct-chart .ct-series.ct-series-g .ct-bar,
.ct-chart .ct-series.ct-series-g .ct-line,
.ct-chart .ct-series.ct-series-g .ct-point,
.ct-chart .ct-series.ct-series-g .ct-slice-donut {
  stroke: #8d6e63;
}

.ct-series-a .ct-area,
.ct-series-a .ct-slice-pie {
  fill: #3693ff;
}

.ct-series-b .ct-area,
.ct-series-b .ct-slice-pie {
  fill: #00a2ff;
}

.ct-series-c .ct-area,
.ct-series-c .ct-slice-pie {
  fill: #ff9800;
}

.ct-series-d .ct-area,
.ct-series-d .ct-slice-pie {
  fill: #ff9800;
}

.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 0.625rem;
  padding: 0.125rem 0.625rem;
  border-radius: 0.125rem;
  background: #313a46;
  color: #ffffff;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.chartist-tooltip.tooltip-show {
  opacity: 1;
}

#donught_graph .ct-series.ct-series-a .ct-slice-donut {
  stroke: #3fc6d4;
}

#donught_graph .ct-series.ct-series-b .ct-slice-donut {
  stroke: #333333;
}

#donught_graph .ct-series.ct-series-c .ct-slice-donut {
  stroke: #f63465;
}

@media only screen and (max-width: 47.9375rem) {
  #pie-chart .ct-label {
    color: #fff;
    fill: #fff;
  }
}

#visitor_graph {
  height: 15.8125rem !important;
}

#user_rating_graph {
  height: 17.5rem !important;
}

#activity {
  height: 16.875rem !important;
}

#visitorOnline {
  height: 4.5rem !important;
}

#trendMeter {
  height: 4.5rem !important;
}

#widget-revenue1,
#widget-revenue2,
#widget-revenue3 {
  height: 7.3125rem !important;
}

#widget-profit1,
#widget-profit2,
#widget-profit3 {
  height: 10rem !important;
}

#comparison-rate {
  height: 14.375rem;
}

#session_day {
  height: 10.9375rem;
  width: auto !important;
  margin: 0 auto;
}

#walet-status {
  height: 8.75rem;
}

#bar1 {
  height: 9.375rem;
}

#sold-product {
  height: 14.375rem;
}

#chart-venue-expenses,
#chart-online-sale,
#chart-gross-sale {
  height: 9.375rem !important;
}

#areaChart_3 {
  height: 18.4375rem !important;
  width: 100% !important;
}

@media only screen and (max-width: 35.9375rem) {
  #areaChart_3 {
    height: 100% !important;
  }
}

.chart-point {
  display: flex;
  align-items: center;
}

.chart-point .check-point-area {
  width: 6.25rem;
  height: 6.25rem;
  margin-top: -0.625rem;
  margin-left: -0.625rem;
}

.chart-point .chart-point-list {
  margin: 0;
  padding-left: 0.3125rem;
}

.chart-point .chart-point-list li {
  list-style: none;
  font-size: 0.8125rem;
  padding: 0.125rem 0;
}

.chart-point .chart-point-list li i {
  margin-right: 0.3125rem;
  font-size: 0.6875rem;
  position: relative;
  top: -0.0625rem;
}

#activity {
  height: 20.875rem !important;
}

.flot-chart {
  height: 15.7rem;
  direction: ltr;
}

.tooltipflot {
  background-color: transparent;
  font-size: 1.4rem;
  padding: 0.5rem 1rem;
  color: rgba(255, 255, 255, 0.7);
  border-radius: 0.2rem;
}

.legendColorBox>div {
  border: 0 !important;
  padding: 0 !important;
}

.legendLabel {
  font-size: 0.825rem;
  padding-left: 0.5rem;
  color: #000;
}

.flotTip {
  background: #000;
  border: 0.0625rem solid #000;
  color: #fff;
}

.legend>div {
  background: transparent !important;
}

#balance_graph {
  height: 16.25rem;
}

.morris-hover {
  position: absolute;
  z-index: 1;
  background: var(--primary);
  color: #fff;
}

.morris-hover .morris-hover-point {
  color: #fff !important;
  margin: 0.1875rem 0;
  text-align: center;
  padding: 0 1.5625rem;
  color: #fff;
}

.morris-hover .morris-hover-row-label {
  background-color: #5b5e81;
  text-align: center;
  padding: 0.3125rem;
  color: #fff;
  margin-bottom: 0.3125rem;
}

.morris-hover.morris-default-style {
  border-radius: 0.3125rem;
  padding: 0;
  margin: 0;
  border: none;
  overflow: hidden;
}

#morris_donught,
#morris_donught_2,
#line_chart_2,
#morris_bar,
#morris_bar_stalked,
#morris_bar_2,
#morris_area_2,
#morris_area {
  height: 15rem !important;
}

#morris_line {
  height: 17.375rem !important;
}

#crypto-btc-card,
#crypto-eth-card,
#crypto-rpl-card,
#crypto-ltc-card {
  height: 9.375rem;
}

#daily-sales,
#comparison-rate,
#usage-chart,
#walet-status {
  width: 100%;
  display: block;
}

#daily-sales canvas,
#comparison-rate canvas,
#usage-chart canvas,
#walet-status canvas {
  max-width: 100% !important;
  width: 100% !important;
}

#sparkline-composite-chart canvas,
#composite-bar canvas,
#sparkline11 canvas,
#StackedBarChart canvas,
#spark-bar canvas,
#tristate canvas {
  height: 6.25rem !important;
}

#sparkline11 canvas {
  width: 6.25rem !important;
}

.easy-pie-chart {
  position: relative;
  text-align: center;
}

.easy-pie-chart .inner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
}

.easy-pie-chart img {
  width: 4.6875rem;
  height: 4.6875rem;
  border-radius: 50%;
}

.easy-pie-chart canvas {
  display: block;
  margin: 0 auto;
}

.form-control {
  background: var(--card);
  color: var(--text);
  line-height: 1.7;
  font-size: 0.9rem;
  border-color: var(--border);
  height: 2.75rem;
  border-radius: 0.5rem;
}

.form-control:hover,
.form-control:focus,
.form-control.active {
  box-shadow: none;
  background: var(--card);
  color: var(--text);
}

.form-control:focus {
  border-color: var(--primary);
}

.form-control.solid {
  background: var(--body-color);
  border-color: var(--border);
}

color:var(--text-dark) .form-control.solid[type="file"] {
  padding: 6px 10px;
}

.form-control.form-control-lg {
  line-height: 2.7rem;
}

.form-control.form-control-lg .dropdown-toggle {
  padding: 0.5rem 1rem;
  height: 3.7rem;
}

.form-control.form-control-sm {
  line-height: 1.9rem;
}

.form-control.form-control-sm .dropdown-toggle {
  padding: 0.25rem 1rem;
  height: 2.3rem;
}

.form-control.form-control-sm .dropdown-item {
  line-height: 1.6rem;
}

.input-rounded {
  border-radius: 6.25rem;
}

.form-control[type="file"] {
  line-height: 31px;
  padding-left: 12px;
}

@media only screen and (max-width: 100rem) {
  .form-control[type="file"] {
    line-height: 1.975rem;
    padding-left: 9.8px;
  }
}

.form-control-sm[type="file"] {
  line-height: 22px;
  padding: 4px;
  padding-left: 8px;
}

@media only screen and (max-width: 100rem) {
  .form-control-sm[type="file"] {
    line-height: 27px;
    padding-left: 6.9px;
  }
}

.form-control-lg[type="file"] {
  line-height: 44px;
  padding-left: 10px;
}

[data-theme-version="light"] .input-primary .form-control,
[data-theme-version="dark"] .input-primary .form-control {
  border-color: var(--primary);
}

[data-theme-version="light"] .input-primary .input-group-text,
[data-theme-version="dark"] .input-primary .input-group-text {
  background-color: var(--primary);
  color: #fff;
}

[data-theme-version="light"] .input-danger .form-control,
[data-theme-version="dark"] .input-danger .form-control {
  border-color: #fd5353;
}

[data-theme-version="light"] .input-danger .input-group-text,
[data-theme-version="dark"] .input-danger .input-group-text {
  background-color: #fd5353;
  color: #fff;
}

[data-theme-version="light"] .input-info .form-control,
[data-theme-version="dark"] .input-info .form-control {
  border-color: #00ada3;
}

[data-theme-version="light"] .input-info .input-group-text,
[data-theme-version="dark"] .input-info .input-group-text {
  background-color: #00ada3;
  color: #fff;
}

[data-theme-version="light"] .input-success .form-control,
[data-theme-version="dark"] .input-success .form-control {
  border-color: #3ab67a;
}

[data-theme-version="light"] .input-success .input-group-text,
[data-theme-version="dark"] .input-success .input-group-text {
  background-color: #3ab67a;
  color: #fff;
}

[data-theme-version="light"] .input-warning .form-control,
[data-theme-version="dark"] .input-warning .form-control {
  border-color: #ffab2d;
}

[data-theme-version="light"] .input-warning .input-group-text,
[data-theme-version="dark"] .input-warning .input-group-text {
  background-color: #ffab2d;
  color: #fff;
}

[data-theme-version="light"] .input-primary-o .form-control,
[data-theme-version="dark"] .input-primary-o .form-control {
  border-color: var(--primary);
  margin-left: -2px !important;
}

[data-theme-version="light"] .input-primary-o .input-group-text,
[data-theme-version="dark"] .input-primary-o .input-group-text {
  background-color: transparent;
  border-color: var(--primary);
  color: var(--primary);
}

[data-theme-version="light"] .input-danger-o .form-control,
[data-theme-version="dark"] .input-danger-o .form-control {
  border-color: #fd5353;
  margin-left: -2px !important;
}

[data-theme-version="light"] .input-danger-o .input-group-text,
[data-theme-version="dark"] .input-danger-o .input-group-text {
  background-color: transparent;
  border-color: #fd5353;
  color: #fd5353;
}

[data-theme-version="light"] .input-info-o .form-control,
[data-theme-version="dark"] .input-info-o .form-control {
  border-color: #00ada3;
  margin-left: -2px !important;
}

[data-theme-version="light"] .input-info-o .input-group-text,
[data-theme-version="dark"] .input-info-o .input-group-text {
  background-color: transparent;
  border-color: #00ada3;
  color: #00ada3;
}

[data-theme-version="light"] .input-success-o .form-control,
[data-theme-version="dark"] .input-success-o .form-control {
  border-color: #3ab67a;
  margin-left: -2px !important;
}

[data-theme-version="light"] .input-success-o .input-group-text,
[data-theme-version="dark"] .input-success-o .input-group-text {
  background-color: transparent;
  border-color: #3ab67a;
  color: #3ab67a;
}

[data-theme-version="light"] .input-warning-o .form-control,
[data-theme-version="dark"] .input-warning-o .form-control {
  border-color: #ffab2d;
  margin-left: -2px !important;
}

[data-theme-version="light"] .input-warning-o .input-group-text,
[data-theme-version="dark"] .input-warning-o .input-group-text {
  background-color: transparent;
  border-color: #ffab2d;
  color: #ffab2d;
}

.input-group-text {
  min-width: 3.125rem;
  justify-content: center;
  border-radius: 0.5rem;
  background-color: var(--light);
  border-color: var(--border);
}

button.btn.btn-primary.btn-outline-primary.dropdown-toggle {
  border: 0;
}

.form-file-label {
  height: 2.5rem;
  padding: 0.5rem 0.75rem;
}

.input-group-prepend .btn,
.input-group-append .btn {
  z-index: 0;
}

.custom-select {
  background: none;
  border-color: #e6e6e6;
  color: #5b5e81;
}

.custom-select:focus {
  box-shadow: none;
  border-color: var(--primary);
  color: var(--primary);
}

.form-file-label {
  background: #656c73;
  white-space: nowrap;
  color: #fff;
}

[data-theme-version="dark"] .form-file-label {
  background: #2a3969;
  border-color: #2a3969;
  color: #717579;
}

.custom_file_input .form-file-label::after {
  height: 100%;
}

.form-control:disabled,
.form-control[readonly] {
  background: var(--light);
  opacity: 1;
}

.form-file {
  border: 0.0625rem solid #e6e6e6;
  background: #fff;
}

[data-theme-version="dark"] .form-file {
  background: #09153f;
  border-color: #2a3969;
}

.input-group {
  /* &> .form-file {
		display: flex;
		align-items: center;

		&:not(:last-child) .form-file-label,
		&:not(:last-child) .form-file-label::after { @include border-right-radius(0); }
		&:not(:first-child) .form-file-label { @include border-left-radius(0); }
	  } */
}

.input-group>.form-control-plaintext,
.input-group>.form-select,
.input-group>.form-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  border-radius: 0.5rem;
}

.form-file .form-control {
  margin: 0;
  border-radius: 0;
  border: 0;
  height: auto;
}

.form-file .form-control {
  margin: 0;
  border-radius: 0;
  border: 0;
  height: auto;
}

.input-group .input-group-text+.form-file {
  border-radius: 0 0.5rem 0.5rem 0;
}

.input-group .input-group-text+.form-file .form-control {
  border-radius: inherit;
}

.custom_file_input .form-file {
  border-radius: 0.5rem;
}

.custom_file_input .form-file .form-control {
  margin: 0;
  border: 0;
  line-height: 3.125rem;
  border-radius: inherit;
  font-size: 16px;
  font-weight: 600;
}

.custom_file_input .form-control {
  padding-left: 12px;
  line-height: 45px;
}

#username {
  border-radius: 0 0.5rem 0.5rem 0;
}

.form-control-lg {
  min-height: 3.75rem;
  padding: 0.5rem 1.1rem;
  font-size: 1.09375rem;
  border-radius: 0.5rem;
  height: calc(1.5em + 1rem + 2px);
}

.form-control-sm {
  min-height: 2.5rem;
  padding: 0.25rem 1.1rem;
  font-size: 0.76563rem;
  border-radius: 0.5rem;
  height: calc(1.5em + 0.5rem + 2px);
}

.ck.ck-editor__main>.ck-editor__editable {
  background: #f9fafb !important;
}

.modal-grid {
  background: var(--body-bg);
}

.nice-select.wide.form-control-lg {
  line-height: 2.7125rem;
}

.nice-select.wide.form-control-sm {
  line-height: 1.8125rem;
}

.inline-check {
  line-height: 1.8rem !important;
}

.form-check {
  font-size: 14px;
  font-weight: 600;
  padding: 0;
  padding-left: 1.5em;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.input-full {
  border: 0;
}

.border-1 {
  border-radius: 0.5rem 0 0 0.5rem !important;
}

.border3 {
  border-radius: 0 0.5rem 0.5rem 0 !important;
}

.custome-input .input-group-text {
  border-color: transparent !important;
}

.bootstrap-select .btn {
  padding: 0.6875rem 0.65rem;
  border-color: var(--borer);
  border-width: 1px;
  line-height: 1.2;
  height: 2.7rem;
  font-size: 0.9rem;
}

form-control[type="file"] {
  line-height: 44px;
  padding-left: 12px;
}

.form-control-sm[type="file"] {
  line-height: 32px;
  padding: 4px;
  padding-left: 8px;
}

@media only screen and (max-width: 47.9375rem) {
  .form-control-sm[type="file"] {
    line-height: 28px;
    padding: 3px;
    padding-left: 7px;
  }
}

.form-control-lg[type="file"] {
  line-height: 42px;
  padding-left: 10px;
}

@media only screen and (max-width: 47.9375rem) {
  .form-control-lg[type="file"] {
    line-height: 36.1px;
  }
}

.bootstrap-select.show-tick .dropdown-menu .selected span.check-mark {
  right: 15px;
  left: auto;
}

.input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n + 3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.input-group button.dropdown-toggle.dropdown-toggle-split:nth-child(2) {
  border-radius: 0 !important;
}

.vertical-radius .input-group:not(.has-validation) :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating).br-style {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.custom-select .dropdown-menu {
  z-index: 3;
}

.col-form-label {
  color: var(--text-dark);
}

.right-radius {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.left-radius {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.form-check-label {
  margin-left: 0.3125rem;
  margin-top: 5px;
}

.form-check-inline .form-check-input {
  margin-right: 0.625rem;
}

.form-check-input {
  top: 0.125rem;
  border-width: 0.125rem;
  width: 1.25rem;
  height: 1.25rem;
  border-color: var(--border);
  background-color: var(--body-bg);
}

.rtl .form-check-label:before,
.rtl .form-check-label:after {
  right: -1.5rem !important;
  left: inherit;
}

.form-check {
  line-height: normal;
}

.toggle-switch {
  padding-left: 3.125rem;
  line-height: 1.25;
  display: inline-block;
  color: var(--text-dark);
  font-weight: 600;
}

.toggle-switch .form-check-input {
  border: 0;
  cursor: pointer;
  background: #d8d8d8;
  width: 2.2125rem;
  border-radius: 1.25rem !important;
  height: 0.875rem;
  position: relative;
  left: -0.3125rem;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  background-image: none;
}

.toggle-switch .form-check-input:focus {
  background-image: none !important;
}

.toggle-switch .form-check-input:checked {
  background: var(--rgba-primary-1);
  background-image: none !important;
}

.toggle-switch .form-check-input:checked:after {
  left: 1.3rem;
  background: var(--primary);
}

.toggle-switch .form-check-input:focus {
  box-shadow: none;
}

.toggle-switch .form-check-input:after {
  width: 1rem;
  background: #909090;
  height: 1rem;
  content: "";
  position: absolute;
  border-radius: 1.5rem;
  top: -0.0475rem;
  left: 0;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

.toggle-switch.text-end {
  padding-right: 0rem;
  padding-left: 0;
}

.toggle-switch.text-end .form-check-input {
  left: auto;
  margin-left: 0;
  float: right;
  right: 0rem;
}

.toggle-switch.text-end .form-check-label {
  margin-right: 0.9375rem;
  margin-left: 0;
}

.toggle-switch .form-check-label {
  cursor: pointer;
}

@media only screen and (max-width: 35.9375rem) {
  .toggle-switch {
    padding-left: 1.925rem;
  }
}

.form-check-input:focus~.form-check-label::before {
  box-shadow: none !important;
}

.form-check-label::before {
  background-color: transparent;
  border-color: #dedeed;
  border-width: 0.125rem;
  border-radius: 0.125rem !important;
}

[data-theme-version="dark"] .form-check-label::before {
  background-color: transparent;
  border-color: #2a3969;
}

.check-xs .form-check-input {
  width: 1.125rem;
  height: 1.125rem;
}

.check-lg .form-check-input {
  width: 1.5rem;
  height: 1.5rem;
}

.check-xl .form-check-input {
  width: 1.75rem;
  height: 1.75rem;
}

.checkbox-info .form-check-input:focus {
  border-color: #00ada3;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(0, 173, 163, 0.25);
}

.checkbox-info .form-check-input:checked {
  background-color: #00ada3;
  border-color: #00ada3;
}

.checkbox-danger .form-check-input:focus {
  border-color: #fd5353;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(253, 83, 83, 0.25);
}

.checkbox-danger .form-check-input:checked {
  background-color: #fd5353;
  border-color: #fd5353;
}

.checkbox-success .form-check-input:focus {
  border-color: #3ab67a;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(58, 182, 122, 0.25);
}

.checkbox-success .form-check-input:checked {
  background-color: #3ab67a;
  border-color: #3ab67a;
}

.checkbox-warning .form-check-input:focus {
  border-color: #ffab2d;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 171, 45, 0.25);
}

.checkbox-warning .form-check-input:checked {
  background-color: #ffab2d;
  border-color: #ffab2d;
}

.checkbox-secondary .form-check-input:focus {
  border-color: #3693ff;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(54, 147, 255, 0.25);
}

.checkbox-secondary .form-check-input:checked {
  background-color: #3693ff;
  border-color: #3693ff;
}

.check-switch {
  padding-left: 2.5rem;
}

.check-switch .form-check-label {
  line-height: 1.875rem;
  font-weight: 500;
}

.check-switch .form-check-label span {
  line-height: 1;
}

.check-switch .form-check-label:after,
.check-switch .form-check-label:before {
  height: 1.5rem;
  width: 1.5rem;
  left: -2rem;
  border-radius: 3rem !important;
  border-color: var(--rgba-primary-3);
}

.check-switch .form-check-input:checked~.form-check-label::after {
  background-image: url("../images/svg/check.svg");
}

.check-switch .form-check-input:checked~.form-check-label::before {
  background: #fff;
}

.form-check .form-check-input[type="radio"] {
  top: 0.03rem;
}

.form-check-input:checked {
  background-color: var(--primary);
  border-color: var(--primary);
}

.form-check-input:focus {
  border-color: var(--primary);
  box-shadow: var(--rgba-primary-1);
}

.form-check-input[type="radio"] {
  width: 1.313rem;
  min-width: 1.313rem;
  height: 1.313rem;
  position: relative;
  border-color: var(--border);
  border-width: 0.125rem;
  border-style: solid;
  top: -0.125rem;
}

.form-check-input[type="radio"]:focus {
  box-shadow: none;
}

.form-check-input[type="radio"]+.form-check-label {
  margin-bottom: 0;
}

.form-check-input:checked[type="radio"] {
  background-image: none;
  background-color: var(--rgba-primary-3);
  border-color: transparent !important;
}

.form-check-input:checked[type="radio"]:after {
  content: "";
  height: 0.688rem;
  width: 0.688rem;
  background-color: var(--primary);
  position: absolute;
  top: 0.188rem;
  left: 0.188rem;
  border-radius: 50%;
}

.checkbox-info .form-check-input[type="radio"] {
  border-color: #00ada3;
}

.checkbox-info .form-check-input:checked[type="radio"] {
  background-color: #94fff9;
}

.checkbox-info .form-check-input:checked[type="radio"]:after {
  background-color: #00ada3;
}

.checkbox-danger .form-check-input[type="radio"] {
  border-color: #fd5353;
}

.checkbox-danger .form-check-input:checked[type="radio"] {
  background-color: #ffeaea;
}

.checkbox-danger .form-check-input:checked[type="radio"]:after {
  background-color: #fd5353;
}

.checkbox-success .form-check-input[type="radio"] {
  border-color: #3ab67a;
}

.checkbox-success .form-check-input:checked[type="radio"] {
  background-color: #e0f5eb;
}

.checkbox-success .form-check-input:checked[type="radio"]:after {
  background-color: #3ab67a;
}

.checkbox-warning .form-check-input[type="radio"] {
  border-color: #ffab2d;
}

.checkbox-warning .form-check-input:checked[type="radio"] {
  background-color: #fff2e0;
}

.checkbox-warning .form-check-input:checked[type="radio"]:after {
  background-color: #ffab2d;
}

.checkbox-dark .form-check-input[type="radio"] {
  border-color: #5b5e81;
}

.checkbox-dark .form-check-input:checked[type="radio"] {
  background-color: white;
}

.checkbox-dark .form-check-input:checked[type="radio"]:after {
  background-color: #3736af;
}

.checkbox-secondary .form-check-input[type="radio"] {
  border-color: #3693ff;
}

.checkbox-secondary .form-check-input:checked[type="radio"] {
  background-color: #cfe5ff;
}

.checkbox-secondary .form-check-input:checked[type="radio"]:after {
  background-color: #3693ff;
}

.js-switch+.switchery {
  border-radius: 3.125rem;
  margin-right: 4rem;
}

@media (max-width: 575.98px) {
  .js-switch+.switchery {
    margin-right: 1rem;
  }
}

.js-switch+.switchery>small,
.js-switch+.switchery>.small {
  top: 0.125rem;
}

.js-switch.js-switch-lg+.switchery {
  height: 2rem;
  width: 4.5rem;
}

.js-switch.js-switch-lg+.switchery>small,
.js-switch.js-switch-lg+.switchery>.small {
  width: 1.75rem;
  height: 1.75rem;
}

.js-switch.js-switch-md+.switchery {
  height: 1.5rem;
  width: 3.5rem;
}

.js-switch.js-switch-md+.switchery>small,
.js-switch.js-switch-md+.switchery>.small {
  width: 1.25rem;
  height: 1.25rem;
}

.js-switch.js-switch-sm+.switchery {
  height: 1rem;
  width: 2.2rem;
}

.js-switch.js-switch-sm+.switchery>small,
.js-switch.js-switch-sm+.switchery>.small {
  width: 0.875rem;
  height: 0.875rem;
  top: 0.0625rem;
}

.js-switch-square+.switchery {
  border-radius: 0;
}

.js-switch-square+.switchery>small,
.js-switch-square+.switchery>.small {
  border-radius: 0;
  top: 0.125rem;
}

.js-switch-square.js-switch-lg+.switchery {
  height: 2rem;
  width: 4.5rem;
}

.js-switch-square.js-switch-lg+.switchery>small,
.js-switch-square.js-switch-lg+.switchery>.small {
  width: 1.75rem;
  height: 1.75rem;
}

.js-switch-square.js-switch-md+.switchery {
  height: 1.5rem;
  width: 3.5rem;
}

.js-switch-square.js-switch-md+.switchery>small,
.js-switch-square.js-switch-md+.switchery>.small {
  width: 1.25rem;
  height: 1.25rem;
}

.js-switch-square.js-switch-sm+.switchery {
  height: 1rem;
  width: 2.2rem;
}

.js-switch-square.js-switch-sm+.switchery>small,
.js-switch-square.js-switch-sm+.switchery>.small {
  width: 0.875rem;
  height: 0.875rem;
  top: 0.0625rem;
}

.form-control.is-valid {
  border-color: #3ab67a !important;
  border-right: 0rem !important;
}

.form-control.is-valid:focus {
  box-shadow: none;
}

.form-control.is-warning {
  border-color: #ffab2d !important;
  border-right: 0rem !important;
}

.form-control.is-warning:focus {
  box-shadow: none;
}

.form-control.is-invalid {
  border-color: #fd5353 !important;
  border-right: 0rem !important;
}

.form-control.is-invalid:focus {
  box-shadow: none;
}

.is-valid .input-group-prepend .input-group-text i {
  color: #3ab67a;
}

.is-invalid .input-group-prepend .input-group-text i {
  color: var(--rgba-primary-2);
}

.eye,
.show-pass,
.show-pass-1 {
  cursor: pointer;
}

.show-pass .fa-eye,
.show-pass-1 .fa-eye {
  display: none;
}

.show-pass.active .fa-eye-slash,
.show-pass-1.active .fa-eye-slash {
  display: none;
}

.show-pass.active .fa-eye,
.show-pass-1.active .fa-eye {
  display: inline-block;
}

.asColorPicker-dropdown {
  max-width: 26rem;
}

.asColorPicker-trigger {
  border: 0 none;
  height: 100%;
  position: absolute;
  left: 99%;
  top: 0;
  width: 2.1875rem;
}

[direction="rtl"] .asColorPicker-trigger {
  right: 99%;
  left: auto;
}

.asColorPicker-clear {
  display: none;
  position: absolute;
  right: 1rem;
  text-decoration: none;
  top: 0.5rem;
}

.daterangepicker td.active {
  background-color: #3736af;
}

.daterangepicker td.active:hover {
  background-color: #3736af;
}

.daterangepicker button.applyBtn {
  background-color: #3736af;
  border-color: #3736af;
}

.datepicker.datepicker-dropdown {
  background: #f5f5f5;
  border-radius: 1px;
  border: 1px solid #eeeeee;
}

.datepicker.datepicker-dropdown td.day,
.datepicker.datepicker-dropdown th.next,
.datepicker.datepicker-dropdown th.prev {
  height: 30px;
  width: 30px !important;
  padding: 0;
  text-align: center;
  font-weight: 300;
  border-radius: 50px;
}

.datepicker.datepicker-dropdown td.day:hover,
.datepicker.datepicker-dropdown th.next:hover,
.datepicker.datepicker-dropdown th.prev:hover {
  box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
  color: #fff;
}

.datepicker.datepicker-dropdown th.datepicker-switch,
.datepicker.datepicker-dropdown th.next,
.datepicker.datepicker-dropdown th.prev {
  font-weight: 300;
  color: #333;
}

.datepicker.datepicker-dropdown th.dow {
  font-weight: 300;
}

.datepicker table tr td.selected,
.datepicker table tr td.active.active {
  box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
  border: 0;
}

.datepicker table tr td.today {
  box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
  color: #ffffff;
}

.datepicker table tr td.today:hover {
  box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
  color: #ffffff;
}

.datepicker table tr td.today.disabled {
  box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
  color: #ffffff;
}

.datepicker table tr td.today.disabled:hover {
  box-shadow: 0px 0px 30px 5px rgba(243, 30, 122, 0.3);
  color: #ffffff;
}

.picker__select--month,
.picker__select--year {
  height: 2.5em;
}

.picker__input {
  background-color: transparent !important;
}

[data-theme-version="dark"] .picker__input {
  background-color: transparent !important;
  border: 1px solid #2a3969;
}

.clockpicker-popover {
  position: absolute;
}

.clockpicker-popover .popover-title {
  padding: 10px 0px;
}

/* .asColorPicker-trigger span{
	border-radius:0 0.625rem 0.625rem 0;
} */
.daterangepicker .calendar-table td {
  width: 33px;
  height: 33px;
  border-radius: 0.5rem;
  color: #b5b5c3;
}

.daterangepicker td.in-range {
  background-color: transparent;
  color: #5e6278 !important;
}

.daterangepicker td.active {
  background-color: #3736af;
  color: #fff !important;
}

.daterangepicker td.active:hover {
  color: #fff !important;
}

button.cancelBtn.btn.btn-sm.btn-inverse,
.btn-group-sm>button.cancelBtn.btn.btn-inverse {
  background-color: #f9f9f9;
}

.asColorPicker-dropdown {
  max-width: 26rem;
}

.asColorPicker-trigger {
  background: none;
}

.asColorPicker-trigger {
  border: 0 none;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 2.1875rem;
}

[direction="rtl"] .asColorPicker-trigger {
  left: 0;
  right: auto;
}

.asColorPicker-trigger span {
  border-radius: 0 0.425rem 0.425rem 0;
}

.asColorPicker-clear {
  display: none;
  position: absolute;
  right: 1rem;
  text-decoration: none;
  top: 0.5rem;
}

.daterangepicker td.active {
  background-color: var(--primary);
}

.daterangepicker td.active:hover {
  background-color: var(--primary);
}

.daterangepicker button.applyBtn {
  background-color: var(--primary);
  border-color: var(--primary);
}

.datepicker.datepicker-dropdown {
  background: #fff;
  border-radius: 0.0625rem;
  border: 0.0625rem solid #eeeeee;
}

.datepicker.datepicker-dropdown td.day,
.datepicker.datepicker-dropdown th.next,
.datepicker.datepicker-dropdown th.prev {
  height: 1.875rem;
  width: 1.875rem !important;
  padding: 0;
  text-align: center;
  font-weight: 500;
  border-radius: 0.5rem;
}

.datepicker.datepicker-dropdown td.day:hover,
.datepicker.datepicker-dropdown th.next:hover,
.datepicker.datepicker-dropdown th.prev:hover {
  box-shadow: unset;
  /*  color: $main-color; */
  background-color: var(--rgba-primary-1);
}

.datepicker.datepicker-dropdown th.datepicker-switch,
.datepicker.datepicker-dropdown th.next,
.datepicker.datepicker-dropdown th.prev {
  font-weight: 600;
  color: #333;
}

.datepicker.datepicker-dropdown th.dow {
  font-weight: 600;
}

.datepicker table tr td.selected,
.datepicker table tr td.active.active {
  background-color: var(--primary);
  border-radius: 0.5rem;
  border: 0;
  color: #fff;
  background-image: none;
  position: relative;
}

.datepicker table tr td.today {
  border-radius: 0.5rem;
  /* color : $main-color; */
}

.datepicker table tr td.today:hover {
  background-color: var(--primary);
  box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
  color: #ffffff;
}

.datepicker table tr td.today.disabled {
  box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
  color: #ffffff;
}

.datepicker table tr td.today.disabled:hover {
  box-shadow: 0rem 0rem 1.875rem 0.3125rem rgba(243, 30, 122, 0.3);
  color: #ffffff;
}

.datepicker table thead tr:first-child th:hover {
  background: var(--rgba-primary-1);
}

.datepicker table thead tr th:hover {
  background: var(--rgba-primary-1);
}

.datepicker table td.day:hover {
  background: var(--rgba-primary-1);
  border-radius: 0.5rem;
}

.picker__select--month,
.picker__select--year {
  height: 2.5em;
}

.picker__input {
  background-color: transparent !important;
}

[data-theme-version="dark"] .picker__input {
  background-color: transparent !important;
  border: 0.0625rem solid #2a3969;
}

.bootstrap-select.form-control-lg .dropdown-toggle {
  min-height: 3.75rem;
  padding: 0.5rem 1.563rem;
}

.bootstrap-select.form-control-sm .dropdown-toggle {
  min-height: 2.5rem;
  padding: 0.25rem 1.563rem;
}

.basic-form .form-check-input[type="radio"]+.form-check-label {
  margin-top: 5px;
}

.input-group-sm>.form-control,
.input-group-sm>.form-select,
.input-group-sm>.input-group-text,
.input-group-sm>.btn {
  min-height: 2.5rem;
  padding: 0.25rem 1.1rem;
  border-radius: 0.5rem;
}

.input-group.input-group-lg {
  min-height: 3.75rem;
  border-radius: 0.5rem;
}

.input-group-lg>.form-control,
.input-group-lg>.form-select,
.input-group-lg>.input-group-text,
.input-group-lg>.btn {
  border-radius: 0.5rem;
}

.clockpicker-popover .popover-title {
  padding: 0.625rem 0px;
}

.picker-data .color-time-picker .react-time-picker {
  width: 100%;
}

.picker-data .color-time-picker .react-time-picker__wrapper {
  border: 0;
  color: #7e7e7e;
}

.picker-data .color-time-picker .react-time-picker__wrapper .react-time-picker__clear-button {
  display: none;
}

.picker-data .color-time-picker .react-time-picker__wrapper .react-time-picker__inputGroup {
  border: 1px solid #b7b7b7;
  border-width: 0 0 1px 0;
  padding: 0.35rem 0rem;
}

.picker-data .color-time-picker .react-time-picker__wrapper .react-time-picker__inputGroup__input {
  color: #7e7e7e;
}

.picker-data .color-time-picker .react-time-picker__wrapper .react-time-picker__inputGroup__amPm {
  color: #7e7e7e;
  appearance: none;
}

.picker-data .color-time-picker .react-time-picker__wrapper .react-time-picker__clock-button {
  background: #3736af;
  color: #b7b7b7;
  padding: 0.532rem 0.75rem;
  border-radius: 0 0.75rem 0.75rem 0;
  min-width: 3.125rem;
  text-align: center;
  line-height: 1;
}

.picker-data .color-time-picker .react-time-picker__wrapper .react-time-picker__clock-button svg {
  stroke: #fff;
  display: inline-block;
}

.picker-data .color-time-picker .react-clock__face {
  background: #ededed;
  padding: 0.625rem 0.625rem;
  border: 0.625rem solid #ededed;
}

.picker-data .color-time-picker .react-clock__hand__body {
  background-color: #3736af;
}

.picker-data .color-time-picker .react-time-picker__clock {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  border: 0 !important;
}

.picker-data .color-time-picker.style-1 .react-time-picker__wrapper .react-time-picker__clock-button {
  display: none;
}

.color-gradian-tixia .gpw {
  padding: 0;
}

.color-gradian-tixia .gpw .trigger .inner {
  height: 1.5rem;
  width: 6.25rem;
}

.input-hasicon {
  position: relative;
}

.input-hasicon .icon {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  width: 2rem;
  display: flex;
  align-items: center;
  pointer-events: none;
}

#image {
  max-width: 100%;
}

.docs-options .dropdown-menu {
  padding: 1.5rem;
}

.docs-preview {
  margin-bottom: 3rem;
}

.docs-preview .img-preview {
  float: left;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  overflow: hidden;
  max-width: 100% !important;
}

.docs-preview .img-preview>img {
  max-width: 100% !important;
}

.docs-preview .img-preview.preview-lg {
  width: 16rem;
  height: 9rem;
}

.docs-preview .img-preview.preview-md {
  width: 8rem;
  height: 4.5rem;
}

.docs-preview .img-preview.preview-sm {
  width: 4rem;
  height: 2.25rem;
}

.docs-preview .img-preview.preview-xs {
  width: 2rem;
  height: 1.125rem;
  margin-right: 0;
}

.select2-container--default .select2-selection--single {
  border-radius: 0.5rem;
  border: 1px solid var(--border);
  height: 2.75rem;
  background: var(--card);
}

.select2-container--default .select2-selection--single:hover,
.select2-container--default .select2-selection--single:focus,
.select2-container--default .select2-selection--single.active {
  box-shadow: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 2.8rem;
  color: var(--text);
  padding-left: 15px;
  min-height: 2.8rem;
  text-align: left;
}

.select2-container--default .select2-selection--multiple {
  border-color: #e6e6e6;
  border-radius: 0;
}

.select2-dropdown {
  border-radius: 0;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: var(--primary);
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: var(--border);
  background: var(--card);
  color: var(--text-dark);
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 10px;
  left: auto;
  right: 15px;
}

@media only screen and (max-width: 100rem) {
  .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 6px;
  }
}

.select2-container .select2-selection--multiple {
  min-height: 2.8rem;
  color: var(--text);
  border-radius: 0.5rem;
  border: 1px solid var(--border);
  background-color: var(--card);
}

.swal2-popup .swal2-content {
  color: var(--text);
}

.select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: var(--primary);
}

.select2-container--classic.select2-container--open .select2-selection--multiple,
.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: var(--primary);
}

.select2-container--open .select2-dropdown {
  background-color: var(--card);
  border-radius: 0.5rem;
  padding: 1rem 0;
  border-color: var(--border);
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  z-index: 1;
}

.select2-container--open .select2-dropdown.select2-dropdown--above {
  border-radius: 0.5rem 0.5rem 0 0;
}

.select2-container--open .select2-dropdown.select2-dropdown--below {
  border-radius: 0 0 0.5rem 0.5rem;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border-radius: 0.5rem;
  padding: 0.5rem 1.25rem;
  border-color: var(--border);
  margin: 0 0 0.5rem 0;
  background-color: var(--card);
  color: var(--text-dark);
  text-align: left;
}

.select2-search--dropdown {
  padding: 0.5rem 1.25rem;
  margin: 0 0 0.5rem 0;
}

.select2-results__options li {
  padding: var(--dz-list-space-y) var(--dz-list-space-x);
  font-size: var(--dz-list-size);
  margin: 0 0;
  background-color: var(--card);
}

.select2-container--default .select2-results__option--selected {
  background-color: var(--light);
  color: var(--primary);
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: var(--primary);
  color: #fff;
}

.select2-container .select2-search--inline .select2-search__field {
  background: transparent;
  color: var(--text-dark);
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice,
.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  border-radius: 0.5rem;
  border: 0;
  background-color: var(--rgba-primary-1);
  font-size: 0.85rem;
  color: var(--primary);
  padding: 5px 0;
  padding-right: 8px;
  padding-left: 32px;
  position: relative;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove,
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  height: 100%;
  padding: 0 6px 0 8px;
  font-size: 18px;
  font-weight: 500;
  border-right: 1px solid;
  border-color: #fff;
  color: var(--primary);
  position: absolute;
  right: auto;
  left: 0;
  top: 0;
}

[data-theme-version="dark"] .select2-container--default .select2-selection--multiple .select2-selection__choice__remove,
[data-theme-version="dark"] .select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  border-color: rgba(255, 255, 255, 0.1);
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  display: block;
  color: var(--primary);
  font-size: 13px;
  width: 100%;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-label {
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--bs-heading-color);
  font-size: 15px;
}

.select2-container--default.select2-container--disabled .select2-selection__choice {
  padding: 5px 8px 5px 8px;
}

.select2-container--classic.select2-container--open .select2-selection--single,
.select2-container--classic .select2-selection--single:focus {
  border-color: var(--primary);
}

select+.select2-container {
  width: 100% !important;
  text-align: left !important;
}

.select2-container--default .select2-results__option--selectable .select2-result-repository__avatar {
  height: 50px;
  width: 50px;
  margin-right: 12px;
}

.select2-container--default .select2-results__option--selectable .select2-result-repository__avatar img {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}

.select2-container--default .select2-results__option--selectable.select2-results__option--highlighted .select2-result-repository__title {
  color: #fff;
}

.select2-container--default .select2-results__option--selectable.select2-results__option--highlighted .select2-result-repository__forks,
.select2-container--default .select2-results__option--selectable.select2-results__option--highlighted .select2-result-repository__stargazers,
.select2-container--default .select2-results__option--selectable.select2-results__option--highlighted .select2-result-repository__watchers {
  color: #fff;
}

.select2-container--default .select2-results__option--selectable .select2-result-repository {
  display: flex;
  align-items: center;
}

.select2-container--default .select2-results__option--selectable .select2-result-repository__meta {
  flex: 1;
}

.select2-container--default .select2-results__option--selectable .select2-result-repository__title {
  font-size: 0.95rem;
  color: #000;
}

.select2-container--default .select2-results__option--selectable .select2-result-repository__description {
  font-size: 0.75rem;
  margin-bottom: 3px;
}

.select2-container--default .select2-results__option--selectable .select2-result-repository__statistics {
  display: flex;
}

.select2-container--default .select2-results__option--selectable .select2-result-repository__forks,
.select2-container--default .select2-results__option--selectable .select2-result-repository__stargazers,
.select2-container--default .select2-results__option--selectable .select2-result-repository__watchers {
  margin-right: 12px;
  font-size: 0.75rem;
  color: #424562;
}

@media only screen and (max-width: 87.5rem) {
  .tagify.form-control {
    padding: 5px 5px;
  }
}

.tagify__tag>div::before {
  box-shadow: 0 0 0 20px var(--tag-bg) inset;
}

.tagify__tag__removeBtn:hover+div::before {
  box-shadow: 0 0 0 20px var(--tag-remove-bg, rgba(211, 148, 148, 0.3)) inset !important;
}

.select2-results__options {
  text-align: left;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple,
.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: var(--light);
}

:root {
  --primary: #3736af;
  --secondary: #3693ff;
  --primary-hover: #2b2a88;
  --primary-dark: #12123a;
  --rgba-primary-1: rgba(55, 54, 175, 0.1);
  --rgba-primary-2: rgba(55, 54, 175, 0.2);
  --rgba-primary-3: rgba(55, 54, 175, 0.3);
  --rgba-primary-4: rgba(55, 54, 175, 0.4);
  --rgba-primary-5: rgba(55, 54, 175, 0.5);
  --rgba-primary-6: rgba(55, 54, 175, 0.6);
  --rgba-primary-7: rgba(55, 54, 175, 0.7);
  --rgba-primary-8: rgba(55, 54, 175, 0.8);
  --rgba-primary-9: rgba(55, 54, 175, 0.9);
  --rgba-secondary-1: rgba(54, 147, 255, 0.1);
  --rgba-secondary-2: rgba(54, 147, 255, 0.2);
  --rgba-secondary-3: rgba(54, 147, 255, 0.3);
  --rgba-secondary-4: rgba(54, 147, 255, 0.4);
  --rgba-secondary-5: rgba(54, 147, 255, 0.5);
  --rgba-secondary-6: rgba(54, 147, 255, 0.6);
  --rgba-secondary-7: rgba(54, 147, 255, 0.7);
  --rgba-secondary-8: rgba(54, 147, 255, 0.8);
  --rgba-secondary-9: rgba(54, 147, 255, 0.9);
  --font-family-base: Poppins, sans-serif;
  --font-family-title: Poppins, sans-serif;
  --font-family-base: Poppins, sans-serif;
  --font-family-title: Poppins, sans-serif;
  --title: #424562;
  --dz-list-space-x: 1.25rem;
  --dz-list-space-y: 0.5rem;
  --dz-list-size: 0.875rem;
}

:root {
  --card: #fff;
  --text-dark: #312a2a;
  --text-gray: #737b8b;
  --text: #717579;
  --body-bg: #e7e7e7;
  --light: #f3f3f3;
  --border: #e6e6e6;
  --card-element-color: #f3f3f3;
  --bs-border-color: #e6e6e6;
  --bs-tertiary-bg: #f3f3f3;
}

/*
0 - 600: Phone
600 - 900: Tablet portrait
900 - 1200: Tablet landscape
1200 - 1800: Normal styles
1800+ : Big Desktop
1em = 16px
The smaller device rules always should write below the bigger device rules
Fixing Order => Base + Typography >> General Layout + Grid >> Page Layout + Component
*/
.form-wizard {
  border: 0;
}

.form-wizard .nav-wizard {
  box-shadow: none !important;
  margin-bottom: 2rem;
}

.form-wizard .nav-wizard #RFS-ConnectorContainer {
  display: none;
}

.form-wizard .nav-wizard .nav-link {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent !important;
}

.form-wizard .nav-wizard .nav-link span {
  border-radius: 3.125rem;
  width: 3rem;
  height: 3rem;
  border: 0.125rem solid #3736af;
  display: block;
  line-height: 3rem;
  color: #3736af;
  font-size: 1.125rem;
  margin: auto;
  background-color: #fff;
  position: relative;
  z-index: 1;
}

.form-wizard .nav-wizard .nav-link:after {
  content: "" !important;
  position: absolute;
  top: 50%;
  left: 50%;
  height: 0.1875rem;
  transform: translateY(-50%);
  background: #eaebf1 !important;
  z-index: 0;
  width: 100%;
}

.form-wizard .nav-wizard .nav-link.active span {
  background: #3736af;
  color: #fff;
}

.form-wizard .nav-wizard .nav-link.active:after {
  background: #3736af !important;
}

.form-wizard .nav-wizard .nav-link.completed span {
  background-color: #3736af;
  color: #fff;
}

.form-wizard .nav-wizard .nav-link.completed:after {
  background: #3736af !important;
}

.form-wizard .nav-wizard>div:last-child .nav-link:after {
  display: none;
}

.form-wizard .toolbar-bottom .btn {
  border: 0;
  padding: 0.75rem 1.125rem;
}

.form-wizard .tab-content .tab-pane {
  padding: 0;
}

.form-wizard .emial-setup label.mailclinet {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: #eef5f9;
  text-align: center;
  margin: auto;
}

[data-theme-version="dark"] .form-wizard .emial-setup label.mailclinet {
  background-color: #09153f;
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .emial-setup label.mailclinet {
    width: 7rem;
    height: 7rem;
  }
}

.form-wizard .emial-setup label.mailclinet .mail-icon {
  font-size: 3rem;
  display: inline-block;
  line-height: 1;
  margin-top: -1rem;
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .emial-setup label.mailclinet .mail-icon {
    font-size: 2rem;
  }
}

.form-wizard .emial-setup label.mailclinet .mail-text {
  font-size: 1rem;
  text-align: center;
  margin-top: 0.5rem;
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .emial-setup label.mailclinet .mail-text {
    font-size: 0.75rem;
    line-height: 1.25rem;
  }
}

.form-wizard .emial-setup label.mailclinet input[type="radio"] {
  display: none;
}

@media only screen and (max-width: 47.9375rem) {
  .form-wizard .nav-wizard {
    flex-direction: unset !important;
  }

  .form-wizard .tab-content {
    height: 100% !important;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .form-wizard .nav-wizard li .nav-link {
    padding: 0;
  }
}

.wizard-style-one {
  border: 0;
  padding: 0 100px;
}

.wizard-style-one .nav-wizard {
  margin-bottom: 40px;
  box-shadow: none;
}

.wizard-style-one label {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.wizard-style-one .tab-content {
  overflow: visible;
}

.custom-ekeditor ul {
  padding-left: 1.25rem;
}

.custom-ekeditor ul li {
  list-style: unset;
}

.custom-ekeditor ol li {
  list-style: decimal;
}

.ck.ck-toolbar>.ck-toolbar__items {
  flex-flow: row wrap !important;
}

.ck.ck-toolbar .ck-button {
  justify-content: left !important;
}

.ck.ck-media-form {
  flex-flow: row wrap !important;
}

.ck.ck-media-form .ck.ck-label,
.ck.ck-media-form .ck.ck-input {
  text-align: left !important;
}

.ck.ck-media-form .ck.ck-label {
  left: 0 !important;
  right: auto !important;
}

.ck.ck-content {
  text-align: left !important;
}

.ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_se {
  left: 0 !important;
  right: auto !important;
}

#world-datamap {
  padding-bottom: 46% !important;
}

.datamaps-hoverover {
  background: #fff;
  padding: 0.3125rem;
  border-radius: 0.3125rem;
  font-family: "Roboto" !important;
  color: var(--primary);
  border: 0.0625rem solid var(--rgba-primary-3);
}

@media only screen and (max-width: 90rem) {
  .world_map_card ul.list-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 2.1875rem;
  }
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  height: 1.25rem;
  width: 1.25rem;
  line-height: 0.875rem;
  background-color: var(--primary);
  color: #fff;
}

.jqvmap-zoomout {
  top: 2.1875rem;
}

#world-map,
#usa {
  height: 25rem;
}

@media only screen and (max-width: 61.9375rem) {

  #world-map,
  #usa {
    height: 21.875rem;
  }
}

@media only screen and (max-width: 35.9375rem) {

  #world-map,
  #usa {
    height: 14.375rem;
  }
}

.bootstrap-select {
  margin-bottom: 0;
}

.bootstrap-select .btn {
  border: 1px solid var(--border) !important;
  background-color: transparent !important;
  font-weight: 400;
  color: var(--text) !important;
}

.bootstrap-select .btn:active,
.bootstrap-select .btn:focus,
.bootstrap-select .btn:hover {
  outline: none !important;
  outline-offset: 0;
}

.bootstrap-select .dropdown-menu {
  border-color: var(--border) !important;
  box-shadow: 0rem 0rem 2.5rem 0rem rgba(82, 63, 105, 0.1);
}

.bootstrap-select .dropdown-menu .dropdown-item {
  padding: 0.25rem 1rem;
}

.input-group>.bootstrap-select:not(:first-child) .dropdown-toggle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group>.bootstrap-select:not(:last-child) .dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.clipboard-btn {
  transition: all 0.1s ease-in-out;
}

.clipboard-btn:hover {
  background-color: var(--primary);
  color: #fff;
}

.twitter-typeahead {
  width: 100%;
}

.twitter-typeahead .tt-dataset.tt-dataset-states {
  border: 1px solid #e6e6e6;
}

.twitter-typeahead .tt-menu {
  width: 100%;
  background-color: #fff;
}

.twitter-typeahead .tt-menu .tt-suggestion {
  padding: 0.625rem;
  cursor: pointer;
}

.twitter-typeahead .tt-menu .tt-suggestion:hover {
  background-color: var(--primary);
  color: #fff;
}

.noUi-target {
  border-color: transparent;
  border-radius: 0;
  border-color: var(--border);
}

.noUi-connect {
  background-color: var(--primary);
}

.noUi-connects {
  background-color: var(--body-bg);
}

.noUi-connect.c-1-color {
  background-color: #3ab67a;
}

.noUi-connect.c-2-color {
  background-color: #00ada3;
}

.noUi-connect.c-3-color {
  background-color: var(--primary);
}

.noUi-connect.c-4-color {
  background-color: #ffab2d;
}

.noUi-vertical {
  width: 0.375rem;
}

.noUi-horizontal {
  height: 0.375rem;
  margin-bottom: 10px;
}

.noUi-horizontal .noUi-handle,
.noUi-vertical .noUi-handle {
  height: 12px;
  width: 12px;
  border-radius: 50px;
  box-shadow: none;
  border: none;
  background-color: var(--primary);
}

.noUi-horizontal .noUi-handle::after,
.noUi-horizontal .noUi-handle::before,
.noUi-vertical .noUi-handle::after,
.noUi-vertical .noUi-handle::before {
  display: none;
}

.noUi-vertical .noUi-handle {
  right: -4px;
  bottom: -11px;
}

.noUi-horizontal .noUi-handle {
  top: -4px;
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -6px;
}

#slider-toggle {
  height: 50px;
}

#slider-toggle.off .noUi-handle {
  border-color: var(--primary);
}

.colorpicker-slider .sliders.noUi-target#red,
.colorpicker-slider .sliders.noUi-target#green,
.colorpicker-slider .sliders.noUi-target#blue {
  margin: 10px;
  display: inline-block;
  height: 200px;
}

.colorpicker-slider .sliders.noUi-target#red .noUi-connect {
  background: #c0392b;
}

.colorpicker-slider .sliders.noUi-target#green .noUi-connect {
  background: #27ae60;
}

.colorpicker-slider .sliders.noUi-target#blue .noUi-connect {
  background: #2980b9;
}

.colorpicker-slider #result {
  margin: 60px 26px;
  height: 100px;
  width: 100px;
  display: inline-block;
  vertical-align: top;
  color: #7f7f7f;
  background: #7f7f7f;
  border: 1px solid #fff;
  box-shadow: 0 0 10px;
}

.slider-vertical {
  height: 18rem;
}

.nestable-cart {
  overflow: hidden;
}

.dd-handle {
  color: #fff;
  background: var(--primary);
  border-radius: 0.3125rem;
  padding: 0.5rem 1rem;
  height: auto;
  border: 1px solid var(--border);
  cursor: all-scroll;
}

.dd-handle:hover {
  color: #fff;
  background: var(--primary);
}

.dd3-content:hover {
  color: #fff;
  background: var(--primary);
}

.dd3-content {
  color: #fff;
}

.dd-item>button {
  line-height: 28px;
  color: #fff;
  float: left;
}

.pignose-calendar {
  box-shadow: none;
  width: 100%;
  max-width: none;
  border-color: var(--primary);
}

.pignose-calendar .pignose-calendar-top-date {
  background-color: var(--primary);
}

.pignose-calendar .pignose-calendar-top-date .pignose-calendar-top-month {
  color: #fff;
}

.pignose-calendar.pignose-calendar-blue .pignose-calendar-body .pignose-calendar-row .pignose-calendar-unit.pignose-calendar-unit-active a {
  background-color: var(--primary);
  box-shadow: none;
}

.pignose-calendar .pignose-calendar-top {
  box-shadow: none;
  border-bottom: 0;
}

.pignose-calendar.pignose-calendar-blue {
  background-color: rgba(0, 0, 0, 0.15);
}

.pignose-calendar .pignose-calendar-unit {
  height: 4.8em;
}

.cd-h-timeline {
  opacity: 0;
  transition: opacity 0.2s;
}

.cd-h-timeline--loaded {
  opacity: 1;
}

.cd-h-timeline__container {
  position: relative;
  height: 6.25rem;
  max-width: 50rem;
}

.cd-h-timeline__dates {
  position: relative;
  height: 100%;
  margin: 0 2.5rem;
  overflow: hidden;
}

.cd-h-timeline__dates::after,
.cd-h-timeline__dates::before {
  content: "";
  position: absolute;
  z-index: 2;
  top: 0;
  height: 100%;
  width: 1.25rem;
}

.cd-h-timeline__dates::before {
  left: 0;
  background: var(--primary);
}

.cd-h-timeline__dates::after {
  right: 0;
  background: var(--primary);
}

.cd-h-timeline__line {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 3.0625rem;
  height: 0.125rem;
  background-color: var(--primary);
  transition: transform 0.4s;
}

.cd-h-timeline__filling-line {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #3ab67a;
  transform: scaleX(0);
  transform-origin: left center;
  transition: transform 0.3s;
}

.cd-h-timeline__date {
  position: absolute;
  bottom: 0;
  z-index: 2;
  text-align: center;
  font-size: 0.8em;
  padding-bottom: var(--space-sm);
  color: var(--cd-color-1);
  user-select: none;
  text-decoration: none;
}

.cd-h-timeline__date::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -0.3125rem;
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 50%;
  border-width: 0.125rem;
  border-style: solid;
  border-color: var(--rgba-primary-6);
  background-color: var(--primary);
  transition: background-color 0.3s, border-color 0.3s;
}

.cd-h-timeline__date:hover::after {
  background-color: #3ab67a;
  border-color: #3ab67a;
}

.cd-h-timeline__date--selected {
  pointer-events: none;
}

.cd-h-timeline__date--selected::after {
  background-color: #3ab67a;
  border-color: #3ab67a;
}

.cd-h-timeline__date--older-event::after {
  border-color: #3ab67a;
}

.cd-h-timeline__navigation {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  height: 2.125rem;
  width: 2.125rem;
  border-radius: 50%;
  border-width: 0.125rem;
  border-style: solid;
  border-color: var(--rgba-primary-6);
  transition: border-color 0.3s;
}

.cd-h-timeline__navigation::after {
  content: "";
  position: absolute;
  height: 1rem;
  width: 1rem;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.cd-h-timeline__navigation:hover {
  border-color: #3ab67a;
}

.cd-h-timeline__navigation--prev {
  left: 0;
  transform: translateY(-50%) rotate(180deg);
}

.cd-h-timeline__navigation--next {
  right: 0;
}

.cd-h-timeline__navigation--inactive {
  cursor: not-allowed;
}

.cd-h-timeline__navigation--inactive::after {
  background-position: 0 -1rem;
}

.cd-h-timeline__navigation--inactive:hover {
  border-color: var(--rgba-primary-6);
}

.cd-h-timeline__events {
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: height 0.4s;
}

.cd-h-timeline__event {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
  transform: translateX(-100%);
  padding: 0.0625rem 5%;
  opacity: 0;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;
}

.cd-h-timeline__event--selected {
  position: relative;
  z-index: 2;
  opacity: 1;
  transform: translateX(0);
}

.cd-h-timeline__event--enter-right,
.cd-h-timeline__event--leave-right {
  animation-name: cd-enter-right;
}

.cd-h-timeline__event--enter-left,
.cd-h-timeline__event--leave-left {
  animation-name: cd-enter-left;
}

.cd-h-timeline__event--leave-right,
.cd-h-timeline__event--leave-left {
  animation-direction: reverse;
}

.cd-h-timeline__event-content {
  max-width: 50rem;
}

.cd-h-timeline__event-title {
  color: var(--cd-color-1);
  font-family: var(--font-secondary);
  font-weight: 700;
  font-size: var(--text-xxxl);
}

.cd-h-timeline__event-date {
  display: block;
  font-style: italic;
  margin: var(--space-xs) auto;
}

.cd-h-timeline__event-date::before {
  content: "- ";
}

@keyframes cd-enter-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes cd-enter-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.toast-success {
  background-color: var(--primary);
}

.toast-info {
  background-color: #00ada3;
}

.toast-warning {
  background-color: #ffab2d;
}

.toast-error {
  background-color: #fd5353;
}

#toast-container>div {
  box-shadow: none;
  border-radius: 0;
  width: auto;
  max-width: 15.625rem;
  opacity: 1;
  border-radius: 0.5rem;
}

[direction="rtl"] #toast-container>div {
  padding: 0.9375rem 3.125rem 0.9375rem 0.9375rem;
  background-position: calc(100% - 0.9375rem);
}

#toast-container>div:hover {
  box-shadow: none;
}

#toast-container .toast-title {
  margin-bottom: 0.3125rem;
  font-weight: 600;
}

#toast-container .toast-message {
  font-size: 0.75rem;
}

#toast-container .toast-close-button {
  opacity: 1;
  font-size: 1.25rem;
  font-weight: normal;
  text-shadow: none;
}

[direction="rtl"] .toast-top-right.demo_rtl_class {
  left: 0.75rem;
  right: auto;
}

/* Light Gallery */
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-sub-html,
.lg-toolbar {
  background-color: rgba(30, 30, 30, 0.6);
}

.lg-outer .lg-toogle-thumb,
.lg-outer .lg-thumb-outer,
.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
  background-color: transparent;
}

.lg-thumb-outer.lg-grab,
.lg-toogle-thumb.lg-icon {
  background-color: rgba(30, 30, 30, 0.6);
}

.lg-backdrop {
  background-color: rgba(30, 30, 30, 0.9);
}

.lg-outer .lg-toogle-thumb,
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-toolbar .lg-icon,
#lg-counter {
  color: #fff;
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
  border-color: var(--primary);
}

.lightimg {
  cursor: pointer;
}

[dir="rtl"] .lg-outer.lg-visible {
  direction: ltr;
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  position: absolute;
  left: 0.625rem;
  -webkit-border-radius: 0.1875rem;
  -moz-border-radius: 0.1875rem;
  border-radius: 0.1875rem;
  background: #000000;
  padding: 0.1875rem;
  color: white;
  width: 1.0625rem;
  height: 1.0625rem;
  cursor: pointer;
  line-height: 0.625rem;
  text-align: center;
}

.jqvmap-zoomin {
  top: 0.625rem;
}

.jqvmap-zoomout {
  top: 1.875rem;
}

.fc-h-event,
.fc-v-event {
  background: var(--primary);
  border-radius: 0.42rem;
}

.fc-h-event .fc-event-title {
  color: #fff;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border-color: var(--border);
}

.fc-unthemed .fc-h-event,
.fc-unthemed .fc-event-dot {
  padding: 0;
  border-radius: 0.42rem;
}

.fc-theme-standard th {
  padding: 0.75rem 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: #b5b5c3;
}

@media only screen and (max-width: 35.9375rem) {
  .fc-theme-standard th {
    font-size: 14px;
    font-weight: 400;
    padding: 3px 0px;
  }
}

.fc-theme-standard .fc-scrollgrid.fc-scrollgrid-liquid,
.fc-scrollgrid,
table {
  border-color: var(--border);
}

.fc-daygrid-dot-event {
  background: var(--card);
  border: 1px solid var(--border);
  -webkit-box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
}

.fc-daygrid-dot-event .fc-daygrid-event-dot {
  border-color: var(--primary);
}

@media only screen and (max-width: 74.9375rem) {
  .fc-daygrid-dot-event {
    width: auto;
    height: auto;
    flex-wrap: wrap;
  }
}

.fc-daygrid-dot-event .fc-event-title {
  font-weight: 500;
}

.fc-event.bg-primary,
.fc-event.bg-success,
.fc-event.bg-warning,
.fc-event.bg-secondary,
.fc-event.bg-dark,
.fc-event.bg-info {
  color: #fff !important;
  border-radius: 8px;
}

.fc-event.bg-primary .fc-daygrid-event-dot,
.fc-event.bg-success .fc-daygrid-event-dot,
.fc-event.bg-warning .fc-daygrid-event-dot,
.fc-event.bg-secondary .fc-daygrid-event-dot,
.fc-event.bg-dark .fc-daygrid-event-dot,
.fc-event.bg-info .fc-daygrid-event-dot {
  border-color: #fff;
}

.fc .fc-scroller-liquid-absolute,
.fc-scroller {
  position: relative;
  overflow: visible !important;
}

.fc .fc-button-group>.fc-button {
  color: #b5b5c3;
  background: 0 0;
  border: 1px solid var(--border);
  border-color: var(--border) !important;
  text-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  text-transform: capitalize;
}

.fc .fc-button-group>.fc-button:focus,
.fc .fc-button-group>.fc-button:active,
.fc .fc-button-group>.fc-button:hover,
.fc .fc-button-group>.fc-button.fc-button-active {
  background: var(--primary);
  color: #fff;
}

.fc .fc-button-group>.fc-button:active {
  background: var(--primary);
}

.fc-button.fc-button-primary.fc-today-button {
  background: var(--primary);
  color: #fff;
  border: 0;
  opacity: 1;
  text-transform: capitalize;
}

.fc-unthemed .fc-toolbar .fc-button.fc-button-active,
.fc-unthemed .fc-toolbar .fc-button:active,
.fc-unthemed .fc-toolbar .fc-button:focus {
  background: var(--primary);
  color: #fff;
  border: 1px solid var(--primary);
  -webkit-box-shadow: none;
  box-shadow: none;
  text-shadow: none;
}

.fc .fc-toolbar-title {
  font-size: 20px;
  margin: 0;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.5em;
}

.external-event {
  padding: 8px 10px;
  display: flex;
  align-items: center;
  border-radius: 5px;
}

.external-event:hover:before {
  background: #fff !important;
}

.fc-event {
  overflow: hidden;
}

.fc .fc-view-harness {
  height: 800px !important;
  overflow: auto;
}

@media only screen and (max-width: 61.9375rem) {
  .fc .fc-view-harness {
    width: 800px !important;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .fc .fc-toolbar.fc-header-toolbar {
    display: block;
  }

  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk {
    display: flex;
    justify-content: center;
  }

  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk:first-child {
    justify-content: space-between;
  }

  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-title {
    margin-bottom: 8px;
  }
}

#external-events .external-event {
  text-align: left;
  font-size: 16px;
}

@media only screen and (max-width: 35.9375rem) {
  .fc.app-fullcalendar .fc-timegrid-slot-label {
    width: 40px !important;
    font-size: 10px;
  }

  .fc.app-fullcalendar .fc-event,
  .fc.app-fullcalendar .external-event {
    font-size: 10px;
    margin: 0;
    padding: 2px 0;
    text-align: center;
    line-height: 1.3;
  }

  .fc.app-fullcalendar .fc-col-header-cell-cushion {
    display: inline-block;
    padding: 2px 4px;
    font-size: 10px;
  }
}

.fc-media-screen .fc-timegrid-event-harness {
  height: 61px;
}

.fc .fc-media-screen {
  width: 100%;
  overflow: auto;
}

.app-fullcalendar,
.fullcalendar {
  overflow: auto;
}

.fc table {
  font-size: 0.8rem;
}

.ck.ck-editor .ck.ck-button {
  padding: 8px 12px;
  background-color: var(--card);
  color: var(--text-gray);
  font-weight: 700;
  border-radius: 4px;
  cursor: pointer;
}

.ck.ck-editor .ck.ck-button .ck-button__label {
  line-height: 1.2;
}

@media only screen and (max-width: 87.5rem) {
  .ck.ck-editor .ck.ck-button {
    padding: 6px 8px;
  }

  .ck.ck-editor .ck.ck-button .ck-icon {
    height: 16px;
    width: 16px;
  }

  .ck.ck-editor .ck.ck-button .ck-dropdown__arrow {
    height: 10px;
    width: 10px;
  }
}

.ck.ck-editor .ck.ck-toolbar {
  background-color: var(--light);
  border: 0;
  padding: 5px 10px;
}

.ck.ck-editor .ck.ck-toolbar .ck.ck-button:not(.ck-on):hover {
  background-color: var(--rgba-primary-1);
}

.ck.ck-editor .ck.ck-toolbar .ck.ck-button:not(.ck-on):hover span:not(.ck-tooltip__text) {
  color: var(--primary);
}

.ck.ck-editor .ck.ck-toolbar .ck.ck-button:not(.ck-on):hover svg {
  color: var(--primary);
}

.ck.ck-editor .ck.ck-toolbar__separator {
  display: none !important;
}

.ck.ck-editor .ck-content {
  background-color: #f7f7f7;
  border-width: 1px 0 0;
  border-color: #e7e5ef;
}

.ck.ck-editor .ck-reset.ck-list .ck-list__item .ck.ck-button {
  border-radius: 0;
}

.ck.ck-editor .ck-reset.ck-list .ck-list__item .ck-button.ck-on {
  background: #3736af;
}

.ck.ck-button.ck-on:not(.ck-disabled):hover,
a.ck.ck-button.ck-on:not(.ck-disabled):hover {
  background-color: var(--card) !important;
}

.ck.ck-button.ck-on:not(.ck-disabled):hover .ck-button__label,
a.ck.ck-button.ck-on:not(.ck-disabled):hover .ck-button__label {
  color: var(--text-dark);
}

.ck.ck-toolbar>.ck.ck-toolbar__grouped-dropdown>.ck.ck-button.ck-dropdown__button {
  padding-left: 12px !important;
}

@media only screen and (max-width: 87.5rem) {
  .ck.ck-toolbar>.ck.ck-toolbar__grouped-dropdown>.ck.ck-button.ck-dropdown__button {
    padding-left: 8px !important;
  }
}

.ck-editor__editable {
  background: var(--body-bg) !important;
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
  border-color: var(--border);
  padding: 0.865rem var(--ck-spacing-standard);
}

.ck.ck-toolbar .ck-toolbar__items .ck.ck-toolbar__separator {
  background: var(--border);
}

.ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
  min-height: 12.375rem;
  background: var(--body-bg) !important;
}

@media only screen and (max-width: 35.9375rem) {
  .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred {
    min-height: 8rem;
  }
}

button.ck.ck-button.ck-off:hover {
  background-color: #eeeeee;
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  min-height: 12.375rem;
  border-color: var(--rgba-primary-4) !important;
}

@media only screen and (max-width: 35.9375rem) {
  .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
    min-height: 8rem;
  }
}

.ck.ck-button.ck-on:not(.ck-disabled):hover,
a.ck.ck-button.ck-on:not(.ck-disabled):hover {
  background-color: var(--primary);
}

.ck.ck-reset.ck-dropdown__panel.ck-dropdown__panel_se.ck-dropdown__panel-visible {
  box-shadow: none;
  border: 0;
}

.ck.ck-dropdown .ck-dropdown__panel .ck-list .ck-list__item:first-child .ck-button {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0.5rem !important;
}

.ck.ck-dropdown .ck-dropdown__panel .ck-list .ck-list__item:last-child .ck-button {
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners,
.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable,
.ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
  --ck-border-radius: 0.5rem;
}

.ck.ck-editor__main>.ck-editor__editable {
  background-color: var(--body-bg) !important;
}

.ck.ck-toolbar .ck-button {
  justify-content: left !important;
}

.ck.ck-editor {
  z-index: 1;
}

.accordion-button:not(.collapsed) {
  color: inherit;
  background: var(--card);
}

.accordion-button.collapsed {
  border-bottom-width: 1px;
}

.accordion-primary .accordion-button {
  background: var(--primary);
  border-color: var(--primary);
  color: #fff;
  box-shadow: var(--rgba-primary-2) 0px 8px 24px;
}

.accordion-primary .accordion-button.collapsed {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
  box-shadow: none;
}

.accordion-primary-solid .accordion-button {
  background: var(--primary);
  border-color: var(--primary);
  color: #fff;
  box-shadow: 0 -0.625rem 1.25rem 0 var(--rgba-primary-1);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-primary-solid .accordion-button.collapsed {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
  box-shadow: none;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.accordion-primary-solid .accordion-body {
  border: 0.125rem solid var(--primary);
  border-top: none;
  box-shadow: 0 0.9375rem 1.25rem 0 var(--rgba-primary-1);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.accordion-danger .accordion-button {
  background: #fd5353;
  border-color: #fd5353;
  color: #fff;
  box-shadow: 0 0.9375rem 1.25rem 0 rgba(253, 83, 83, 0.15);
}

.accordion-danger .accordion-button.collapsed {
  background: #ffeaea;
  border-color: #ffeaea;
  color: #211c37;
  box-shadow: none;
}

.accordion-danger-solid .accordion-button {
  background: #fd5353;
  border-color: #fd5353;
  color: #fff;
  box-shadow: 0 -0.625rem 1.25rem 0 rgba(253, 83, 83, 0.15);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-danger-solid .accordion-button.collapsed {
  background: rgba(253, 83, 83, 0.15);
  border-color: rgba(253, 83, 83, 0.15);
  color: var(--text-dark);
  box-shadow: none;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.accordion-danger-solid .accordion-body {
  border: 0.125rem solid #fd5353;
  border-top: none;
  box-shadow: 0 0.2rem 1rem 0 rgba(253, 83, 83, 0.15);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.accordion-item {
  margin-bottom: 1.25rem;
  border: 0;
  background-color: transparent;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.accordion-button {
  padding: 0.75rem 1.75rem;
  border: 0.0625rem solid var(--border);
  cursor: pointer;
  position: relative;
  color: var(--text-dark);
  font-weight: 400;
  border-radius: 0.5rem;
  --bs-accordion-btn-bg: var(--card);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.accordion-button:focus {
  z-index: 2;
}

@media only screen and (max-width: 87.5rem) {
  .accordion-button {
    padding: 0.75rem 1.75rem;
  }
}

.accordion-button-indicator.indicator_bordered {
  display: inline-block;
  width: 1.5625rem;
  text-align: center;
  height: 1.5625rem;
  border: 0.0625rem solid var(--border);
  border-radius: 50%;
  line-height: 1.5625rem;
}

.accordion-button:not(.collapsed)::after {
  content: "\e622";
  background-image: none;
  transform: rotate(0) translateY(-50%);
}

.accordion-button:not(.collapsed).style_two::after {
  content: "\e648";
}

.accordion-button::after {
  content: "\e61a";
  font-family: "themify";
  position: absolute;
  right: 1.5625rem;
  top: 50%;
  transform: translateY(-50%);
  background-image: none !important;
  width: auto;
  height: auto;
}

.accordion-button.collapsed.style_two::after {
  content: "\e64b";
}

.accordion-body {
  padding: 0.875rem 1.25rem;
}

.accordion-collapse {
  border: 0;
}

.accordion-bordered .accordion-body {
  border: 0.0625rem solid var(--border);
  border-top: none;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.accordion-bordered .accordion-button.collapsed {
  border-radius: 0.5rem;
}

.accordion-bordered .accordion-button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-no-gutter .accordion-item {
  margin-bottom: 0;
}

.accordion-no-gutter .accordion-item .accordion-button.collapsed {
  border-bottom: none;
}

.accordion-no-gutter .accordion-item:last-child .accordion-button {
  border-bottom: 0.0625rem solid var(--border);
}

.accordion-no-gutter.accordion-bordered .accordion-item:not(:last-child) .accordion-body {
  border-bottom: none;
}

.accordion-no-gutter.accordion-bordered .accordion-item .accordion-button {
  box-shadow: none;
}

.accordion-left-indicator .accordion-button {
  box-shadow: none;
}

.accordion-left-indicator .accordion-button-text {
  padding-left: 2.5rem;
}

.accordion-left-indicator .accordion-button:after {
  left: 1rem;
}

.accordion-left-indicator .accordion-button .accordion-header-text {
  padding-left: 2rem;
}

.accordion-bordered .accordion-button {
  box-shadow: none;
}

.accordion-with-icon .accordion-button {
  box-shadow: none;
}

.accordion-with-icon .accordion-button-text {
  padding-left: 2.5rem;
}

.accordion-with-icon .accordion-button .accordion-header-icon {
  position: absolute;
  right: auto;
  left: 1.5625rem;
  font-family: "themify";
}

.accordion-with-icon .accordion-button .accordion-header-icon::before {
  content: "\e645";
}

.accordion-with-icon .accordion-button .accordion-header-text {
  padding-left: 2rem;
}

.accordion-header-bg .accordion-header .accordion-button {
  background-color: var(--light);
}

.accordion-header-bg .accordion-header-primary .accordion-button {
  background-color: var(--primary);
  color: #fff;
}

.accordion-header-bg .accordion-header-info .accordion-button {
  background-color: #00ada3;
  color: #fff;
  border-color: #00ada3;
}

.accordion-header-bg .accordion-header-success .accordion-button {
  background-color: #3ab67a;
  color: #fff;
  border-color: #3ab67a;
}

.accordion-header-bg.accordion-no-gutter .accordion-button {
  border-color: transparent;
  border-radius: 0;
  box-shadow: none;
}

.accordion-header-bg.accordion-no-gutter .accordion-item:first-child .accordion-button {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.accordion-header-bg.accordion-no-gutter .accordion-item:last-child .accordion-button {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.accordion.accordion-no-gutter .accordion-button {
  border-radius: 0;
  box-shadow: none;
}

.accordion.accordion-no-gutter .accordion-button.collapsed {
  border-radius: 0;
}

.accordion.accordion-no-gutter .accordion-body {
  border-radius: 0;
}

.accordion.accordion-no-gutter .accordion-item:first-child .accordion-button {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.accordion.accordion-no-gutter .accordion-item:last-child .accordion-button.collapsed {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.accordion.accordion-no-gutter .accordion-item:last-child .accordion-body {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.accordion-solid-bg .accordion-button {
  box-shadow: none;
  border-color: transparent;
  background-color: var(--rgba-primary-1);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.accordion-solid-bg .accordion-button.collapsed {
  border-radius: 0.5rem;
}

.accordion-solid-bg .accordion-body {
  border-color: transparent;
  background-color: var(--rgba-primary-1);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.accordion-active-header .accordion-button {
  box-shadow: none;
}

.accordion-active-header .accordion-button:not(.collapsed) {
  background-color: #00ada3;
  border-color: #00ada3;
  color: #fff;
}

.accordion-header-shadow .accordion-button {
  border: none;
  box-shadow: 0 0 0.9375rem -0.1875rem rgba(0, 0, 0, 0.3);
}

.accordion-rounded-stylish .accordion-button {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  box-shadow: none;
}

.accordion-rounded-stylish .accordion-body {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.accordion-rounded .accordion-button {
  border-radius: 0.3125rem;
}

.accordion-gradient .accordion-button {
  color: #fff;
  background-image: linear-gradient(to right,
      rgba(186, 1, 181, 0.85) 0%,
      rgba(103, 25, 255, 0.85) 100%);
  border-color: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: none;
}

.accordion-gradient .accordion-button.collapsed {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.accordion-gradient .accordion-body {
  color: #fff;
  background-image: linear-gradient(to right,
      rgba(186, 1, 181, 0.85) 0%,
      rgba(103, 25, 255, 0.85) 100%);
  border-color: transparent;
}

.accordion-body {
  font-size: 0.85rem;
}

.accordion-button:focus {
  border-color: var(--rgba-primary-3);
}

.accordion-left-indicator .accordion-button {
  padding-left: 3rem;
}

.navbar-nav {
  cursor: pointer;
}

.navbar-nav .active .scroll {
  color: var(--primary);
  font-weight: 500;
}

.navbar-nav .active:after {
  background-color: var(--primary);
}

.navbar-nav .scroll.active {
  color: var(--primary);
  font-weight: 500;
}

.navbar-nav .scroll.active:after {
  background-color: var(--primary);
}

.accordion-header-bg.box-shadow-none .accordion-button {
  box-shadow: none;
}

.dzm-tabs {
  border-bottom: none;
}

.dzm-tabs .nav-link {
  color: var(--text);
  border-radius: 0.5rem;
  font-weight: 500;
}

.dzm-tabs .nav-link.active {
  background: var(--rgba-primary-1);
  color: var(--primary);
  box-shadow: none;
  border-color: transparent;
}

.dzm-tabs .nav-link:hover {
  color: var(--primary);
  border: 1px solid transparent;
}

.alert {
  border-radius: 0.5rem;
  padding: 0.75rem 1.5rem;
}

.alert p {
  line-height: 1.5;
}

.alert-square {
  border-radius: 0;
}

.alert-rounded {
  border-radius: 3.125rem;
}

.alert-primary {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
}

.alert-secondary {
  background: rgba(54, 147, 255, 0.2);
  border-color: rgba(54, 147, 255, 0.2);
  color: #3693ff;
}

.alert-secondary {
  background: rgba(54, 147, 255, 0.2);
  border-color: rgba(54, 147, 255, 0.2);
  color: #3693ff;
}

.alert-success {
  background: rgba(58, 182, 122, 0.2);
  border-color: rgba(58, 182, 122, 0.2);
  color: #3ab67a;
}

.alert-warning {
  background: rgba(255, 171, 45, 0.2);
  border-color: rgba(255, 171, 45, 0.2);
  color: #ffab2d;
}

.alert-danger {
  background: rgba(253, 83, 83, 0.2);
  border-color: rgba(253, 83, 83, 0.2);
  color: #fd5353;
}

.alert-info {
  background-color: rgba(0, 173, 163, 0.2);
  border-color: rgba(0, 173, 163, 0.2);
  color: #00ada3;
}

.alert-dark {
  background-color: rgba(91, 94, 129, 0.2);
  border-color: rgba(91, 94, 129, 0.2);
  color: #5b5e81;
}

.alert-light {
  background-color: rgba(222, 222, 237, 0.4);
  border-color: rgba(222, 222, 237, 0.4);
  color: #5b5e81;
}

.alert-alt.alert-primary {
  border-left: 0.25rem solid var(--primary);
}

.alert-alt.alert-secondary {
  border-left: 0.25rem solid #3693ff;
}

.alert-alt.alert-success {
  border-left: 0.25rem solid #3ab67a;
}

.alert-alt.alert-warning {
  border-left: 0.25rem solid #ffab2d;
}

.alert-alt.alert-danger {
  border-left: 0.25rem solid #fd5353;
}

.alert-alt.alert-info {
  border-left: 0.25rem solid #00ada3;
}

.alert-alt.alert-dark {
  border-left: 0.25rem solid #5b5e81;
}

.alert-alt.alert-light {
  border-left: 0.25rem solid #adadd2;
}

.alert-alt.alert-primary.solid {
  border-left: 0.25rem solid var(--primary-dark) !important;
}

.alert-alt.alert-secondary.solid {
  border-left: 0.25rem solid #0054b6 !important;
}

.alert-alt.alert-success.solid {
  border-left: 0.25rem solid #1b5539 !important;
}

.alert-alt.alert-warning.solid {
  border-left: 0.25rem solid #ad6800 !important;
}

.alert-alt.alert-danger.solid {
  border-left: 0.25rem solid #ce0202 !important;
}

.alert-alt.alert-info.solid {
  border-left: 0.25rem solid #002e2b !important;
}

.alert-alt.alert-dark.solid {
  border-left: 0.25rem solid #262836 !important;
}

.alert-alt.alert-light.solid {
  border-left: 0.25rem solid #8c8cc0 !important;
}

.alert-dismissible.solid .close:hover {
  color: #fff;
  opacity: 1;
}

.alert.alert-primary.solid {
  background: var(--primary);
  color: #fff;
  border-color: var(--primary);
}

.alert.alert-secondary.solid {
  background: #3693ff;
  color: #fff;
  border-color: #3693ff;
}

.alert.alert-success.solid {
  background: #3ab67a;
  color: #fff;
  border-color: #3ab67a;
}

.card-primary.card {

  background-color: var(--headerbg) !important;
  background: var(--headerbg) !important;
  color: #fff;
}

.alert.alert-warning.solid {
  background: var(--primary);
  color: #fff;
  border-color: var(--primary);
}

.alert.alert-danger.solid {
  background: #fd5353;
  color: #fff;
  border-color: #fd5353;
}

.alert.alert-info.solid {
  background: #00ada3;
  color: #fff;
  border-color: #00ada3;
}

.alert.alert-dark.solid {
  background: #5b5e81;
  color: #fff;
  border-color: #5b5e81;
}

.alert.alert-light.solid {
  background: #dedeed;
  color: #5b5e81;
  border-color: #dedeed;
}

.alert-right-icon>span i {
  font-size: 1.125rem;
  margin-right: 0.3125rem;
}

.alert-right-icon .close i {
  font-size: 1rem;
}

.alert.alert-outline-primary {
  background: transparent;
  color: var(--primary);
  border-color: var(--primary);
}

.alert.alert-outline-secondary {
  background: transparent;
  color: #717579;
  border-color: #3693ff;
}

.alert.alert-outline-success {
  background: transparent;
  color: #3ab67a;
  border-color: #3ab67a;
}

.alert.alert-outline-info {
  background: transparent;
  color: #00ada3;
  border-color: #00ada3;
}

.alert.alert-outline-warning {
  background: transparent;
  color: #ffab2d;
  border-color: #ffab2d;
}

.alert.alert-outline-danger {
  background: transparent;
  color: #fd5353;
  border-color: #fd5353;
}

.alert.alert-outline-dark {
  background: transparent;
  color: #717579;
  border-color: #5b5e81;
}

.alert.alert-outline-light {
  background: transparent;
  color: #5b5e81;
  border-color: #dedeed;
}

.alert-social {
  color: #fff;
}

.alert-social .alert-social-icon {
  align-self: center;
  margin-right: 0.9375rem;
}

.alert-social .alert-social-icon i {
  font-size: 2.625rem;
}

.alert-social.facebook {
  background-color: #3b5998;
}

.alert-social.twitter {
  background-color: #1da1f2;
}

.alert-social.linkedin {
  background-color: #007bb6;
}

.alert-social.google-plus {
  background-color: #db4439;
}

.alert-social .close:hover {
  opacity: 1 !important;
  color: #fff !important;
}

.left-icon-big .alert-left-icon-big {
  align-self: center;
  margin-right: 0.9375rem;
}

.left-icon-big .alert-left-icon-big i {
  font-size: 2.1875rem;
  line-height: 1;
}

.alert-dismissible.solid:not(.alert-light) .btn-close,
.alert-social .btn-close {
  color: #fff;
  opacity: 1;
}

.left-icon-big .alert-left-icon-big {
  align-self: center;
  margin-right: 0.9375rem;
}

.left-icon-big .alert-left-icon-big i {
  font-size: 2.1875rem;
  line-height: 1;
}

.alert-dismissible.solid:not(.alert-light) .btn-close,
.alert-social .btn-close {
  color: #fff;
  opacity: 1;
}

.alert-icon {
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.25rem;
}

.alert-dismissible .btn-close {
  padding: 0.85rem 1rem;
  right: 0;
}

div:where(.swal2-container) div:where(.swal2-popup) {
  background: var(--card) !important;
  color: var(--text-dark) !important;
}

.badge {
  line-height: 1.2;
  border-radius: 0.325rem;
  font-weight: 600;
  padding: 0.25rem 0.625rem;
  border: 0.0625rem solid transparent;
}

.badge-rounded {
  border-radius: 1.25rem;
  padding: 0.1875rem 0.8125rem;
}

.badge-circle {
  border-radius: 6.25rem;
  padding: 0.1875rem 0.4375rem;
}

.badge-outline-primary {
  border: 0.0625rem solid var(--primary);
  color: var(--primary);
}

.badge-outline-secondary {
  border: 0.0625rem solid #3693ff;
  color: #3693ff;
}

.badge-outline-success {
  border: 0.0625rem solid #3ab67a;
  color: #3ab67a;
}

.badge-outline-info {
  border: 0.0625rem solid #00ada3;
  color: #00ada3;
}

.badge-outline-warning {
  border: 0.0625rem solid #ffab2d;
  color: #ffab2d;
}

.badge-outline-danger {
  border: 0.0625rem solid #fd5353;
  color: #fd5353;
}

.badge-outline-light {
  border: 0.0625rem solid #e6e6e6;
  color: #5b5e81;
}

.badge-outline-dark {
  border: 0.0625rem solid #5b5e81;
  color: #5b5e81;
}

.badge-xs {
  padding: 0px 8px;
  line-height: 14px;
  border-radius: 0.375rem;
  font-weight: 500;
  height: 16px;
  min-height: 16px;
  font-size: 0.58rem;
}

.badge-sm {
  font-size: 0.6875rem;
  padding: 0.3125rem 0.5rem;
  line-height: 0.6875rem;
}

.badge-lg {
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  font-weight: 400;
  font-size: 0.813rem;
  line-height: 1.4;
}

.badge-lg:hover {
  color: #fff;
}

.badge-xl {
  font-size: 1rem;
  padding: 0rem 0.9375rem;
  line-height: 2;
}

.badge-default {
  background: #adb6c7;
}

.badge-success {
  background-color: #3ab67a;
}

.badge-secondary {
  background-color: #3693ff;
}

.badge-info {
  background-color: #00ada3;
}

.badge-primary {
  background-color: var(--primary);
}

.badge-warning {
  background-color: #ffab2d;
}

.badge-danger {
  background-color: #fd5353;
}

.badge-dark {
  background-color: #5b5e81;
}

.badge-light {
  background-color: #dedeed;
  color: #000 !important;
}

.light.badge-default {
  background: #adb6c7;
}

.light.badge-success {
  background-color: rgba(58, 182, 122, 0.2);
  color: #3ab67a;
}

.light.badge-info {
  background-color: rgba(0, 173, 163, 0.2);
  color: #00ada3;
}

.light.badge-primary {
  background-color: var(--rgba-primary-1);
  color: var(--primary);
}

.light.badge-secondary {
  background: rgba(54, 147, 255, 0.2);
  color: #3693ff;
}

.light.badge-warning {
  background: rgba(255, 171, 45, 0.2);
  color: #ffab2d;
}

.light.badge-danger {
  background: rgba(253, 83, 83, 0.2);
  color: #fd5353;
}

.light.badge-dark {
  background: rgba(91, 94, 129, 0.2);
  color: #fff;
}

.bootstrap-label .label {
  display: inline-block;
  margin-right: 1rem;
}

.bootstrap-label .label:last-child {
  margin-right: 0;
}

.badge-demo .badge {
  margin-right: 0.3125rem;
  margin-bottom: 0.3125rem;
}

.badge-demo .badge:last-child {
  margin-right: 0;
}

.bootstrap-badge-buttons button {
  margin-right: 0.2rem;
  margin-bottom: 1rem;
}

.bootstrap-badge-buttons button:last-child {
  margin-right: 0;
}

.badge-xs.style-1 {
  margin-left: 1rem;
}

.breadcrumb {
  font-size: 1.1875rem;
}

.breadcrumb .breadcrumb-item.active a {
  color: var(--primary);
}

.breadcrumb .breadcrumb-item svg {
  width: 22px;
  margin-bottom: 3px;
}

.breadcrumb .breadcrumb-item+.breadcrumb-item::before {
  color: var(--primary);
}

.page-titles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 0.075rem;
  margin-top: 80.7px;
  position: relative;
  padding-left: 18.5rem;
  padding-right: 2.4rem;
  z-index: -1;
  flex-flow: wrap;
}

.page-titles h2,
.page-titles .h2 {
  margin: 0;
  font-size: 28px;
}

@media only screen and (max-width: 100rem) {
  .page-titles {
    padding: 1.75rem 0.075rem;
    padding-left: 18.5rem;
    padding-right: 2.4rem;
  }

  .page-titles h2,
  .page-titles .h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 64rem) {
  .page-titles {
    margin-top: 77px !important;
  }
}

@media only screen and (max-width: 87.5rem) {
  .page-titles {
    padding: 1.75rem 2.875rem;
    margin-top: 76.1px;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .page-titles {
    padding: 1.75rem 2.075rem;
    margin-top: 69px !important;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .page-titles {
    margin-top: 76px !important;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .page-titles {
    margin-left: -1.25rem;
    margin-right: 0rem;
    margin-bottom: 0.9375rem;
    padding: 1.4375rem 1.25rem;
    margin-top: 69px !important;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .page-titles {
    padding: 0.6375rem 2.55rem;
    margin-top: 5.45rem;
    margin-right: 0rem;
    margin-bottom: 0;
  }

  .page-titles h2,
  .page-titles .h2 {
    font-size: 1rem;
  }
}

.page-titles .justify-content-sm-end {
  align-items: center;
}

.page-titles h4,
.page-titles .h4 {
  margin-bottom: 0;
  margin-top: 0;
  color: var(--primary);
  font-size: 1.25rem;
}

.page-titles h4 span,
.page-titles .h4 span {
  font-size: 0.875rem;
  font-weight: 400;
}

.page-titles .breadcrumb {
  margin-bottom: 0;
  padding: 0;
  background: transparent;
  align-items: center;
}

@media only screen and (max-width: 47.9375rem) {
  .page-titles .breadcrumb {
    padding-right: 0px;
  }
}

.page-titles .breadcrumb h2,
.page-titles .breadcrumb .h2 {
  margin: 0;
  font-size: 28px;
  font-weight: 500;
}

.page-titles .breadcrumb p {
  margin: 0;
  font-size: 16px;
  color: #fff000;
}

.page-titles .breadcrumb li {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 2rem;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 35.9375rem) {
  .page-titles .breadcrumb li:first-child {
    padding-left: 0;
  }
}

.page-titles .breadcrumb li a {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 35.9375rem) {
  .page-titles .breadcrumb li a {
    font-size: 0.95rem;
  }
}

.page-titles .breadcrumb li.active {
  color: var(--primary);
}

.page-titles .breadcrumb li.active a {
  color: #dedeed;
}

.page-titles .breadcrumb .breadcrumb-item+.breadcrumb-item:before {
  content: "\f105";
  font-family: "Font Awesome 6 Free";
  font-weight: 600;
  position: absolute;
  font-size: 14px;
  color: #dedeed;
  margin-left: -20px;
}

.page-titles .breadcrumb-datepicker {
  font-size: 0.75rem;
  color: #89879f;
}

.page-titles .breadcrumb-datepicker__icon {
  font-size: 0.875rem;
}

.page-titles .sub-dz-head {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  position: relative;
}

.page-titles .sub-dz-head:before {
  content: "";
  top: -18px;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.12);
  height: 1px;
  position: absolute;
}

@media only screen and (max-width: 35.9375rem) {
  .page-titles .sub-dz-head:before {
    top: -5px;
  }
}

.page-titles .breadcrumb-widget .border-dark {
  border-color: #dee2e6 !important;
}

.page-titles .breadcrumb-widget h4,
.page-titles .breadcrumb-widget .h4 {
  color: #646c9a;
  font-weight: 600;
}

@media only screen and (max-width: 35.9375rem) {
  .page-titles .breadcrumb-widget {
    text-align: left !important;
    margin-bottom: 0.9375rem;
  }
}

.page-titles p {
  margin: 0;
  color: #fff000;
}

.breadcrumb-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.125rem 1.875rem;
  background: #fff;
  margin-bottom: 1.875rem;
  border-radius: 0.5rem;
  position: relative;
  z-index: 1;
  flex-wrap: wrap;
}

.breadcrumb-title.style1 {
  padding: 0.92rem 1.875rem;
}

.breadcrumb-title h2,
.breadcrumb-title .h2 {
  margin: 0;
}

@media only screen and (max-width: 100rem) {

  .breadcrumb-title h2,
  .breadcrumb-title .h2 {
    font-size: 20px;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .breadcrumb-title {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .breadcrumb-title {
    padding: 0.6375rem 1rem;
    flex-wrap: wrap;
  }

  .breadcrumb-title h2,
  .breadcrumb-title .h2 {
    font-size: 1rem;
  }
}

.breadcrumb-title .justify-content-sm-end {
  align-items: center;
}

@media only screen and (max-width: 35.9375rem) {
  .breadcrumb-title .left-title {
    margin-bottom: 0.5rem;
  }
}

.breadcrumb-title h4,
.breadcrumb-title .h4 {
  margin-bottom: 0;
  margin-top: 0;
  color: var(--primary);
  font-size: 1.25rem;
}

.breadcrumb-title h4 span,
.breadcrumb-title .h4 span {
  font-size: 0.875rem;
  font-weight: 400;
}

.breadcrumb-title .breadcrumb {
  margin-bottom: 0;
  padding: 0;
  background: transparent;
  align-items: center;
}

@media only screen and (max-width: 47.9375rem) {
  .breadcrumb-title .breadcrumb {
    padding-right: 0px;
  }
}

.breadcrumb-title .breadcrumb h2,
.breadcrumb-title .breadcrumb .h2 {
  margin: 0;
  font-size: 28px;
  font-weight: 500;
}

.breadcrumb-title .breadcrumb p {
  margin: 0;
  font-size: 16px;
  color: #fff000;
}

.breadcrumb-title .breadcrumb li {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 2rem;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 35.9375rem) {
  .breadcrumb-title .breadcrumb li:first-child {
    padding-left: 0;
  }
}

.breadcrumb-title .breadcrumb li a {
  font-size: 16px;
  color: #000;
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 35.9375rem) {
  .breadcrumb-title .breadcrumb li a {
    font-size: 0.95rem;
  }
}

.breadcrumb-title .breadcrumb li.active {
  color: var(--primary);
  font-size: 1rem;
}

.breadcrumb-title .breadcrumb li.active a {
  color: #dedeed;
}

@media only screen and (max-width: 35.9375rem) {
  .breadcrumb-title .breadcrumb li.active {
    font-size: 0.95rem;
  }
}

.breadcrumb-title .breadcrumb .breadcrumb-item+.breadcrumb-item:before {
  content: "\f105";
  font-family: "Font Awesome 6 Free";
  font-weight: 600;
  position: absolute;
  font-size: 14px;
  color: var(--primary);
  margin-left: -20px;
}

.breadcrumb-title .breadcrumb-datepicker {
  font-size: 0.75rem;
  color: #89879f;
}

.breadcrumb-title .breadcrumb-datepicker__icon {
  font-size: 0.875rem;
}

.breadcrumb-title .sub-dz-head {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  position: relative;
}

.breadcrumb-title .sub-dz-head:before {
  content: "";
  top: -18px;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.12);
  height: 1px;
  position: absolute;
}

.breadcrumb-title .breadcrumb-widget .border-dark {
  border-color: #dee2e6 !important;
}

.breadcrumb-title .breadcrumb-widget h4,
.breadcrumb-title .breadcrumb-widget .h4 {
  color: #646c9a;
  font-weight: 600;
}

@media only screen and (max-width: 35.9375rem) {
  .breadcrumb-title .breadcrumb-widget {
    text-align: left !important;
    margin-bottom: 0.9375rem;
  }
}

.breadcrumb-title p {
  margin: 0;
  color: #fff000;
}

@media only screen and (max-width: 74.9375rem) {
  .breadcrumb-title .btn svg {
    width: 18px;
    height: 18px;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .breadcrumb-title .btn svg {
    width: 18px;
    height: 18px;
  }
}

button {
  cursor: pointer;
}

button:focus {
  outline: 0;
  box-shadow: none;
}

.btn {
  padding: 0.59375rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}

.btn:hover,
.btn:focus,
.btn:active,
.btn.active {
  outline: 0 !important;
}

@media only screen and (max-width: 87.5rem) {
  .btn {
    padding: 0.625rem 1rem;
    font-size: 0.813rem;
  }
}

.btn.btn-success,
.btn.btn-secondary,
.btn.btn-warning,
.btn.btn-primary,
.btn.btn-danger,
.btn.btn-info {
  color: #fff;
}

.btn-transparent {
  background-color: transparent;
}

.btn.btn-primary:active,
.btn.btn-secondary:active,
.btn.btn-danger:active,
.btn.btn-info:active,
.btn.btn-dark:active,
.btn.btn-success:active,
.btn.btn-warning:active {
  color: #fff !important;
}

.btn-primary {
  border-color: var(--primary);
  background-color: var(--primary);
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  border-color: var(--primary-hover);
  background-color: var(--primary-hover);
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.25rem var(--rgba-primary-5);
}

.btn-primary:disabled,
.btn-primary.disabled {
  background-color: var(--primary);
  border-color: var(--primary);
}

.btn-link {
  color: var(--primary);
  text-decoration: none;
}

.btn-link:hover {
  color: var(--primary-hover);
}

.btn-outline-primary {
  color: var(--primary);
  border-color: var(--primary);
}

.btn-outline-primary:hover {
  border-color: var(--primary-hover);
  background-color: var(--primary-hover);
}

.sharp {
  min-width: 2.4rem;
  padding: 0.4375rem;
  height: 2.4rem;
  line-height: 1.5;
  min-height: 2.5rem;
}

.sharp.btn-xs {
  padding: 0.1875rem;
  width: 1.625rem;
  height: 1.625rem;
  min-width: 1.625rem;
  min-height: 1.625rem;
}

.sharp-lg {
  height: 2.813rem;
  width: 2.813rem;
  line-height: 1.813rem;
}

@media only screen and (max-width: 87.5rem) {
  .sharp-lg {
    height: 2.285rem;
    width: 2.285rem;
    line-height: 1.4rem;
    font-size: 0.813rem;
  }
}

.btn-block {
  display: block;
  width: 100%;
}

.light.tp-btn {
  background-color: transparent;
}

.light.btn-default {
  background: #adb6c7;
}

.light.btn-success {
  background: rgba(58, 182, 122, 0.2);
  border-color: rgba(58, 182, 122, 0.2);
  color: #3ab67a;
}

.light.btn-success g [fill] {
  fill: #3ab67a;
}

.light.btn-success:hover {
  background-color: #3ab67a;
  border-color: #3ab67a;
  color: #fff;
}

.light.btn-success:hover g [fill] {
  fill: #fff;
}

.light.btn-info {
  background: rgba(0, 173, 163, 0.2);
  border-color: rgba(0, 173, 163, 0.2);
  color: #00ada3;
}

.light.btn-info g [fill] {
  fill: #00ada3;
}

.light.btn-info:hover {
  background-color: #00ada3;
  border-color: #00ada3;
  color: #fff;
}

.light.btn-info:hover g [fill] {
  fill: #fff;
}

.light.btn-light {
  background: rgba(222, 222, 237, 0.2);
  border-color: rgba(222, 222, 237, 0.2);
  color: #5b5e81;
}

.light.btn-light g [fill] {
  fill: #dedeed;
}

.light.btn-light:hover {
  background-color: #dedeed;
  border-color: #dedeed;
}

.light.btn-primary {
  background-color: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
}

.light.btn-primary g [fill] {
  fill: var(--primary);
}

.light.btn-primary:focus {
  color: var(--primary);
}

.light.btn-primary:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.light.btn-primary:hover g [fill] {
  fill: #fff;
}

.light.btn-secondary {
  background: rgba(54, 147, 255, 0.2);
  border-color: rgba(54, 147, 255, 0.2);
  color: #3693ff;
}

.light.btn-secondary g [fill] {
  fill: #3693ff;
}

.light.btn-secondary:hover {
  background-color: #3693ff;
  border-color: #3693ff;
  color: #fff;
}

.light.btn-secondary:hover g [fill] {
  fill: #fff;
}

.light.btn-warning {
  background: rgba(255, 171, 45, 0.2);
  border-color: rgba(255, 171, 45, 0.2);
  color: #ffab2d;
}

.light.btn-warning g [fill] {
  fill: #ffab2d;
}

.light.btn-warning:hover {
  background-color: #ffab2d;
  border-color: #ffab2d;
  color: #fff;
}

.light.btn-warning:hover g [fill] {
  fill: #fff;
}

.light.btn-danger {
  background: rgba(253, 83, 83, 0.2);
  border-color: rgba(253, 83, 83, 0.2);
  color: #fd5353;
}

.light.btn-danger g [fill] {
  fill: #fd5353;
}

.light.btn-danger:hover {
  background-color: #fd5353;
  border-color: #fd5353;
  color: #fff;
}

.light.btn-danger:hover g [fill] {
  fill: #fff;
}

.light.btn-dark {
  background: rgba(91, 94, 129, 0.5);
  border-color: rgba(91, 94, 129, 0.5);
  color: #fff;
}

.light.btn-dark g [fill] {
  fill: #5b5e81;
}

.light.btn-dark:hover {
  background-color: #5b5e81;
  border-color: #5b5e81;
  color: #fff;
}

.light.btn-dark:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn {
  background-color: transparent;
  border-color: transparent;
}

.btn.tp-btn.btn-default {
  background: #adb6c7;
}

.btn.tp-btn.btn-success {
  color: #3ab67a;
}

.btn.tp-btn.btn-success g [fill] {
  fill: #3ab67a;
}

.btn.tp-btn.btn-success:hover {
  background-color: #3ab67a;
  border-color: #3ab67a;
  color: #fff;
}

.btn.tp-btn.btn-success:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-info {
  color: #00ada3;
}

.btn.tp-btn.btn-info g [fill] {
  fill: #00ada3;
}

.btn.tp-btn.btn-info:hover {
  background-color: #00ada3;
  border-color: #00ada3;
  color: #fff;
}

.btn.tp-btn.btn-info:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-primary {
  color: var(--primary);
  background-color: var(--rgba-primary-1);
}

.btn.tp-btn.btn-primary g [fill] {
  fill: var(--primary);
}

.btn.tp-btn.btn-primary:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.btn.tp-btn.btn-primary:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-primary:hover svg path {
  fill: #fff;
}

.btn.tp-btn.btn-secondary {
  color: var(--secondary);
}

.btn.tp-btn.btn-secondary g [fill] {
  fill: var(--secondary);
}

.btn.tp-btn.btn-secondary:hover {
  background-color: #3693ff;
  border-color: #3693ff;
  color: #fff;
}

.btn.tp-btn.btn-secondary:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-warning {
  color: #ffab2d;
}

.btn.tp-btn.btn-warning g [fill] {
  fill: #ffab2d;
}

.btn.tp-btn.btn-warning:hover {
  background-color: #ffab2d;
  border-color: #ffab2d;
  color: #fff;
}

.btn.tp-btn.btn-warning:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-danger {
  color: #fd5353;
}

.btn.tp-btn.btn-danger g [fill] {
  fill: #fd5353;
}

.btn.tp-btn.btn-danger:hover {
  background-color: #fd5353;
  border-color: #fd5353;
  color: #fff;
}

.btn.tp-btn.btn-danger:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-light {
  color: #5b5e81;
}

.btn.tp-btn.btn-light g [fill] {
  fill: #5b5e81;
}

.btn.tp-btn.btn-light:hover {
  background-color: #dedeed;
  border-color: #dedeed;
  color: #5b5e81;
}

.btn.tp-btn.btn-light:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn.btn-dark {
  color: #5b5e81;
}

.btn.tp-btn.btn-dark g [fill] {
  fill: #5b5e81;
}

.btn.tp-btn.btn-dark:hover {
  background-color: #5b5e81;
  border-color: #5b5e81;
  color: #fff;
}

.btn.tp-btn.btn-dark:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn-light {
  background-color: transparent;
  border-color: transparent;
}

.btn.tp-btn-light.btn-success {
  color: #3ab67a;
}

.btn.tp-btn-light.btn-success g [fill] {
  fill: #3ab67a;
}

.btn.tp-btn-light.btn-success:hover {
  background-color: #e0f5eb;
  border-color: #e0f5eb;
  color: #3ab67a;
}

.btn.tp-btn-light.btn-success:hover g [fill] {
  fill: #3ab67a;
}

.btn.tp-btn-light.btn-info {
  color: #00ada3;
}

.btn.tp-btn-light.btn-info g [fill] {
  fill: #00ada3;
}

.btn.tp-btn-light.btn-info:hover {
  background-color: #94fff9;
  border-color: #94fff9;
  color: #00ada3;
}

.btn.tp-btn-light.btn-info:hover g [fill] {
  fill: #00ada3;
}

.btn.tp-btn-light.btn-primary {
  color: var(--primary);
}

.btn.tp-btn-light.btn-primary g [fill] {
  fill: var(--primary);
}

.btn.tp-btn-light.btn-primary:hover {
  background-color: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
}

.btn.tp-btn-light.btn-primary:hover g [fill] {
  fill: var(--primary);
}

.btn.tp-btn-light.btn-secondary {
  color: var(--secondary);
}

.btn.tp-btn-light.btn-secondary g [fill] {
  fill: var(--secondary);
}

.btn.tp-btn-light.btn-secondary:hover {
  background-color: var(--rgba-secondary-1);
  border-color: var(--rgba-secondary-1);
  color: var(--secondary);
}

.btn.tp-btn-light.btn-secondary:hover g [fill] {
  fill: var(--secondary);
}

.btn.tp-btn-light.btn-warning {
  color: #ffab2d;
}

.btn.tp-btn-light.btn-warning g [fill] {
  fill: #ffab2d;
}

.btn.tp-btn-light.btn-warning:hover {
  background-color: #fff2e0;
  border-color: #fff2e0;
  color: #ffab2d;
}

.btn.tp-btn-light.btn-warning:hover g [fill] {
  fill: #ffab2d;
}

.btn.tp-btn-light.btn-danger {
  color: #fd5353;
}

.btn.tp-btn-light.btn-danger g [fill] {
  fill: #fd5353;
}

.btn.tp-btn-light.btn-danger:hover {
  background-color: #ffeaea;
  border-color: #ffeaea;
  color: #fd5353;
}

.btn.tp-btn-light.btn-danger:hover g [fill] {
  fill: #fff;
}

.btn.tp-btn-light.btn-dark {
  color: #5b5e81;
}

.btn.tp-btn-light.btn-dark g [fill] {
  fill: #5b5e81;
}

.btn.tp-btn-light.btn-dark:hover {
  background-color: #eaebf1;
  border-color: #eaebf1;
  color: #5b5e81;
}

.btn.tp-btn-light.btn-dark:hover g [fill] {
  fill: #fff;
}

.shadow.btn-primary {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 var(--rgba-primary-2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 var(--rgba-primary-2) !important;
}

.shadow.btn-secondary {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(54, 147, 255, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(54, 147, 255, 0.2) !important;
}

.shadow.btn-warning {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(255, 171, 45, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(255, 171, 45, 0.2) !important;
}

.shadow.btn-danger {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(253, 83, 83, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(253, 83, 83, 0.2) !important;
}

.shadow.btn-info {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(0, 173, 163, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(0, 173, 163, 0.2) !important;
}

.shadow.btn-success {
  -webkit-box-shadow: 0 0.3125rem 0.9375rem 0 rgba(58, 182, 122, 0.2) !important;
  box-shadow: 0 0.3125rem 0.9375rem 0 rgba(58, 182, 122, 0.2) !important;
}

.btn-xxs {
  padding: 0.375rem 0.9375rem;
  font-size: 0.6875rem;
  line-height: 1.3;
}

.btn-xs {
  font-size: 0.75rem;
  padding: 0.438rem 1rem;
  font-weight: 600;
}

.btn-sm,
.btn-group-sm>.btn {
  font-size: 0.813rem !important;
  padding: 0.625rem 1rem;
}

.btn-md {
  font-size: 0.875rem !important;
  padding: 0.875rem 1.25rem;
}

.btn-lg,
.btn-group-lg>.btn {
  padding: 1rem 2rem;
  font-size: 1.125rem !important;
}

@media only screen and (max-width: 35.9375rem) {

  .btn-lg,
  .btn-group-lg>.btn {
    padding: 0.75rem 1.25rem;
  }
}

.btn-xl {
  padding: 0.6rem 1rem;
}

.btn-xl.btn-default {
  font-weight: 600;
}

.btn-square {
  border-radius: 0;
}

.btn-rounded {
  border-radius: 2.5rem !important;
}

.btn-icon-end {
  border-left: 0.0625rem solid var(--border);
  display: inline-block;
  margin: -0.8rem 0 -0.8rem 1rem;
  padding: 0.4375rem 0 0.4375rem 1rem;
  margin: -1rem -0.25rem -1rem 1rem;
  padding: 0.7rem 0 0.7rem 1.25rem;
}

.btn-icon-start {
  background: #fff;
  border-radius: 0.5rem;
  display: inline-block;
  margin: -0.2rem 0.55rem -0.4rem -1.188rem;
  padding: 0.3rem 0.6rem 0.2rem;
  float: left;
}

@media only screen and (max-width: 87.5rem) {
  .btn-icon-start {
    margin: -0.5rem 0.75rem -0.5rem -0.88rem;
  }
}

.toggle-dropdown::after {
  margin-left: 0.755em;
}

.social-btn-icon .btn {
  min-width: 7.5rem;
  margin-bottom: 1.5rem;
}

.social-icon .btn {
  padding: 0.7rem 1.4rem;
}

.btn-circle {
  height: 5rem;
  width: 5rem;
  border-radius: 50% !important;
}

.btn-circle-sm {
  width: 4.5rem;
  height: 4.5rem;
  font-size: 1.8rem;
}

.btn-circle-md {
  width: 6rem;
  height: 6rem;
  font-size: 2.5rem;
}

.btn-circle-md i {
  font-size: 2.4rem;
}

.btn-circle-lg {
  width: 8rem;
  height: 8rem;
  font-size: 3.2rem;
}

.btn-circle-lg i {
  font-size: 3.1rem;
}

.btn-page .btn {
  min-width: 6.875rem;
  margin-right: 0.25rem;
  margin-bottom: 0.5rem;
}

.size-1 {
  min-width: 10rem !important;
  font-size: 1.5rem;
  padding: 0.68rem 0.75rem;
}

.size-2 {
  font-size: 1.25rem;
  min-width: 8.125rem !important;
  padding: 0.57rem 0.75rem;
}

.size-3 {
  font-size: 0.875rem;
  min-width: 6.875rem !important;
  padding: 0.536rem 0.75rem;
}

.size-4 {
  font-size: 0.875rem;
  min-width: 6.25rem !important;
}

.size-5 {
  font-size: 0.875rem;
  min-width: 5.625rem !important;
  padding: 0.22rem 0.75rem;
}

.size-6 {
  font-size: 0.8125rem;
  min-width: 5rem !important;
  padding: 0.097rem 0.75rem;
}

.size-7 {
  font-size: 0.75rem;
  min-width: 3.75rem !important;
  padding: 0.001rem 0.75rem;
}

.btn-light {
  background: #dedeed;
  border-color: #dedeed;
  color: #5b5e81;
}

.btn-light:active,
.btn-light:focus,
.btn-light:hover {
  background: #d7d7db;
  border-color: transparent;
}

.btn-outline-primary:hover {
  color: #fff;
}

.btn-outline-secondary:hover {
  color: #fff;
}

.btn-outline-success:hover {
  color: #fff;
}

.btn-outline-info:hover {
  color: #fff;
}

.btn-outline-warning:hover {
  color: #fff;
}

.btn-outline-danger:hover {
  color: #fff;
}

.btn-outline-light {
  color: #5b5e81;
}

.btn-dark {
  background: #5b5e81;
  border-color: #5b5e81;
  color: #fff;
}

.btn-dark:active,
.btn-dark:focus,
.btn-dark:hover {
  background: #464863;
  color: #fff;
  border-color: #464863;
}

.btn-group.btn-rounded .btn:first-child {
  border-top-left-radius: 1.875rem;
  border-bottom-left-radius: 1.875rem;
}

.btn-group.btn-rounded .btn:last-child {
  border-top-right-radius: 1.875rem;
  border-bottom-right-radius: 1.875rem;
}

.btn-facebook {
  background: #3b5998;
  border-color: #3b5998;
  color: #fff;
}

.btn-facebook:active,
.btn-facebook:focus,
.btn-facebook:hover {
  background: #2d4373 !important;
  color: #fff;
  border-color: #2d4373;
}

.btn-twitter {
  background: #1da1f2;
  border-color: #1da1f2;
  color: #fff;
}

.btn-twitter:active,
.btn-twitter:focus,
.btn-twitter:hover {
  background: #0c85d0 !important;
  color: #fff;
  border-color: #0c85d0;
}

.btn-youtube {
  background: #ff0000;
  border-color: #ff0000;
  color: #fff;
}

.btn-youtube:active,
.btn-youtube:focus,
.btn-youtube:hover {
  background: #cc0000 !important;
  color: #fff;
  border-color: #cc0000;
}

.btn-instagram {
  background: #c32aa3;
  border-color: #c32aa3;
  color: #fff;
}

.btn-instagram:active,
.btn-instagram:focus,
.btn-instagram:hover {
  background: #992180 !important;
  color: #fff;
  border-color: #992180;
}

.btn-pinterest {
  background: #bd081c;
  border-color: #bd081c;
  color: #fff;
}

.btn-pinterest:active,
.btn-pinterest:focus,
.btn-pinterest:hover {
  background: #8c0615 !important;
  color: #fff;
  border-color: #8c0615;
}

.btn-linkedin {
  background: #007bb6;
  border-color: #007bb6;
  color: #fff;
}

.btn-linkedin:active,
.btn-linkedin:focus,
.btn-linkedin:hover {
  background: #005983 !important;
  color: #fff;
  border-color: #005983;
}

.btn-google-plus {
  background: #db4439;
  border-color: #db4439;
  color: #fff;
}

.btn-google-plus:active,
.btn-google-plus:focus,
.btn-google-plus:hover {
  background: #be2d23 !important;
  color: #fff;
  border-color: #be2d23;
}

.btn-google {
  background: #4285f4;
  border-color: #4285f4;
  color: #fff;
}

.btn-google:active,
.btn-google:focus,
.btn-google:hover {
  background: #1266f1 !important;
  color: #fff;
  border-color: #1266f1;
}

.btn-snapchat {
  background: #fffc00;
  border-color: #fffc00;
  color: #000;
}

.btn-snapchat:active,
.btn-snapchat:focus,
.btn-snapchat:hover {
  background: #ccca00 !important;
  color: #000;
  border-color: #ccca00;
}

.btn-whatsapp {
  background: #25d366;
  border-color: #25d366;
  color: #fff;
}

.btn-whatsapp:active,
.btn-whatsapp:focus,
.btn-whatsapp:hover {
  background: #1da851 !important;
  color: #fff;
  border-color: #1da851;
}

.btn-tumblr {
  background: #35465d;
  border-color: #35465d;
  color: #fff;
}

.btn-tumblr:active,
.btn-tumblr:focus,
.btn-tumblr:hover {
  background: #222e3d !important;
  color: #fff;
  border-color: #222e3d;
}

.btn-reddit {
  background: #ff4500;
  border-color: #ff4500;
  color: #fff;
}

.btn-reddit:active,
.btn-reddit:focus,
.btn-reddit:hover {
  background: #cc3700 !important;
  color: #fff;
  border-color: #cc3700;
}

.btn-spotify {
  background: #1ed760;
  border-color: #1ed760;
  color: #fff;
}

.btn-spotify:active,
.btn-spotify:focus,
.btn-spotify:hover {
  background: #18aa4c !important;
  color: #fff;
  border-color: #18aa4c;
}

.btn-yahoo {
  background: #430297;
  border-color: #430297;
  color: #fff;
}

.btn-yahoo:active,
.btn-yahoo:focus,
.btn-yahoo:hover {
  background: #2d0165 !important;
  color: #fff;
  border-color: #2d0165;
}

.btn-dribbble {
  background: #ea4c89;
  border-color: #ea4c89;
  color: #fff;
}

.btn-dribbble:active,
.btn-dribbble:focus,
.btn-dribbble:hover {
  background: #e51e6b !important;
  color: #fff;
  border-color: #e51e6b;
}

.btn-skype {
  background: #00aff0;
  border-color: #00aff0;
  color: #fff;
}

.btn-skype:active,
.btn-skype:focus,
.btn-skype:hover {
  background: #008abd !important;
  color: #fff;
  border-color: #008abd;
}

.btn-quora {
  background: #aa2200;
  border-color: #aa2200;
  color: #fff;
}

.btn-quora:active,
.btn-quora:focus,
.btn-quora:hover {
  background: #771800 !important;
  color: #fff;
  border-color: #771800;
}

.btn-vimeo {
  background: #1ab7ea;
  border-color: #1ab7ea;
  color: #fff;
}

.btn-vimeo:active,
.btn-vimeo:focus,
.btn-vimeo:hover {
  background: #1295bf !important;
  color: #fff;
  border-color: #1295bf;
}

.btn-check:checked+.btn-outline-primary,
.btn-check:active+.btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.btn-check:checked+.btn-outline-primary:focus,
.btn-check:active+.btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem var(--rgba-primary-5);
}

.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem var(--rgba-primary-5);
}

.btn-close:focus {
  box-shadow: 0 0 0 0.25rem var(--rgba-primary-5);
}

.btn-icon-lg {
  padding: 15px 18px;
  min-width: 20px !important;
}

.btn-icon-md {
  padding: 13px 16px;
  min-width: 20px !important;
}

.btn-icon-sm {
  padding: 11px 14px;
  min-width: 20px !important;
}

.btn-icon-xs {
  padding: 10px 12px;
  min-width: 20px !important;
}

.btn-icon-xxs {
  padding: 8px 10px;
  min-width: 20px !important;
}

.btn-close {
  background: none;
  position: relative;
  font-family: "Font Awesome 5 Free";
  margin-left: auto !important;
  margin-right: unset !important;
  color: var(--text-dark);
}

.btn-close:before {
  content: "\f00d";
  font-weight: 600;
}

.btn-close:hover {
  color: var(--text-dark);
}

.btn-group>.btn:not(:last-child):not(:first-child):not(.dropdown-toggle) {
  border-radius: 0;
}

.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.btn-group>.btn.dropdown-toggle-split:first-child,
.btn-group .btn-group>.btn-group:not(:last-child)>.btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.btn-group button.dropdown-toggle.dropdown-toggle-split:first-child {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.btn-group button.dropdown-toggle.dropdown-toggle-split {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.btn-group> :not(.btn-check)+.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.btn-group>.btn-group:not(:first-child)>.btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.dropdown-toggle::after {
  margin: 0;
  margin-left: 0.255em;
}

.btn-close:focus {
  box-shadow: unset;
}

.card {
  margin-bottom: 1.875rem;
  background-color: var(--card);
  transition: all 0.5s ease-in-out;
  position: relative;
  border: 0rem solid transparent;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05);
  height: calc(100% - 30px);
  color: inherit;
}

@media only screen and (max-width: 35.9375rem) {
  .card {
    margin-bottom: 0.938rem;
    height: calc(100% - 0.938rem);
  }
}

.card-body {
  padding: 1.5rem 1.875rem 1.25rem;
}

@media only screen and (max-width: 35.9375rem) {
  .card-body {
    padding: 1rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .card-footer {
    padding: 1rem;
  }
}

.card-title {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--text-dark);
  text-transform: capitalize;
}

.card-title--large {
  font-size: 1.5rem;
}

.card-title--medium {
  font-size: 1rem;
}

.card-title--small {
  font-size: 0.875rem;
}

.card-header {
  border-color: var(--border);
  position: relative;
  background: transparent;
  padding: 1.5rem 1.875rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 35.9375rem) {
  .card-header {
    padding: 1.25rem 1rem 1.25rem;
  }
}

[data-theme-version="dark"] .card-header {
  border-color: #2a3969;
}

.card-header .card-title {
  margin-bottom: 0rem;
}

.card-header .subtitle {
  padding-top: 0.3125rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.card-footer {
  border-color: #e6e6e6;
  background: transparent;
  padding: 1.25rem 1.875rem 1.25rem;
}

[data-theme-version="dark"] .card-footer {
  border-color: #2a3969;
}

.transparent-card.card {
  background: transparent;
  border: 0.0625rem solid transparent;
  box-shadow: none;
}

.card-action>a {
  display: inline-block;
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  border-radius: 0.3125rem;
  border-color: transparent;
  text-align: center;
  background: var(--primary-dark);
  color: #fff;
  margin-right: 0.5rem;
}

[data-theme-version="dark"] .card-action>a {
  background: #09153f;
}

.card-action>a:last-child {
  margin-right: 0;
}

.card-action>a:hover,
.card-action>a:focus {
  background: var(--primary-dark);
}

[data-theme-version="dark"] .card-action>a:hover,
[data-theme-version="dark"] .card-action>a:focus {
  background: #09153f;
}

.card-action>a i,
.card-action>a span {
  font-size: 1rem;
}

.card-action .dropdown {
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 0.3125rem;
  border-color: transparent;
  text-align: center;
  margin-right: 0.5rem;
  top: -0.125rem;
  position: relative;
  display: inline-block;
  background: var(--primary-dark);
  color: var(--primary);
}

[data-theme-version="dark"] .card-action .dropdown {
  background: #09153f;
}

.card-action .dropdown:hover,
.card-action .dropdown:focus {
  background: var(--primary-dark);
}

[data-theme-version="dark"] .card-action .dropdown:hover,
[data-theme-version="dark"] .card-action .dropdown:focus {
  background: #09153f;
}

.card-action .dropdown .btn {
  padding: 0;
  line-height: 1.6875rem;
  color: #fff;
}

.card-action .dropdown .btn:focus {
  box-shadow: none;
}

.card-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  overflow: auto;
}

.card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

.card-loader i {
  margin: 0 auto;
  background: var(--primary-dark);
  padding: 0.625rem;
  border-radius: 50%;
  color: #fff;
  font-size: 1rem;
}

.rotate-refresh {
  -webkit-animation: mymove 0.8s infinite linear;
  animation: mymove 0.8s infinite linear;
  display: inline-block;
}

.card-header .date_picker {
  display: inline-block;
  padding: 0.5rem;
  border: 0.0625rem solid #e6e6e6;
  cursor: pointer;
  border-radius: 0.375rem;
}

.card-header .border-0 {
  padding-bottom: 0;
}

@-webkit-keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.card-link:hover {
  color: var(--primary);
}

.carousel-custom-prev,
.carousel-custom-next {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  display: inline-block;
  border-radius: 50%;
  background: #fff;
  text-align: center;
}

.carousel-custom-prev i,
.carousel-custom-next i {
  font-size: 1rem;
}

.carousel-custom-prev:hover,
.carousel-custom-next:hover {
  background: linear-gradient(to right,
      rgba(245, 60, 121, 0.99) 0%,
      rgba(246, 104, 47, 0.99) 100%);
  color: #fff;
}

.carousel-custom-next {
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
}

.carousel-custom-prev {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
}

.carousel-caption {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.78);
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
}

.carousel-caption h5,
.carousel-caption .h5 {
  color: #fff;
  font-size: 1.8rem;
}

.carousel-caption p {
  margin-bottom: 0;
}

.carousel-indicators {
  z-index: 1;
}

.dropdown-toggle:focus {
  box-shadow: none !important;
}

.dropdown-outline {
  border: 0.1rem solid var(--primary);
}

.dropdown-menu {
  font-size: inherit;
  border: 0;
  z-index: 2;
  overflow: hidden;
  border-radius: 0.5rem;
  background-color: var(--card);
  box-shadow: 0 0 3.125rem 0 rgba(82, 63, 105, 0.15);
  margin-top: 0;
}

.dropdown-menu .dropdown-item {
  font-size: 1rem;
  color: var(--text);
  padding: 0.5rem 1.75rem;
  text-align: left;
}

@media only screen and (max-width: 87.5rem) {
  .dropdown-menu .dropdown-item {
    padding: 0.375rem 1rem;
    font-size: 0.875rem;
  }
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus,
.dropdown-menu .dropdown-item:active,
.dropdown-menu .dropdown-item.active {
  color: #514e5f;
  background-color: var(--body-bg);
}

.dropdown-menu .dropdown-item.active,
.dropdown-menu .dropdown-item:active {
  color: var(--primary);
  background: var(--rgba-primary-1);
}

.dropdown-menu.show {
  right: 0;
  left: auto;
}

.dropdown-toggle-split {
  padding: 0 0.625rem;
  opacity: 0.85;
}

.dropdown-toggle-split:after {
  margin-left: 0 !important;
}

.dropdown-toggle-split:active,
.dropdown-toggle-split:focus,
.dropdown-toggle-split:hover {
  opacity: 1;
}

.dropright .dropdown-toggle::after {
  content: none;
}

.custom-dropdown {
  display: inline-block;
}

.custom-dropdown .dropdown-menu {
  border: 0rem;
  min-width: 10rem;
}

.card-action .custom-dropdown {
  margin: 0rem;
  background: var(--rgba-primary-1);
}

.card-action .custom-dropdown.show,
.card-action .custom-dropdown:focus,
.card-action .custom-dropdown:hover {
  background: var(--primary);
  color: #fff;
}

.card-action .custom-dropdown i {
  display: inline-block;
  padding-top: 0.5625rem;
}

.dropdown .dropdown-dots {
  position: relative;
  height: 0.3125rem;
  width: 0.3125rem;
  background: rgba(91, 94, 129, 0.4);
  border-radius: 0.3125rem;
  display: block;
}

.dropdown .dropdown-dots:after,
.dropdown .dropdown-dots:before {
  content: "";
  height: 0.3125rem;
  width: 0.3125rem;
  background: rgba(91, 94, 129, 0.4);
  position: absolute;
  border-radius: 0.3125rem;
}

.dropdown .dropdown-dots:after {
  right: -0.5rem;
}

.dropdown .dropdown-dots:before {
  left: -0.5rem;
}

.dropdown .dropdown-dots.text-white {
  background: rgba(255, 255, 255, 0.7);
}

.dropdown .dropdown-dots.text-white:after,
.dropdown .dropdown-dots.text-white:before {
  background: rgba(255, 255, 255, 0.7);
}

.grid-col {
  padding: 0.5rem !important;
  background: var(--body-bg);
}

.row.grid {
  margin-bottom: 1.5rem;
  text-align: center;
}

.row.grid .grid-col:first-child {
  text-align: left;
}

.label {
  display: inline-block;
  text-align: center;
  font-size: 0.75rem;
  padding: 0.2rem 0.8rem;
  border-radius: 0.5rem;
}

.label-fixed {
  width: 7.5rem;
  padding: 0.6rem 0;
}

.label-fixed-lg {
  width: 9.5rem;
  padding: 0.6rem 0;
}

.label-big {
  width: 16.8rem;
  font-size: 1.4rem;
  padding: 1.1rem 0;
}

.label-xl {
  width: 10.5rem;
  padding: 1.1rem 0;
  font-size: 1.5rem;
}

.label-lg {
  width: 9.5rem;
  padding: 1.1rem 0;
}

.label-md {
  width: 8.5rem;
  padding: 1.1rem 0;
}

.label-sm {
  width: 7.5rem;
  padding: 1.1rem 0;
}

.label-default {
  background: #adb6c7;
}

.label-primary {
  background: var(--primary);
  color: #fff;
}

.label-secondary {
  background: #3693ff;
  color: #fff;
}

.label-info {
  background: #00ada3;
  color: #fff;
}

.label-success {
  background: #3ab67a;
  color: #fff;
}

.label-warning {
  background: #ffab2d;
  color: #fff;
}

.label-danger {
  background: #fd5353;
  color: #fff;
}

.label-light {
  background: #dedeed;
  color: #000;
}

.label-dark {
  background: #5b5e81;
  color: #fff;
}

code {
  word-break: break-word;
  padding: 0.125rem 0.3125rem;
  border-radius: 0.1875rem;
  background: #fff9f9;
  color: #fd5353;
}

[data-theme-version="dark"] code {
  background: rgba(253, 83, 83, 0.1);
}

.heading-labels {
  color: #333333;
}

.heading-labels>* {
  margin-bottom: 0.8rem;
}

.heading-labels h1 .label,
.heading-labels .h1 .label {
  font-size: 1.125rem;
  font-weight: normal;
  padding: 0.4rem 0.9rem;
}

.heading-labels h2 .label,
.heading-labels .h2 .label {
  font-size: 1rem;
  font-weight: normal;
  padding: 0.3rem 0.9rem;
}

.heading-labels h3 .label,
.heading-labels .h3 .label {
  font-size: 0.875rem;
  font-weight: normal;
}

.heading-labels h4 .label,
.heading-labels .h4 .label {
  font-size: 0.75rem;
  font-weight: normal;
}

.heading-labels h5 .label,
.heading-labels .h5 .label {
  font-size: 0.75rem;
  font-weight: normal;
}

.heading-labels h6 .label,
.heading-labels .h6 .label {
  font-size: 0.75rem;
  font-weight: normal;
}

.list-group-item {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid var(--border);
  padding: 1rem 1.5rem;
}

.list-group-item.active {
  background-color: var(--primary) !important;
  border-color: var(--primary);
  color: #fff;
}

.list-group-item.active:focus {
  color: #fff;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #fff;
  background-color: #3693ff;
  border-color: #3693ff;
}

[class*="bg-"] .list-group-item {
  border-color: rgba(255, 255, 255, 0.05);
  color: #fff;
}

.bg-warning .list-group-item {
  border-color: rgba(0, 0, 0, 0.05);
}

.list-group-item-action:active {
  background-color: var(--card);
}

.list-group-item-action:focus {
  color: var(--text-dark);
}

.media img {
  border-radius: 0.1875rem;
}

.vertical-card__menu:hover {
  box-shadow: none;
}

.vertical-card__menu--image {
  text-align: center;
}

.vertical-card__menu--image img {
  width: 100%;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.vertical-card__menu--status.closed {
  background: #fd5353;
}

.vertical-card__menu--status.closed:after {
  border-top: 0.625rem solid #fd5353;
}

.vertical-card__menu--status.closed .ribbon-curve {
  border-top: 0.6875rem solid #fd5353;
  border-bottom: 0.625rem solid #fd5353;
}

.vertical-card__menu--desc p {
  margin-bottom: 0.625rem;
}

.vertical-card__menu--price {
  font-size: 1.25rem;
  margin: 0;
  font-weight: 700;
}

.vertical-card__menu--rating {
  font-size: 0.75rem;
}

.vertical-card__menu--title {
  font-weight: 700;
}

.vertical-card__menu--button button {
  font-size: 0.75rem;
}

.vertical-card__menu .card-footer {
  padding: 0.9375rem 1.25rem;
  background-color: #e6e6e6;
  border-top: none;
}

@media only screen and (min-width: 75rem) and (max-width: 90rem) {
  .vertical-card__menu .card-footer {
    padding: 0.9375rem;
  }
}

.vertical-card__menu .card-footer span {
  color: #5b5e81;
  margin-right: 0.3125rem;
}

.horizontal-card__menu {
  box-shadow: 0 0 0.4375rem rgba(173, 173, 173, 0.32);
  display: flex;
  flex-direction: row;
  display: block;
}

.horizontal-card__menu .card-body {
  padding: 0;
  padding: 1.25rem 1.875rem;
}

.horizontal-card__menu--image {
  flex-basis: 30%;
  max-width: 30%;
  max-width: unset;
  flex-basis: 100%;
}

.horizontal-card__menu--image img {
  height: 100%;
  width: 100%;
}

.horizontal-card__menu--title {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.3125rem;
}

.horizontal-card__menu--fav {
  margin-right: 0.5rem;
}

.horizontal-card__menu--price {
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
}

.horizontal-card__menu--rating {
  font-size: 0.625rem;
}

.horizontal-card__menu--footer {
  margin-top: 0.625rem;
}

.prev_price {
  text-decoration: line-through;
  font-size: 80%;
  opacity: 0.5;
}

.modal-header {
  padding: 1rem 1.875rem;
}

.modal-header .close {
  padding: 0.875rem 1.815rem;
  margin: 0;
  position: absolute;
  right: 0;
  float: none;
  top: 0;
  font-size: 1.875rem;
  font-weight: 100;
}

.modal-body {
  padding: 1.875rem;
}

.modal-footer {
  padding: 1rem 1.875rem;
}

.modal-content {
  border-radius: 0.5rem;
  background-color: var(--card);
}

.modal-backdrop {
  z-index: 10 !important;
}

.pagination .page-item .page-link:hover {
  background: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.pagination .page-item.active .page-link {
  background: var(--primary);
  border-color: var(--primary);
  color: #fff;
}

.pagination {
  margin-bottom: 1.25rem;
}

.pagination .page-item:first-child .page-link {
  border-radius: 0;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.pagination .page-item:last-child .page-link {
  border-radius: 0;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.pagination .page-item.page-indicator .page-link {
  padding: 0.65rem 0.8rem;
  font-size: 0.875rem;
}

.pagination .page-item.page-indicator:hover .page-link {
  color: #5b5e81;
}

.pagination .page-item .page-link {
  text-align: center;
  z-index: 1;
  padding: 0.55rem 1rem;
  font-size: 1rem;
  background: var(--card);
  color: #5b5e81;
  border: 0.0625rem solid var(--border);
}

.pagination .page-item .page-link:hover i,
.pagination .page-item .page-link span {
  color: #fff;
}

.pagination .page-item .page-link:focus {
  outline: 0;
  box-shadow: none;
}

.pagination .page-item .page-link:hover {
  background: var(--primary);
  color: #fff;
  border-color: var(--primary);
}

.pagination .page-item.active .page-link {
  background-color: var(--primary);
  border-color: var(--primary);
  color: #fff;
  box-shadow: 0 0.625rem 1.25rem 0rem var(--rgba-primary-2);
}

.pagination .page-item .page-link {
  color: var(--text);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.pagination .page-item:last-child .page-link {
  margin-right: 0;
}

.pagination.no-bg li:not(.page-indicator):not(.active) .page-link {
  background: transparent;
  border-color: transparent;
}

.pagination.no-bg.pagination-primary li:not(.page-indicator):not(.active):hover .page-link {
  background: var(--primary);
  border-color: var(--primary);
}

.pagination.no-bg.pagination-danger li:not(.page-indicator):not(.active):hover .page-link {
  background: #fd5353;
  border-color: #fd5353;
}

.pagination.no-bg.pagination-info li:not(.page-indicator):not(.active):hover .page-link {
  background: #00ada3;
  border-color: #00ada3;
}

.pagination.no-bg.pagination-warning li:not(.page-indicator):not(.active):hover .page-link {
  background: #ffab2d;
  border-color: #ffab2d;
}

.pagination-primary .page-item .page-link {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
}

.pagination-primary .page-item:hover .page-link,
.pagination-primary .page-item.active .page-link {
  background: var(--primary);
  border-color: var(--primary);
  box-shadow: 0 0.625rem 1.25rem 0rem var(--rgba-primary-2);
}

.pagination-danger .page-item .page-link {
  background: rgba(253, 83, 83, 0.2);
  border-color: rgba(253, 83, 83, 0.2);
  color: #fd5353;
}

.pagination-danger .page-item:hover .page-link,
.pagination-danger .page-item.active .page-link {
  background: #fd5353;
  border-color: #fd5353;
  box-shadow: 0 0.625rem 1.25rem 0rem rgba(253, 83, 83, 0.2);
}

.pagination-info .page-item .page-link {
  background: rgba(0, 173, 163, 0.2);
  border-color: rgba(0, 173, 163, 0.2);
  color: #00ada3;
}

.pagination-info .page-item:hover .page-link,
.pagination-info .page-item.active .page-link {
  background: #00ada3;
  border-color: #00ada3;
  box-shadow: 0 0.625rem 1.25rem 0rem rgba(0, 173, 163, 0.2);
}

.pagination-warning .page-item .page-link {
  background: rgba(255, 171, 45, 0.2);
  border-color: rgba(255, 171, 45, 0.2);
  color: #ffab2d;
}

.pagination-warning .page-item:hover .page-link,
.pagination-warning .page-item.active .page-link {
  background: #ffab2d;
  border-color: #ffab2d;
  box-shadow: 0 0.625rem 1.25rem 0rem rgba(255, 171, 45, 0.2);
}

.pagination-gutter .page-item {
  margin-right: 0.4375rem;
}

.pagination-gutter .page-item .page-link {
  border-radius: 0.5rem !important;
}

.pagination-circle .page-item {
  margin-right: 0.4375rem;
}

.pagination-circle .page-item .page-link,
.pagination-circle .page-item .page-link {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  line-height: 2.5rem;
  border-radius: 50% !important;
  padding: 0;
}

.pagination-circle .page-item.page-indicator .page-link {
  width: 2.5rem;
  border: 1px solid var(--primary);
  border-radius: 22px !important;
  line-height: 1.3rem;
  height: 2.5rem;
  background: var(--rgba-primary-1);
  color: var(--primary);
}

.pagination-circle .page-item.page-indicator .page-link:hover {
  color: #fff;
  background: var(--primary);
}

.pagination.pagination-md .page-item .page-link {
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  font-size: 0.875rem;
}

.pagination.pagination-sm .page-item.page-indicator .page-link {
  font-size: 0.75rem;
}

.pagination.pagination-sm .page-item .page-link {
  padding: 0;
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  font-size: 0.875rem;
}

.pagination.pagination-xs .page-item.page-indicator .page-link {
  font-size: 0.625rem;
}

.pagination.pagination-xs .page-item .page-link {
  padding: 0;
  width: 1.5625rem;
  height: 1.5625rem;
  line-height: 1.5625rem;
  font-size: 0.75rem;
}

.popover {
  border: 0.125rem solid #3693ff;
  min-width: 13.125rem;
  box-shadow: 0 0 1.875rem 0 rgba(0, 0, 0, 0.1);
}

[data-theme-version="dark"] .popover {
  background-color: #09153f;
}

.popover-header {
  background: #3693ff;
  color: #fff;
  font-weight: 300;
}

.popover-header::before {
  border-bottom: 0 !important;
}

.popover-body {
  font-size: 0.75rem;
  background-color: var(--card);
}

[data-theme-version="dark"] .popover .popover-header {
  border-color: #18254f;
}

@media only screen and (max-width: 47.9375rem) {
  .popover {
    z-index: 1;
  }
}

.popover.clockpicker-popover {
  position: absolute;
  border: 0;
  left: 1263px;
  top: 560.688px;
}

.popover.clockpicker-popover.left {
  left: 1263px;
  top: 560.688px;
}

.bootstrap-popover-wrapper .bootstrap-popover:not(:last-child) {
  margin-right: 0.5rem;
}

.bootstrap-popover-wrapper .bootstrap-popover {
  margin-bottom: 0.5rem;
}

.bootstrap-popover-wrapper .bootstrap-popover button:hover,
.bootstrap-popover-wrapper .bootstrap-popover button:focus {
  background: var(--primary);
  color: #fff;
  box-shadow: none;
}

.clockpicker-popover .popover-title {
  padding: 5px 0;
  background-color: var(--card);
}

.clockpicker-popover .popover-content {
  background-color: var(--body-bg);
}

.clockpicker-plate {
  background-color: var(--card);
  border-color: var(--border);
}

.bs-popover-top .arrow::after,
.bs-popover-auto[data-popper-placement^="top"] .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
  bottom: 0rem;
  border-top-color: #3693ff;
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
  right: 0rem;
  border-left-color: #3693ff;
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
  left: 0rem;
  border-right-color: #3693ff;
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
  top: 0rem;
  border-bottom-color: #3693ff;
}

.note-popover {
  display: none;
}

.clockpicker-popover {
  border-color: var(--border);
}

.clockpicker-popover .popover-title {
  background: var(--card);
  color: var(--text-gray);
  border-bottom: 1px solid var(--border);
}

.clockpicker-popover .popover-content {
  background: var(--card);
}

.clockpicker-popover .clockpicker-plate {
  background: var(--card);
  border-color: var(--border);
}

.clockpicker-popover .clockpicker-plate .clockpicker-tick {
  color: var(--text-dark);
}

.clockpicker-button {
  background-color: var(--light);
  color: var(--text-dark);
  border-radius: 0;
}

.toltip-popover .tooltip-inner {
  background: #ffffff;
  padding: 0;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  border: 0.125rem solid #3693ff;
}

.toltip-popover .tooltip-inner strong {
  color: #777777;
}

.popover-header {
  background: #3693ff;
  border-top-left-radius: 0.1rem;
  border-top-right-radius: 0.1rem;
  padding: 10px;
}

.progress-bar {
  background-color: var(--primary);
}

.progress-bar-primary {
  background-color: var(--primary);
}

.progress-bar-success {
  background-color: #3ab67a;
}

.progress-bar-info {
  background-color: #00ada3;
}

.progress-bar-danger {
  background-color: #fd5353;
}

.progress-bar-warning {
  background-color: #ffab2d;
}

.progress-bar-pink {
  background-color: #ac4cbc;
}

.progress {
  height: 10px;
  background-color: #f6f6f6;
  overflow: hidden;
}

[data-theme-version="dark"] .progress {
  background-color: #09153f;
}

.progress-bar {
  border-radius: 0.5rem;
}

.progress-vertical {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 2rem;
  min-height: 17rem;
  position: relative;
}

@media (max-width: 767.98px) {
  .progress-vertical {
    margin-right: 1rem;
  }
}

.progress-vertical-bottom {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 2rem;
  min-height: 17rem;
  position: relative;
  transform: rotate(180deg);
}

@media (max-width: 767.98px) {
  .progress-vertical-bottom {
    margin-right: 1rem;
  }
}

.progress-animated {
  animation-duration: 5s;
  animation-name: myanimation;
  transition: all 5s ease 0s;
}

@keyframes myanimation {
  0% {
    width: 0;
  }
}

@keyframes myanimation {
  0% {
    width: 0;
  }
}

.ribbon {
  position: absolute;
  z-index: 1;
  text-transform: uppercase;
}

.ribbon__one {
  top: 0.9375rem;
  left: -0.6875rem;
  min-height: 1.25rem;
  min-width: 3.25rem;
  text-align: center;
  padding: 0.1875rem 0.625rem;
  background: #3ab54b;
  color: #fff;
  font-size: 0.625rem;
}

.ribbon__one::after {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 0.625rem solid #239132;
  border-left: 0.6875rem solid transparent;
  left: 0;
  content: "";
  bottom: -0.625rem;
}

.ribbon__two {
  width: 3.125rem;
  height: 3.125rem;
  display: inline-block;
  background: #ffab2d;
  line-height: 3.125rem;
  text-align: center;
  font-size: 1rem;
  color: #fff;
  right: 0.9375rem;
  top: 0.9375rem;
  border-radius: 0.1875rem;
}

.ribbon__three {
  left: -1.875rem;
  top: 0.875rem;
  width: 6.875rem;
  height: 1.5625rem;
  background-color: #fd5353;
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
  transform: rotate(-45deg);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
}

.ribbon__four {
  left: 0.5rem;
  top: -0.5rem;
  width: 6.875rem;
  height: 3.125rem;
  background-color: var(--primary);
  z-index: auto;
  color: #fff;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ribbon__four:after {
  right: -0.3125rem;
}

.ribbon__four:before {
  left: -0.3125rem;
}

.ribbon__four:after,
.ribbon__four:before {
  z-index: -1;
  background-color: var(--rgba-primary-1);
  top: 0.1875rem;
  transform: rotate(45deg);
  content: "";
  height: 0.625rem;
  width: 0.625rem;
  position: absolute;
}

.ribbon__five {
  left: -1.875rem;
  top: 0.625rem;
  width: 6.875rem;
  height: 1.875rem;
  background-color: var(--primary);
  transform: rotate(-45deg);
  font-size: 0.75rem;
  color: #fff;
  padding-bottom: 0.3125rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}

.ribbon__five::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 3.125rem 3.125rem 3.125rem;
  border-color: transparent transparent var(--primary) transparent;
  left: 0.25rem;
  top: -2.8125rem;
}

.ribbon__six {
  left: 0;
  top: 1.125rem;
  width: 6.875rem;
  height: 2.375rem;
  background-color: var(--primary);
  -webkit-clip-path: polygon(0 0,
      100% 0%,
      100% 0,
      85% 50%,
      100% 100%,
      100% 100%,
      0 100%);
  clip-path: polygon(0 0,
      100% 0%,
      100% 0,
      85% 50%,
      100% 100%,
      100% 100%,
      0 100%);
  display: flex;
  font-size: 1.25rem;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.ribbon-curve {
  position: absolute;
  top: 0;
  right: -0.375rem;
  width: 0.625rem;
  height: 0.6875rem;
  border-top: 0.6875rem solid #3ab54b;
  border-bottom: 0.625rem solid #3ab54b;
  border-right: 0.3125rem solid transparent;
}

.jsgrid-grid-body::-webkit-scrollbar,
.dataTables_scrollBody::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar {
  background-color: #f5f5f5;
  width: 0.5rem;
  height: 0.5rem;
}

.jsgrid-grid-body::-webkit-scrollbar-track,
.dataTables_scrollBody::-webkit-scrollbar-track,
.table-responsive::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  border-radius: 0.625rem;
  background-color: #f5f5f5;
}

.jsgrid-grid-body::-webkit-scrollbar-thumb,
.dataTables_scrollBody::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 0.625rem;
  -webkit-box-shadow: inset 0 0 0.375rem rgba(0, 0, 0, 0.3);
  background-color: var(--primary);
}

.multi-steps>li.is-active:before,
.multi-steps>li.is-active~li:before {
  content: counter(stepNum);
  font-family: inherit;
  font-weight: 400;
}

.multi-steps>li.is-active:after,
.multi-steps>li.is-active~li:after {
  background-color: #e6e6e6;
}

.multi-steps {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.multi-steps>li {
  counter-increment: stepNum;
  text-align: center;
  display: table-cell;
  position: relative;
  color: var(--primary);
  font-size: 0.75rem;
}

.multi-steps>li:before {
  content: "\2713";
  display: block;
  margin: 0 auto 0.25rem;
  background-color: #fff;
  width: 1.5625rem;
  height: 1.5625rem;
  line-height: 1.375rem;
  text-align: center;
  font-weight: bold;
  position: relative;
  z-index: 1;
  width: 1.5625rem;
  height: 1.5625rem;
  line-height: 1.3125rem;
  border-width: 0.125rem;
  border-style: solid;
  border-color: var(--primary);
  border-radius: 0.3125rem;
}

.multi-steps>li:after {
  content: "";
  height: 0.125rem;
  width: 100%;
  background-color: var(--primary);
  position: absolute;
  top: 0.75rem;
  left: 50%;
  top: 0.75rem;
}

[direction="rtl"] .multi-steps>li:after {
  left: auto;
  right: 50%;
}

.multi-steps>li:last-child:after {
  display: none;
}

.multi-steps>li.is-active:before {
  background-color: #fff;
  border-color: var(--primary);
}

.multi-steps>li.is-active~li {
  color: #717579;
}

.multi-steps>li.is-active~li:before {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
}

.nav-pills .nav-link {
  border-radius: 0.325rem;
  padding: 0.5rem 1rem;
  font-size: 14px;
  font-weight: 500;
  color: #5b5e81;
  text-transform: capitalize;
}

[data-theme-version="dark"] .nav-pills .nav-link {
  color: #7e8dbf;
}

.nav-pills .nav-link:hover {
  color: var(--primary);
}

@media only screen and (max-width: 35.9375rem) {
  .nav-pills .nav-link {
    padding: 0.5rem 0.9rem;
    margin-top: 0.5rem;
  }
}

.default-tab .nav-link {
  background: transparent;
  border-radius: 0rem;
  font-weight: 500;
}

.default-tab .nav-link i {
  display: inline-block;
  transform: scale(1.5);
  color: #5b5e81;
}

.default-tab .nav-link:focus,
.default-tab .nav-link:hover,
.default-tab .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff #ebeef6;
  border-radius: 0.5rem 0.5rem 0 0;
  color: var(--primary);
}

[data-theme-version="dark"] .default-tab .nav-link:focus,
[data-theme-version="dark"] .default-tab .nav-link:hover,
[data-theme-version="dark"] .default-tab .nav-link.active {
  background-color: var(--rgba-primary-1);
  border-color: transparent transparent #2a3969 transparent;
}

.custom-tab-1 .nav-link {
  background: transparent;
  border-radius: 0rem;
  font-weight: 500;
  border-bottom: 0.1875rem solid transparent;
  border-width: 0 0rem 0.1875rem 0;
}

.custom-tab-1 .nav-link i {
  display: inline-block;
  transform: scale(1.5);
  color: var(--primary);
}

.custom-tab-1 .nav-link:focus,
.custom-tab-1 .nav-link:hover,
.custom-tab-1 .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: var(--primary);
  border-radius: 0;
  color: var(--primary);
  border-width: 0 0rem 0.1875rem 0;
}

[data-theme-version="dark"] .custom-tab-1 .nav-link:focus,
[data-theme-version="dark"] .custom-tab-1 .nav-link:hover,
[data-theme-version="dark"] .custom-tab-1 .nav-link.active {
  background-color: var(--rgba-primary-1);
}

.nav-pills.light .nav-link.active,
.nav-pills.light .show>.nav-link {
  background: var(--rgba-primary-1);
  color: var(--primary);
  box-shadow: none;
}

[data-theme-version="dark"] .nav-pills.light .nav-link.active,
[data-theme-version="dark"] .nav-pills.light .show>.nav-link {
  background: var(--rgba-primary-1);
}

.nav-pills .nav-link.active,
.nav-pills:hover .show>.nav-link {
  background-color: var(--rgba-primary-1);
  color: var(--primary);
}

.icon-tab .nav-tabs .nav-link.active {
  background-color: var(--body-bg);
  border-color: var(--border);
}

.icon-tab .nav-tabs .nav-link.active i {
  color: var(--text-dark);
}

.icon-tab .nav-tabs .nav-link:hover {
  border-color: var(--border);
}

.tooltip-wrapper button:not(:last-child) {
  margin-right: 0.5rem;
}

.tooltip-wrapper button:hover {
  background: var(--primary);
  color: #fff;
}

.tooltip-wrapper button {
  margin-bottom: 0.5rem;
}

.tooltip-wrapper button:focus {
  box-shadow: none;
}

.tooltip-inner {
  border-radius: 0;
  background: #333333;
  font-size: 0.75rem;
  font-weight: 300;
  padding: 0.35rem 0.7rem;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #333333;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #333333;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #333333;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #333333;
}

.tooltip-inner {
  background-color: var(--rgba-primary-1);
  box-shadow: rgba(149, 157, 165, 0.2) 0rem 0.5rem 1.5rem;
  color: var(--primary);
  font-weight: 400;
  width: auto;
  border-radius: 0.5rem;
}

.tooltip-inner:before {
  background-color: #fff !important;
}

.tooltip .arrow {
  border-top-color: #008ec3 !important;
  background-color: #fff !important;
}

.tooltip.bs-tooltip-top .tooltip-arrow::before,
.tooltip.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  border-top-color: var(--primary);
  /* Set arrow color to red */
  box-shadow: rgba(149, 157, 165, 0.2) 0rem 0.5rem 1.5rem;
}

.tooltip.bs-tooltip-bottom .tooltip-arrow::before,
.tooltip.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  border-bottom-color: var(--primary);
  /* Set arrow color to red */
  box-shadow: rgba(149, 157, 165, 0.2) 0rem 0.5rem 1.5rem;
}

.tooltip.bs-tooltip-start .tooltip-arrow::before,
.tooltip.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  border-left-color: var(--primary);
  /* Set arrow color to red */
  box-shadow: rgba(149, 157, 165, 0.2) 0rem 0.5rem 1.5rem;
}

.tooltip.bs-tooltip-end .tooltip-arrow::before,
.tooltip.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  border-right-color: var(--primary);
  /* Set arrow color to red */
  box-shadow: rgba(149, 157, 165, 0.2) 0rem 0.5rem 1.5rem;
}

.widget-timeline .timeline {
  list-style: none;
  position: relative;
}

.widget-timeline .timeline:before {
  top: 1.25rem;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 0.1875rem;
  left: 0.5625rem;
  margin-right: -0.0938rem;
  background: var(--body-bg);
}

.widget-timeline .timeline>li {
  margin-bottom: 0.9375rem;
  position: relative;
}

.widget-timeline .timeline>li:before {
  content: " ";
  display: table;
}

.widget-timeline .timeline>li:after {
  content: " ";
  display: table;
  clear: both;
}

.widget-timeline .timeline>li>.timeline-panel {
  border-radius: 0.5rem;
  padding: 0.9375rem 1.25rem;
  position: relative;
  display: block;
  margin-left: 2.5rem;
  border-width: 0.0625rem;
  border-style: solid;
}

.widget-timeline .timeline>li>.timeline-panel span {
  font-size: 0.75rem;
  display: block;
  margin-bottom: 0.3125rem;
  opacity: 0.8;
  letter-spacing: 0.0625rem;
}

.widget-timeline .timeline>li>.timeline-panel p {
  font-size: 0.875rem;
}

.widget-timeline .timeline>li>.timeline-panel:after {
  content: "";
  width: 0.625rem;
  height: 0.625rem;
  background: inherit;
  border-color: inherit;
  border-style: solid;
  border-width: 0rem 0rem 0.0625rem 0.0625rem;
  display: block;
  position: absolute;
  left: -0.3125rem;
  transform: rotate(45deg);
  top: 0.9375rem;
}

.widget-timeline .timeline>li>.timeline-badge {
  border-radius: 50%;
  height: 1.375rem;
  left: 0;
  position: absolute;
  top: 0.625rem;
  width: 1.375rem;
  border-width: 0.125rem;
  border-style: solid;
  background: var(--card);
  padding: 0.25rem;
}

.widget-timeline .timeline>li>.timeline-badge:after {
  content: "";
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 100%;
  display: block;
}

.widget-timeline .timeline-body>p {
  font-size: 0.75rem;
}

.widget-timeline .timeline-badge.primary {
  border-color: var(--rgba-primary-1);
}

.widget-timeline .timeline-badge.primary:after {
  background-color: var(--primary);
  box-shadow: 0 0.3125rem 0.625rem 0 var(--rgba-primary-2);
}

.widget-timeline .timeline-badge.primary+.timeline-panel {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
}

.widget-timeline .timeline-badge.success {
  border-color: rgba(58, 182, 122, 0.2);
}

.widget-timeline .timeline-badge.success:after {
  background-color: #3ab67a !important;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(58, 182, 122, 0.2);
}

.widget-timeline .timeline-badge.success+.timeline-panel {
  background: rgba(58, 182, 122, 0.2);
  border-color: rgba(58, 182, 122, 0.2);
}

.widget-timeline .timeline-badge.warning {
  border-color: rgba(255, 171, 45, 0.2);
}

.widget-timeline .timeline-badge.warning:after {
  background-color: #ffab2d !important;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(255, 171, 45, 0.2);
}

.widget-timeline .timeline-badge.warning+.timeline-panel {
  background: rgba(255, 171, 45, 0.2);
  border-color: rgba(255, 171, 45, 0.2);
}

.widget-timeline .timeline-badge.danger {
  border-color: rgba(253, 83, 83, 0.2);
}

.widget-timeline .timeline-badge.danger:after {
  background-color: #fd5353 !important;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(253, 83, 83, 0.2);
}

.widget-timeline .timeline-badge.danger+.timeline-panel {
  background: rgba(253, 83, 83, 0.2);
  border-color: rgba(253, 83, 83, 0.2);
}

.widget-timeline .timeline-badge.info {
  border-color: rgba(0, 173, 163, 0.2);
}

.widget-timeline .timeline-badge.info:after {
  background-color: #00ada3 !important;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(0, 173, 163, 0.2);
}

.widget-timeline .timeline-badge.info+.timeline-panel {
  background: rgba(0, 173, 163, 0.2);
  border-color: rgba(0, 173, 163, 0.2);
}

.widget-timeline .timeline-badge.dark {
  border-color: rgba(91, 94, 129, 0.2);
}

.widget-timeline .timeline-badge.dark:after {
  background-color: #5b5e81 !important;
  box-shadow: 0 0.3125rem 0.625rem 0 rgba(91, 94, 129, 0.2);
}

.widget-timeline .timeline-badge.dark+.timeline-panel {
  background: rgba(91, 94, 129, 0.2);
  border-color: rgba(91, 94, 129, 0.2);
}

.widget-timeline.style-1 .timeline-panel {
  background: transparent;
}

.widget-timeline.style-1 .timeline .timeline-badge.timeline-badge+.timeline-panel {
  background: transparent !important;
  border: 0;
  border-left: 0.3125rem solid;
  border-radius: 0;
  padding: 0.3125rem 0.625rem 0.3125rem 0.9375rem;
}

.widget-timeline.style-1 .timeline .timeline-badge.timeline-badge+.timeline-panel:after {
  border: 0;
  left: -0.5625rem;
  width: 0.4375rem;
  height: 0.4375rem;
}

.widget-timeline.style-1 .timeline .timeline-badge.primary+.timeline-panel {
  border-color: var(--primary);
}

.widget-timeline.style-1 .timeline .timeline-badge.primary+.timeline-panel:after {
  background: var(--primary);
}

.widget-timeline.style-1 .timeline .timeline-badge.success+.timeline-panel {
  border-color: #3ab67a;
}

.widget-timeline.style-1 .timeline .timeline-badge.success+.timeline-panel:after {
  background: #3ab67a;
}

.widget-timeline.style-1 .timeline .timeline-badge.warning+.timeline-panel {
  border-color: #ffab2d;
}

.widget-timeline.style-1 .timeline .timeline-badge.warning+.timeline-panel:after {
  background: #ffab2d;
}

.widget-timeline.style-1 .timeline .timeline-badge.danger+.timeline-panel {
  border-color: #fd5353;
}

.widget-timeline.style-1 .timeline .timeline-badge.danger+.timeline-panel:after {
  background: #fd5353;
}

.widget-timeline.style-1 .timeline .timeline-badge.info+.timeline-panel {
  border-color: #00ada3;
}

.widget-timeline.style-1 .timeline .timeline-badge.info+.timeline-panel:after {
  background: #00ada3;
}

.widget-timeline.style-1 .timeline .timeline-badge.dark+.timeline-panel {
  border-color: #5b5e81;
}

.widget-timeline.style-1 .timeline .timeline-badge.dark+.timeline-panel:after {
  background: #5b5e81;
}

#chart_widget_4 {
  height: 15.9375rem !important;
}

#chart_widget_5 .ct-series-a .ct-line,
#chart_widget_5 .ct-series-a .ct-point {
  stroke: #46ffc8;
}

#chart_widget_5 .ct-line {
  stroke-width: 0.0625rem;
}

#chart_widget_5 .ct-point {
  stroke-width: 0.125rem;
}

#chart_widget_5 .ct-series-a .ct-area {
  fill: #20dea6;
}

#chart_widget_5 .ct-area {
  fill-opacity: 1;
}

#chart_widget_6 .ct-series-a .ct-line,
#chart_widget_6 .ct-series-a .ct-point {
  stroke: #00ada3;
}

#chart_widget_6 .ct-line {
  stroke-width: 0.125rem;
}

#chart_widget_6 .ct-point {
  stroke-width: 0.3125rem;
}

#chart_widget_6 .ct-series-a .ct-area {
  fill: #00ada3;
}

#chart_widget_6 .ct-area {
  fill-opacity: 0.5;
}

#chart_widget_8 {
  height: 15.9375rem;
}

#chart_widget_8 .ct-series-a .ct-line,
#chart_widget_8 .ct-series-a .ct-point {
  stroke: #00ada3;
}

#chart_widget_8 .ct-line {
  stroke-width: 0.125rem;
}

#chart_widget_8 .ct-point {
  stroke-width: 0.3125rem;
}

#chart_widget_8 .ct-series-a .ct-area {
  fill: #00ada3;
}

#chart_widget_8 .ct-area {
  fill-opacity: 0.5;
}

#chart_widget_9,
#chart_widget_10 {
  height: 15.625rem !important;
  margin-bottom: -6px;
  margin-left: -4px;
  width: 49.125rem !important;
}

@media only screen and (max-width: 74.9375rem) {

  #chart_widget_9,
  #chart_widget_10 {
    width: 53.225rem !important;
  }
}

#chart_widget_11 .ct-slice-donut,
#chart_widget_12 .ct-slice-donut,
#chart_widget_13 .ct-slice-donut {
  stroke-width: 1.5625rem !important;
}

#chart_widget_11 {
  height: 19.875rem !important;
  margin-bottom: -6px;
}

#chart_widget_17 {
  height: 9.375rem !important;
}

.chart_widget_tab_one .nav-link {
  border: 0.0625rem solid #ddd;
}

.chart_widget_tab_one .nav-link.active {
  background-color: var(--primary);
  border: 0.0625rem solid var(--primary);
  color: #fff;
}

.chart_widget_tab_one .nav-link.active:hover {
  border: 0.0625rem solid var(--primary);
}

.chart_widget_tab_one .nav-link:hover {
  border: 0.0625rem solid #ddd;
}

[data-theme-version="dark"] .ccc-widget>div {
  background: #18254f !important;
  border-color: #2a3969 !important;
}

.social-icon {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 0.25rem;
  text-align: center;
  background: var(--body-bg);
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
}

.social-icon i {
  color: #fff;
}

.social-icon.youtube {
  background: #ff0000;
}

.social-icon.facebook {
  background: #3b5998;
}

.social-icon.twitter {
  background: #1da1f2;
}

.social-graph-wrapper {
  text-align: center;
  padding: 1.25rem;
  position: relative;
  color: #fff;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.social-graph-wrapper.widget-facebook {
  background-color: #3b5998;
}

.social-graph-wrapper.widget-twitter {
  background-color: #1da1f2;
}

.social-graph-wrapper.widget-linkedin {
  background-color: #007bb6;
}

.social-graph-wrapper.widget-googleplus {
  background-color: #db4439;
}

.social-graph-wrapper .s-icon {
  font-size: 1.5rem;
  position: relative;
  padding: 0 0.625rem;
}

.social-icon2 a {
  border: 0.1rem solid var(--primary);
  display: block;
  padding: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.3rem;
  font-size: 1.6rem;
}

.social-icon2 i {
  font-size: 0.75rem;
  color: var(--primary);
}

.social-icon3 ul li {
  display: inline-block;
}

.social-icon3 ul li a {
  display: block;
}

.social-icon3 ul li a i {
  font-size: 1.4rem;
  padding: 1rem 0.7rem;
  color: #464a53;
}

.social-icon3 ul li a:hover i {
  color: var(--primary);
}

.social-icons-muted ul li a i {
  color: #89879f;
}

.social-links a {
  padding: 0.5rem;
}

.widget-stat .media {
  padding: 0px 0;
  align-items: center;
}

.widget-stat .media>span {
  height: 85px;
  width: 85px;
  border-radius: 50px;
  padding: 10px 12px;
  font-size: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #464a53;
}

@media only screen and (max-width: 87.5rem) {
  .widget-stat .media>span {
    height: 70px;
    width: 70px;
    min-width: 70px;
  }
}

.widget-stat .media .media-body p {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.5;
}

@media only screen and (max-width: 87.5rem) {
  .widget-stat .media .media-body p {
    font-size: 14px;
  }
}

.widget-stat .media .media-body small,
.widget-stat .media .media-body .small,
.widget-stat .media .media-body .small {
  font-size: 75%;
}

.widget-stat .media .media-body h3,
.widget-stat .media .media-body .h3 {
  font-size: 30px;
  font-weight: 600;
  margin: 0;
  line-height: 1.2;
}

.widget-stat .media .media-body h4,
.widget-stat .media .media-body .h4 {
  font-size: 24px;
  display: inline-block;
  vertical-align: middle;
}

.widget-stat .media .media-body span {
  margin-left: 5px;
}

.widget-stat[class*="bg-"] .media>span {
  background-color: rgba(255, 255, 255, 0.25);
  color: #fff;
}

.widget-stat[class*="bg-"] .progress {
  background-color: rgba(255, 255, 255, 0.25) !important;
}

.dez-widget-1 .card {
  background: #ffe7db;
}

.dez-widget-1 .card .card-body p {
  color: #f87533;
}

.dez-widget-1 .icon {
  float: right;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 28px;
}

.bgl-primary {
  background: var(--rgba-primary-1) !important;
  border-color: var(--rgba-primary-1) !important;
}

.bgl-secondary {
  background: rgba(54, 147, 255, 0.2) !important;
  border-color: rgba(54, 147, 255, 0.2) !important;
}

.bgl-success {
  background: rgba(58, 182, 122, 0.2) !important;
  border-color: rgba(58, 182, 122, 0.2) !important;
}

.bgl-warning {
  background: rgba(255, 171, 45, 0.2);
  border-color: rgba(255, 171, 45, 0.2);
}

.bgl-danger {
  background: rgba(253, 83, 83, 0.2);
  border-color: rgba(253, 83, 83, 0.2);
}

.bgl-info {
  background: rgba(0, 173, 163, 0.2);
  border-color: rgba(0, 173, 163, 0.2);
}

.bg-primary-light {
  background: rgba(255, 255, 255, 0.5);
}

.bg-secondary-light {
  background: rgba(207, 229, 255, 0.5);
}

.bg-success-light {
  background: rgba(224, 245, 235, 0.5);
}

.bg-warning-light {
  background: rgba(255, 242, 224, 0.5);
}

.bg-danger-light {
  background: rgba(255, 234, 234, 0.5);
}

.bg-info-light {
  background: rgba(148, 255, 249, 0.5);
}

.bgl-dark {
  background: #eaebf1;
  border-color: #eaebf1;
}

.bgl-light {
  background: #dedeed;
  border-color: #dedeed;
}

.overlay-box {
  position: relative;
  z-index: 1;
}

.overlay-box:after {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  opacity: 0.85;
  background: #3736af;
  z-index: -1;
}

.rating-bar {
  font-size: 13px;
}

.tdl-holder {
  margin: 0 auto;
}

.tdl-holder ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tdl-holder li {
  background-color: var(--card);
  border-bottom: 0.0625rem solid var(--border);
  list-style: none none;
  margin: 0;
  padding: 0;
}

.tdl-holder li span {
  margin-left: 2.1875rem;
  font-size: 1rem;
  vertical-align: middle;
  transition: all 0.2s linear;
}

.tdl-holder label {
  cursor: pointer;
  display: block;
  line-height: 3.125rem;
  padding-left: 1.5rem;
  position: relative;
  margin: 0 !important;
}

.tdl-holder label:hover {
  background-color: #eef1f6;
  color: #5b5e81;
}

.tdl-holder label:hover a {
  color: #fd5353;
}

.tdl-holder label a {
  color: #fff;
  display: inline-block;
  line-height: normal;
  height: 100%;
  text-align: center;
  text-decoration: none;
  width: 3.125rem;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  padding: 1.125rem 0rem;
  font-size: 1.125rem;
  position: absolute;
  right: 0;
}

.tdl-holder input[type="checkbox"] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
}

.tdl-holder input[type="checkbox"]+i {
  background-color: #fff;
  border: 0.0625rem solid #e7e7e7;
  display: block;
  height: 1.25rem;
  position: absolute;
  top: 0.9375rem;
  width: 1.25rem;
  z-index: 1;
  border-radius: 0rem;
  font-size: 0.6875rem;
  border-radius: 0.25rem;
}

.tdl-holder input[type="checkbox"]:checked+i {
  background: var(--primary);
  border-color: transparent;
}

.tdl-holder input[type="checkbox"]:checked+i::after {
  content: "\f00c";
  font-family: "fontAwesome";
  display: block;
  left: 0.125rem;
  position: absolute;
  top: -0.875rem;
  z-index: 2;
  color: #fff;
}

.tdl-holder input[type="checkbox"]:checked~span {
  text-decoration: line-through;
  position: relative;
}

.tdl-holder input[type="text"] {
  background-color: #fff;
  height: 3.125rem;
  margin-top: 1.8125rem;
  border-radius: 0.1875rem;
}

.tdl-holder input[type="text"]::placeholder {
  color: #5b5e81;
}

.widget-message p {
  font-size: 0.875rem;
  line-height: 1.5;
}

.picker {
  color: var(--text-dark);
}

.picker .picker__frame {
  min-width: 28.125rem;
  max-width: 28.125rem;
  height: 100%;
  top: 0;
  max-height: 100%;
  align-items: center;
  display: flex;
}

.picker .picker__frame .picker__wrap {
  height: auto;
}

.picker .picker__frame .picker__box {
  padding: 1.25rem;
  border: 0;
  background-color: var(--card);
  box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.1);
}

.picker .picker__frame .picker__box .picker__header {
  margin-top: 0;
}

.picker .picker__frame .picker__box .picker__header .picker__year {
  color: var(--primary);
  font-style: inherit;
  font-size: 1.25rem;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev,
.picker .picker__frame .picker__box .picker__header .picker__nav--next {
  width: 2.5rem;
  height: 2.5rem;
  padding: 0;
  line-height: 2.5rem;
  border-radius: 0.125rem;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:before,
.picker .picker__frame .picker__box .picker__header .picker__nav--next:before {
  content: none !important;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:after,
.picker .picker__frame .picker__box .picker__header .picker__nav--next:after {
  font-family: "Font Awesome 5 Free";
  border: 0;
  margin: 0 auto;
  font-size: 1.375rem;
  font-weight: 700;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:hover,
.picker .picker__frame .picker__box .picker__header .picker__nav--next:hover {
  background-color: var(--primary);
  color: #fff;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev {
  transition: 0.4s;
  left: 0;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--prev:after {
  content: "\f0d9";
}

.picker .picker__frame .picker__box .picker__header .picker__nav--next {
  transition: 0.4s;
  right: 0;
}

.picker .picker__frame .picker__box .picker__header .picker__nav--next:after {
  content: "\f0da";
}

.picker .picker__frame .picker__box .picker__table .picker__weekday {
  padding: 0.625rem 0;
  font-size: 1rem;
}

.picker .picker__frame .picker__box .picker__table .picker__day {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 3.125rem;
  padding: 0 !important;
  font-size: 1rem;
  line-height: 2.5rem;
  margin: auto;
  border: 0 !important;
}

.picker .picker__frame .picker__box .picker__table .picker__day.picker__day--today:before {
  content: none !important;
}

.picker .picker__frame .picker__box .picker__table .picker__day.picker__day--highlighted {
  border: 0 !important;
  padding: 0;
  background-color: var(--primary);
  color: #fff !important;
}

.picker .picker__frame .picker__box .picker__table .picker__day:hover {
  background-color: var(--primary);
  color: #fff !important;
}

.picker .picker__frame .picker__box .picker__footer {
  display: flex;
  gap: 5px;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--today {
  background-color: var(--primary);
  color: #fff !important;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--today:before {
  content: none !important;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--clear,
.picker .picker__frame .picker__box .picker__footer .picker__button--close {
  background-color: var(--card);
}

.picker .picker__frame .picker__box .picker__footer .picker__button--clear,
.picker .picker__frame .picker__box .picker__footer .picker__button--close,
.picker .picker__frame .picker__box .picker__footer .picker__button--today {
  border: 0;
  border-radius: 0.125rem;
  font-size: 1rem;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--clear:hover,
.picker .picker__frame .picker__box .picker__footer .picker__button--close:hover,
.picker .picker__frame .picker__box .picker__footer .picker__button--today:hover {
  background-color: var(--primary);
  color: #fff !important;
}

.picker .picker__frame .picker__box .picker__footer .picker__button--clear:hover::before,
.picker .picker__frame .picker__box .picker__footer .picker__button--close:hover::before,
.picker .picker__frame .picker__box .picker__footer .picker__button--today:hover::before {
  border-color: #fff;
  color: #fff;
}

@media only screen and (max-width: 35.9375rem) {
  .picker .picker__frame {
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .picker .picker__frame .picker__box {
    padding: 0.9375rem;
    margin: 0 0.625rem;
  }
}

.picker__button--clear:before {
  margin: 0;
  margin-right: 0.45em;
}

.picker__button--close:before {
  margin: 0;
  margin-right: 0.35em;
}

.card-list {
  overflow: unset;
  height: calc(100% - 50px);
}

.card-list .card-header {
  padding-top: 0;
  padding-bottom: 0;
}

.card-list .card-header .photo {
  overflow: hidden;
  border-radius: 0.3125rem;
  width: 100%;
}

@media only screen and (max-width: 74.9375rem) {
  .card-list {
    height: calc(100% - 40px);
  }
}

.card-list.list-left {
  margin-top: 0.9375rem;
  margin-left: 0.9375rem;
}

.card-list.list-left .card-header {
  padding-left: 0;
}

.card-list.list-left .card-header .photo {
  margin: -0.9375rem 0.9375rem 0 -0.9375rem;
}

.card-list.list-right {
  margin-top: 0.9375rem;
  margin-right: 0.9375rem;
  text-align: right;
}

.card-list.list-right .card-header {
  padding-right: 0;
}

.card-list.list-right .card-header .photo {
  margin: -0.9375rem -0.9375rem 0 0.9375rem;
}

.card-list.list-right .card-header .photo img {
  width: 100%;
}

.card-list.list-center {
  margin-top: 0.9375rem;
}

.card-list.list-center .card-header .photo {
  margin: -0.9375rem 0 0 0;
}

.card-list .photo img {
  width: 100%;
}

.card-profile .profile-photo {
  margin-top: -1.25rem;
}

.widget-media .timeline .timeline-panel {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border);
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.widget-media .timeline .timeline-panel .media {
  width: 50px;
  height: 50px;
  background: #eee;
  border-radius: 12px;
  overflow: hidden;
  font-size: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  align-self: start;
}

.widget-media .timeline .timeline-panel .media-primary {
  background: var(--rgba-primary-1);
  color: #3736af;
}

.widget-media .timeline .timeline-panel .media-info {
  background: rgba(0, 173, 163, 0.2);
  color: #00ada3;
}

.widget-media .timeline .timeline-panel .media-warning {
  background: rgba(255, 171, 45, 0.2);
  color: #ffab2d;
}

.widget-media .timeline .timeline-panel .media-danger {
  background: rgba(253, 83, 83, 0.2);
  color: #fd5353;
}

.widget-media .timeline .timeline-panel .media-success {
  background: rgba(58, 182, 122, 0.2);
  color: #3ab67a;
}

.widget-media .timeline .timeline-panel .media-body p {
  font-size: 14px;
  line-height: 1.5;
}

.widget-media .timeline .timeline-panel .dropdown {
  align-self: self-end;
  margin-top: 5px;
}

.widget-media .timeline li:last-child .timeline-panel {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.card[class*="bg-"] .timeline .timeline-panel {
  border-color: rgba(255, 255, 255, 0.2) !important;
}

.table {
  --bs-table-bg: var(--card);
}

.table strong {
  color: var(--text-dark);
}

.table th,
.table td {
  border-color: var(--border);
  padding: 0.9375rem 0.625rem;
}

.table.table-striped tbody tr:nth-of-type(odd),
.table.table-hover tr:hover {
  background-color: var(--body-bg);
}

.table.shadow-hover tbody tr:hover {
  background-color: var(--card);
  box-shadow: 0 0rem 1.875rem var(--rgba-primary-2);
}

.table.shadow-hover tbody tr td {
  background-color: transparent;
}

.table.tr-rounded tr td:first-child,
.table.tr-rounded tr th:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.table.tr-rounded tr td:last-child,
.table.tr-rounded tr th:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.table.order-tbl tr th {
  font-size: 14px;
  font-weight: 600;
  border: 0;
}

.table.order-tbl tr td {
  font-weight: 500;
  border: 0;
}

.table.border-hover tr td {
  border-width: 0.0625rem 0 0.0625rem 0;
  border-bottom: 0.0625rem solid transparent;
  border-color: transparent;
}

.table.border-hover tr td:first-child {
  border-width: 0.0625rem 0 0.0625rem 0.0625rem;
}

.table.border-hover tr td:last-child {
  border-width: 0.0625rem 0.0625rem 0.0625rem 0;
}

.table.border-hover tr:hover td {
  border-color: var(--border);
}

.table.bg-secondary-hover td {
  color: #000;
  padding-left: 0;
}

.table.bg-secondary-hover th {
  color: #3693ff !important;
}

.table.bg-secondary-hover tr:hover td,
.table.bg-secondary-hover tr:hover th {
  background: #3693ff;
  color: #fff;
  box-shadow: 10px 0 30px rgba(65, 63, 187, 0.3);
}

.table.bg-primary-hover td {
  color: var(--text-dark);
  padding-left: 0;
}

.table.bg-primary-hover th {
  color: var(--primary);
}

.table.bg-primary-hover tr:hover td,
.table.bg-primary-hover tr:hover th {
  background: var(--primary);
  color: #fff;
  box-shadow: 10px 0 30px rgba(54, 147, 255, 0.3);
}

.table.bg-eth-hover td {
  color: #000;
  padding-left: 0;
}

.table.bg-eth-hover th {
  color: #00ada3;
}

.table.bg-eth-hover tr:hover td,
.table.bg-eth-hover tr:hover th {
  background: #00ada3;
  color: #fff;
  box-shadow: 10px 0 30px rgba(0, 173, 163, 0.3);
}

.table.bg-ripple-hover td {
  color: #000;
  padding-left: 0;
}

.table.bg-ripple-hover th {
  color: #23292f;
}

.table.bg-ripple-hover tr:hover td,
.table.bg-ripple-hover tr:hover th {
  background: #23292f;
  color: #fff;
  box-shadow: 10px 0 30px rgba(35, 41, 47, 0.3);
}

.table.bg-pink-hover td {
  color: var(--text-dark);
  padding-left: 0;
}

.table.bg-pink-hover th {
  color: var(--primary);
}

.table.bg-pink-hover tr:hover td,
.table.bg-pink-hover tr:hover th {
  background: var(--primary);
  color: #fff;
  box-shadow: 10px 0 30px rgba(172, 76, 188, 0.3);
}

.table.bg-secondary-hover td {
  color: #5b5e81;
}

.table.bg-secondary-hover th {
  color: #6c6c6c;
}

.table.bg-secondary-hover tr:hover td,
.table.bg-secondary-hover tr:hover th {
  background: var(--secondary);
  color: #fff !important;
}

.table.bg-info-hover td {
  color: #000;
}

.table.bg-info-hover th {
  color: #6c6c6c;
}

.table.bg-info-hover tr:hover td,
.table.bg-info-hover tr:hover th {
  background: #00ada3;
  color: #fff !important;
}

.table.bg-warning-hover td {
  color: var(--text);
}

.table.bg-warning-hover th {
  color: var(--text-dark);
}

.table.bg-warning-hover tr:hover td,
.table.bg-warning-hover tr:hover th {
  background: #ffab2d;
  color: #fff !important;
}

.table.border-no td {
  border: 0;
}

.table.short-one tr td:first-child,
.table.short-one tr th:first-child {
  width: 3.75rem;
}

.table thead th {
  border-bottom: 0.0625rem solid var(--border);
  text-transform: capitalize;
  font-size: 1rem;
  white-space: nowrap;
  font-weight: 500;
  color: var(--text-dark);
  border-color: var(--border) !important;
}

.table tbody tr td {
  vertical-align: middle;
  border-color: var(--border);
}

.table:not(.table-bordered) thead th {
  border-top: none;
}

.table .thead-primary th {
  background-color: var(--primary);
  color: #fff;
}

.table .thead-info th {
  background-color: #00ada3;
  color: #fff;
}

.table .thead-warning th {
  background-color: #ffab2d;
  color: #fff;
}

.table .thead-danger th {
  background-color: #fd5353;
  color: #fff;
}

.table .thead-success th {
  background-color: #3ab67a;
  color: #fff;
}

.table.primary-table-bordered {
  border: 0.0625rem solid var(--border);
}

.table.primary-table-bg-hover tbody {
  border-color: var(--primary-dark);
}

.table.primary-table-bg-hover thead th {
  background-color: var(--primary-dark);
  color: #fff;
  border-bottom: none;
  border-color: var(--primary-dark);
}

.table.primary-table-bg-hover tbody tr {
  background-color: var(--primary);
  color: #fff;
  transition: all 0.1s ease;
}

.table.primary-table-bg-hover tbody tr th,
.table.primary-table-bg-hover tbody tr td {
  border-color: var(--primary-dark) !important;
  background-color: transparent;
  color: #fff;
}

.table.primary-table-bg-hover tbody tr:hover {
  background-color: var(--rgba-primary-8);
}

.table.primary-table-bg-hover tbody tr:not(:last-child) td,
.table.primary-table-bg-hover tbody tr:not(:last-child) th {
  border-bottom: 0.0625rem solid var(--primary-dark);
  border-color: #fff;
}

.table-responsive-tiny {
  min-width: 18.75rem;
}

.table-responsive-sm {
  min-width: 30rem !important;
}

.table-responsive-md {
  min-width: 36rem !important;
}

.table-responsive-lg {
  min-width: 60.9375rem !important;
}

.table-responsive-xl {
  min-width: 70.9375rem !important;
}

.table> :not(caption)>*>* {
  color: inherit;
}

.table-primary,
.table-primary>th,
.table-primary>td {
  background-color: var(--rgba-primary-1);
  color: var(--primary);
}

.table-success,
.table-success>th,
.table-success>td {
  background-color: rgba(58, 182, 122, 0.2);
  color: #3ab67a;
}

.table-info,
.table-info>th,
.table-info>td {
  background-color: rgba(0, 173, 163, 0.2);
  color: #00ada3;
}

.table-warning,
.table-warning>th,
.table-warning>td {
  background-color: rgba(255, 171, 45, 0.2);
  color: #ffab2d;
}

.table-danger,
.table-danger>th,
.table-danger>td {
  background-color: rgba(253, 83, 83, 0.2);
  color: #fd5353;
}

.table-active,
.table-active>th,
.table-active>td {
  background-color: rgba(222, 222, 237, 0.2);
}

.table tbody tr td:last-child {
  text-align: right;
}

.table thead tr th:last-child {
  text-align: right !important;
}

.card-table th:first-child,
.card-table td:first-child {
  padding-left: 1.875rem;
}

@media only screen and (max-width: 35.9375rem) {

  .card-table th:first-child,
  .card-table td:first-child {
    padding-left: 0.9375rem;
  }
}

.card-table th:last-child,
.card-table td:last-child {
  padding-right: 1.875rem;
}

@media only screen and (max-width: 35.9375rem) {

  .card-table th:last-child,
  .card-table td:last-child {
    padding-right: 0.9375rem;
  }
}

.table> :not(:first-child) {
  border-top: 1px solid currentColor;
}

.bootgrid-header {
  padding: 0 !important;
  margin: 0;
}

@media only screen and (max-width: 35.9375rem) {
  .bootgrid-header .actionBar {
    padding: 0;
  }

  .bootgrid-header .search {
    margin: 0 0 0.625rem 0;
  }
}

table#example {
  padding: 0rem 0 2rem 0;
}

table.dataTable {
  font-size: 0.875rem;
  color: var(--text-gray);
}

table.dataTable .form-check {
  display: inline-block;
}

#example2_wrapper .dataTables_scrollBody {
  max-height: 33.25rem !important;
}

#employees,
#custommers {
  padding: 0.5rem 0 1rem 0;
}

.dataTables_wrapper .dataTables_paginate {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}

table.dataTable thead>tr>th.sorting::after,
table.dataTable thead>tr>th.sorting_desc::after,
table.dataTable thead>tr>th.sorting_asc::after {
  content: "";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  background-image: none;
  margin-left: 5px;
  font-size: calc(100% - 2px);
  opacity: 0.5;
}

table.dataTable thead>tr>th.sorting::after {
  content: "\f0dc";
}

table.dataTable thead>tr>th.sorting.sorting_desc::after {
  content: "\f0dd";
  opacity: 0.5;
}

table.dataTable thead>tr>th.sorting.sorting_asc::after {
  content: "\f0de";
  opacity: 0.5;
}

table.dataTable thead>tr>th.sorting::before {
  content: none;
}

table.dataTable thead th,
table.dataTable thead td {
  border-bottom: 1px solid var(--border);
  border-top: 0;
}

table.dataTable tfoot th,
table.dataTable tfoot td {
  border-top: 0;
}

table.dataTable tbody tr.selected>* {
  color: var(--text-dark);
  border-color: var(--border) !important;
}

table.dataTable tbody tr,
table.dataTable tbody td {
  background: transparent !important;
}

table.dataTable.hover>tbody>tr.selected:hover>*,
table.dataTable.display>tbody>tr.selected:hover>* {
  box-shadow: unset !important;
}

table.dataTable thead th {
  color: var(--text-dark);
  white-space: nowrap;
  font-size: 0.9375rem;
  text-transform: capitalize;
  font-weight: 500;
  padding: 1.25rem 0.9375rem;
}

table.dataTable>thead>tr>th {
  padding: 1rem 0.9375rem;
  text-align: left;
}

@media only screen and (max-width: 35.9375rem) {
  table.dataTable>thead>tr>th {
    padding: 1rem 0.5375rem;
  }
}

.dataTables_wrapper .dataTables_info {
  padding-top: 1rem;
}

table.dataTable tbody td {
  padding: 0.85rem 0.9375rem;
  font-size: 0.875rem;
  font-weight: 400;
  border-bottom: 0;
}

@media only screen and (max-width: 35.9375rem) {
  table.dataTable tbody td {
    padding: 0.5rem 0.3125rem;
  }
}

table.dataTable tr.selected {
  color: var(--primary);
}

table.dataTable tfoot th {
  color: var(--text-dark);
  font-weight: 500;
  font-size: 0.9375rem;
  text-transform: capitalize;
}

table.dataTable>tfoot>tr>th,
table.dataTable>tfoot>tr>td {
  padding: 1rem 0.9375rem;
  text-align: left;
}

td.text-ov {
  white-space: nowrap;
}

.dataTables_wrapper .dataTables_paginate {
  align-items: center;
  display: flex;
  flex-flow: wrap;
  justify-content: center;
}

@media only screen and (max-width: 35.9375rem) {
  .dataTables_wrapper .dataTables_paginate {
    flex-flow: nowrap;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous,
.dataTables_wrapper .dataTables_paginate .paginate_button.next {
  height: 2.5rem;
  width: 2.5rem;
  border: 0.0625rem solid var(--primary);
  border-radius: 2.8125rem;
  padding: 0 0 0.75rem;
  line-height: 2.5rem;
  margin: 0 0.625rem;
  display: inline-block;
  color: var(--primary) !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous.current:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.previous.previous:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.previous.next:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.next.current:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.next.previous:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.next.next:hover {
  color: #fff !important;
  background: var(--primary) !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.previous.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.next.disabled {
  color: var(--primary) !important;
}

.dataTables_wrapper .dataTables_paginate span .paginate_button {
  height: 2.5rem;
  width: 2.5rem;
  padding: 0;
  margin-left: 0rem;
  margin: 0 0.125rem;
  line-height: 2.5rem;
  text-align: center;
  border-radius: 2.8125rem;
  color: var(--primary) !important;
  background: var(--rgba-primary-1);
}

.dataTables_wrapper .dataTables_paginate span .paginate_button.current,
.dataTables_wrapper .dataTables_paginate span .paginate_button:hover {
  color: #fff !important;
  background: var(--primary);
  border: 1px solid transparent;
}

.dataTables_wrapper .dataTables_paginate span .paginate_button.current:hover,
.dataTables_wrapper .dataTables_paginate span .paginate_button:hover:hover {
  color: #fff !important;
  background: var(--primary) !important;
}

.dataTables_wrapper input[type="search"],
.dataTables_wrapper input[type="text"],
.dataTables_wrapper select {
  border: 1px solid var(--border);
  padding: 0.3rem 0.5rem;
  height: 2.5rem;
  color: var(--text-dark);
  border-radius: 0.5rem;
}

.dataTables_wrapper .dataTables_length {
  margin-bottom: 0.9375rem;
}

.dataTables_wrapper .dataTables_length .bootstrap-select {
  width: 5rem !important;
  margin: 0 0.3125rem;
}

table.dataTable.no-footer {
  border-bottom: 0;
}

.rounded-lg {
  min-width: 1.875rem;
}

.dataTables_scroll {
  padding: 1rem 0;
}

.dataTables_scrollFoot {
  padding-top: 1rem;
}

.dataTablesCard {
  background-color: var(--card);
  border-radius: 0.5rem;
  overflow: hidden;
}

.dataTablesCard.border-no td {
  border-top: 0 !important;
}

@media (max-width: 767.98px) {

  .dataTables_wrapper .dataTables_length,
  .dataTables_wrapper .dataTables_filter {
    text-align: left;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
  color: #717579 !important;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: #717579;
  border-radius: 1rem;
  padding: 0.125rem 0;
}

[data-theme-version="dark"] .dataTables_wrapper .dataTables_length,
[data-theme-version="dark"] .dataTables_wrapper .dataTables_filter,
[data-theme-version="dark"] .dataTables_wrapper .dataTables_processing,
[data-theme-version="dark"] .dataTables_wrapper .dataTables_paginate {
  color: #7e8dbf;
}

.paging_simple_numbers.dataTables_paginate {
  padding: 0.3125rem 0.3125rem;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  color: #717579 !important;
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
  border-color: #eeeeee;
  box-shadow: none !important;
}

[data-theme-version="dark"] table.dataTable.row-border tbody th,
[data-theme-version="dark"] table.dataTable.row-border tbody td,
[data-theme-version="dark"] table.dataTable.display tbody th,
[data-theme-version="dark"] table.dataTable.display tbody td {
  border-color: #2a3969;
  color: rgba(255, 255, 255, 0.7);
}

.dataTables_wrapper .dataTables_length .bootstrap-select .dropdown-toggle {
  font-size: 0.813rem !important;
  padding: 0.625rem 1rem;
  line-height: 2;
}

[data-theme-version="dark"] .dataTables_wrapper .dataTables_length {
  color: #7e8dbf;
}

@media only screen and (max-width: 35.9375rem) {
  .dataTables_paginate.paging_simple_numbers span {
    display: flex;
  }
}

.table.dataTable.display>tbody>tr>td {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

table.dataTable.row-border>tbody>tr>th,
table.dataTable.row-border>tbody>tr>td,
table.dataTable.display>tbody>tr>th,
table.dataTable.display>tbody>tr>td {
  border-color: var(--border);
}

table.dataTable thead>tr>th.sorting::before,
table.dataTable thead>tr>th.sorting::after,
table.dataTable thead>tr>th.sorting_asc::before,
table.dataTable thead>tr>th.sorting_asc::after,
table.dataTable thead>tr>th.sorting_desc::before,
table.dataTable thead>tr>th.sorting_desc::after,
table.dataTable thead>tr>th.sorting_asc_disabled::before,
table.dataTable thead>tr>th.sorting_asc_disabled::after,
table.dataTable thead>tr>th.sorting_desc_disabled::before,
table.dataTable thead>tr>th.sorting_desc_disabled::after,
table.dataTable thead>tr>td.sorting::before,
table.dataTable thead>tr>td.sorting::after,
table.dataTable thead>tr>td.sorting_asc::before,
table.dataTable thead>tr>td.sorting_asc::after,
table.dataTable thead>tr>td.sorting_desc::before,
table.dataTable thead>tr>td.sorting_desc::after,
table.dataTable thead>tr>td.sorting_asc_disabled::before,
table.dataTable thead>tr>td.sorting_asc_disabled::after,
table.dataTable thead>tr>td.sorting_desc_disabled::before,
table.dataTable thead>tr>td.sorting_desc_disabled::after {
  position: relative;
  display: inline-block;
  right: 0;
}

table.dataTable>thead>tr>th,
table.dataTable>thead>tr>td,
table.dataTable>tfoot>tr>th,
table.dataTable>tfoot>tr>td {
  border-color: var(--border);
}

.fooicon {
  font-size: 1.25rem;
  color: #5b5e81;
}

.jsgrid-table .jsgrid-header-row>.jsgrid-header-cell {
  color: #5b5e81;
}

.jsgrid-table>tbody>tr>td {
  padding: 1.2em;
}

.jsgrid-table .jsgrid-insert-row input,
.jsgrid-table .jsgrid-insert-row select,
.jsgrid-table .jsgrid-edit-row input,
.jsgrid-table .jsgrid-edit-row select {
  border: 0.0625rem solid #dddfe1;
}

.jsgrid .jsgrid-button {
  border: 0 !important;
  margin-left: 0.625rem;
}

.error-page {
  height: 100vh;
  padding: 50px;
  position: relative;
  background-blend-mode: luminosity;
  background-size: cover;
}

.error-page:before {
  content: "";
  position: absolute;
  opacity: 0.8;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.error-page .error-inner {
  z-index: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 600px;
  transform: translate(-50%, -50%);
  padding: 20px;
  width: 100%;
}

@media only screen and (max-width: 35.9375rem) {
  .error-page .error-inner {
    width: 100%;
  }
}

.error-page .error-head {
  margin-bottom: 40px;
  font-size: 30px;
  line-height: 1.4;
  font-weight: 500;
  color: var(--primary);
}

@media only screen and (max-width: 35.9375rem) {
  .error-page .error-head {
    font-size: 20px;
    margin-bottom: 30px;
  }
}

.error-page .dz-error {
  position: relative;
  color: var(--text-dark);
  font-size: 200px;
  letter-spacing: 0px;
  animation: dzError 1s infinite linear alternate-reverse;
  font-weight: 900;
  line-height: 200px;
  margin: auto;
}

@media only screen and (max-width: 61.9375rem) {
  .error-page .dz-error {
    font-size: 150px;
    line-height: 150px;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .error-page .dz-error {
    font-size: 80px;
    line-height: 80px;
    letter-spacing: 5px;
  }
}

.error-page .dz-error::before,
.error-page .dz-error::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.error-page .dz-error::before {
  left: 2px;
  text-shadow: -2px 0 #f7cf47;
  clip: rect(44px, 450px, 56px, 0);
  animation: dzError1 5s infinite linear alternate-reverse;
}

.error-page .dz-error::after {
  left: -2px;
  text-shadow: -2px 0 #f7cf47, 2px 2px #f7cf47;
  clip: rect(44px, 450px, 56px, 0);
  animation: dzError2 5s infinite linear alternate-reverse;
}

@keyframes dzError1 {
  0% {
    clip: rect(162px, 9999px, 60px, 0);
    transform: skew(0.432deg);
  }

  2.5% {
    clip: rect(171px, 9999px, 319px, 0);
    transform: skew(0.572deg);
  }

  5% {
    clip: rect(21px, 9999px, 33px, 0);
    transform: skew(0.87deg);
  }

  7.5% {
    clip: rect(304px, 9999px, 39px, 0);
    transform: skew(0.864deg);
  }

  10% {
    clip: rect(22px, 9999px, 268px, 0);
    transform: skew(0.46deg);
  }

  12.5% {
    clip: rect(186px, 9999px, 335px, 0);
    transform: skew(0.864deg);
  }

  15% {
    clip: rect(398px, 9999px, 430px, 0);
    transform: skew(0.378deg);
  }

  17.5% {
    clip: rect(460px, 9999px, 393px, 0);
    transform: skew(0.216deg);
  }

  20% {
    clip: rect(485px, 9999px, 381px, 0);
    transform: skew(0.118deg);
  }

  22.5% {
    clip: rect(485px, 9999px, 347px, 0);
    transform: skew(0.772deg);
  }

  25% {
    clip: rect(247px, 9999px, 371px, 0);
    transform: skew(0.648deg);
  }

  27.5% {
    clip: rect(414px, 9999px, 297px, 0);
    transform: skew(0.92deg);
  }

  30% {
    clip: rect(122px, 9999px, 453px, 0);
    transform: skew(0.012deg);
  }

  32.5% {
    clip: rect(332px, 9999px, 242px, 0);
    transform: skew(0.24deg);
  }

  35% {
    clip: rect(411px, 9999px, 164px, 0);
    transform: skew(0.12deg);
  }

  37.5% {
    clip: rect(22px, 9999px, 261px, 0);
    transform: skew(0.408deg);
  }

  40% {
    clip: rect(69px, 9999px, 255px, 0);
    transform: skew(0.246deg);
  }

  42.5% {
    clip: rect(181px, 9999px, 312px, 0);
    transform: skew(0.926deg);
  }

  45% {
    clip: rect(73px, 9999px, 432px, 0);
    transform: skew(0.742deg);
  }

  47.5% {
    clip: rect(267px, 9999px, 220px, 0);
    transform: skew(0.61deg);
  }

  50% {
    clip: rect(71px, 9999px, 27px, 0);
    transform: skew(0.488deg);
  }

  52.5% {
    clip: rect(148px, 9999px, 366px, 0);
    transform: skew(0.662deg);
  }

  55% {
    clip: rect(103px, 9999px, 243px, 0);
    transform: skew(0.958deg);
  }

  57.5% {
    clip: rect(73px, 9999px, 310px, 0);
    transform: skew(0.61deg);
  }

  60% {
    clip: rect(199px, 9999px, 203px, 0);
    transform: skew(0.498deg);
  }

  62.5% {
    clip: rect(394px, 9999px, 455px, 0);
    transform: skew(0.982deg);
  }

  65% {
    clip: rect(161px, 9999px, 52px, 0);
    transform: skew(0.522deg);
  }

  67.5% {
    clip: rect(342px, 9999px, 303px, 0);
    transform: skew(0.382deg);
  }

  70% {
    clip: rect(415px, 9999px, 273px, 0);
    transform: skew(0.092deg);
  }

  72.5% {
    clip: rect(369px, 9999px, 159px, 0);
    transform: skew(0.746deg);
  }

  75% {
    clip: rect(390px, 9999px, 32px, 0);
    transform: skew(0.662deg);
  }

  77.5% {
    clip: rect(70px, 9999px, 446px, 0);
    transform: skew(0.716deg);
  }

  80% {
    clip: rect(454px, 9999px, 406px, 0);
    transform: skew(0.74deg);
  }

  82.5% {
    clip: rect(405px, 9999px, 403px, 0);
    transform: skew(0.296deg);
  }

  85% {
    clip: rect(282px, 9999px, 153px, 0);
    transform: skew(0.238deg);
  }

  87.5% {
    clip: rect(118px, 9999px, 492px, 0);
    transform: skew(0.756deg);
  }

  90% {
    clip: rect(5px, 9999px, 346px, 0);
    transform: skew(0.71deg);
  }

  92.5% {
    clip: rect(448px, 9999px, 442px, 0);
    transform: skew(0.494deg);
  }

  95% {
    clip: rect(446px, 9999px, 163px, 0);
    transform: skew(0.168deg);
  }

  97.5% {
    clip: rect(300px, 9999px, 469px, 0);
    transform: skew(0.934deg);
  }
}

@keyframes dzError2 {
  0% {
    clip: rect(391px, 9999px, 454px, 0);
    transform: skew(0.82deg);
  }

  2.5% {
    clip: rect(207px, 9999px, 37px, 0);
    transform: skew(0.096deg);
  }

  5% {
    clip: rect(166px, 9999px, 69px, 0);
    transform: skew(0.112deg);
  }

  7.5% {
    clip: rect(296px, 9999px, 133px, 0);
    transform: skew(0.468deg);
  }

  10% {
    clip: rect(63px, 9999px, 33px, 0);
    transform: skew(0.296deg);
  }

  12.5% {
    clip: rect(242px, 9999px, 357px, 0);
    transform: skew(0.42deg);
  }

  15% {
    clip: rect(201px, 9999px, 319px, 0);
    transform: skew(0.898deg);
  }

  17.5% {
    clip: rect(109px, 9999px, 25px, 0);
    transform: skew(0.154deg);
  }

  20% {
    clip: rect(57px, 9999px, 368px, 0);
    transform: skew(0.24deg);
  }

  22.5% {
    clip: rect(81px, 9999px, 358px, 0);
    transform: skew(0.466deg);
  }

  25% {
    clip: rect(200px, 9999px, 389px, 0);
    transform: skew(0.112deg);
  }

  27.5% {
    clip: rect(494px, 9999px, 408px, 0);
    transform: skew(0.276deg);
  }

  30% {
    clip: rect(236px, 9999px, 485px, 0);
    transform: skew(0.668deg);
  }

  32.5% {
    clip: rect(436px, 9999px, 418px, 0);
    transform: skew(0.55deg);
  }

  35% {
    clip: rect(360px, 9999px, 36px, 0);
    transform: skew(0.082deg);
  }

  37.5% {
    clip: rect(36px, 9999px, 321px, 0);
    transform: skew(0.828deg);
  }

  40% {
    clip: rect(413px, 9999px, 316px, 0);
    transform: skew(0.61deg);
  }

  42.5% {
    clip: rect(450px, 9999px, 330px, 0);
    transform: skew(0.782deg);
  }

  45% {
    clip: rect(24px, 9999px, 295px, 0);
    transform: skew(0.72deg);
  }

  47.5% {
    clip: rect(7px, 9999px, 421px, 0);
    transform: skew(0.766deg);
  }

  50% {
    clip: rect(436px, 9999px, 227px, 0);
    transform: skew(0.94deg);
  }

  52.5% {
    clip: rect(421px, 9999px, 212px, 0);
    transform: skew(0.36deg);
  }

  55% {
    clip: rect(278px, 9999px, 42px, 0);
    transform: skew(0.362deg);
  }

  57.5% {
    clip: rect(113px, 9999px, 260px, 0);
    transform: skew(0.452deg);
  }

  60% {
    clip: rect(160px, 9999px, 183px, 0);
    transform: skew(0.294deg);
  }

  62.5% {
    clip: rect(405px, 9999px, 152px, 0);
    transform: skew(0.264deg);
  }

  65% {
    clip: rect(481px, 9999px, 394px, 0);
    transform: skew(0.284deg);
  }

  67.5% {
    clip: rect(69px, 9999px, 427px, 0);
    transform: skew(0.82deg);
  }

  70% {
    clip: rect(440px, 9999px, 276px, 0);
    transform: skew(0.146deg);
  }

  72.5% {
    clip: rect(243px, 9999px, 269px, 0);
    transform: skew(0.354deg);
  }

  75% {
    clip: rect(455px, 9999px, 193px, 0);
    transform: skew(0.47deg);
  }

  77.5% {
    clip: rect(417px, 9999px, 421px, 0);
    transform: skew(0.618deg);
  }

  80% {
    clip: rect(262px, 9999px, 224px, 0);
    transform: skew(0.58deg);
  }

  82.5% {
    clip: rect(8px, 9999px, 390px, 0);
    transform: skew(0.802deg);
  }

  85% {
    clip: rect(67px, 9999px, 259px, 0);
    transform: skew(0.852deg);
  }

  87.5% {
    clip: rect(358px, 9999px, 449px, 0);
    transform: skew(0.658deg);
  }

  90% {
    clip: rect(234px, 9999px, 313px, 0);
    transform: skew(0.62deg);
  }

  92.5% {
    clip: rect(197px, 9999px, 294px, 0);
    transform: skew(0.254deg);
  }

  95% {
    clip: rect(262px, 9999px, 78px, 0);
    transform: skew(0.484deg);
  }

  97.5% {
    clip: rect(3px, 9999px, 340px, 0);
    transform: skew(0.25deg);
  }
}

@keyframes dzError {
  0% {
    transform: skew(3deg);
  }

  10% {
    transform: skew(-3deg);
  }

  20% {
    transform: skew(4deg);
  }

  30% {
    transform: skew(3deg);
  }

  40% {
    transform: skew(-1deg);
  }

  50% {
    transform: skew(-4deg);
  }

  60% {
    transform: skew(4deg);
  }

  70% {
    transform: skew(2deg);
  }

  80% {
    transform: skew(3deg);
  }

  90% {
    transform: skew(4deg);
  }
}

.flex-row-fluid {
  -webkit-box-flex: 1;
  flex: 1 auto;
  -ms-flex: 1 0 0rem;
  min-width: 0;
}

.authincation {
  background: var(--card);
  display: flex;
  min-height: 100vh;
}

.authincation .login-aside {
  background: var(--primary);
  padding-top: 5rem;
  max-width: 35rem;
  width: 100%;
  z-index: 1;
  position: relative;
}

.authincation .login-aside:after {
  content: "";
  clip-path: polygon(0% 100%, 100% 0%, 0% 0%);
  width: 8.75rem;
  height: 100%;
  position: absolute;
  right: -8.75rem;
  z-index: -1;
  top: 0;
  background-color: var(--primary);
  box-shadow: 0.125rem 0rem 1.875rem rgba(0, 0, 0, 0.15);
}

.authincation .login-aside .aside-image {
  min-height: 28.125rem;
  margin: auto 0;
  min-width: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media only screen and (max-width: 61.9375rem) {
  .authincation .login-aside .aside-image {
    display: none;
  }
}

.authincation .login-aside .aside-image .img1 {
  position: absolute;
  top: 0;
  right: 0;
  width: 125px;
  border-radius: 0.5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

@media only screen and (max-width: 85.375rem) {
  .authincation .login-aside .aside-image .img1 {
    width: 82px;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .authincation .login-aside .aside-image .img1 {
    width: 150px;
  }
}

.authincation .login-aside .aside-image .img2 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 35px;
  width: 150px;
  border-radius: 0.5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

@media only screen and (max-width: 85.375rem) {
  .authincation .login-aside .aside-image .img2 {
    width: 100px;
    left: 21px;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .authincation .login-aside .aside-image .img2 {
    width: 150px;
    left: 35px;
  }
}

.authincation .login-aside .aside-image .img3 {
  position: absolute;
  border-radius: 0.5rem;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 150px;
  right: 32px;
  bottom: 19px;
}

@media only screen and (max-width: 85.375rem) {
  .authincation .login-aside .aside-image .img3 {
    width: 82px;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .authincation .login-aside .aside-image .img3 {
    width: 125px;
    right: 94px;
  }
}

.authincation .login-aside p {
  color: rgba(255, 255, 255, 0.7);
}

@media only screen and (max-width: 87.5rem) {
  .authincation .login-aside {
    max-width: 22.5rem;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .authincation .login-aside {
    max-width: 100%;
    padding-top: 0;
  }

  .authincation .login-aside:after {
    content: none;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .authincation .login-aside .aside-image {
    min-height: 18.75rem;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .login-aside .logo img {
    width: 140px;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .login-aside .logo img {
    width: 120px;
  }
}

.move-1 {
  -webkit-animation: move1 5s infinite;
  animation: move1 5s infinite;
}

.move-2 {
  -webkit-animation: move2 5s infinite;
  animation: move2 5s infinite;
}

.move-3 {
  -webkit-animation: move3 5s infinite;
  animation: move3 5s infinite;
}

@-webkit-keyframes move1 {
  0% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px);
  }

  50% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px);
  }

  100% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px);
  }
}

@keyframes move1 {
  0% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px);
  }

  50% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px);
  }

  100% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px);
  }
}

@-webkit-keyframes move2 {
  0% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px);
  }

  50% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px);
  }

  100% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px);
  }
}

@-webkit-keyframes move3 {
  0% {
    transform: translate(0, 0);
  }

  20% {
    transform: translate(5px, 0);
  }

  40% {
    transform: translate(5px, 5px);
  }

  65% {
    transform: translate(0, 5px);
  }

  65% {
    transform: translate(5px, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

.authincation-content {
  background: #fff;
  box-shadow: 0 0 2.1875rem 0 rgba(154, 161, 171, 0.15);
  border-radius: 0.3125rem;
}

[data-theme-version="dark"] .authincation-content {
  background: #18254f;
  box-shadow: none;
}

.authincation-content.style-1 {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(1.25rem);
}

.authincation-content.style-1 .form-control {
  background: rgba(255, 255, 255, 0.6);
  border-radius: 0.3125rem;
}

.authincation-content.style-1 .user-icon {
  height: 6.25rem;
  background: var(--primary);
  width: 6.25rem;
  text-align: center;
  border-radius: 6.25rem;
  line-height: 6.25rem;
  margin-left: auto;
  font-size: 3.75rem;
  text-align: center;
  color: white;
  margin-right: auto;
  margin-top: -6.25rem;
  margin-bottom: 1.25rem;
}

.authincation-content.style-2 {
  background: transparent;
  box-shadow: none;
  max-width: 33.125rem;
  width: 100%;
}

@media only screen and (max-width: 35.9375rem) {
  .authincation-content.style-2 .auth-form {
    padding: 1.875rem 0rem;
  }
}

.welcome-content {
  background-size: cover;
  background-position: center;
  height: 100%;
  padding: 4.6875rem 3.125rem;
  position: relative;
  z-index: 1;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.welcome-content::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: var(--primary);
  opacity: 0.75;
  z-index: -1;
  border-top-left-radius: 0.3125rem;
  border-bottom-left-radius: 0.3125rem;
}

.welcome-content .welcome-title {
  color: #fff;
  font-weight: 500;
  font-size: 1.25rem;
}

.welcome-content p {
  color: #fff;
}

.welcome-content .brand-logo a {
  display: inline-block;
  margin-bottom: 1.25rem;
  font-weight: 700;
  color: #fff;
  font-size: 1.25rem;
}

.welcome-content .brand-logo a img {
  width: 6.25rem;
}

.welcome-content .intro-social {
  position: absolute;
  bottom: 4.6875rem;
}

.welcome-content .intro-social ul {
  margin-bottom: 0;
}

.welcome-content .intro-social ul li {
  display: inline-block;
}

.welcome-content .intro-social ul li a {
  color: #fff;
  font-size: 0.875rem;
  padding: 0rem 0.4375rem;
}

.auth-form {
  padding: 3.125rem 3.125rem;
}

@media only screen and (max-width: 35.9375rem) {
  .auth-form {
    padding: 1.875rem;
  }
}

.auth-form .page-back {
  display: inline-block;
  margin-bottom: 0.9375rem;
}

.sepertor {
  position: relative;
  text-align: center;
}

.sepertor:before,
.sepertor:after {
  position: absolute;
  content: "";
  width: 157px;
  height: 1px;
  background-color: var(--light);
  top: 10px;
}

@media only screen and (max-width: 100rem) {

  .sepertor:before,
  .sepertor:after {
    width: 140px;
    top: 9px;
  }
}

@media only screen and (max-width: 35.9375rem) {

  .sepertor:before,
  .sepertor:after {
    width: 116px;
  }
}

.sepertor:before {
  left: 0;
}

.sepertor:after {
  right: 0;
}

.social-bx span {
  font-size: 13px;
}

@media only screen and (max-width: 100rem) {
  .social-bx span {
    font-size: 10px;
  }
}

.label-color {
  color: #131340;
}

.fix-wrapper {
  min-height: 100vh;
  display: flex;
  padding: 30px 0;
  align-items: center;
}

.new-account p {
  margin-bottom: 0;
}

.heart {
  width: 60px;
  height: 60px;
  display: inline-block;
  background: url("../images/like.png");
  cursor: pointer;
  margin: -25px -15px;
}

.heart-blast {
  background-position: -1680px 0 !important;
  transition: background 1s steps(28);
}

.dlab-scroll {
  overflow-y: scroll;
}

body>* {
  scrollbar-width: thin;
  scrollbar-color: rgba(111, 133, 147, 0.1) #fff0;
}

::-webkit-scrollbar {
  width: 5px;
  opacity: 0;
}

::-webkit-scrollbar-thumb {
  background: rgba(111, 133, 147, 0.1);
}

.students h4,
.students .h4 {
  font-size: 2rem;
  font-weight: 800;
  color: #fff;
}

.students h5,
.students .h5 {
  font-size: 1.25rem;
  font-weight: 700;
  color: #fff;
}

.students span {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
}

.students1 h4,
.students1 .h4 {
  font-size: 2rem;
  font-weight: 800;
}

.students1 h5,
.students1 .h5 {
  font-size: 1.125rem;
  font-weight: 700;
  color: #717579;
}

.bg-students {
  background: #c61cca;
}

.bg-teachers {
  background: #093cbd;
}

.donut-chart-sale small,
.donut-chart-sale .small,
.donut-chart-sale .small {
  font-size: 1.25rem;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  display: flex;
  align-items: center;
  top: 0;
  justify-content: center;
  font-weight: 700;
  color: black;
}

.booking-calender thead .picker-switch {
  position: absolute;
  top: 1.25rem;
  right: 5.4rem;
  font-size: 1.5rem;
  font-weight: 800;
  white-space: nowrap;
}

@media only screen and (max-width: 61.9375rem) {
  .booking-calender thead .picker-switch {
    display: none;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .booking-calender thead .picker-switch {
    display: block;
  }
}

.booking-calender thead .picker-switch:hover {
  background: transparent !important;
}

.booking-calender thead .prev {
  position: absolute;
  right: 3.3125rem;
  top: 4.6875rem;
  padding: 0rem 3.1875rem;
  font-size: 1.25rem;
}

.booking-calender thead .prev:hover {
  background: transparent !important;
}

.booking-calender thead .next {
  position: absolute;
  right: 2.5rem;
  top: 4.6875rem;
  padding: 0;
  font-size: 1.25rem;
}

.booking-calender thead .next:hover {
  background: transparent !important;
}

.booking-calender thead .dow {
  border: 0.0625rem solid var(--border);
}

.booking-calender tbody tr th {
  padding: 1rem;
}

.booking-calender tbody tr .day {
  padding: 1rem;
  height: 3.75rem;
  width: 3.75rem;
  font-size: 1.25rem;
  font-weight: 600;
  border: 0.0625rem solid var(--border);
}

.booking-calender tbody tr .day.active {
  background: var(--primary) !important;
  box-shadow: none !important;
  color: #fff;
}

@media only screen and (max-width: 74.9375rem) {
  .booking-calender tbody tr .day {
    padding: 0.5rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .booking-calender tbody tr .day {
    font-size: 1rem;
  }
}

.booking-calender tbody tr .today {
  box-shadow: none;
}

@media only screen and (max-width: 87.5rem) {
  .booking-calender thead .picker-switch {
    right: 3.125rem;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .booking-calender thead .picker-switch {
    right: 0rem;
    font-size: 1rem;
  }

  .booking-calender tbody .day {
    height: 2.5rem;
    width: 2.5rem;
    font-size: 0.875rem;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .booking-calender thead .picker-switch {
    right: -1rem;
    font-size: 0.8rem;
  }
}

.intuition-table .intuition-profile img {
  height: 4rem;
  width: 4rem;
  border-radius: 1rem;
}

.intuition-table .intuition-profile .info h5,
.intuition-table .intuition-profile .info .h5 {
  font-size: 1.125rem;
  font-weight: 700;
  color: #000;
}

.intuition-table .intuition-profile .info span {
  font-size: 1rem;
  font-weight: 600;
  color: #717579;
}

.intuition-table .intuition-profile .info a {
  white-space: nowrap;
}

.intuition-table .intuition-profile .form-check-input {
  margin-top: 0.5rem;
  margin-right: 0.3125rem;
  border-radius: 0.25rem;
}

.intuition-table tr td {
  border-color: transparent !important;
}

.round {
  position: relative;
}

.round span {
  margin: 0 1.25rem;
  font-size: 1rem;
  font-weight: 600;
}

.round label {
  background-color: #fff;
  border: 0.0625rem solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 1.5rem;
  left: 0;
  position: absolute;
  top: 0rem;
  width: 1.5rem;
}

.round label:after {
  border: 0.125rem solid var(--primary);
  border-top: none;
  border-right: none;
  content: "";
  height: 0.375rem;
  left: 0.375rem;
  opacity: 0;
  position: absolute;
  top: 0.4375rem;
  transform: rotate(-45deg);
  width: 0.75rem;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked+label {
  background-color: #fff;
  border-color: var(--primary);
}

.round input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.student img {
  height: 3.125rem;
  width: 3.125rem;
  border-radius: 1rem;
}

.student a {
  white-space: nowrap;
}

.whitesp-no {
  white-space: nowrap;
}

.intuition-table .card-table tr td {
  border: 0;
}

.form-group {
  margin-bottom: 1rem;
}

.ck {
  height: 12.5rem;
}

.teacher-model .close {
  border: 0;
}

.facebook-log {
  color: #1b74e4;
}

.login-social a {
  background: var(--rgba-primary-1);
}

.sign-in-your {
  padding: 3.125rem 1.875rem;
}

.sign {
  background: #cfe5ff;
  position: relative;
  border-radius: 0.5rem 0 0 0.5rem;
}

.sign .bitcoin-img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.sd-shape7 {
  position: absolute;
  -webkit-animation: movedelement 10s linear infinite;
  animation: movedelement 10s linear infinite;
}

@keyframes movedelement {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }

  25% {
    -webkit-transform: translate(0.625rem, 0.625rem);
    transform: translate(0.625rem, 0.625rem);
  }

  50% {
    -webkit-transform: translate(0.3125rem, 0.3125rem);
    transform: translate(0.3125rem, 0.3125rem);
  }

  75% {
    -webkit-transform: translate(0.625rem, -0.3125rem);
    transform: translate(0.625rem, -0.3125rem);
  }

  to {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

.modal-header .close {
  border: 0;
}

/* content body */
.chart-title h4,
.chart-title .h4 {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
}

.chart-title p {
  font-size: 0.875rem;
  font-weight: 400;
  margin: 0;
  color: #717579;
}

.main-card {
  margin-top: -2.5rem;
}

@media only screen and (max-width: 100rem) {
  .main-card {
    margin-top: -1.875rem;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .main-card {
    margin-top: -0.075rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .main-card {
    margin-top: 0;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .main-card {
    margin-top: -1rem;
  }
}

.card-box {
  overflow: hidden;
}

.card-box .card-header {
  color: #fff;
  padding: 0.625rem 1.25rem;
}

.card-box .card-header .chart-num P {
  margin-bottom: 0;
}

.card-box .card-header .chart-num P i {
  font-size: 1.5rem;
}

.card-box .card-header .chart-num h2,
.card-box .card-header .chart-num .h2 {
  color: #fff;
  font-size: 1.75rem;
}

@media only screen and (max-width: 87.5rem) {

  .card-box .card-header .chart-num h2,
  .card-box .card-header .chart-num .h2 {
    font-size: 1rem;
  }
}

.chart-primary .apexcharts-tooltip.apexcharts-theme-light {
  box-shadow: 0rem 0.625rem 1.875rem 0rem rgba(0, 0, 0, 0.1);
  border: 0;
  background: #fff;
}

.chart-primary .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: #fff;
  border-bottom: 0.0625rem solid #eee;
  font-family: poppins;
  padding: 0.5rem 0.9375rem;
}

.chart-primary .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-series-group {
  padding: 0.9375rem 0.9375rem;
}

.chart-primary .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-series-group .apexcharts-tooltip-marker {
  background-color: #ac4cbc;
}

.chart-primary .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-series-group .apexcharts-tooltip-text {
  font-family: poppins;
}

.chart-primary .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-series-group .apexcharts-tooltip-text .apexcharts-tooltip-y-group {
  padding: 0;
}

.chart-primary .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-series-group .apexcharts-tooltip-text .apexcharts-tooltip-y-group .apexcharts-tooltip-text-value {
  color: var(--primary);
  font-weight: 400;
}

.img-btc {
  width: 100%;
  max-width: 1.5625rem;
}

.transaction-table .card-body .table-responsive thead th {
  padding: 0.5rem 1.7rem;
  color: var(--text-dark);
  font-size: 0.9375rem;
  background-color: var(--body-bg);
}

@media only screen and (max-width: 35.9375rem) {
  .transaction-table .card-body .table-responsive thead th {
    padding: 0.5rem 1.4rem;
  }
}

.transaction-table .card-body .table-responsive thead th:nth-child(2) {
  padding-left: 0;
}

.transaction-table .card-body .table-responsive thead th .custom-checkbox .form-check-input[type="checkbox"] {
  border-color: var(--primary);
}

.transaction-table .card-body .table-responsive tbody tr:last-child td {
  border-bottom: 0;
}

.transaction-table .card-body .table-responsive tbody td {
  padding: 0.7rem 1.7rem;
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
}

@media only screen and (max-width: 35.9375rem) {
  .transaction-table .card-body .table-responsive tbody td {
    padding: 0.5rem 1.4rem;
  }
}

.transaction-table .card-body .table-responsive tbody td:nth-child(2) {
  padding-left: 0;
}

.transaction-table .card-body .table-responsive tbody td img {
  border-radius: 50%;
}

.transaction-table .card-body .table-responsive tbody .arrow {
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.875rem;
  border-radius: 0.325rem;
  background-color: #3ab67a;
  transform: rotate(90deg);
}

.transaction-table .card-body .table-responsive tbody .arrow.style-1 {
  transform: rotate(-90deg);
  background-color: #5b5e81;
}

.transaction-table .card-body .table-responsive tbody .arrow.style-2 {
  transform: rotate(-90deg);
  background-color: #fd5353;
}

.form-control .dropdown-toggle::after {
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  content: "\f078";
  border: 0;
  font-size: 0.7rem;
}

.form-control.custom-image-select-1 {
  width: auto !important;
}

.form-control.custom-image-select-1 img {
  width: 1.625rem;
  margin-right: 0.625rem;
}

@media only screen and (max-width: 87.5rem) {
  .form-control.custom-image-select-1 .dropdown-toggle {
    padding: 6px 10px;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .form-control.custom-image-select-1 .dropdown-toggle {
    padding: 5px 10px;
  }
}

.form-control.custom-image-select-1 .filter-option-inner-inner {
  margin-right: 1.5625rem;
  color: var(--text-dark);
  font-size: 14px;
}

.form-control.custom-image-select-1 .dropdown-menu .dropdown-item {
  padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
  text-align: left;
}

.form-control.custom-image-select-1 .form-control {
  width: 0 !important;
}

.form-control.custom-image-select-1 .form-control .dropdown-toggle::after {
  color: #3693ff;
}

.form-control.custom-image-select-2 {
  background: var(--rgba-primary-1);
  border-radius: 0.5rem;
  margin-bottom: 0.625rem;
  margin-left: 0.375rem;
  width: 300px !important;
}

.form-control.custom-image-select-2.style-1 {
  width: 100% !important;
  margin-bottom: 0;
  margin-left: 0;
  left: 0;
  z-index: 0 !important;
}

@media only screen and (max-width: 100rem) {
  .form-control.custom-image-select-2 {
    width: 263px !important;
  }
}

.form-control.custom-image-select-2 img {
  margin-right: 0.525rem;
  width: 1.5rem;
}

.form-control.custom-image-select-2 .dropdown-toggle {
  border: 0 !important;
}

.form-control.custom-image-select-2 .filter-option-inner-inner {
  margin-right: 1.25rem;
  color: var(--text-dark);
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.form-control.custom-image-select-2 .dropdown-menu .dropdown-item {
  padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
  text-align: left;
  transition: all 0.5s;
}

.form-control.custom-image-select-2.bit {
  background: #fff2e0;
}

.form-control.custom-image-select-2.eth {
  background: #94fff9;
}

.form-control.custom-image-select-2.dash {
  background: var(--rgba-primary-1);
}

.form-control.custom-image-select-2.lit {
  background: #cfe5ff;
}

.form-control.custom-image-select-2.ripp {
  background: rgba(35, 41, 47, 0.1);
}

.form-control.custom-image-select-2.pink-light {
  background: rgba(172, 76, 188, 0.1);
}

.form-control.custom-image-select-2.pink-light .dropdown-toggle::after {
  color: #ac4cbc;
}

@media only screen and (max-width: 87.5rem) {
  .form-control.custom-image-select-1 {
    height: 3rem;
  }

  .form-control.custom-image-select-1 img {
    width: 1.75rem;
    margin-right: 1.125rem;
  }
}

.price-list .order-tbl tbody,
.price-list-1 .order-tbl tbody {
  border-top: 0;
}

.price-list.style-2,
.price-list-1.style-2 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.price-list.style-2 .custom-dropdown .btn-pink,
.price-list-1.style-2 .custom-dropdown .btn-pink {
  background: rgba(172, 76, 188, 0.1);
}

.price-list.style-2 .custom-dropdown .btn-pink svg circle,
.price-list-1.style-2 .custom-dropdown .btn-pink svg circle {
  fill: #c70aef;
}

.price-list.style-2 .custom-dropdown .btn-pink:hover,
.price-list-1.style-2 .custom-dropdown .btn-pink:hover {
  background: #c70aef;
}

.price-list.style-2 .custom-dropdown .btn-pink:hover svg circle,
.price-list-1.style-2 .custom-dropdown .btn-pink:hover svg circle {
  fill: white;
}

.price-list.style-2.border-style,
.price-list-1.style-2.border-style {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.price-list .table th,
.price-list-1 .table th {
  padding: 0.5375rem 0.625rem;
}

.price-list .table td,
.price-list-1 .table td {
  padding: 0.5375rem 0.625rem;
}

.price-list .card-footer a i,
.price-list-1 .card-footer a i {
  transform: scale(1.5);
  color: #c4c5cd;
}

.support-box {
  background: #3693ff;
  border-radius: 0.625rem;
  margin: 0 1rem;
  margin-top: 4rem;
  background-image: url("../images/support.png");
  text-align: center;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.support-box .media {
  justify-content: center;
}

.support-box .media span {
  height: 5rem;
  width: 5rem;
  font-size: 3.125rem;
  display: block;
  background: var(--primary);
  border-radius: 50%;
  text-align: center;
  color: white;
  line-height: 1.5;
  margin-top: -2.0625rem;
}

.support-box .info {
  padding: 0.9375rem 0.9375rem;
}

.support-box .info p {
  color: white;
  font-size: 1rem;
}

.support-box .info a {
  font-size: 0.875rem !important;
  font-weight: 600;
}

.support-box .info .text-dark {
  color: #000 !important;
}

.amount-bx {
  display: block;
  border-top: 0.0625rem solid #eeeeee;
  border-bottom: 0.0625rem solid #eeeeee;
  text-align: center;
  padding: 1.25rem 0;
  margin: 2.1875rem -1.875rem;
}

.amount-bx .form-control {
  width: 100%;
  font-size: 2rem;
  text-align: center;
  border: 0;
  color: #000;
  font-weight: 700;
}

.amount-bx ::placeholder {
  color: black;
}

.quick-transfer .testimonial-one .items img {
  height: 5.625rem;
  width: 5.625rem;
  margin: auto;
}

.quick-transfer .btn-link {
  font-size: 1.125rem;
  font-weight: 500;
}

.dropdown-header {
  background-color: var(--secondary);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  padding: 1.25rem 1.5625rem;
}

.dropdown-header .title {
  color: #fff;
}

.dropdown-header a i {
  color: #fff;
  font-size: 1.125rem;
}

.dropdown-header:after {
  content: "";
  position: absolute;
  background-color: var(--secondary);
  top: -0.4375rem;
  right: 1.25rem;
  width: 100%;
  height: 100%;
  max-width: 1.875rem;
  max-height: 1.875rem;
  z-index: -1;
  transform: rotate(45deg);
}

.of-visible {
  overflow: visible !important;
}

[data-sidebarbg="color_1"] .feature-box {
  background-color: #3736af;
}

.feature-box {
  background-color: var(--sidebar-bg);
  overflow: hidden;
  padding: 0rem 0.625rem;
}

.feature-box small,
.feature-box .small {
  color: #aaa9ff;
}

.feature-box .wallet-box {
  display: flex;
  align-items: center;
  background-color: #413fbb;
  border-radius: 0.5rem;
  padding: 0.625rem 1.25rem 0.625rem 1.25rem;
  cursor: pointer;
  margin: 0.525rem 0.25rem;
}

@media only screen and (max-width: 100rem) {
  .feature-box .wallet-box {
    margin: 0.325rem 0.25rem;
  }
}

@media only screen and (max-width: 87.5rem) {
  .feature-box .wallet-box {
    margin: 0.525rem 0.25rem;
  }
}

.feature-box .item-1 {
  padding: 0.625rem 1.5625rem;
  margin: 0 0.25rem;
  text-align: center;
  transition: all 0.5s;
  border-radius: 0.5rem;
  cursor: pointer;
  margin-bottom: 9.5px;
}

@media only screen and (max-width: 100rem) {
  .feature-box .item-1 {
    margin-bottom: 4.9px;
  }
}

@media only screen and (max-width: 87.5rem) {
  .feature-box .item-1 {
    margin-bottom: 8.3px;
  }
}

.feature-box .item-1 small,
.feature-box .item-1 .small {
  font-size: 0.875rem;
}

.feature-box .item-1:hover {
  background: #413fbb;
}

.feature-box.style-3 {
  padding: 0.4rem 0.625rem;
}

.feature-box.style-3 .wallet-box {
  margin: 0rem 0.25rem;
}

.wallet-wrapper {
  position: relative;
  background: var(--secondary);
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: self-start;
  padding: 0.95rem 1.1875rem 0.5rem;
  overflow: hidden;
}

.wallet-wrapper span {
  position: relative;
  z-index: 1;
  color: white;
}

.wallet-wrapper span svg {
  border: 0.0625rem solid rgba(255, 255, 255, 0.39);
  border-radius: 0.375rem;
  padding: 0.125rem 0.1875rem;
  margin-bottom: 0.3125rem;
}

.wallet-wrapper h4,
.wallet-wrapper .h4 {
  color: white;
}

.wallet-wrapper h5,
.wallet-wrapper .h5 {
  color: white;
}

.wallet-wrapper:after {
  content: "";
  position: absolute;
  top: -1.7rem;
  right: -5.1125rem;
  height: 15rem;
  width: 15rem;
  background: #36a8ff;
  border-radius: 50%;
}

.change-btn-1 {
  text-align: center;
  margin-top: -1.55rem;
  position: relative;
  margin-bottom: 0.875rem;
}

.change-btn-1 a {
  margin: 0 0.3125rem;
  color: black !important;
  background: #fff;
}

.change-btn-1 a:hover,
.change-btn-1 a:active {
  background-color: #fff !important;
}

.wallet-bar .order-history {
  margin-top: 1.75rem;
}

.tooltip_box {
  display: flex;
  align-items: center;
  background: #d8eaff;
  padding: 0.625rem;
  border-color: #d8eaff;
  border-radius: 0.5rem;
}

.tooltip_box .tooltip-data {
  display: grid;
  padding: 1.25rem 0.625rem 0.3125rem 0.9375rem;
}

.tooltip_box .tooltip-data span {
  font-size: 1rem;
  color: #1d1b23;
  font-weight: 500;
}

.tooltip_box .tooltip-data span.data-point {
  background: none !important;
  position: relative;
  font-size: 0.875rem;
  font-weight: 400;
  color: #717579;
  margin-left: 1.25rem;
}

.tooltip_box .tooltip-data span.data-point:after {
  content: "";
  position: absolute;
  top: 0.1875rem;
  left: -1.25rem;
  width: 0.8125rem;
  height: 0.8125rem;
  border-radius: 50%;
}

.tooltip_box .tooltip-data span.data-point.bg-primary:after {
  background-color: var(--secondary);
}

.tooltip_box .tooltip-data span.data-point.bg-secondary:after {
  background-color: var(--primary);
}

.crypto-chart .apexcharts-tooltip {
  box-shadow: 0 0.625rem 2.3125rem 0rem rgba(54, 48, 98, 0.1);
  border-radius: 0.5rem;
  border-bottom-left-radius: 0.3125rem;
}

.crypto-chart .apexcharts-tooltip.apexcharts-theme-light {
  border: none;
  background: none;
}

.bookmark-btn {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-bottom: 0;
  font-family: "Font Awesome 6 Free";
}

.bookmark-btn .checkmark {
  font-weight: 900;
  line-height: 1;
}

.bookmark-btn .checkmark:before {
  content: "\f005";
  font-size: 1.125rem;
  color: #89879f;
  font-weight: 900;
}

.apexcharts-text tspan {
  fill: var(--text);
}

.bookmark-btn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  display: none;
}

.bookmark-btn input:checked~.checkmark:before {
  color: #ff9838;
  font-weight: 900;
}

.swal2-popup .swal2-styled:focus {
  box-shadow: none;
}

.get-report {
  font-weight: 500;
}

.market-chart .apexcharts-theme-light .apexcharts-tooltip.apexcharts-theme-light {
  border: 0.0625rem solid #e3e3e3;
  background: var(--primary);
  color: #fff;
  width: 15.9375rem;
  height: 4.375rem;
}

.market-chart .apexcharts-theme-light .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-box {
  display: flex;
  flex-direction: column;
  flex-flow: wrap;
  padding: 0.3125rem 1.125rem;
}

.market-chart .apexcharts-theme-light .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-box div {
  margin: 0.3125rem 0.375rem;
}

.market-chart .market-line {
  margin-left: -6px;
}

.dz-head-title {
  flex-flow: wrap;
}

@media only screen and (max-width: 35.9375rem) {
  .dz-head-title {
    display: none !important;
  }
}

.setting {
  height: 40px;
  width: 40px;
  background: var(--light);
  line-height: 40px;
  text-align: center;
  display: block;
  margin: 0 5px;
  border-radius: 0.5rem;
  cursor: pointer;
}

@media only screen and (max-width: 35.9375rem) {
  .setting {
    margin-left: 0;
  }
}

.banking-card {
  transition: ease all 0.5s;
}

.banking-card:hover .setting svg {
  animation-name: shake 1s ease-out infinite;
  -webkit-animation: shake 1s ease-out infinite;
}

.ps--active-x>.ps__rail-x,
.ps--active-y>.ps__rail-y {
  z-index: -1;
}

[data-headerbg="color_1"] .header-profile2 span {
  color: #fff;
}

[data-headerbg="color_1"] .header .header-right svg path {
  fill: #fff;
}

[data-headerbg="color_1"] .header .header-right .header-profile2 .dropdown-menu a svg path {
  fill: var(--primary);
}

[data-headerbg="color_1"] .header .header-right .logout path {
  fill: #fd5353 !important;
}

/* bootsrap-pages */
.element-area {
  display: flex;
}

.element-area .demo-view {
  width: calc(100% - 270px);
}

@media only screen and (max-width: 100rem) {
  .element-area .demo-view {
    width: calc(100% - 236px);
  }
}

@media only screen and (max-width: 74.9375rem) {
  .element-area .demo-view {
    width: calc(100% - 224px);
  }
}

@media only screen and (max-width: 61.9375rem) {
  .element-area .demo-view {
    width: 100%;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .element-area .demo-view {
    width: 100%;
  }

  .element-area .demo-view .container-sm {
    padding: 0;
  }
}

.element-area .demo-right {
  width: 16.875rem;
  position: sticky;
  top: 7rem;
  height: calc(100% - 140px);
  z-index: 2;
}

@media only screen and (max-width: 74.9375rem) {
  .element-area .demo-right {
    width: 15.875rem;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .element-area .demo-right {
    display: none;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .element-area .demo-right {
    display: none;
  }
}

.element-area .demo-right.sticky {
  top: 100px;
  transition: 0.5s;
}

.demo-right-inner {
  padding: 1rem 1.625rem;
  background-color: var(--card);
  border-radius: 0.5rem;
  height: 100%;
  overflow: hidden;
}

.demo-right-inner h4,
.demo-right-inner .h4 {
  padding: 0.625rem 0;
}

.demo-right-inner ul {
  padding-left: 15px;
}

.demo-right-inner li {
  font-size: 1rem;
  position: relative;
}

.demo-right-inner li a {
  padding: 3px 0;
  font-size: 14px;
  display: block;
}

.navbar-nav .active .scroll {
  color: var(--primary);
}

.navbar-nav li.active:after {
  opacity: 1;
  background-color: var(--primary);
}

.demo-right-inner li:after {
  content: "";
  height: 5px;
  width: 5px;
  background-color: var(--text);
  border-radius: 50px;
  position: absolute;
  top: 11px;
  left: -15px;
  opacity: 0.1;
}

.market-icon {
  height: 1.875rem;
  width: 1.875rem;
  border-radius: 50%;
  text-align: center;
}

@media only screen and (max-width: 74.9375rem) {
  .market-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.market-icon i {
  font-size: 17px;
  line-height: 30px;
  color: #fff;
}

@media only screen and (max-width: 74.9375rem) {
  .market-icon i {
    font-size: 0.813rem;
    line-height: 1.5rem;
  }
}

.market-icon svg {
  height: 1.875rem;
  width: 1.875rem;
}

@media only screen and (max-width: 74.9375rem) {
  .market-icon svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.dataTablemarket tbody tr td {
  text-align: center;
}

.order.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background: var(--primary);
  color: #fff;
}

.market-list {
  padding: 8px 10px;
  border-radius: 0.625rem;
  margin: 0 -7.7px;
}

@media only screen and (max-width: 85.375rem) {
  .market-list {
    margin: 0 -10px;
  }
}

.market-list:hover {
  background: var(--rgba-primary-1);
}

.custom-search-area .input-group-text {
  background: var(--rgba-primary-1);
  padding-right: 0;
  border-color: transparent;
}

.custom-search-area .form-control {
  background: var(--rgba-primary-1);
  padding-left: 5px;
  margin-left: 0 !important;
  border-color: transparent;
}

.custom-search-area .form-control:focus {
  box-shadow: none;
  border-color: transparent;
  outline: 0;
}

.border-bottom-dashed {
  border-bottom: 1px dashed var(--border) !important;
}

.width-300 {
  width: 300px;
}

@media only screen and (max-width: 35.9375rem) {
  .width-300 {
    width: 100% !important;
  }
}

.width-100 {
  width: 100px !important;
}

@media only screen and (max-width: 35.9375rem) {
  .width-100 {
    width: 100% !important;
  }
}

.width-200 {
  width: 200px !important;
}

@media only screen and (max-width: 35.9375rem) {
  .width-200 {
    width: 100% !important;
  }
}

.p2p-select {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

@media only screen and (max-width: 35.9375rem) {
  .p2p-select {
    margin-left: 0px !important;
    margin-right: 0rem;
  }
}

.dataTabletrade .dataTables_wrapper .dataTables_length {
  display: none;
}

.dataTablehistory .dataTables_wrapper .dataTables_length {
  display: none;
}

.ico-icon img {
  width: 3.125rem;
  height: 3.125rem;
  object-fit: cover;
  transform: rotateY(0deg);
  transition: ease all 0.8s;
}

.pull-up {
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
  cursor: pointer;
}

.pull-up:hover {
  -webkit-transform: translateY(-4px) scale(1.02);
  -moz-transform: translateY(-4px) scale(1.02);
  -ms-transform: translateY(-4px) scale(1.02);
  -o-transform: translateY(-4px) scale(1.02);
  transform: translateY(-4px) scale(1.02);
  -webkit-box-shadow: 0 14px 24px rgba(0, 0, 0, 0.08);
  box-shadow: 0 14px 24px rgba(0, 0, 0, 0.08);
  z-index: 1;
}

.pull-up:hover .ico-icon img {
  transform: rotateY(-360deg);
}

table.tb-transaction tbody td {
  padding: 0.75rem 1.875rem;
}

@media only screen and (max-width: 47.9375rem) {
  table.tb-transaction tbody td {
    white-space: nowrap;
  }
}

.table-sell tbody td {
  border-color: rgba(255, 255, 255, 0.3) !important;
  background-color: transparent;
  color: #fff;
}

.table-sell thead th {
  border-color: rgba(255, 255, 255, 0.3) !important;
  background-color: transparent;
  color: #fff;
}

.table-pagenation {
  padding: 0 20px;
}

.count-num {
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0;
}

.avatar {
  display: inline-block;
  position: relative;
  text-align: center;
  height: 3rem;
  width: 3rem;
  line-height: 3rem;
  border-radius: 0.625rem;
}

.avatar.avatar-xxl {
  width: 3.75rem;
  height: 3.75rem;
  line-height: 3.75rem;
}

.student-tbl thead {
  background-color: var(--primary);
  color: #fff;
}

.student-tbl thead th:first-child {
  padding-left: 20px;
  border-top-left-radius: 0.5rem;
}

.student-tbl thead th:last-child {
  padding-right: 20px;
  border-top-right-radius: 0.5rem;
}

.student-tbl tbody td:first-child {
  padding-left: 20px;
}

.student-tbl tbody td:last-child {
  padding-right: 20px;
}

@media only screen and (max-width: 74.9375rem) {
  .table.student-tbl td {
    white-space: nowrap;
  }
}

.sweet-image {
  width: 20%;
}

.equalto {
  display: inline-block;
  font-size: 30px;
  height: 48px;
  line-height: 48px;
  vertical-align: middle;
}

#example4_wrapper .dataTables_length {
  display: none;
}

#example4_wrapper #example4_filter {
  display: none;
}

.amount {
  background-color: var(--light) !important;
}

.dropzone.style-1 {
  min-height: 2.5rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: white;
  border-radius: 0.5rem;
  padding: 5px;
  border-color: #e6e6e6;
}

.dropzone.style-1 .dz-message {
  margin-top: 3px;
  margin-bottom: 0;
}

@media only screen and (max-width: 64rem) {
  .header:not(.home) {
    z-index: 1;
  }

  .header:not(.home):after {
    background-position: center !important;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .exchange-select {
    width: 100% !important;
  }
}

@media only screen and (max-width: 35.9375rem) {

  .earning h3,
  .earning .h3 {
    font-size: 1.125rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .table.tbl-report td {
    white-space: nowrap;
  }
}

.brand-logo.invoice-logo .logo-compact {
  display: none;
}

.brand-logo.invoice-logo .logo-abbr {
  dipslay: block;
}

.custom-react-select>div:nth-child(4)>div {
  background-color: var(--card);
}

.custom-react-select>div:nth-child(4)>div>div {
  background-color: var(--card);
  cursor: pointer;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.custom-react-select>div:nth-child(4)>div>div:hover {
  background-color: var(--body-bg) !important;
  color: #514e5f;
}

.custom-react-select>div:nth-child(3) {
  height: 2.7rem;
  background-color: transparent;
  border-radius: 0.5rem;
  border-color: var(--border) !important;
}

.custom-react-select>div:nth-child(4) {
  border-radius: 10px;
  min-width: 150px;
  overflow: hidden;
  box-shadow: 0px 10px 40px 0px rgba(21, 50, 93, 0.1);
}

.custom-react-select>div>div:first-child div {
  font-size: 0.875rem;
  color: var(--text);
}

.custom-react-select>div>div:nth-child(2)>span {
  display: none;
}

/* edit */
.author-profile {
  text-align: center;
}

.author-profile .card-body {
  padding: 0;
}

.author-profile .author-media {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  display: inline-block;
}

.author-profile .author-media img {
  width: 130px;
  border-radius: 100%;
}

.author-profile .author-info .title {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
}

.author-profile .author-info span {
  display: block;
  color: var(--text);
}

.author-profile .info-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  border-top: 1px solid var(--border);
  padding: 18px 30px;
  color: var(--text);
}

.author-profile .info-list li a {
  color: var(--text);
}

.author-profile .info-list li span {
  color: var(--text);
  font-weight: 500;
}

.author-profile .card-footer {
  padding: 30px;
  display: block;
}

.author-profile .card-footer .form-control {
  height: 45px;
  border-color: var(--border);
  overflow: hidden;
  line-height: 34px;
}

.author-profile .upload-link {
  position: absolute;
  width: 35px;
  height: 35px;
  line-height: 32px;
  background: var(--primary);
  bottom: 0;
  left: 0px;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
  border-radius: 100%;
  color: #fff;
  overflow: hidden;
  border: 2px solid #fff;
}

.author-profile .upload-link .update-flie {
  position: absolute;
  opacity: 0;
  z-index: 0;
  width: 100%;
  cursor: pointer;
  left: 0;
  height: 100%;
}

.eye {
  position: absolute;
  right: 20px;
  bottom: 0.625rem;
}

.eye.style-1 {
  right: 25px;
}

.product-grid-card {
  padding: 15px;
}

.dlab-swiper-circle {
  position: relative;
}

@media only screen and (max-width: 74.9375rem) {
  .dlab-swiper-circle svg {
    width: 38px;
    height: 38px;
  }
}

.dlab-swiper-circle:before {
  content: "";
  position: absolute;
  width: 60px;
  height: 60px;
  opacity: 0;
  border-radius: 50%;
  border: 2px dashed rgba(255, 255, 255, 0.5);
  padding: 0;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  -webkit-animation: spin 10s infinite linear;
  animation: spin 10s infinite linear;
  top: -7px;
  left: -5px;
}

@media only screen and (max-width: 74.9375rem) {
  .dlab-swiper-circle:before {
    width: 50px;
    height: 50px;
    left: -6px;
    top: -6px;
  }
}

@keyframes spin {
  100% {
    transform: rotate(1turn);
  }
}

.card-box:hover .dlab-swiper-circle:before {
  opacity: 1;
}

.trad-card {
  transition: ease 0.5s;
}

.trad-card:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@media only screen and (max-width: 100rem) {
  .trad-card img {
    width: 3.125rem;
    height: 3.125rem;
  }
}

.trad-card:hover img {
  -webkit-animation: bell 1s ease-out infinite;
  animation: bell 1s ease-out infinite;
}

@keyframes bell {
  0% {
    transform: scaleX(1);
  }

  10% {
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
  }

  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
  }

  30% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }

  50% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }

  70% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }

  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }

  40% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }

  60% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }

  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }

  100% {
    transform: scaleX(1);
  }
}

.owl-bank-wallet .owl-stage-outer {
  padding: 7% 0;
}

.owl-bank-wallet .active.center {
  z-index: 1;
}

.owl-bank-wallet .active.center .card-bx {
  transform: scale(1.5);
  z-index: 1;
}

.owl-bank-wallet .card-bx {
  position: relative;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.owl-bank-wallet .card-bx .cr-logo {
  width: 40px;
}

.owl-bank-wallet .card-bx .card-info {
  padding: 30px 30px;
}

.owl-bank-wallet .card-bx .pattern-img {
  position: absolute;
  height: 100%;
  z-index: -1;
  left: 0;
  top: 0;
}

.owl-bank-wallet .card-bx .dots-img {
  width: 52px;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .owl-bank-wallet .card-bx p {
    font-size: 10px !important;
  }

  .owl-bank-wallet .card-bx .cr-logo {
    width: 18px;
  }

  .owl-bank-wallet .card-bx .card-info h3,
  .owl-bank-wallet .card-bx .card-info .h3 {
    font-size: 14px;
  }

  .owl-bank-wallet .card-bx .dots-img {
    width: 25px;
  }

  .owl-bank-wallet .card-bx .fs-11 {
    font-size: 8px !important;
  }

  .owl-bank-wallet .card-bx .card-info {
    padding: 22px 13px;
  }
}

.owl-bank-wallet.owl-nav-text .card-bx .card-info {
  opacity: 0;
}

.owl-bank-wallet.owl-nav-text .active.center .card-bx .card-info {
  opacity: 1;
}

.owl-bank-wallet.owl-nav-text .owl-nav .owl-prev,
.owl-bank-wallet.owl-nav-text .owl-nav .owl-next {
  cursor: pointer;
  writing-mode: tb;
  position: absolute;
  top: 50%;
  color: #fff;
  font-size: 18px;
  transform: rotate(180deg) translateY(50%);
}

.owl-bank-wallet.owl-nav-text .owl-nav .owl-prev i,
.owl-bank-wallet.owl-nav-text .owl-nav .owl-next i {
  margin-top: 10px;
}

.owl-bank-wallet.owl-nav-text .owl-nav .owl-prev {
  left: 30px;
}

.owl-bank-wallet.owl-nav-text .owl-nav .owl-next {
  right: 30px;
}

@media only screen and (max-width: 35.9375rem) {
  .owl-bank-wallet .active.center .card-bx {
    transform: scale(1);
  }
}

.border-primary {
  border-color: var(--primary) !important;
  border-width: 2px;
  border-style: solid;
}

.card.price-list-1 {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/* 9. hover-slide-right */
.btn.hover-slide-right::before {
  top: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 0%;
}

.btn.hover-slide-right:hover::before {
  width: 100%;
}

.order-list li,
.unorder-list li {
  list-style: unset;
}

.email-list .message-single i.yellow {
  color: #ffab2d !important;
}

.orderbookTable th,
.orderbookTable td {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.dataTabletrade .dataTables_wrapper .dataTables_paginate .paginate_button.next {
  margin-right: 0;
}

.dataTabletrade .paging_simple_numbers.dataTables_paginate {
  margin-top: 10px;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.react-datepicker {
  background-color: var(--card);
  border-color: var(--border);
}

.react-datepicker .react-datepicker__header {
  background-color: var(--card);
  border-color: var(--border);
}

.react-datepicker .react-datepicker__navigation-icon {
  top: 6px;
}

.market-preview {
  border-bottom: 0.0625rem solid var(--border);
  padding: 0.5625rem 1.875rem;
}

@media only screen and (max-width: 35.9375rem) {
  .market-preview {
    padding: 0.5625rem 1.175rem;
  }
}

.market-preview:hover {
  background: var(--light);
}

@media only screen and (max-width: 47.9375rem) {
  .market-preview span svg {
    width: 1.875rem;
  }
}

.market-coin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.975rem 0.625rem;
}

.market-coin .coin-search-area {
  width: 16.875rem;
}

.market-coin .coin-search-area .form-control {
  border-color: var(--border);
  background: var(--body-bg);
  border-right: 0;
}

.market-coin .coin-search-area .input-group-text {
  border-color: var(--border);
  background: var(--body-bg);
}

.market-coin .coin-box {
  padding: 0rem 0.575rem;
}

@media only screen and (max-width: 106.25rem) {
  .market-coin .coin-box svg {
    width: 2.5rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .market-coin .coin-box {
    padding: 0rem rem;
  }

  .market-coin .coin-box svg {
    width: 1.5rem;
  }
}

@media only screen and (max-width: 100rem) {
  .market-coin .coin-box {
    padding: 0rem 1rem;
    margin-bottom: 0.625rem;
  }

  .market-coin .coin-box h4,
  .market-coin .coin-box .h4 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .market-coin .coin-box {
    border: 0;
    padding: 0rem 0.1rem;
  }

  .market-coin .coin-box svg {
    width: 1.5rem;
  }

  .market-coin .coin-box h4,
  .market-coin .coin-box .h4 {
    font-size: 0.8rem;
  }
}

.trade-history .table thead tr th {
  padding-left: 6px;
  border: 0;
}

.trade-history .table tbody {
  border: 0;
}

.trade-history .table tbody tr td {
  font-size: 0.875rem;
  font-weight: 500;
  padding-left: 6px;
  border: 0;
}

.market-overview .card-header h4,
.market-overview .card-header .h4 {
  padding: 0 1.25rem;
  border-right: 0.0625rem solid #d7d7d7;
}

.market-overview .card-header h4:not(:first-child),
.market-overview .card-header .h4:not(:first-child) {
  color: #717579;
}

.market-overview .card-header h4:first-child,
.market-overview .card-header .h4:first-child {
  padding-left: 0;
}

@media only screen and (max-width: 35.9375rem) {

  .market-overview .card-header h4,
  .market-overview .card-header .h4 {
    padding: 0 0.65rem;
  }
}

.market-overview .card-header span svg {
  margin-left: 1.25rem;
  width: 15px;
}

.market-overview .get-report {
  font-weight: 500;
  border-left: 0.0625rem solid #d7d7d7;
  padding-left: 1.75rem;
  margin-left: 15px;
}

@media only screen and (max-width: 35.9375rem) {

  .market-overview h4,
  .market-overview .h4 {
    font-size: 0.8rem !important;
    margin-bottom: 0.625rem;
  }

  .market-overview span svg {
    display: none;
  }
}

.bar-chart h3,
.bar-chart .h3 {
  font-size: 1.65rem;
  margin: 0;
}

@media only screen and (max-width: 87.5rem) {

  .bar-chart h3,
  .bar-chart .h3 {
    font-size: 1.65rem;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .my-order-ile {
    order: 1;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .my-order-ile-2 {
    order: 1;
  }
}

#revenueMap svg line {
  stroke: var(--border);
}

.table.table-sell tr:last-child td {
  border-bottom: 0;
}

.drop-future .dropdown-menu {
  z-index: 3;
}

.coin-warpper {
  padding: 0.625rem 1.5625rem;
  background: var(--card);
  border: 0.0625rem solid var(--border);
  border-radius: 0.5rem;
  margin-bottom: 1.875rem;
  border-radius: 0.5rem;
}

.coin-warpper .nav .nav-item {
  border-right: 0.0625rem solid var(--border);
}

@media only screen and (max-width: 74.9375rem) {
  .coin-warpper .nav .nav-item {
    border-right: 0;
    margin-bottom: 0;
  }
}

.coin-warpper .nav .nav-item .nav-link {
  align-items: center;
  display: flex;
  padding: 0.5rem 0.7rem;
  margin: 0 0.75rem;
}

.coin-warpper .nav .nav-item .nav-link svg {
  margin-right: 0.625rem;
}

@media only screen and (max-width: 74.9375rem) {
  .coin-warpper .nav .nav-item .nav-link {
    margin: 0 0.15rem;
  }
}

@media only screen and (max-width: 74.9375rem) and (max-width: 61.9375rem) {
  .coin-warpper .nav .nav-item .nav-link {
    margin: 0 0.05rem;
  }
}

.coin-warpper .nav .nav-item .nav-link.active {
  color: white;
  background: var(--primary);
}

.coin-warpper .nav .nav-item .nav-link.active svg path {
  fill: white;
}

.coin-warpper .nav .nav-item .nav-link.active.bitcoin {
  background: #ffab2d;
}

.coin-warpper .nav .nav-item .nav-link.active.etherum {
  background: #00ada3;
}

.coin-warpper .nav .nav-item .nav-link.active.dash {
  background: var(--secondary);
}

.coin-warpper .nav .nav-item .nav-link.active.litcoin {
  background: #374c98;
}

.coin-warpper .nav .nav-item .nav-link.active.ripple {
  background: #ff782c;
}

.coin-warpper .nav .nav-item .nav-link.active.ripple svg path {
  fill: white;
}

@media only screen and (max-width: 35.9375rem) {
  .coin-warpper .nav .nav-item .nav-link {
    padding: 0.4rem 0.8rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .coin-warpper .nav .nav-item {
    border-right: 0;
  }
}

.coin-warpper .search-area .form-control {
  border-color: white;
}

.coin-warpper .search-area .input-group-text {
  background: white;
  border-color: white;
}

@media only screen and (max-width: 35.9375rem) {
  .coin-warpper .search-area {
    margin-top: 8px;
    width: 100% !important;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .coin-warpper {
    padding: 0.925rem 0.9625rem;
  }
}

@media only screen and (max-width: 87.5rem) {
  .nav .nav-item {
    margin-bottom: 0.875rem;
  }
}

@media only screen and (max-width: 1366px) {
  .nav .nav-item {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 74.9375rem) {
  padding: 0.9375rem 0.625rem;
}

@media only screen and (max-width: 61.9375rem) {
  .coin-details-search .input-group .form-control {
    height: 2.5rem;
  }

  .coin-details-search .input-group .input-group-text {
    height: 2.5rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .nav .nav-item {
    border: 0;
    margin-bottom: 0;
  }

  .nav .nav-item .nav-link {
    font-size: 0.95rem;
    margin: 0 0.25rem;
    padding: 0.3rem 0.4rem;
  }

  .nav .nav-item .nav-link svg {
    width: 1.425rem;
  }
}

@media only screen and (max-width: 35.9375rem) and (max-width: 35.9375rem) {
  .nav .nav-item .nav-link {
    margin: 0 0.05rem;
  }
}

.coin-content .guest-calendar {
  border: 0.0625rem solid var(--border);
  padding: 0.49375rem 0.875rem;
  border-radius: 0.5rem;
}

.coin-content .guest-calendar span svg {
  margin-right: 0.625rem;
  width: 1rem;
}

.coin-content .guest-calendar .reportrange span {
  font-weight: 400;
  color: var(--text-dark);
  font-size: 0.825rem;
}

.coin-content .default-select {
  padding: 0.59375rem 1.25rem;
  background-color: transparent;
  border-radius: 0.5rem;
  width: 12.5rem;
  color: #1d1b23;
  font-size: 1rem;
  font-weight: 400;
}

@media only screen and (max-width: 87.5rem) {
  .coin-content .default-select {
    padding: 0;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .coin-content .default-select {
    padding: 0;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .coin-content .default-select {
    margin: 5px 0;
  }
}

.coin-content .price-content {
  padding-right: 3.125rem;
}

@media only screen and (max-width: 74.9375rem) {
  .coin-content .price-content {
    padding-right: 1.625rem;
  }
}

.coin-content .price-content h4,
.coin-content .price-content .h4 {
  font-size: 1.25rem;
}

@media only screen and (max-width: 87.5rem) {
  .coin-content .price-content {
    margin-bottom: 0.625rem;
  }
}

.digital-cash .media .media-content p {
  border-top: 0.0625rem solid var(--border);
  padding-top: 1.175rem;
}

@media only screen and (max-width: 47.9375rem) {
  .digital-cash .media svg {
    width: 4.375rem;
  }
}

@media only screen and (max-width: 106.25rem) {
  .digital-cash .media .media-content p {
    width: 100%;
  }
}

.search-area.coin-tab-search .input-group-text,
.search-area.coin-tab-search .form-control {
  background-color: var(--light);
  border-color: transparent;
}

.form-wrapper.trade-form .input-group .input-group-text {
  min-width: 7.625rem;
  background: var(--primary);
  color: white;
  border-color: var(--primary);
}

.portofolio .my-profile .media .media-img {
  height: 100px;
  width: 100px;
  position: relative;
  object-fit: cover;
  margin: auto;
  border: 2px solid var(--primary);
  border-radius: 50%;
}

.portofolio .my-profile .media .media-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  margin: 0 auto;
  max-width: 12.5rem;
}

.portofolio .my-profile .media .media-img a {
  background-color: var(--secondary);
  display: block;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  bottom: 0;
  right: 0;
  border: 2px solid #fff;
}

.portofolio .my-profile .media .media-img a i {
  font-size: 13px;
  color: #fff;
}

.portofolio .my-profile .media h3,
.portofolio .my-profile .media .h3 {
  font-size: 18px;
}

.portofolio .my-profile .portofolio-social {
  display: flex;
  justify-content: center;
}

.portofolio .my-profile .portofolio-social li {
  padding-right: 0.625rem;
}

.portofolio .my-profile .portofolio-social li a {
  display: block;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  border: 0.0625rem solid var(--border);
  border-radius: 50%;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.portofolio .my-profile .portofolio-social li a i {
  font-size: 1rem;
  color: var(--secondary);
}

@media only screen and (max-width: 87.5rem) {
  .portofolio .my-profile .portofolio-social li a i {
    font-size: 1.2625rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .portofolio .my-profile .portofolio-social li a i {
    font-size: 1rem;
  }
}

.portofolio .my-profile .portofolio-social li a:hover {
  border-color: #3693ff;
  background-color: #3693ff;
}

.portofolio .my-profile .portofolio-social li a:hover i {
  color: #fff;
}

.coin-holding {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 0.0625rem solid var(--border);
}

.coin-holding:last-child {
  border-bottom: 0;
}

.coin-holding .coin-box-warper {
  margin-top: 10px;
  margin-bottom: 10px;
  min-width: 13%;
}

.coin-holding .coin-box-warper a {
  height: 2.5rem;
  width: 2.5rem;
  background: var(--light);
  line-height: 2.8;
  text-align: center;
  display: block;
  margin: 0 5px;
  border-radius: 0.5rem;
}

.coin-holding .coin-box-warper a:hover svg path {
  fill: var(--primary);
}

.coin-holding .coin-box-warper h4,
.coin-holding .coin-box-warper .h4 {
  cursor: pointer;
  font-size: 1rem;
}

@media only screen and (max-width: 100rem) {
  .coin-holding .coin-box-warper .coin-svg {
    width: 40px;
    height: 40px;
  }
}

@media only screen and (max-width: 87.5rem) {
  .coin-holding .coin-box-warper .peity {
    width: 12.5rem;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .coin-holding .coin-box-warper .peity {
    width: 10.625rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .coin-holding .coin-box-warper .peity {
    width: 7.625rem;
  }
}

.c-heading span {
  font-size: 25px;
}

.portfolio-table tbody tr td {
  white-space: nowrap;
}

.portfolio-table tbody tr td:first-child {
  padding-left: 0;
}

.portfolio-table tbody tr td svg {
  width: 40px;
  height: 40px;
}

.portfolio-table tbody tr:last-child td {
  border-bottom: 0;
}

.card-tabs .nav-tabs {
  border: 0;
}

.card-tabs .nav-tabs .nav-item .nav-link {
  border-radius: 0.5rem;
}

.card-tabs .nav-tabs .nav-item .nav-link.active {
  background: #cfe5ff;
  color: #3693ff;
}

.modal-date .input-hasicon .react-datepicker-wrapper {
  width: 100%;
}

.coin-card {
  background-color: #3693ff;
  overflow: hidden;
  z-index: 1;
}

.coin-card .back-image {
  position: absolute;
  right: 0;
  z-index: -1;
}

.coin-card .title h4,
.coin-card .title .h4 {
  font-size: 1.125rem;
  font-weight: 600;
  color: #fff;
}

.coin-card .title svg {
  margin-bottom: 1.875rem;
  margin-top: 1.25rem;
}

@media only screen and (max-width: 87.5rem) {
  .coin-card .title svg {
    width: 48px;
    height: 48px;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 81.25rem) {
  .coin-card .title svg {
    width: 40px;
    height: 38px;
    margin-top: 0.625rem;
  }
}

@media only screen and (max-width: 74.9375rem) {
  .coin-card .title svg {
    width: 40px;
    height: 40px;
    margin-bottom: 0.625rem;
    margin-top: 0.625rem;
  }
}

.coin-card .chart-num h2,
.coin-card .chart-num .h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 87.5rem) {

  .coin-card .chart-num h2,
  .coin-card .chart-num .h2 {
    font-size: 1.125rem;
  }
}

@media only screen and (max-width: 81.25rem) {

  .coin-card .chart-num h2,
  .coin-card .chart-num .h2 {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 74.9375rem) {

  .coin-card .chart-num h2,
  .coin-card .chart-num .h2 {
    font-size: 1.125rem;
  }
}

.coin-card .chart-num span {
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
}

.coin-card.secondary {
  background-color: #3693ff !important;
}

.coin-card.blue {
  background-color: var(--primary) !important;
}

.coin-card.green {
  background-color: #3ab67a !important;
}

.coin-card.pink {
  background-color: #ac4cbc !important;
}

.market-data {
  display: flex;
  flex-wrap: wrap;
}

.market-data .data {
  padding: 0 1.5rem;
}

.market-data .data h4,
.market-data .data .h4 {
  font-size: 1rem;
  font-weight: 600;
}

.market-data .data sub {
  font-size: 0.813rem;
  color: #ac4cbc;
}

.market-data .data:first-child {
  padding-left: 0;
}

@media only screen and (max-width: 35.9375rem) {
  .market-data {
    flex-wrap: wrap;
    display: none;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .market-data .data {
    padding-left: 0;
  }
}

.market-preview-3 {
  padding: 12px 0;
}

.previews-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 1.8rem;
}

.previews-info .pre-icon {
  display: flex;
  align-items: center;
}

.previews-info .pre-icon .icon {
  background-color: #3ab67a;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.625rem;
}

.previews-info .pre-icon h6,
.previews-info .pre-icon .h6 {
  margin-bottom: 0;
  font-size: 0.938rem;
  font-weight: 500;
}

.previews-info .count h6,
.previews-info .count .h6 {
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 600;
}

.previews-info .count span {
  color: #eb62d0;
}

.previews-info:hover {
  background-color: #f3f3f3;
}

.previews-info.green .pre-icon .icon {
  background-color: #3ab67a;
}

.previews-info.green .count span {
  color: #3ab67a;
}

.previews-info.yellow .pre-icon .icon {
  background-color: #ffd125;
}

.previews-info.blue .pre-icon .icon {
  background-color: var(--primary);
}

.previews-info.pink .pre-icon .icon {
  background-color: #ac4cbc;
}

.exchange .balance {
  padding: 1rem;
  border-radius: 0.5rem;
  margin-top: 0.725rem;
}

.exchange .balance .header-content {
  display: flex;
  justify-content: space-between;
}

.exchange .balance .header-content h6,
.exchange .balance .header-content .h6 {
  font-size: 0.938rem;
  font-weight: 500;
  color: #fff;
}

.exchange .balance .header-content span {
  color: #fff;
}

.exchange .balance .count {
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 0;
}

.selling {
  margin-bottom: 0.938rem;
}

.selling h4,
.selling .h4 {
  font-size: 0.875rem;
  font-weight: 500;
  color: #717579;
}

.selling .form_exchange {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.selling .form_exchange .input_exchange {
  height: 3rem;
  flex: 0 0 50%;
  padding-right: 0.625rem;
}

.selling .form_exchange .input_exchange .input-select {
  height: 100%;
  border-radius: 0.625rem;
  width: 100%;
  padding: 0.625rem;
  border: 1px solid #2a3969;
  background-color: #f3f3f3;
  font-weight: 400;
}

.selling .form_exchange .input_exchange input::placeholder {
  font-size: 1.125rem;
}

.selling .form_exchange .default-select {
  font-size: 1.125rem;
}

.selling .form_exchange .crypto-select {
  flex: 0 0 50%;
  padding-left: 0.625rem;
}

.selling .form_exchange .bootstrap-select.image-select.default-select.dashboard-select {
  height: 3rem;
  width: 100%;
}

.selling .form_exchange .bootstrap-select.image-select.default-select.dashboard-select .btn {
  align-items: center;
  line-height: 2.813rem;
}

.market_chart .custome-tooltip .apexcharts-xaxistooltip {
  color: #ffffff;
  background: var(--primary);
  border: 1px solid var(--rgba-primary-1);
  border-radius: 0.625rem;
}

.market_chart .custome-tooltip .apexcharts-xaxistooltip-bottom:after {
  border-bottom-color: var(--primary);
}

.previews-info-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.825rem 1.9rem;
}

@media only screen and (max-width: 35.9375rem) {
  .previews-info-list {
    padding: 0.825rem 1rem;
  }
}

.previews-info-list .pre-icon {
  display: flex;
  align-items: center;
}

.previews-info-list .count h6,
.previews-info-list .count .h6 {
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 600;
}

.previews-info-list .count span {
  color: #eb62d0;
  font-size: 12px;
}

.previews-info-list:hover {
  background-color: var(--body-bg);
}

.icon-box {
  height: 50px;
  width: 50px;
  min-width: 50px;
  border-radius: 0.5rem;
  line-height: 49px;
  text-align: center;
  display: inline-block;
  position: relative;
}

.icon-box svg {
  width: 25px;
}

.icon-box i {
  transform: scale(2);
}

.icon-box.icon-box-sm {
  height: 40px;
  width: 40px;
  min-width: 40px;
  line-height: 40px;
  border-radius: 0.325rem;
  cursor: pointer;
}

.icon-box.icon-box-sm i {
  transform: scale(1.5);
}

.icon-box.icon-box-sm svg {
  width: 20px;
}

.icon-box.icon-box-lg {
  height: 60px;
  width: 60px;
  line-height: 60px;
  border-radius: 0.5rem;
}

.icon-box.icon-box-lg svg {
  width: 30px;
}

.icon-box.icon-box-lg i {
  transform: scale(2.5);
}

.prim-card {
  background-color: var(--primary);
  overflow: hidden;
  z-index: 2;
}

.prim-card .number {
  font-size: 1.25rem;
  font-weight: 400;
  color: #fff;
  font-family: monospace;
  margin-bottom: 2rem;
}

.prim-card .master-card {
  text-align: center;
}

.prim-card::before {
  content: "";
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: 58%;
  left: 50%;
  transform: translateX(-50%);
  width: 23.063rem;
  height: 23.063rem;
  border-radius: 50%;
  opacity: 0.14;
}

.prim-card::after {
  content: "";
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  top: -27%;
  left: 50%;
  width: 23.063rem;
  height: 23.063rem;
  border-radius: 50%;
  z-index: -1;
  opacity: 0.14;
}

.prim-card img {
  width: 60px;
}

.exchange .balance {
  padding: 1.8rem;
  background-color: #eeee;
  border-radius: 0.5rem;
  margin-top: 0.725rem;
}

.exchange .balance .header-content {
  display: flex;
  justify-content: space-between;
}

.exchange .balance .header-content h6,
.exchange .balance .header-content .h6 {
  font-size: 0.938rem;
  font-weight: 500;
}

.exchange .balance .count {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0;
}

.rec-design1 {
  border: 2px solid rgba(252, 252, 252, 0.5);
  height: 114px;
  width: 173px;
  border-radius: 20px;
  position: absolute;
  top: -56px;
  right: -66px;
}

.rec-design2 {
  border: 2px solid rgba(252, 252, 252, 0.5);
  height: 114px;
  width: 173px;
  border-radius: 20px;
  position: absolute;
  top: -29px;
  right: -98px;
}

.chart-num h2,
.chart-num .h2 {
  font-size: 28px;
  font-weight: 600;
}

.chart-num span {
  font-size: 20px;
  font-weight: 500;
}

.money-select {
  width: 100px !important;
}

.apexcharts-legend-text {
  padding-left: 15px;
  margin-left: -15px;
}

.profile-interest .row {
  margin: 0 -0.0437rem;
}

.profile-interest .row .int-col {
  padding: 0 0.0437rem;
}

.profile-interest .row .int-col .interest-cat {
  margin-bottom: 0.0875rem;
  position: relative;
  display: block;
}

.profile-interest .row .int-col .interest-cat:after {
  background: #000;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

.profile-interest .row .int-col .interest-cat p {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0.3125rem;
  left: 0;
  margin: 0;
  z-index: 1;
  color: white;
  font-size: 0.075rem;
}

.post-input {
  margin-bottom: 1.875rem;
}

.post-input .form-control {
  font-weight: 400;
  margin: 0.9375rem 0;
}

.post-input .btn-social {
  font-size: 1.25rem;
  height: 3.4375rem;
  display: inline-block;
  padding: 0;
  text-align: center;
  border-radius: 0.5rem;
  color: #fff;
  width: 3.4375rem;
  line-height: 3.4375rem;
}

.post-input .btn-social.facebook {
  background-color: #3b5998;
}

.post-input .btn-social.google-plus {
  background-color: #de4e43;
}

.post-input .btn-social.linkedin {
  background-color: #007bb6;
}

.post-input .btn-social.instagram {
  background-color: #8a5a4e;
}

.post-input .btn-social.twitter {
  background-color: #1ea1f3;
}

.post-input .btn-social.youtube {
  background-color: #ce201f;
}

.post-input .btn-social.whatsapp {
  background-color: #01c854;
}

.post-input .btn-social i {
  margin: 0 !important;
}

.profile-uoloaded-post img {
  margin-bottom: 1.25rem;
}

.profile-uoloaded-post a h4,
.profile-uoloaded-post a .h4 {
  margin-bottom: 0.625rem;
  color: #464a53;
}

.prot-blog {
  padding: 20px;
  background-color: var(--primary);
  position: relative;
  overflow: hidden;
  z-index: 0;
  border-radius: 0.5rem;
}

@media only screen and (max-width: 74.9375rem) {
  .prot-blog {
    height: 100%;
  }
}

@media only screen and (max-width: 87.5rem) {
  .prot-blog {
    padding: 1.5rem 1.875rem 1.25rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .prot-blog {
    padding: 1rem;
  }
}

.prot-blog .upg {
  background-color: var(--secondary);
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0.5rem;
  color: #fff;
}

@media only screen and (max-width: 78.125rem) {
  .prot-blog .upg {
    padding: 5px 5px;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .prot-blog .upg {
    padding: 8px 17px;
  }
}

.prot-blog .post .text {
  font-size: 18px;
  font-weight: 500;
  color: white;
}

.prot-blog .fill .text {
  font-size: 20px;
  font-weight: 700;
  color: white;
  line-height: 35px;
}

.prot-blog .text-bla {
  color: white !important;
  font-size: 0.875rem;
  font-weight: 500;
}

@media only screen and (max-width: 87.5rem) {
  .prot-blog .text-bla {
    font-size: 0.75rem;
  }
}

.prot-blog p {
  color: white;
  font-weight: 400;
  font-size: 14px;
}

.prot-blog .shape {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: -1;
}

.prot-blog .shape svg path {
  fill: #3ab5ff;
}

.profile-card .card-header {
  border-color: var(--border);
  padding: 15px 30px;
}

.profile-card .card-header .title {
  position: relative;
  margin-bottom: 0;
  color: #5b5e81;
}

.profile-card .card-header .title:after {
  content: "";
  height: 3px;
  width: 100%;
  background-color: var(--primary);
  position: absolute;
  bottom: -15px;
  left: 0;
}

.profile-card .m-b30 {
  margin-bottom: 30px;
}

.profile-card .card-body {
  padding: 40px 40px 10px;
}

.profile-card .card-footer {
  padding: 35px 40px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-color: var(--border);
}

.profile-card .card-footer .btn-lg,
.profile-card .card-footer .btn-group-lg>.btn {
  padding: 18px 50px;
  font-size: 15px;
}

.profile-card .card-footer .btn-link {
  font-size: 15px;
}

@media only screen and (max-width: 35.9375rem) {
  .profile-card .card-header {
    padding: 25px;
  }

  .profile-card .card-header .title:after {
    bottom: -26px;
  }

  .profile-card .card-body {
    padding: 25px 25px 0;
  }

  .profile-card .card-footer {
    padding: 25px 25px 25px;
  }

  .profile-card .card-footer .btn-lg,
  .profile-card .card-footer .btn-group-lg>.btn {
    padding: 18px 30px;
  }
}

.author-profile {
  text-align: center;
}

.author-profile .card-body {
  padding: 0;
}

.author-profile .author-media {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
  display: inline-block;
}

.author-profile .author-media img {
  width: 130px;
  border-radius: 100%;
}

.author-profile .author-info .title {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
}

.author-profile .author-info span {
  display: block;
  color: #717579;
}

.author-profile .info-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  border-top: 1px solid var(--border);
  padding: 18px 30px;
  color: #5b5e81;
}

.author-profile .info-list li a {
  color: #5b5e81;
}

.author-profile .info-list li span {
  color: #717579;
  font-weight: 500;
}

.author-profile .card-footer {
  padding: 30px;
  display: block;
}

.author-profile .card-footer .form-control {
  height: 48px;
  border-color: #eeeeee;
  overflow: hidden;
}

[data-theme-version="dark"] .author-profile .card-footer .form-control {
  border-color: #2a3969;
}

.author-profile .upload-link {
  position: absolute;
  width: 35px;
  height: 35px;
  line-height: 32px;
  background: #395ee2;
  bottom: 0;
  right: 0px;
  box-shadow: 0 0 10px 0 rgba(0, 24, 128, 0.1);
  border-radius: 100%;
  color: #fff;
  overflow: hidden;
  border: 2px solid #fff;
}

.author-profile .upload-link .update-flie {
  position: absolute;
  opacity: 0;
  z-index: 0;
  width: 100%;
  cursor: pointer;
  left: 0;
  height: 100%;
}

.contacts {
  box-shadow: none;
}

.contacts .add {
  background-color: var(--primary);
  display: block;
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

a.btn.dz-load-more {
  padding: 0.69375rem 1.5rem;
  font-weight: 500;
}

.tags {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: none;
}

.tags .tag {
  display: inline-block;
  padding: 0.625rem 0.875rem;
  margin-bottom: 0.626rem;
  margin-right: 0.313rem;
  background: rgba(255, 255, 255, 0.1);
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 0.5rem;
  transition: 0.5s;
  color: #fff;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}

.tags .tag:hover {
  background: rgba(255, 255, 255, 0.3);
}

.tags::after {
  display: none;
}

.donut-chart-sale small,
.donut-chart-sale .small {
  font-size: 1.125rem;
  font-weight: 600;
}

@media only screen and (max-width: 35.9375rem) {
  .btn-edit {
    display: none;
  }
}

.dashboard-select.style-1 {
  background: transparent;
  border-color: #a098ae;
  font-weight: 500;
  color: #424562;
  margin: 0 1rem;
}

.dashboard-select.style-1:after {
  border-color: #a098ae;
  right: 0;
}

@media only screen and (max-width: 35.9375rem) {
  .dashboard-select.style-1 {
    height: 2.5rem;
    padding-left: 0.75rem;
    line-height: 2.8;
    font-size: 0.85rem;
  }
}

.page-titles .btn-group {
  height: 2.5rem;
}

.page-titles .btn-group button {
  line-height: 1.1;
}

@media only screen and (max-width: 25.125rem) {
  .page-titles .btn-group {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
}

.timeline-active li {
  position: relative;
  padding: 1.1rem 0;
}

.timeline-active li .time {
  padding: 0.5rem 0rem;
  display: block;
  position: relative;
  z-index: 0;
  font-size: 14px;
}

@media only screen and (max-width: 35.9375rem) {
  .timeline-active li .time {
    font-size: 0.75rem !important;
  }
}

.timeline-active li:before {
  top: 3.875rem;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 0.125rem;
  height: calc(100% - 1.875rem);
  left: 1.4625rem;
  margin-right: -0.0938rem;
  background: #eeee;
}

[data-theme-version="dark"] .timeline-active li:before {
  background: #2a3969;
}

.timeline-active li:last-child:before {
  content: none;
}

@media only screen and (max-width: 35.9375rem) {
  .timeline-active li {
    padding: 0.5rem 0;
    flex-wrap: wrap;
  }

  .timeline-active li:before {
    top: 3rem;
  }
}

.timeline-active .panel {
  margin-left: 1.125rem;
  flex: 1;
}

@media only screen and (max-width: 35.9375rem) {
  .timeline-active .panel {
    margin-left: 1rem;
  }
}

.timeline-active .panel .badge {
  margin-right: 0.625rem;
  height: 3rem;
  width: 3rem;
  min-width: 3rem;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 35.9375rem) {
  .timeline-active .panel .badge {
    height: 2.5rem;
    width: 2.5rem;
    min-width: 2.5rem;
    font-size: 1rem;
  }
}

.timeline-active .panel .timeline-panel h4,
.timeline-active .panel .timeline-panel .h4 {
  font-weight: 500;
  margin-right: 0.625rem;
  font-size: 1rem;
}

@media only screen and (max-width: 35.9375rem) {

  .timeline-active .panel .timeline-panel h4,
  .timeline-active .panel .timeline-panel .h4 {
    font-size: 0.875rem;
  }
}

.timeline-active .panel .timeline-panel h4 strong,
.timeline-active .panel .timeline-panel .h4 strong {
  font-weight: 500;
}

.timeline-active .modulel {
  display: inline-flex;
}

@media only screen and (max-width: 35.9375rem) {
  .timeline-active .modulel {
    padding: 0.5rem 0.5rem;
  }

  .timeline-active .modulel>div {
    align-items: center;
  }
}

.timeline-active .modulel .sub-module {
  background: #f9f9f9;
  padding: 0.8125rem 1.0625rem;
  border-radius: 0.625rem;
  margin: 1.0625rem 0;
}

@media only screen and (max-width: 35.9375rem) {
  .timeline-active .modulel .sub-module {
    margin: 0.4625rem 0;
  }
}

.timeline-active .modulel .sub-module h4,
.timeline-active .modulel .sub-module .h4 {
  line-height: 1;
  font-size: 0.75rem !important;
}

.timeline-active .modulel .sub-module span {
  font-size: 0.7rem;
}

.timeline-active .modulel .sub-module:hover {
  background: var(--primary);
}

.timeline-active .modulel .sub-module:hover span,
.timeline-active .modulel .sub-module:hover h4,
.timeline-active .modulel .sub-module:hover .h4 {
  color: #fff;
}

.timeline-active .modulel .sub-module:hover span svg path {
  fill: var(--primary);
}

.timeline-active.style-4 li {
  padding: 0.375rem 0;
}

.timeline-active.style-4 li:before {
  content: none;
}

.timeline-active.style-4 li .time-1 {
  color: #a098ae;
}

.timeline-active.style-4 li:hover .timeline-panel h4,
.timeline-active.style-4 li:hover .timeline-panel .h4 {
  color: var(--primary);
}

.timeline-active.style-4 .panel {
  display: flex;
  align-items: center;
}

.timeline-active.style-4 .panel .line-color {
  width: 0.25rem;
  height: 65%;
  border-radius: 4px;
  position: absolute;
}

.timeline-active.style-4 .panel .timeline-panel {
  margin-left: 1.25rem;
  display: block;
}

.widget-timeline .side-border {
  position: relative;
}

.widget-timeline .side-border:after {
  position: absolute;
  content: "";
  width: 100%;
  border-top: 0.0625rem solid #dbdbdb;
  top: 0.75rem;
  left: 0;
}

.widget-timeline .side-border h4,
.widget-timeline .side-border .h4 {
  background: white;
  position: relative;
  z-index: 2;
  padding-right: 1.25rem;
}

.widget-timeline .side-border .custom-dropdown {
  background: white;
  position: relative;
  z-index: 2;
  padding-left: 1.25rem;
  margin-top: -7px;
}

.widget-timeline .loadmore-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.widget-timeline .loadmore-btn button {
  width: 12.5rem;
}

.timeline-panel {
  margin-bottom: 0.5rem;
}

.timeline-list .dz-media {
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
}

.timeline-list .dz-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.timeline-list .modulel .dz-media {
  width: 16.875rem;
  height: 10.5rem;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
}

.timeline-list .modulel .dz-media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 74.9375rem) {
  .timeline-list .modulel .dz-media {
    width: 14rem;
    height: 10.5rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 61.9375rem) {
  .timeline-list .modulel .dz-media {
    width: 15rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
}

@media only screen and (max-width: 47.9375rem) {
  .timeline-list .modulel .dz-media {
    width: 14rem;
    height: 9rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .timeline-list .modulel .dz-media {
    width: 12rem;
    height: 8rem;
  }
}

@media only screen and (max-width: 35.9375rem) {
  .acti-space .default-select {
    display: none;
  }

  .acti-space .btn.light.btn-primary.btn-sm.mx-1,
  .acti-space .btn-group-sm>.btn.light.btn-primary.mx-1 {
    display: none;
  }
}

.cm-content-box {
  background: var(--card);
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.cm-content-box.filter {
  border-top: 0;
}

.cm-content-box .content-title {
  padding: 1rem 1.5rem;
  margin-bottom: 0;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
}

.cm-content-box .content-title .cpa {
  color: var(--text-dark);
  font-weight: 600;
  font-size: 1rem;
}

.cm-content-box .content-title .cpa i {
  color: var(--text);
}

@media only screen and (max-width: 35.9375rem) {
  .cm-content-box .content-title {
    padding: 1rem 1.2rem;
  }
}

.cm-content-box .content-title {
  border-top-left-radius: 0.425rem;
  border-top-right-radius: 0.425rem;
}

.cm-content-box.style-1 .content-title {
  border-radius: 0.5rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 1px solid var(--border);
}

.cm-content-box .tools a {
  display: inline-block;
}

.cm-content-box .tools a:hover i {
  opacity: 0.5;
}

.cm-content-box .tools a i {
  font-family: "Font Awesome 6 Free";
  font-weight: 600;
  color: #c2c2c2;
  font-size: 1.25rem;
}

.cm-content-box .tools a.expand {
  transform: rotate(-180deg);
}

.cm-content-box .cm-content-body {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top: 1px solid var(--border);
}

.cm-content-box .cm-content-body .table thead tr th .custm-select {
  height: 45px;
  background-color: transparent;
  border-radius: 0.5rem;
}

.cm-content-box .cm-content-body .table thead tr th .custm-select.input-small {
  width: 9.063rem !important;
}

.cm-content-box .cm-content-body .table thead tr th .form-control {
  border-radius: 0.5rem;
}

.cm-content-box .cm-content-body .table thead tr th .form-control.input-large {
  width: 20rem !important;
}

.cm-content-box .cm-content-body .table thead tr:nth-child(2) {
  background-color: #000;
  box-shadow: 10px 10px 8px #888888;
}

.cm-content-box .cm-content-body .table.table-striped th {
  padding: 1rem;
  color: #717579;
}

.cm-content-box .cm-content-body .table.table-striped td {
  padding: 1rem;
  white-space: nowrap;
}

.cm-content-box .cm-content-body .table.table-striped td:first-child {
  padding-left: 2rem;
}

.cm-content-box .cm-content-body .table.table-bordered thead {
  background-color: unset;
}

.cm-content-box .cm-content-body .table .btn {
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 0.313rem;
}

.cm-content-box .cm-content-body.excerpt .textarea {
  padding: 0.438rem 0.25rem;
}

.cm-content-box .cm-content-body.custom-fields .name-value {
  line-height: 2.375rem;
  font-size: 1rem;
  text-align: center;
}

.cm-content-box .cm-content-body .contact {
  border-radius: 0.5rem !important;
}

.cm-content-box .cm-content-body .collapse.show {
  margin-bottom: 0.625rem;
}

.contents-list .btn-icon-only {
  height: 2.25rem;
  width: 2.25rem;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  line-height: 1;
}

.contents-list .btn-icon-only i {
  font-size: 1rem;
}

.contents-list .table tbody tr td {
  padding: 0.4375rem 0.625rem;
}

.fatch-date {
  display: block;
}

.new-scroll {
  background-color: var(--body-bg);
  padding: 0.625rem;
  height: 9.375rem;
  overflow-y: auto;
  border-radius: 0.5rem;
}

.content-icon {
  width: 2.188rem;
  height: 2.188rem;
  display: inline-block;
  text-align: center;
  line-height: 2.125rem;
  font-size: 0.625rem;
  padding: 0px;
}

.dd-handle.move-media {
  padding: 0.5rem 1rem;
  display: flex;
  margin: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 100%;
  border: 0;
  z-index: 2;
  background: none;
  position: absolute;
  background-color: var(--rgba-primary-8);
  top: 0;
  font-size: 1.25rem;
  left: 0;
  align-items: center;
}

.dd-handle.move-media+.accordion-button {
  padding-left: 4.063rem;
  background: var(--card);
  color: var(--text-dark);
  position: relative;
  z-index: 1;
}

.cm-content-box .dd-list {
  margin-top: 20px;
}

.dd-dragel .accordion-item.dd-item.menu-ac-item {
  background-color: var(--card);
  border-radius: 0.5rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.dd-dragel .accordion-item.dd-item.menu-ac-item .dd-list {
  padding-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
}

.text-hover:hover {
  color: var(--primary);
}

.accordion-item.dd-item.menu-ac-item .dd-list {
  margin-top: 10px;
}

.menu-ac-item {
  position: relative;
}

.menu-ac-item .dd-collapse {
  height: 2.8rem;
  width: 3.338rem;
  border: 1px solid #d3d3d3;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  font-size: 1.75rem;
  color: black;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.menu-ac-item .dd-collapse+.accordion {
  margin-left: 3.25rem;
}

@media only screen and (max-width: 1600px) {
  .menu-ac-item .dd-collapse {
    height: 3rem;
    width: 3rem;
  }
}

.menu-ac-item .dd-expand {
  display: none;
}

.dz-menu-btn {
  margin-top: 0.625rem;
}

@media only screen and (max-width: 1600px) {
  .dz-menu-btn {
    margin-top: 0;
    margin-left: 0.313rem;
  }
}

@media only screen and (max-width: 1480px) {
  .dz-menu-btn {
    margin-top: 0.625rem;
    margin-left: 0;
  }
}

.dz-terms {
  border: 1px solid var(--border);
  border-radius: 0.5rem;
  padding: 0.625rem;
}

/* menu-scss */
.tab-my.nav-tabs {
  border-bottom: unset;
}

.tab-my.nav-tabs .nav-link {
  background-color: var(--light);
  border-radius: 0.5rem;
  border: 1px solid transparent;
  margin: 8px 2px;
  color: var(--text-dark);
}

.tab-my.nav-tabs .nav-link.active {
  background-color: var(--primary);
  color: #fff;
}

.menu-tabs {
  border: 1px solid var(--border);
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 0.75rem;
  z-index: 1;
  top: 0.625rem;
}

.avatar-upload .avatar-preview {
  border: 1px solid var(--border);
  padding: 0.5rem;
  margin-bottom: 0.625rem;
  border-radius: 0.5rem;
}

.avatar-upload .avatar-preview>div {
  display: block;
  width: 100%;
  height: 7.5rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.main-check {
  background-color: var(--card);
  border: 1px solid var(--rgba-primary-5);
  padding: 1.5rem 1.5rem 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
}

@media only screen and (max-width: 35.9375rem) {
  .main-check {
    padding: 1rem 1rem 0.5rem;
  }
}

/* view-content */
.view-bottom {
  flex-direction: column;
  justify-content: end;
  height: 88%;
  display: flex;
}

/* add-email */
.dd .accordion-button:not(.collapsed) {
  box-shadow: unset;
}

.dd .accordion-body {
  border: 1px solid var(--border);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top: 0;
}

@media only screen and (max-width: 35.9375rem) {
  .dd .dd-list {
    padding-left: 0;
  }
}

.collapse {
  border-radius: 0.5rem;
}

.publish-drop ul:hover {
  background-color: unset;
}

input.form-control {
  position: relative;
}

@media only screen and (max-width: 74.9375rem) {
  .input-number {
    height: 2.5rem;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.right-sidebar-sticky {
  position: sticky;
  top: 7.5rem;
}

.content-title.box-primary,
.content-title.box-secondary,
.content-title.box-success,
.content-title.box-danger,
.content-title.box-warning,
.content-title.box-info,
.content-title.box-dark,
.content-title.box-light {
  background-color: var(--primary);
}

.content-title.box-primary .cpa,
.content-title.box-primary i,
.content-title.box-secondary .cpa,
.content-title.box-secondary i,
.content-title.box-success .cpa,
.content-title.box-success i,
.content-title.box-danger .cpa,
.content-title.box-danger i,
.content-title.box-warning .cpa,
.content-title.box-warning i,
.content-title.box-info .cpa,
.content-title.box-info i,
.content-title.box-dark .cpa,
.content-title.box-dark i,
.content-title.box-light .cpa,
.content-title.box-light i {
  color: #fff !important;
}

.content-title.box-secondary {
  background-color: var(--secondary);
}

.content-title.box-success {
  background-color: #3ab67a;
}

.content-title.box-danger {
  background-color: #fd5353;
}

.content-title.box-warning {
  background-color: #ffab2d;
}

.content-title.box-info {
  background-color: #00ada3;
}

.content-title.box-dark {
  background-color: #5b5e81;
}

.content-title.box-light {
  background-color: #dedeed;
}

.list-style-1.block>li {
  display: block;
}

.list-style-1>li {
  border-bottom: 1px dashed var(--border);
  padding: 0.75rem 0;
  display: flex;
  align-items: center;
}

.remove:hover {
  color: #fd5353;
}

.accord-data .menu-accord .accordion-button {
  padding-left: 4.1rem;
  box-shadow: none;
}

.accord-data .menu-accord .nestable-list {
  padding-left: 0;
}

.accord-data .menu-ac-item .nestable-list.nestable-group {
  padding-left: 0;
}

.accord-data .menu-ac-item .accordion-body {
  border: 1px solid var(--border);
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top: 0;
  margin-top: -5px;
}

.cms-double-select .css-13cymwt-control {
  background-color: transparent;
  border-color: var(--border);
}

/*
===================================
    Product detail
===================================*/
.produtct-detail-tag {
  display: inline-block;
}

.produtct-detail-tag a {
  font-style: 0.8125rem;
  color: #5b5e81;
}

.product-detail-content .item-tag {
  background: #828690;
  border-radius: 0.375rem;
  display: inline-block;
  font-size: 0.75rem;
  margin-right: 0.25rem;
  padding: 0.125rem 0.75rem;
  color: #fff;
}

.filtaring-area h4,
.filtaring-area .h4 {
  color: var(--text-dark);
  font-size: 1rem;
  font-weight: 400;
  text-transform: lowercase;
}

.plus-minus-input .input-icon {
  font-size: 0.8125rem;
  color: #5b5e81;
}

.plus-minus-input {
  display: flex;
  width: 7.5rem;
}

.plus-minus-input .custom-btn {
  border-radius: 0;
  height: 2.5rem;
  padding: 0.75rem 0.5rem;
  background: #fff;
  border: 0.0625rem solid #e6e6e6;
}

.plus-minus-input .form-control:hover,
.plus-minus-input .form-control:focus,
.plus-minus-input .form-control:active {
  border: 0.0625rem solid #e6e6e6;
}

.btn-reveal-trigger .avatar-xl {
  min-width: 1.875rem;
}

.share-view {
  display: inline-block;
}

.share-view ul li {
  display: inline-block;
}

.share-view .share-icon {
  width: 2.5rem;
  height: 2.5rem;
  display: inline-block;
  border: 0.0625rem solid #e6e6e6;
  text-align: center;
  line-height: 2.5rem;
  font-style: 1rem;
  color: #e6e6e6;
  margin-right: 0.5rem;
}

/*--------tab----------*/
.veritical-line {
  padding: 1.25rem 1.875rem;
  border-top: 0.0625rem solid #e6e6e6;
  border-right: 0.0625rem solid #e6e6e6;
  border-bottom: 0.0625rem solid #e6e6e6;
  position: relative;
}

.veritical-line:before {
  background: #e6e6e6;
  bottom: 0;
  content: "";
  height: 100%;
  left: -0.0625rem;
  max-height: 40%;
  position: absolute;
  width: 0.0625rem;
}

.tab-content-text p {
  color: #5b5e81;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin-bottom: 1.5625rem;
}

.tab-item-list li a {
  background: #fff;
  border-top: 0.0625rem solid #e6e6e6;
  border-left: 0.0625rem solid #e6e6e6;
  border-right: 0.0625rem solid #e6e6e6;
  color: #5b5e81;
  display: block;
  font-size: 1rem;
  padding: 1rem;
  text-transform: uppercase;
}

.tab-item-list li a:hover,
.tab-item-list li a:focus {
  background: #fff;
  color: #5b5e81;
  border-right: 0rem;
}

.tab-item-list li:last-child {
  border-bottom: 0.0625rem solid #e6e6e6;
}

.tab-list li {
  margin-bottom: 0.4375rem;
  font-size: 0.8125rem;
}

.tab-list li i {
  font-size: 0.8125rem;
  margin-right: 0.875rem;
}

.nav-tabs.slide-item-list {
  text-align: center;
  margin: 0 -0.3125rem;
  border: 0;
}

.nav-tabs.slide-item-list li {
  display: inline-block;
  flex: 0 0 25%;
  width: 25%;
  padding: 0 0.3125rem;
}

.nav-tabs.slide-item-list li .nav-link {
  display: inline-block;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  background: transparent;
  padding-bottom: 0rem;
  border: 0;
}

.nav-tabs.slide-item-list li .nav-link:hover,
.nav-tabs.slide-item-list li .nav-link:focus {
  background: transparent;
}

.nav-tabs.slide-item-list li .nav-link img {
  width: 100%;
}

.nav-tabs.slide-item-list li .nav-link.active {
  border: 0;
  background-color: transparent;
}

.product-detail-text {
  padding-top: 1.75rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  padding-bottom: 4.375rem;
}

.star-rating .product-review {
  font-style: 0.8125rem;
  color: #5b5e81;
  font-weight: 400;
  text-decoration: underline !important;
}

.product-detail .tab-content img {
  display: inline-block;
  width: 100%;
}

.popular-tag ul {
  margin: 0rem;
  padding: 0rem;
}

.popular-tag ul li {
  display: inline-block;
  padding: 0.5rem 0.9375rem;
  background: #f8f8f8;
  font-size: 0.8125rem;
  color: #fff;
  margin-right: 0.625rem;
  margin-bottom: 0.625rem;
}

.size-filter ul li {
  display: inline-block;
}

.intro {
  border: 0.0625rem solid red;
  color: #1d1d1d;
}

#listResults .slider {
  margin: 1.5625rem 0;
}

#listResults .slider-box {
  width: 90%;
  margin: 1.5625rem auto;
}

#listResults input {
  width: 10%;
}

#listResults label {
  border: none;
  display: inline-block;
  margin-right: -0.25rem;
  vertical-align: top;
  width: 30%;
}

.plus-minus-input .input-icon {
  font-size: 0.8125rem;
  color: #aaaaaa;
}

.plus-minus-input .custom-btn {
  border-radius: 0;
  height: 2.5rem;
  padding: 0.5rem 0.75rem;
  background: #ffffff;
  border: 0.0625rem solid #c8c8c8;
}

.plus-minus-input .custom-btn:hover,
.plus-minus-input .custom-btn:focus,
.plus-minus-input .custom-btn.active {
  box-shadow: none;
  outline: none;
}

.plus-minus-input .form-control {
  height: 2.5rem;
  border-top: 0.0625rem solid #c8c8c8;
  border-bottom: 0.0625rem solid #c8c8c8;
  border-left: 0rem solid #c8c8c8;
  border-right: 0.0625rem solid #c8c8c8;
}

.plus-minus-input .form-control:hover,
.plus-minus-input .form-control:focus,
.plus-minus-input .form-control:active {
  border-top: 0.0625rem solid #c8c8c8;
  border-bottom: 0.0625rem solid #c8c8c8;
  border-left: 0rem solid #c8c8c8;
  border-right: 0rem solid #c8c8c8;
}

.new-arrival-product .new-arrivals-img-contnent {
  overflow: hidden;
  position: relative;
}

.new-arrival-product .new-arrivals-img-contnent img {
  width: 100%;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 0.5rem;
}

.new-arrival-product .new-arrivals-img-contnent:after {
  content: "";
  position: absolute;
  top: 0;
  left: -85%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.new-arrival-product:hover .new-arrivals-img-contnent:after {
  webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

.star-rating {
  margin-bottom: 0.625rem;
}

.select-size .btn:not(:first-child),
.select-size .btn:not(last-child) {
  margin: 0 5px;
  box-shadow: none;
}

@media only screen and (max-width: 74.9375rem) {

  .select-size .btn:not(:first-child),
  .select-size .btn:not(last-child) {
    margin-left: 0;
  }
}

.product-detail.nav-tabs {
  border: 0;
  justify-content: center;
  margin-top: 1rem;
}

.product-detail.nav-tabs .nav-link active {
  border: 0;
  margin-top: 1rem;
}

.product-detail.nav-tabs .nav-link {
  border: 0;
  padding: 0;
  background-color: transparent;
}

/*
===================================
    list view
===================================*/
.new-arrival-content .item {
  font-size: 0.75rem;
  color: #5b5e81;
}

.new-arrival-content h4,
.new-arrival-content .h4 {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.625rem;
}

.new-arrival-content .price {
  font-weight: 500;
  color: var(--primary);
  font-size: 1.25rem;
  margin-bottom: 0;
  float: right;
}

@media only screen and (max-width: 35.9375rem) {
  .new-arrival-content .price {
    float: none;
    margin-top: 0.625rem;
  }
}

.new-arrival-content .discount {
  font-size: 0.875rem;
  margin-right: 0.5rem;
  opacity: 0.8;
  color: #fd5353;
}

.new-arrival-content p {
  font-size: 0.875rem;
  color: #7e8dbf;
  margin-bottom: 0.375rem;
  line-height: 1.5rem;
}

.new-arrival-content .text-content {
  margin-top: 1.125rem;
}

.new-arrival-content.text-center .price {
  float: unset !important;
}

.success-icon {
  color: #3ab67a;
  font-size: 1rem;
}

.comment-review {
  margin-bottom: 0.9375rem;
  display: table;
  width: 100%;
}

.comment-review .client-review {
  color: #7e8dbf;
  padding-right: 1.25rem;
  text-decoration: underline !important;
  font-size: 0.875rem;
}

.comment-review .span {
  color: #7e8dbf;
  font-size: 0.875rem;
}

@media only screen and (max-width: 35.9375rem) {
  .comment-review {
    margin-bottom: 0;
  }
}

.star-rating li {
  display: inline-block;
}

.star-rating li i {
  color: gold;
}

[data-theme-version="dark"] {
  --nav-headbg: #18254f;
  --sidebar-bg: #18254f;
  --headerbg: #3736af;
  --card: #18254f;
  --text-dark: #fff;
  --text-gray: #b3b3b3;
  --text: #8896b9;
  --body-bg: #09153f;
  --light: #09153f;
  --border: #2a3969;
  --bs-border-color: #2a3969;
  --bs-tertiary-bg: #2a3969;
}

[data-theme-version="dark"][data-primary="color_1"],
[data-theme-version="dark"][data-primary="color_3"] {
  --primary: #7978ff;
  --rgba-primary-1: rgba(121, 120, 255, 0.1);
  --rgba-primary-2: rgba(121, 120, 255, 0.2);
  --rgba-primary-3: rgba(121, 120, 255, 0.3);
  --rgba-primary-4: rgba(121, 120, 255, 0.4);
  --rgba-primary-5: rgba(121, 120, 255, 0.5);
  --rgba-primary-6: rgba(121, 120, 255, 0.6);
  --rgba-primary-7: rgba(121, 120, 255, 0.7);
  --rgba-primary-8: rgba(121, 120, 255, 0.8);
  --rgba-primary-9: rgba(121, 120, 255, 0.9);
}

[data-theme-version="dark"] a {
  color: #fff;
}

[data-theme-version="dark"] a:hover {
  color: #fff;
}

[data-theme-version="dark"] .form-control.custom-image-select-2.bit {
  background-color: #09153f;
}

[data-theme-version="dark"] .brand-logo.invoice-logo .logo-abbr {
  filter: brightness(10.5);
}

[data-theme-version="dark"] .morris_chart_height text tspan {
  fill: #fff;
}

[data-theme-version="dark"] .apexcharts-gridlines-horizontal line {
  stroke: #2a3969;
}

[data-theme-version="dark"] .stacked-2:after {
  background-color: #18254f;
}

[data-theme-version="dark"] .picker .picker__frame .picker__box .picker__footer .picker__button--clear,
[data-theme-version="dark"] .picker .picker__frame .picker__box .picker__footer .picker__button--close,
[data-theme-version="dark"] .picker .picker__frame .picker__box .picker__footer .picker__button--today {
  color: #fff;
}

[data-theme-version="dark"] .apexcharts-xaxis line {
  stroke: #2a3969;
}

[data-theme-version="dark"] .ct-grid {
  stroke: rgba(255, 255, 255, 0.1);
}

[data-theme-version="dark"] .custom-react-select>div:nth-child(4)>div {
  border: 2px solid var(--border) !important;
}

[data-theme-version="dark"] .css-b62m3t-container>div {
  border-color: var(--border);
  background: var(--card);
}

[data-theme-version="dark"] .css-b62m3t-container [class*="-singleValue"],
[data-theme-version="dark"] .css-b62m3t-container [class*="-placeholder"] {
  color: var(--text);
}

[data-theme-version="dark"] .css-b62m3t-container [class*="-multiValue"] {
  background: var(--card);
}

[data-theme-version="dark"] .css-b62m3t-container [class*="-multiValue"] div {
  color: var(--text);
}

[data-theme-version="dark"] .css-b62m3t-container [class*="-IndicatorsContainer"] div {
  color: var(--text);
}

[data-theme-version="dark"] .css-b62m3t-container [class*="-menu"] {
  background: var(--card);
}

[data-theme-version="dark"] .css-b62m3t-container [class*="-menu"]>div>*:hover {
  background: var(--card);
}

[data-theme-version="dark"] .sidebar-right .sidebar-right-inner h4,
[data-theme-version="dark"] .sidebar-right .sidebar-right-inner .h4 {
  background-color: var(--card);
  color: #fff !important;
}

[data-theme-version="dark"] .sidebar-right .tab-content {
  background: var(--card);
}

[data-theme-version="dark"] .sidebar-right .tab-content .tab-pane .admin-settings p {
  color: var(--text);
}

[data-theme-version="dark"] .sidebar-right .sidebar-right-inner .admin-title {
  color: var(--text) !important;
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs {
  background: var(--card);
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link {
  background: var(--card);
  color: var(--text);
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link.active {
  background: var(--card);
}

[data-theme-version="dark"] .daterangepicker {
  background-color: var(--card);
  border-color: var(--border);
  color: var(--text);
}

[data-theme-version="dark"] .table.dataTable.display>tbody>tr>td {
  border-color: rgba(225, 225, 225, 0.15) !important;
}

[data-theme-version="dark"] .fc .fc-daygrid-day.fc-day-today {
  background-color: var(--card);
}

[data-theme-version="dark"] .fc-timegrid-slot fc-timegrid-slot-lane {
  background-color: var(--card);
}

[data-theme-version="dark"] .react-datepicker {
  background-color: var(--card);
  color: #fff;
  border-color: var(--border);
}

[data-theme-version="dark"] .react-datepicker .react-datepicker__current-month {
  color: #fff;
}

[data-theme-version="dark"] .react-datepicker .react-datepicker__header {
  background-color: var(--card);
  border-color: var(--border);
}

[data-theme-version="dark"] .react-datepicker .react-datepicker__day-name,
[data-theme-version="dark"] .react-datepicker .react-datepicker__day {
  color: #fff;
}

[data-theme-version="dark"] .react-datepicker .react-datepicker__navigation-icon {
  top: 6px;
}

[data-theme-version="dark"] .nav-header .brand-title path {
  fill: var(--text-dark);
}

[data-theme-version="dark"] .nav-header .brand-title path {
  fill: var(--text-dark);
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs {
  border-color: var(--rgba-primary-1) !important;
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link {
  color: var(--text) !important;
}

[data-theme-version="dark"] .sidebar-right .card-tabs .nav-tabs .nav-item .nav-link.active {
  background-color: var(--rgba-primary-1);
}

[data-theme-version="dark"] .sidebar-right .form-control {
  background: #fff;
  color: #000;
  border-color: #eeeeee;
}

[data-theme-version="dark"] .sidebar-right .default-select .list {
  background: #fff;
}

[data-theme-version="dark"] .sidebar-right .default-select .list .option.selected,
[data-theme-version="dark"] .sidebar-right .default-select .list .option.focus,
[data-theme-version="dark"] .sidebar-right .default-select .list .option:hover {
  background: rgba(0, 0, 0, 0.05) !important;
}

[data-theme-version="dark"] .sidebar-right .nice-select .option {
  background: #fff;
}

.relative {
  position: relative;
}

.brand-logo img {
  width: -webkit-fill-available;
}