.admin .is-button[data-v-71bb21a6] {
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px
}

.admin .is-button[data-v-71bb21a6]:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color), var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color), 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    outline-color: #cbd5e1;
    outline-style: dashed
}
.bg-primary-400a{
    background-color:#8b5cf6!important;
    /* color:#8b5cf6 !important */
    color:white !important
}

.logo-to-show2,
.logo-to-show {
    width: 300px !important;
    display: block;
    margin: 10px auto;
}

.menu-toggle .for-dk {
    display: none !important;
}

.for-dk {
    display: block;
}

.menu-toggle .for-ml {
    display: block !important;
}

.for-ml {
    display: none
}

@media (max-width: 767px) {
    .for-dk {
        display: none !important;
    }

    .for-ml {
        display: block !important;
    }
}

.admin [data-v-71bb21a6]:is(.dark .is-button:focus-within) {
    outline-color: #475569
}

.admin .is-button[data-v-71bb21a6]:focus-visible {
    outline-width: 2px
}

.admin .is-button[data-v-71bb21a6] {
    align-items: center;
    display: inline-flex;
    height: 2.5rem;
    justify-content: center;
    position: relative
}

.admin .is-button[data-v-71bb21a6]>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-left: .25rem;
    margin-left: calc(.25rem*(1 - var(--tw-space-x-reverse)));
    margin-right: 0;
    margin-right: calc(.25rem*var(--tw-space-x-reverse))
}

.admin .is-button[data-v-71bb21a6] {
    font-family: Roboto Flex, sans-serif;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.25rem;
    padding: .5rem 1.25rem;
    text-decoration-line: none;
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.admin .is-button[data-v-71bb21a6]:hover {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.admin .is-button[data-v-71bb21a6]:disabled {
    cursor: not-allowed;
    opacity: .6
}

.admin .is-button-condensed[data-v-71bb21a6] {
    outline-color: transparent;
    outline-offset: 2px;
    outline-style: dashed;
    outline-width: 1px
}

.admin .is-button-condensed[data-v-71bb21a6]:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color), var(--tw-ring-inset) 0 0 0 calc(var(--tw-ring-offset-width)) var(--tw-ring-color), 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    outline-color: #cbd5e1;
    outline-style: dashed
}

.admin [data-v-71bb21a6]:is(.dark .is-button-condensed:focus-within) {
    outline-color: #475569
}

.admin .is-button-condensed[data-v-71bb21a6]:focus-visible {
    outline-width: 2px
}

.admin .is-button-condensed[data-v-71bb21a6] {
    align-items: center;
    display: inline-flex;
    height: 2rem;
    justify-content: center;
    position: relative
}

.admin .is-button-condensed[data-v-71bb21a6]>:not([hidden])~:not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-left: .25rem;
    margin-left: calc(.25rem*(1 - var(--tw-space-x-reverse)));
    margin-right: 0;
    margin-right: calc(.25rem*var(--tw-space-x-reverse))
}

.admin .is-button-condensed[data-v-71bb21a6] {
    font-family: Roboto Flex, sans-serif;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.25rem;
    padding: .25rem 1rem;
    text-decoration-line: none;
    transition-duration: .3s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1)
}

.admin .is-button-condensed[data-v-71bb21a6]:hover {
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.admin .is-button-condensed[data-v-71bb21a6]:disabled {
    cursor: not-allowed;
    opacity: .6
}

.admin .is-button-default[data-v-71bb21a6] {
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: #fff;
    background-color: rgb(255 255 255/var(--tw-bg-opacity));
    border-color: #cbd5e1;
    border-color: rgb(203 213 225/var(--tw-border-opacity));
    border-width: 1px;
    color: #334155;
    color: rgb(51 65 85/var(--tw-text-opacity))
}

.admin .is-button-default[data-v-71bb21a6]:focus-visible {
    --tw-bg-opacity: 1;
    background-color: #f8fafc;
    background-color: rgb(248 250 252/var(--tw-bg-opacity))
}

.admin .is-button-default[data-v-71bb21a6]:enabled:hover {
    --tw-bg-opacity: 1;
    background-color: #f8fafc;
    background-color: rgb(248 250 252/var(--tw-bg-opacity))
}

.admin .is-button-default[data-v-71bb21a6]:enabled:active {
    --tw-bg-opacity: 1;
    background-color: #fff;
    background-color: rgb(255 255 255/var(--tw-bg-opacity))
}

.admin [data-v-71bb21a6]:is(.dark .is-button-default) {
    --tw-border-opacity: 1;
    --tw-bg-opacity: 1;
    --tw-text-opacity: 1;
    background-color: #334155;
    background-color: rgb(51 65 85/var(--tw-bg-opacity));
    border-color: #475569;
    border-color: rgb(71 85 105/var(--tw-border-opacity));
    color: #fff;
    color: rgb(255 255 255/var(--tw-text-opacity))
}

.admin [data-v-71bb21a6]:is(.dark .is-button-default:focus-visible) {
    --tw-bg-opacity: 1;
    background-color: #475569;
    background-color: rgb(71 85 105/var(--tw-bg-opacity))
}

.admin [data-v-71bb21a6]:is(.dark .is-button-default:enabled:hover) {
    --tw-bg-opacity: 1;
    background-color: #475569;
    background-color: rgb(71 85 105/var(--tw-bg-opacity))
}

.admin [data-v-71bb21a6]:is(.dark .is-button-default:enabled:active) {
    --tw-bg-opacity: 1;
    background-color: #334155;
    background-color: rgb(51 65 85/var(--tw-bg-opacity))
}