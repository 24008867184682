body {
  --wp--preset--color--black: #000000;
  --wp--preset--color--cyan-bluish-gray: #abb8c3;
  --wp--preset--color--white: #ffffff;
  --wp--preset--color--pale-pink: #f78da7;
  --wp--preset--color--vivid-red: #cf2e2e;
  --wp--preset--color--luminous-vivid-orange: #ff6900;
  --wp--preset--color--luminous-vivid-amber: #fcb900;
  --wp--preset--color--light-green-cyan: #7bdcb5;
  --wp--preset--color--vivid-green-cyan: #00d084;
  --wp--preset--color--pale-cyan-blue: #8ed1fc;
  --wp--preset--color--vivid-cyan-blue: #0693e3;
  --wp--preset--color--vivid-purple: #9b51e0;
  --wp--preset--color--theme-palette-1: #09be8b;
  --wp--preset--color--theme-palette-2: #fb4e4e;
  --wp--preset--color--theme-palette-3: #ffffff;
  --wp--preset--color--theme-palette-4: #2d3748;
  --wp--preset--color--theme-palette-5: #cdcfd4;
  --wp--preset--color--theme-palette-6: #a6a6a6;
  --wp--preset--color--theme-palette-7: #104441;
  --wp--preset--color--theme-palette-8: #1e2738;
  --wp--preset--color--theme-palette-9: #141a28;
  --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg,
      rgba(6, 147, 227, 1) 0%,
      rgb(155, 81, 224) 100%);
  --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg,
      rgb(122, 220, 180) 0%,
      rgb(0, 208, 130) 100%);
  --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg,
      rgba(252, 185, 0, 1) 0%,
      rgba(255, 105, 0, 1) 100%);
  --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg,
      rgba(255, 105, 0, 1) 0%,
      rgb(207, 46, 46) 100%);
  --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg,
      rgb(238, 238, 238) 0%,
      rgb(169, 184, 195) 100%);
  --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg,
      rgb(74, 234, 220) 0%,
      rgb(151, 120, 209) 20%,
      rgb(207, 42, 186) 40%,
      rgb(238, 44, 130) 60%,
      rgb(251, 105, 98) 80%,
      rgb(254, 248, 76) 100%);
  --wp--preset--gradient--blush-light-purple: linear-gradient(135deg,
      rgb(255, 206, 236) 0%,
      rgb(152, 150, 240) 100%);
  --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg,
      rgb(254, 205, 165) 0%,
      rgb(254, 45, 45) 50%,
      rgb(107, 0, 62) 100%);
  --wp--preset--gradient--luminous-dusk: linear-gradient(135deg,
      rgb(255, 203, 112) 0%,
      rgb(199, 81, 192) 50%,
      rgb(65, 88, 208) 100%);
  --wp--preset--gradient--pale-ocean: linear-gradient(135deg,
      rgb(255, 245, 203) 0%,
      rgb(182, 227, 212) 50%,
      rgb(51, 167, 181) 100%);
  --wp--preset--gradient--electric-grass: linear-gradient(135deg,
      rgb(202, 248, 128) 0%,
      rgb(113, 206, 126) 100%);
  --wp--preset--gradient--midnight: linear-gradient(135deg,
      rgb(2, 3, 129) 0%,
      rgb(40, 116, 252) 100%);
  --wp--preset--duotone--dark-grayscale: url("#wp-duotone-dark-grayscale");
  --wp--preset--duotone--grayscale: url("#wp-duotone-grayscale");
  --wp--preset--duotone--purple-yellow: url("#wp-duotone-purple-yellow");
  --wp--preset--duotone--blue-red: url("#wp-duotone-blue-red");
  --wp--preset--duotone--midnight: url("#wp-duotone-midnight");
  --wp--preset--duotone--magenta-yellow: url("#wp-duotone-magenta-yellow");
  --wp--preset--duotone--purple-green: url("#wp-duotone-purple-green");
  --wp--preset--duotone--blue-orange: url("#wp-duotone-blue-orange");
  --wp--preset--font-size--small: 13px;
  --wp--preset--font-size--medium: 20px;
  --wp--preset--font-size--large: 36px;
  --wp--preset--font-size--x-large: 42px;
  --wp--preset--spacing--20: 0.44rem;
  --wp--preset--spacing--30: 0.67rem;
  --wp--preset--spacing--40: 1rem;
  --wp--preset--spacing--50: 1.5rem;
  --wp--preset--spacing--60: 2.25rem;
  --wp--preset--spacing--70: 3.38rem;
  --wp--preset--spacing--80: 5.06rem;
  --wp--preset--shadow--natural: 6px 6px 9px rgba(0, 0, 0, 0.2);
  --wp--preset--shadow--deep: 12px 12px 50px rgba(0, 0, 0, 0.4);
  --wp--preset--shadow--sharp: 6px 6px 0px rgba(0, 0, 0, 0.2);
  --wp--preset--shadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1),
    6px 6px rgba(0, 0, 0, 1);
  --wp--preset--shadow--crisp: 6px 6px 0px rgba(0, 0, 0, 1);
}

.css-v57kt1 {
  --Grid-columns: 12;
  --Grid-columnSpacing: 24px;
  --Grid-rowSpacing: 24px;
  flex-flow: wrap;
  min-width: 0px;
  box-sizing: border-box;
  display: flex;
  margin: calc(var(--Grid-rowSpacing) / -2) calc(var(--Grid-columnSpacing) / -2);
}

.css-1m1aas1 {
  -webkit-box-flex: 0;
  flex-grow: 0;
  flex-basis: auto;
  width: calc(100% * 12 / var(--Grid-columns));
  min-width: 0px;
  box-sizing: border-box;
  padding: calc(var(--Grid-rowSpacing) / 2) calc(var(--Grid-columnSpacing) / 2);
}

.nas {
  background-color: transparent;
  border: none;
  outline: none;
}

[data-theme-version="dark"] .majd {
  fill: white !important;
  stroke: white !important;
}

[data-theme-version="dark"] .majd2 {
  stroke: white !important;
}

.majd2 {
  stroke: rgb(0, 0, 0) !important;
}

.p-4 {
  padding: 1rem;
}

.justify-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}

.flex {
  display: flex;
}

.dark .svga svg path {}

.css-1gq5e6f {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid rgb(45, 55, 72);
  border-radius: 8px;
}

.css-4u2is6 {
  display: flex;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
}

.css-drtivy {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  width: 40px;
  height: 40px;

  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  background-color: transparent;
  color: rgb(255, 255, 255);
}

.css-4u2is6> :not(style)~ :not(style) {
  margin: 0px 0px 0px 16px;
}

.css-1msa3n8 {
  display: flex;
  flex-direction: column;
}

.css-15udru3 {
  margin: 0px;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.57;

  color: rgb(160, 174, 192);
}

.css-1msa3n8> :not(style)~ :not(style) {
  margin: 4px 0px 0px;
}

.space-apas {
  justify-content: space-between;
  align-items: center;
}

.css-1oklce5 {
  margin: 0px;
  font-size: 1rem;
  line-height: 1.57;

  font-weight: 500;
}

.swapas {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-top: 20px;
}

.img30 {
  width: 30px;
  margin-right: 8px;
}

.jas {
  justify-content: end;
  gap: 15px;
}

.jas svg {
  cursor: pointer;
}

.tbleas th,
.tbleas td {
  /* display: flex; */
  text-align: center;
  justify-content: center;
}

.tleft {
  text-align: left !important;
}

.col {
  flex-direction: column;
}

.axs {
  display: flex;
  justify-content: space-between;
}

.axs button {
  width: 50%;
}

/*  */
.bgact {
  --tw-bg-opacity: 1;
  background-color: var(--primary);
  /* background-color: rgb(14 165 233 / var(--tw-bg-opacity)); */

  --tw-text-opacity: 1;
  color: #fff;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  font-size: 0.75rem;
  line-height: 1rem;
  border-radius: 0.375rem;
  padding-bottom: 0.375rem;
  padding-top: 0.375rem;
}

@media (min-width: 768px) {
  .modal89 .modal-dialog {
    min-width: 700px !important;
  }
}

.lks {
  text-align: right !important;
}

/* Staking */
.css-jddaxh {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.css-1tz8m30 {
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;
  margin-top: -32px;
  width: calc(100% + 32px);
  margin-left: -32px;
}

/*  */
.css-1tz8m30>.MuiGrid-item {
  padding-left: 32px;
}

.tracking-wider {
  font-size: 20px !important;
}

.css-1tz8m30>.MuiGrid-item {
  padding-top: 32px;
}

.css-i9p3im {
  box-sizing: border-box;
  margin: 0px;
  flex-direction: row;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  flex-grow: 0;
  max-width: 100%;
}

.css-l43idd.MuiPaper-elevation1 {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 22px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 0.5px;
}

.css-l43idd {
  background-color: transparent;
  /* color: rgb(237, 242, 247); */
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 2px;
  background-image: none;
  border: 1px solid rgb(187, 187, 187);
  overflow: hidden;
  border-radius: 20px;
}

[data-theme-version="dark"] .css-l43idd {
  border: 1px solid rgb(196, 196, 196);

}

.css-1dzn5ey:last-child {
  padding-bottom: 32px;
}

.css-1dzn5ey:last-child {
  padding-bottom: 24px;
}

.css-1dzn5ey {
  padding: 32px 24px;
}

.css-jelo4q {
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.css-1m3w9oh {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;

  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  height: 50px;
  width: 50px;
  background-color: transparent;
  color: rgb(255, 255, 255);
}

.css-1hy9t21 {
  width: 100%;
  height: 100%;
  text-align: center;
  object-fit: cover;
  color: transparent;
  text-indent: 10000px;
}

/*  */
.css-jelo4q> :not(style)~ :not(style) {
  margin: 16px 0px 0px;
}

.css-ow70wi {
  margin: 0px;
  font-weight: 700;
  font-size: 1.0625rem;
  line-height: 1.2;
  /* color: rgb(237, 242, 247); */
}

.css-1jorj1k {
  margin: 32px 0px 16px;
  font-size: 0.875rem;
  line-height: 1.57;
  color: rgb(85, 85, 85);
  text-align: center;
  font-weight: 600;
}

.css-krtfz2 {
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;
  margin-top: -16px;
  width: calc(100% + 16px);
  margin-left: -16px;
  place-content: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
}

/*  */
.css-krtfz2>.MuiGrid-item {
  padding-left: 16px;
}

.css-krtfz2>.MuiGrid-item {
  padding-top: 16px;
}

.css-kdq3hv {
  box-sizing: border-box;
  margin: 0px;
  flex-direction: row;
  flex-basis: 50%;
  -webkit-box-flex: 0;
  flex-grow: 0;
  max-width: 50%;
}

/*  */
.css-qy35p.MuiPaper-elevation1 {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 22px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 0.5px;
}

.css-50upxb {
  margin: 0px;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.66;

  /* color: rgb(237, 242, 247); */
  color: black;
}

[data-theme-version="dark"] .css-50upxb {
  color: rgb(255, 255, 255);

}

.bgdark {
  background-color: #f7f7f7 !important;
}

.bglight {
  border: none !important;
}

.main-modal svg {
  width: 20px;
}

.css-qy35p span {
  color: white;
}

.css-9npne8 {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 32px;
}

.css-1canfvu {
  margin: 0px;
  font-size: 0.75rem;
  line-height: 1.66;
  color: rgb(85, 85, 85);
  font-weight: 700;
}

.css-dbb9ax {
  margin: 0px;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.66;
  text-align: right;
}

.css-j0iiqq {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 8px;
}


.css-qy35p {
  background-color: var(--primary);
  color: rgb(237, 242, 247);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 2px;
  background-image: none;
  overflow: hidden;
  padding: 12px;
  border: 1px solid rgb(99, 102, 241);
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
}

.css-1j9kn1e {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 32px 0px 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 600;

  font-size: 0.875rem;
  line-height: 1.75;
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(255, 255, 255);
  background-color: var(--primary) !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 12px;
  text-transform: none;
  padding: 8px 20px;
  width: 100%;
}

.css-w0pj6f {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  inset: 0px;
  border-radius: inherit;
}

.css-18xyzlx.MuiPaper-elevation1 {
  box-shadow: rgba(0, 0, 0, 0.08) 0px 5px 22px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 0.5px;
}

.css-18xyzlx {
  background-color: transparent;

  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 2px;
  background-image: none;
  overflow: hidden;
  padding: 12px;
  border: 1px solid rgb(187, 187, 187);
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
}

@media (min-width: 0px) {
  .css-xtuv66> :not(style)~ :not(style) {
    margin: 24px 0px 0px;
  }
}

@media (min-width: 600px) {
  .css-kdq3hv {
    flex-basis: 50%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 50%;
  }

  .css-ow70wi {
    font-size: 1.0417rem;
  }

  .css-i9p3im {
    flex-basis: 50%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 50%;
  }
}

@media (min-width: 900px) {
  .css-kdq3hv {
    flex-basis: 50%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 50%;
  }

  .css-ow70wi {
    font-size: 1.0417rem;
  }

  .css-i9p3im {
    flex-basis: 33.3333%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 33.3333%;
  }
}

@media (min-width: 1200px) {
  .css-kdq3hv {
    flex-basis: 50%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 50%;
  }

  .css-ow70wi {
    font-size: 1.0417rem;
  }

  .css-xtuv66> :not(style)~ :not(style) {
    margin: 32px 0px 0px;
  }

  .css-i9p3im {
    flex-basis: 33.3333%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 33.3333%;
  }
}

@media (min-width: 1440px) {
  .css-kdq3hv {
    flex-basis: 50%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 50%;
  }

  .css-i9p3im {
    flex-basis: 33.3333%;
    -webkit-box-flex: 0;
    flex-grow: 0;
    max-width: 33.3333%;
  }
}

/*  */
.css-126xj0f {
  position: fixed;
  z-index: 1300;
  inset: 0px;
}

.css-1p6v7w1:not(.MuiBackdrop-invisible) {
  background-color: rgba(0, 0, 0, 0.5);
}

.css-1p6v7w1 {
  position: fixed;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  inset: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
  z-index: -1;
}

.css-ekeie0 {
  height: 100%;
  outline: 0px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

.text-center {
  text-align: center;
}

.css-maa7c0 {
  background-color: #1a1a1a;
  color: rgb(237, 242, 247);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 25px 50px;
  background-image: none;
  margin: 32px;
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 64px);
  max-width: 600px;
  width: calc(100% - 64px);
}

.css-19d9fw5 {
  margin: 0px;
  font-weight: 700;
  font-size: 1.0625rem;
  line-height: 1.2;
  padding: 16px 48px 16px 24px;
  flex: 0 0 auto;
  color: white;
}

.css-inqsmp {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  font-size: 1.5rem;
  padding: 8px;
  border-radius: 50%;
  overflow: visible;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: absolute;
  color: rgb(158, 158, 158);
  top: 16px;
  right: 12px;
}

.css-vubbuv {
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentcolor;
  flex-shrink: 0;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

.css-w0pj6f {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  inset: 0px;
  border-radius: inherit;
}

.MuiDialogTitle-root+.css-z83ub {
  padding-top: 0px;
}

.css-36lwkk {
  display: flex;
  flex-direction: column;
}

.css-1lnu8xy {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  min-width: 0px;
  padding: 0px;
  margin: 16px 0px 0px;
  border: 0px;
  vertical-align: top;
  width: 100%;
}

.css-58zb7v {
  color: rgb(160, 174, 192);
  line-height: 1.4375em;

  padding: 0px;
  font-size: 14px;
  font-weight: 500;
  display: block;
  transform-origin: left top;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 24px);
  position: absolute;
  left: 0px;
  top: 0px;
  transform: translate(14px, 16px) scale(1);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  z-index: 1;
  pointer-events: none;
}

.d-flexa {
  display: flex;
  align-items: center;
  gap: 5px;
}

.css-1a4ax0g {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4375em;

  color: rgb(237, 242, 247);
  box-sizing: border-box;
  cursor: text;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  position: relative;
  border-radius: 8px;
}

.css-f0guyy {
  font-style: inherit;
  font-variant: inherit;
  font-stretch: inherit;
  font-family: inherit;
  font-optical-sizing: inherit;
  font-kerning: inherit;
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  letter-spacing: inherit;
  color: currentcolor;
  border: 0px;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0px;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 16.5px 14px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.css-100o8dq {
  text-align: left;
  position: absolute;
  inset: -5px 0px 0px;
  margin: 0px;
  padding: 0px 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-color: rgb(45, 55, 72);
}

.css-yjsfm1 {
  float: unset;
  width: auto;
  overflow: hidden;
  display: block;
  padding: 0px;
  height: 11px;
  font-size: 0.75em;
  visibility: hidden;
  max-width: 0.01px;
  transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  white-space: nowrap;
}

.css-yjsfm1>span {
  padding-left: 5px;
  padding-right: 5px;
  display: inline-block;
  opacity: 0;
  visibility: visible;
}

.css-126giv0 {
  color: rgb(160, 174, 192);
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.66;

  text-align: left;
  margin: 3px 14px 0px;
}

.css-36lwkk> :not(style)~ :not(style) {
  margin: 16px 0px 0px;
}

.css-9npne8 {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 32px;
}



.css-dbb9ax {
  margin: 0px;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.66;

  text-align: right;
}

.css-j0iiqq {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 8px;
}

.css-1canfvu {
  margin: 0px;
  font-size: 0.75rem;
  line-height: 1.66;
  color: rgb(85, 85, 85);
  font-weight: 700;
}

.css-dbb9ax {
  margin: 0px;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.66;

  text-align: right;
}

.css-1fk8k1h {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 32px 0px 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 600;

  font-size: 0.875rem;
  line-height: 1.75;
  min-width: 64px;
  color: rgb(255, 255, 255);
  background-color: rgb(99, 102, 241);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 12px;
  text-transform: none;
  padding: 8px 20px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  width: 100%;
}

.css-w0pj6f {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  inset: 0px;
  border-radius: inherit;
}

.css-z83ub {
  flex: 1 1 auto;
  overflow: hidden auto;
  padding: 20px 24px;
}

@media (min-width: 600px) {
  .css-19d9fw5 {
    font-size: 1.0417rem;
  }
}

@media (min-width: 900px) {
  .css-19d9fw5 {
    font-size: 1.0417rem;
  }
}

@media (min-width: 1200px) {
  .css-19d9fw5 {
    font-size: 1.0417rem;
  }
}

.ttrr:hover td {
  background-color: rgb(244, 244, 244) !important;
}

.ttrr {
  cursor: pointer;
}

.badgea {
  padding: 0.25em 0.375em;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1;
  border-radius: 4px;
  text-transform: capitalize;
}

.badge-open {
  color: #fff;
  background-color: #E53E3E;
}

.badge-solved {
  color: #fff;
  background-color: #718096;
  /* background-color: #07bc0c; */
}

/* Staking */
.badge.badgea {
  color: white !important;
}

.axa {
  cursor: pointer !important;
}

.axa:hover {
  text-decoration: underline !important;

}

@media (max-width:700px) {
  .tbleas.tbls100 {

    width: 100% !important;
  }

}

@media (max-width:500px) {

  .maxp,
  .tleft {
    max-width: 250px !important;
  }

  .tbleas,
  .tbb {
    width: max-content !important;
  }
}